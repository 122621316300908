import React, { useEffect, useState } from 'react'
import AddCallLog from './AddCallLog'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { getTicketCallLog } from '../../../apis/apiService'
import { useParams } from 'react-router-dom'
import {ReactComponent as AcceptedPhoneLogo} from "../../../assets/icons/phone-accepted.svg"
import { getCallLogStatuses } from '../../../services/api'
import { Doughnut } from 'react-chartjs-2'
import {Chart as ChartJS, ArcElement, BarElement, Legend, Title, Tooltip } from 'chart.js'
const CallLog = () => {
    const donughntoptions = {
        responsive: true,
        plugins: {
          legend: {
            position: "top",
            display: false,
          },
        },
      };
      ChartJS.register(
        Title,
        Tooltip,
        Legend,
        BarElement,
        ArcElement
      );
     

    const dispatch = useDispatch()
    const { xvi_id } = useSelector(state => state.loginSlice)
    const { ticket_id } = useParams();
    const { isTicketCallLogLoading, ticketCallLogList, ticketCallLog_total_elements,ticketStatusChart } = useSelector(state => state.ticketSlice)


    const [page, setPage] = useState(0)

    useEffect(() => {
        if (ticket_id) {   
            dispatch(getTicketCallLog(xvi_id, page, ticket_id))
        }
    }, [page])
    useEffect(() => {
        if (ticket_id) {   
            dispatch(getCallLogStatuses( ticket_id))
        }
    }, [page])
console.log('ticketStatusChart',ticketStatusChart)
    return (

        <div>
            <div className="flex gap-5 justify-between items-start px-8 pt-8 pb-2 w-full bg-white max-md:flex-wrap max-md:px-5 max-md:max-w-full">
                <div className="flex w-[35%]">
                    <div className="flex flex-col font-medium max-md:mt-10">
                        <AddCallLog />
                        <div className="flex flex-col justify-center p-8 mt-5 w-full text-xs tracking-wide leading-3 text-black rounded-lg border border-solid bg-white bg-opacity-80 border-black border-opacity-10 max-md:px-5">
                            <div className="flex gap-5">
                                <div className="flex flex-col my-auto">
                                    <div className="flex gap-2">
                                        <div className="shrink-0 self-start bg-green-500 h-[13px] rounded-[30px] w-[13px]" />
                                        <div>Answered</div>
                                    </div>
                                    <div className="flex gap-2 mt-5">
                                        <div className="shrink-0 self-start bg-orange-500  h-[13px] rounded-[30px] w-[13px]" />
                                        <div>DIsconnected</div>
                                    </div>
                                    <div className="flex gap-2 mt-5">
                                        <div className="shrink-0 self-start bg-gray-500 h-[13px] rounded-[30px] w-[13px]" />
                                        <div>Busy</div>
                                    </div>
                                    <div className="flex gap-2 mt-5">
                                        <div className="shrink-0 self-start bg-[#E2806B]  h-[13px] rounded-[30px] w-[13px]" />
                                        <div>Not Answered</div>
                                    </div>
                                </div>
                                <div className='max-h-[124px]'>

                                    <Doughnut
            data={{
              labels: ["Answered","Disconnected","Busy","Not Answered"],
              datasets: [
                {
                  label: "Call Status",
                  data: ticketStatusChart?[ticketStatusChart?.answered,ticketStatusChart?.disconnected,ticketStatusChart?.busy,ticketStatusChart?.no_ANSWER,]:[],
                  backgroundColor: ["rgb(34,197,94)","rgb(249,115,22)","rgb(107,114,128)","rgb(226,128,107)"],
                  hoverOffset: 0,
                  borderWidth: 0,
                },
              ],
            }}
            options={donughntoptions}
            height={124}
            // height="156px"
            // width="156px"
          />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="flex w-[65%]">
                    <div className="flex flex-col flex-1 max-md:max-w-full">
                        {
                            ticketCallLogList?.map((v, i) => (
                                <div className="flex gap-4 p-5 bg-white rounded-lg max-md:flex-wrap">
                              { v?.status==="ANSWERED"?<div className="flex justify-center items-center self-start px-2.5 w-10 h-10 bg-green-500 rounded-[100px]">
                                        <AcceptedPhoneLogo />
                                    </div>: v?.status==="NO_ANSWER"?<div className="flex justify-center items-center self-start px-2.5 w-10 h-10 bg-[#E2806B] rounded-[100px]">
                                    <svg width="20" height="20" fill='white' viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg"><path d="M5.333 24c0-4.184 1.4-8.043 3.736-11.16L35.16 38.931c-3.117 2.336-6.976 3.736-11.16 3.736-10.291 0-18.667-8.376-18.667-18.667m37.333 0c0 4.184-1.4 8.043-3.736 11.16L12.84 9.069C15.957 6.733 19.816 5.333 24 5.333c10.291 0 18.667 8.376 18.667 18.667M24 0C10.744 0 0 10.744 0 24s10.744 24 24 24 24-10.744 24-24S37.256 0 24 0" fill-rule="evenodd"/></svg>
                                        </div>
                                            :v?.status==="BUSY"?<div className="flex justify-center items-center self-start px-2.5 w-10 h-10 bg-gray-500 rounded-[100px]">
                                            <svg fill='white' width="20" height="20" viewBox="0 0 1.2 1.2" xmlns="http://www.w3.org/2000/svg"><g data-name="Layer 2"><path fill="none" data-name="invisible box" d="M0 0h1.2v1.2H0z"/><path d="M1.1.2H.875V.102A.05.05 0 0 0 .833.05.05.05 0 0 0 .775.1v.1h-.35V.102A.05.05 0 0 0 .383.05.05.05 0 0 0 .325.1v.1H.1a.05.05 0 0 0-.05.05V.4h1.1V.25A.05.05 0 0 0 1.1.2M.05.5v.55a.05.05 0 0 0 .05.05h1a.05.05 0 0 0 .05-.05V.5Zm.71.34a.05.05 0 0 1 0 .07.05.05 0 0 1-.07 0L.6.82.51.91a.05.05 0 0 1-.07 0 .05.05 0 0 1 0-.07L.53.75.44.66A.05.05 0 0 1 .51.59L.6.68.69.59a.05.05 0 0 1 .07.07L.67.75Z" data-name="icons Q2"/></g></svg>
                                        </div>
                                            :<div className="flex justify-center items-center self-start px-2.5 w-10 h-10 bg-orange-500 rounded-[100px]">
                                        <img
                                            loading="lazy"
                                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/d42a27ccbd7160a93850b51b556f239b6fdb9b6230ac83bc635d0f1c51caecff?apiKey=4c514e50e07d4444bfd3ae3d48b8bd32&"
                                            className="w-5 aspect-square"
                                        />
                                        </div>
                                    }
                                    <div className="flex flex-col flex-1 max-md:max-w-full">
                                        <div className="flex gap-5 justify-between w-full max-md:flex-wrap max-md:max-w-full">
                                            <div className="text-sm font-semibold text-zinc-900">
                                                {v?.name}
                                            </div>
                                            {v?.status==="ANSWERED"?   <div className="flex gap-1.5 self-start text-xs font-medium text-green-600 items-center">
                                               <div className='h-1.5 w-1.5 bg-green-500 rounded-full' />
                                                <div>{v?.status}</div>
                                            </div> :v?.status==="NO_ANSWER"?
                                                <div div className="flex gap-1.5 self-start text-xs font-medium text-[#E2806B] items-center">
                                               <div className='bg-[#E2806B] h-1.5 w-1.5 rounded-full'/>
                                                <div>{v?.status}</div>
                                                </div>:v?.status==="BUSY"?<div div className="flex gap-1.5 self-start text-xs font-medium text-gray-500 items-center">
                                             <div className='w-1.5 h-1.5 rounded-full bg-gray-500' />
                                                <div>{v?.status}</div>
                                                </div>:
                                                <div div className="flex gap-1.5 self-start text-xs font-medium text-orange-600">
                                                <img
                                                    loading="lazy"
                                                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/213016f82c0af6481acda003f7ef10e6d93ab91329c57829a006b253ebf45291?apiKey=4c514e50e07d4444bfd3ae3d48b8bd32&"
                                                    className="shrink-0 my-auto w-1.5 aspect-[0.75]"
                                                />
                                                <div>{v?.status}</div>
                                                </div>
                                            }
                                        </div>
                                        <div className="flex gap-3 pr-20 mt-2 font-medium  text-zinc-800 text-opacity-60 max-md:flex-wrap max-md:pr-5">
                                            <div className="flex gap-1 self-start text-xs">
                                                <div>21.02.2023</div>
                                            </div>
                                        </div>
                                        <div className="mt-2 text-xs  text-ellipsis line-clamp-3 text-zinc-800 text-opacity-60 max-md:max-w-full">
                                            {v?.description}
                                        </div>
                                    </div>
                                </div>
                            ))
                        }


                    </div>

                </div>
            </div>
        </div>

    )
}

export default CallLog