import React, { useEffect } from 'react'
import { IoNotificationsCircle } from 'react-icons/io5'
import { RxCross2 } from 'react-icons/rx'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { getAllNotifications } from '../../services/api'
import axiosConfig from '../../www/http'
import { removeMessage, setNotificationsIsSeen } from '../../redux/slices/notificationSlice'
import moment from 'moment/moment'

const Notification = () => {
    const { notifications } = useSelector((state) => state.notification);
    const dispatch = useDispatch();
    useEffect(() => {
        const user_id = localStorage.getItem("admin_id");
        if (user_id) {
            dispatch(getAllNotifications(user_id))
        }
    }, [dispatch])
    const seenAllNotifications = async() => {
        try {
            notifications?.forEach(async (noti,i) => {
                if (!noti?.isSeen) {   
                    const { data } = await axiosConfig.post(`/notification-seen?notificationId=${noti?.id}`);
                    if (data?.status === "SUCCESS") {
                        dispatch(setNotificationsIsSeen(i))
                    }
                }
            })
    } catch (error) {
        
    }
}
    const removeNotifications = async(id,index) => {
        try {
                    const { data } = await axiosConfig.post(`/notification-touched?notificationId=${id}`);
                    if (data?.status === "SUCCESS") {
                        dispatch(removeMessage(index))
                }
    } catch (error) {
        
    }
    }
    function getDifference(date, time) {
        const createdAt = `${date?.split("-")[2]}-${date?.split("-")[1]}-${
          date?.split("-")[0]
        }`;
        let then = moment(`${createdAt} ${time}`, "YYYY-MM-DD HH:mm:ss");
        let now = moment(new Date(), "YYYY-MM-DD HH:ss:ss");
    
        let duration = moment.duration(now.diff(then));
        if (duration.isValid()) {
          const hour = Math.abs(Math.trunc(duration.asHours()));
          let minute = Math.abs(Math.trunc(duration.asMinutes()));
          const day = Math.abs(Math.trunc(duration.asDays()));
          return { day, hour, minute };
        } else {
          return { day: 0, hour: 0, minute: 0 };
        }
      }
    return (

        <>
            <div className='cursor-pointer flex relative' data-hs-overlay="#notification-modal">
                <IoNotificationsCircle fill='white' size={20} />
       {notifications?.filter((noti)=>noti?.isSeen===false)?.length>0  &&         <span className='text-white bg-red-500 absolute -top-2 -right-3 h-5 w-5 rounded-full text-center '>{notifications?.filter((noti)=>noti?.isSeen===false)?.length>9? "9+":notifications?.filter((noti)=>noti?.isSeen===false)?.length}</span>}
            </div>


            <div id="notification-modal" class="hs-overlay hidden size-full fixed top-0 start-0 z-[80] overflow-x-hidden overflow-y-auto pointer-events-none">
                <div class="hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 mt-0 opacity-0 ease-out transition-all sm:max-w-[45%] sm:w-full m-3 sm:mx-auto min-h-[calc(100%-3.5rem)] flex items-center">
                    <div class="w-full flex flex-col bg-white border shadow-sm rounded-xl pointer-events-auto">

              {notifications?.length?          <div className="flex flex-col pb-3  bg-white rounded-3xl ">

                            <div className="flex  justify-between self-center p-5 w-full  max-md:flex-wrap max-md:max-w-full">
                                <div className="text-xl font-bold text-zinc-800">Notifications</div>
                        {notifications?.filter((noti)=>noti?.isSeen===false)?.length>0?        <div className="text-sm leading-5 text-neutral-500 cursor-pointer" onClick={seenAllNotifications}>Mark all read</div>:<div className="text-sm leading-5 text-gray-400 cursor-not-allowed" >Mark all read</div>}
                            </div>


                            {
                                notifications?.map((noti,index)=>(
                                    <div className="flex justify-between  px-6 py-4  border-t border-b border-solid border-zinc-800 border-opacity-10 max-md:flex-wrap max-md:px-5 items-center">
                                        <div className=' flex gap-5'>
                                            <div className="shrink-0 w-12 h-12 bg-gray-300 rounded-full flex justify-center items-center text-black text-lg" >
                                                { noti?.notificationStatus?.slice(0,1)}
                                            </div>


                                            <div className="flex flex-col self-stretch pr-10 max-md:max-w-full">
                                                <div className=" font-bold leading-5 text-neutral-800 max-md:max-w-full">
                                                    {noti?.subject}
                                                </div>
                                                <div className=" font-medium leading-5 text-gray-400 max-md:max-w-full">
                                                    {noti?.description}
                                                </div>
                                                <div className="mt-1 text-sm leading-4 text-neutral-500 max-md:max-w-full">
                                                {getDifference(
                                  noti?.createdAt?.split(" ")[0],
                                  noti?.createdAt?.split(" ")[1]
                                ).minute < 1
                                  ? "just now"
                                  : getDifference(
                                    noti?.createdAt?.split(" ")[0],
                                    noti?.createdAt?.split(" ")[1]
                                    ).hour < 1
                                  ? `${
                                      getDifference(
                                        noti?.createdAt?.split(" ")[0],
                                        noti?.createdAt?.split(" ")[1]
                                      ).minute
                                    } minute ago`
                                  : getDifference(
                                    noti?.createdAt?.split(" ")[0],
                                    noti?.createdAt?.split(" ")[1]
                                    ).day < 1
                                  ? `${
                                      getDifference(
                                        noti?.createdAt?.split(" ")[0],
                                        noti?.createdAt?.split(" ")[1]
                                      ).hour
                                    } hour ago`
                                  : `${
                                      getDifference(
                                        noti?.createdAt?.split(" ")[0],
                                        noti?.createdAt?.split(" ")[1]
                                      ).day
                                    } day ago`}

                                                </div>
                                            </div>
                                        </div>
                                        <div className='bg-white cursor-pointer' onClick={()=>removeNotifications(noti?.id,index)}>
                                            <RxCross2 />
                                        </div>

                                    </div>
                                ))
                            }
                            {/* <div className="justify-center items-start px-8 py-5 w-full text-sm font-medium border-t border-solid bg-black bg-opacity-0 border-black border-opacity-10 text-slate-700 max-md:px-5 max-md:max-w-full">
                                Previous notifications
                            </div> */}
                        </div> : <div className='flex flex-col gap-3 py-3'>
                        <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/0259d5f12e1a4f269e53600915b7dfff7044ec1e4bde5d6e7d60b365d087023e?"
                  className="self-stretch w-[60%] m-auto aspect-square"
                                />  
                                <span className='text-xl text-black/40 m-auto'>No New Notifications</span>
                        </div>}

                        {/* --------------------------------------------------------------------------------- */}




                    </div>
                </div>
            </div>
        </>

    )
}

export default Notification