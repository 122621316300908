import { createSlice } from "@reduxjs/toolkit";

const notificationSlice = createSlice({
    name: "notification",
    initialState: {
        notifications: [],
        isLoading:false,
    },
    reducers: {
        getAllNotificationsRequest: (state) => {
            state.isLoading = true;
        },
        getAllNotificationsSuccess: (state,{payload}) => {
            state.isLoading = false;
            state.notifications = payload;
        },
        getAllNotificationsFail: (state) => {
            state.isLoading = false;
        },
        setNotificationsIsSeen: (state,{payload}) => {
            state.notifications[payload].isSeen = true
        },
        addNewMessage: (state, { payload }) => {
            if (!state.notifications.find((noti) => noti?.id === payload?.id)) {   
                state.notifications = [payload,...state.notifications]
            }
        },
        removeMessage: (state, { payload }) => {
            state.notifications = state.notifications.filter((_, i) => i !== payload);
        }
    }
})

export default notificationSlice.reducer;
export const {getAllNotificationsFail,getAllNotificationsRequest,getAllNotificationsSuccess,addNewMessage,removeMessage,setNotificationsIsSeen } = notificationSlice.actions;