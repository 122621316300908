import React, { useEffect } from 'react'
import { Dialog, Transition, Combobox } from '@headlessui/react'
import { Fragment, useState } from 'react'
import { GoPlus } from 'react-icons/go'
import { useDispatch } from 'react-redux'
import { getAllLeadsList, getAllLeadslistType, getLeadsContactList, getLeadsContactListAll, getLeadsContactListMy, getMyLeadsList, getSalesAllContact, getSalesMyContact } from '../../../../apis/apiService'
import { useSelector } from 'react-redux'
import { BiSolidCheckboxChecked } from "react-icons/bi";
import { useFormik } from 'formik'
import axiosConfig from '../../../../www/http'
import { notifyErrorMessage, notifySuccessMessage } from '../../../../components/utils/toastMessage'
import AccessComponent from '../../../../components/utils/checkAccess'
import { AccessIds, not_access_Message } from '../../../../components/utils/allIdList'
import { MdOutlineEdit } from 'react-icons/md'
import * as Yup from 'yup';


const SubmissionToLead = ({ submissionId }) => {

    const [isOpen, setIsOpen] = useState(false)
    const dispatch = useDispatch()

    const { listLeadType, leadsAllContactList } = useSelector(state => state.salesSlice)
    const { empDetails, xvi_id, branchId } = useSelector(state => state.loginSlice)


    console.log("submissionId", submissionId);


    const ValidationSchema = Yup.object().shape({
        leadTypeId: Yup.string().required('Lead Type is required'),
    });






    const initialValues = {
        account: "",
        leadTypeId: "",
    }


    const handleSelectAgentId = (id) => {
        const selected = listLeadType.find(elm => elm?.leadTypeId === id)
        console.log("SELECTEDDDDDDDDDDD", selected);//leadTypeId
        setFieldValue("leadTypeId", selected?.leadTypeId)
    }




    const { values, handleBlur, handleChange, handleSubmit, errors, touched, setFieldValue, resetForm } = useFormik({
        initialValues: initialValues,
        validationSchema: ValidationSchema,

        onSubmit: async (values) => {

            console.log("errors...............", errors);


            const formdata = new FormData()
            formdata.append("account", values?.account)
            formdata.append("branch", branchId)
            formdata.append("leadTypeId", values?.leadTypeId)
            formdata.append("submissionId", submissionId)

            // CALL THE API HERE
            try {
                const { data } = await axiosConfig.post(`/form/convert-submission-to-lead`, formdata, {
                    headers: {
                        "X-V-I": `${xvi_id}`
                    }
                })
                if (data?.status === "SUCCESS") {
                    notifySuccessMessage(data?.message)
                    resetForm()
                    setIsOpen(false)
                }

            } catch (error) {
                notifyErrorMessage(error.message)
                console.log("Faileddddddddddddddddd", error);
            }


        }
    })







    return (
        <>

            <button type="button" onClick={() => setIsOpen(true)}>
                <div className='cursor-pointer  absolute right-0 flex flex-row-reverse p-2 top-10 '>
                    <MdOutlineEdit size={18} />
                </div>
            </button>


            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={() => { }}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black/25" />
                    </Transition.Child>



                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-[420px]  transform overflow-hidden rounded-2xl bg-white  text-left align-middle shadow-xl transition-all">


                                    {/* ---------------------------------------------------------------- */}

                                    <div class="w-full max-h-full overflow-y-auto flex flex-col bg-white rounded-xl pointer-events-auto dark:bg-neutral-800 dark:border-neutral-700 dark:shadow-neutral-700/70">
                                        <div className="flex flex-col max-w-screen-md font-medium bg-white rounded">
                                            <div className="flex flex-col p-4 bg-[#1B99A1] text-white max-md:px-5 max-md:max-w-full">
                                                <div className="text-2xl max-md:max-w-full">convert-submission-to-lead</div>
                                                <div className="mt-1 text-xs max-md:max-w-full">

                                                </div>
                                            </div>

                                            <div className="flex flex-col self-stretch p-8 max-w-full">




                                                <div className="mt-3 text-sm font-medium leading-5 text-black">
                                                    Account <span className="text-orange-600">*</span>
                                                </div>
                                                <input value={values?.account} onChange={handleChange("account")} type='text' placeholder='Enter Account Number' className="shrink-0 mt-1 py-1.5 px-3 rounded border border-solid border-black border-opacity-20 max-md:max-w-full outline-none" />





                                                <div className="mt-3 text-sm font-medium leading-5 text-zinc-800">
                                                    Lead type
                                                </div>
                                                <div className="flex flex-col justify-center items-end px-3 py-2 mt-1 rounded border border-solid border-black border-opacity-20">
                                                    <select className='w-full outline-none' onChange={(e) => handleSelectAgentId(e.target.value)}>
                                                        <option value="">Select</option>
                                                        {
                                                            listLeadType?.map((v, i) => (
                                                                <option key={i} value={v?.leadTypeId}>{v?.leadTypeName}</option>
                                                            ))
                                                        }
                                                    </select>
                                                </div>

                                                {
                                                    (errors?.leadTypeId && touched?.leadTypeId) &&
                                                    <p className='text-red-700 font-sans pt-2'>{errors?.leadTypeId}</p>
                                                }




                                                <div className="flex justify-end gap-2.5 pl-20 mt-5 font-medium whitespace-nowrap">
                                                    <button type="button" onClick={() => setIsOpen(false)} className="justify-center px-4 py-1.5 text-xs leading-5 rounded-md border border-solid border-black border-opacity-20 text-zinc-800">
                                                        Cancel
                                                    </button>
                                                    <div onClick={handleSubmit} className="cursor-pointer justify-center px-4 py-1.5 text-sm leading-5 text-white rounded-md bg-slate-600">
                                                        Save
                                                    </div>
                                                </div>


                                            </div>
                                        </div>
                                    </div>


                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>


                </Dialog>
            </Transition>
        </>
    )
}

export default SubmissionToLead