import { useSelector } from "react-redux";

// Custom hook to check access
export const useCheckAccess = (id) => {
    const { modules } = useSelector(state => state.loginSlice);
    const hasAccess = modules.includes(id);
    return hasAccess;
}


const AccessComponent = (code) => {
    const hasAccess = useCheckAccess(code);
    return hasAccess ? true : false
}

export default AccessComponent;
