import React, { useEffect, useState } from 'react'

import { ReactComponent as SquareIcon } from "../../../../assets/squareicon.svg"
import { useNavigate } from 'react-router-dom'
import AddCallLog from './AddCallLog'
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { MdDone, MdEdit } from 'react-icons/md'
import { useFormik } from 'formik'
import Template from '../../../../components/common/Template'
import { getLeadDetails } from '../../../../apis/apiService'
import Loader from '../../../../components/Loader/loader'
import { leadDetailsValidation } from '../../../../Validation/leadDetailsSchema'
import { notifyErrorMessage, notifySuccessMessage } from '../../../../components/utils/toastMessage'
import axiosConfig from '../../../../www/http'
import { SalesWhatsapp } from './whatsapp'
import { SalesCallLog } from './callLog'
import { SalesAttachment } from './attachment'
import { IoSearchSharp } from 'react-icons/io5';


const LeadsDetails = () => {

    const { xvi_id } = useSelector(state => state.loginSlice)
    const { isLeadDetailsLoading, leadDetails, leadsList } = useSelector(state => state.salesSlice)
    const { assignedEmployeeList } = useSelector(state => state.campaign)

    const navigate = useNavigate();
    const { lead_id } = useParams();

    const dispatch = useDispatch()

    const [activeTab, setActiveTab] = useState(1)
    const [edit, setEdit] = useState(true)
    const [query, setQuery] = useState(null)

    useEffect(() => {
        dispatch(getLeadDetails({ xvi_id, page: 0, lead_id }))
    }, [])

    console.log("leadDetails..........................", leadDetails?.assignTo);
    // console.log("assignRoleList...........................!!", assignRoleList);

    useEffect(() => {
        setFieldValue("channel", leadDetails?.channel)
        setFieldValue("probability", leadDetails?.probability)
        setFieldValue("registrationMethod", leadDetails?.registrationMethod)
        setFieldValue("registrationMethod", leadDetails?.source)
    }, [])

    let initialValues = {
        channel: leadDetails?.channel,
        probability: leadDetails?.probability,
        registrationMethod: leadDetails?.registrationMethod,
        source: leadDetails?.source
    }

    const { values, handleBlur, handleChange, handleSubmit, errors, touched, setFieldValue, resetForm } = useFormik({
        initialValues: initialValues,
        validationSchema: leadDetailsValidation,
        onSubmit: async (values) => {


            setEdit(true)
            console.log("Valuessss..................", values);



            const formdata = new FormData()
            formdata.append("channel", values?.channel)
            formdata.append("leadId", lead_id)
            formdata.append("probability", values?.probability)
            formdata.append("registrationMethod", values?.registrationMethod)
            formdata.append("source", values?.source)

            // CALL THE API HERE
            try {
                const { data } = await axiosConfig.put(`/lead/edit-lead`, formdata, {
                    headers: {
                        "X-V-I": `${xvi_id}`
                    }
                })
                console.log("Successsssssss..............", data);

                if (data?.status === "SUCCESS") {
                    notifySuccessMessage(data?.message)
                    resetForm()
                    setEdit(true)
                    dispatch(getLeadDetails({ xvi_id, page: 0, lead_id }))
                }

            } catch (error) {
                notifyErrorMessage(error.message)
                console.log("Faileddddddddddddddddd", error);
            }

        }
    })


    const handleSelectType = async (id) => {
        const filterdata = assignedEmployeeList.find(v => v?.roleAssignId == id)
        if (filterdata?.roleAssignId) {
            const formdata = new FormData()
            formdata.append("roleAssignId ", filterdata?.roleAssignId)
            formdata.append("leadId", lead_id)
            // Call Api Here
            try {
                const { data } = await axiosConfig.put(`/lead/assign-to-lead`, formdata, {
                    headers: {
                        "X-V-I": `${xvi_id}`
                    }
                })
                console.log("Successsssssss..............", data);

                if (data?.status === "SUCCESS") {
                    notifySuccessMessage(data?.message)
                }

            } catch (error) {
                notifyErrorMessage(error.message)
                console.log("Faileddddddddddddddddd", error);
            }
        }
    }



    return (
        <Template>

            {/* Header */}
            <div className="flex flex-col justify-center self-stretch px-36 py-6 bg-white border-b border-solid border-black border-opacity-10 max-md:px-5">
                <div className="flex gap-4 text-xs text-zinc-800 max-md:flex-wrap max-md:max-w-full ">
                    <div className="flex flex-col flex-1 items-start pr-20 max-md:max-w-full">
                        <div onClick={() => navigate(-1)} className="cursor-pointer flex gap-2 whitespace-nowrap">
                            <img
                                loading="lazy"
                                src="https://cdn.builder.io/api/v1/image/assets/TEMP/c33b6394a2688e242a9a40efb60dcf06e2a30f986b9f31c9c94280bcfa77d2a6?apiKey=4c514e50e07d4444bfd3ae3d48b8bd32&"
                                className="shrink-0 w-4 aspect-square"
                            />
                            <div>Back</div>
                        </div>
                        <div className="mt-2 text-2xl font-semibold capitalize text-slate-950">
                            {leadDetails?.needs}
                        </div>
                        <div className="mt-1 font-medium">
                            {leadDetails?.contact?.name}
                        </div>
                    </div>
                    <img
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/2423b66c833379e0b1bf7137d335703b83396dacd2d8bdf9e8a5ac65c623a1eb?apiKey=4c514e50e07d4444bfd3ae3d48b8bd32&"
                        className="my-auto aspect-[16.67] w-[487px] max-md:max-w-full"
                    />
                </div>
                <div className="flex gap-2 self-end">
                    <div className="my-auto text-xs font-semibold leading-4 text-zinc-800 text-opacity-60">
                        Assign to
                    </div>
                    <div className="flex gap-2 py-2 pr-3 pl-2 text-sm font-medium rounded border border-solid border-black border-opacity-20 text-zinc-800">
                        <select className='w-full outline-none' value={leadDetails?.assignToId} onChange={(e) => handleSelectType(e.target.value)}>
                            <option className='p-3' value="">Select</option>
                            {
                                assignedEmployeeList?.map((v, i) => (
                                    <option className='p-3' key={i} value={v?.roleAssignId}>{v?.employeeName}</option>
                                ))
                            }
                        </select>
                    </div>
                </div>
            </div>

            {
                isLeadDetailsLoading ?
                    <Loader />
                    :
                    <div className=' max-w-screen-xl m-auto'>
                        <nav className="flex gap-2 items-start  font-semibold bg-[#DBDFEC] max-md:flex-wrap max-md:pr-5 px-2 mt-10 rounded-t-lg" >

                            <button type="button" onClick={() => setActiveTab(1)}>
                                <div className={`justify-center self-stretch py-3  text-xs tracking-wider leading-5 uppercase  ${activeTab === 1 ? "bg-white" : "bg-[#E7ECF6]"} px-7 mt-4 items-center rounded-t-xl`}>
                                    Details <span className='bg-orange-600  py-1 px-3 rounded-full text-white font-semibold'>7</span>
                                </div>
                            </button>

                            <button type="button" onClick={() => setActiveTab(2)}>
                                <div className={`justify-center self-stretch py-3  text-xs tracking-wider leading-5 uppercase  ${activeTab === 2 ? "bg-white" : "bg-[#E7ECF6]"} px-7 mt-4 items-center rounded-t-xl`}>
                                    WhatsApp <span className='bg-orange-600  py-1 px-3 rounded-full text-white font-semibold'>7</span>
                                </div>
                            </button>


                            <button type="button" onClick={() => setActiveTab(3)}>
                                <div className={`justify-center self-stretch py-3  text-xs tracking-wider leading-5 uppercase  ${activeTab === 3 ? "bg-white" : "bg-[#E7ECF6]"} px-7 mt-4 items-center rounded-t-xl`}>
                                    Phone Calls <span className='bg-orange-600  py-1 px-3 rounded-full text-white font-semibold'>7</span>
                                </div>
                            </button>

                            <button type="button" onClick={() => setActiveTab(4)}>
                                <div className={`justify-center self-stretch py-3  text-xs tracking-wider leading-5 uppercase  ${activeTab === 4 ? "bg-white" : "bg-[#E7ECF6]"} px-7 mt-4 items-center rounded-t-xl`}>
                                    Attachments <span className='bg-orange-600  py-1 px-3 rounded-full text-white font-semibold'>7</span>
                                </div>
                            </button>








                        </nav>
                        <div>


                            {
                                activeTab === 1 &&
                                <div >
                                    <div className='bg-white px-8 py-5 flex flex-col'>
                                        <div className="max-md:max-w-full">
                                            <div className="flex gap-5 max-md:flex-col max-md:gap-0">
                                                <div className="flex flex-col w-[68%] max-md:ml-0 max-md:w-full">

                                                    {/* Details Box */}

                                                    <div className="flex flex-col grow self-stretch p-6 w-full  rounded-3xl bg-[#F0F2F5]">

                                                        {
                                                            edit ?
                                                                <div onClick={() => setEdit(false)} className=' w-full flex justify-end'>
                                                                    <MdEdit size={18} />
                                                                </div>
                                                                :
                                                                <div onClick={handleSubmit} className=' w-full flex justify-end'>
                                                                    <MdDone color='green' size={26} />
                                                                </div>

                                                        }

                                                        {/* 1st Row */}
                                                        <div className="flex gap-6 justify-between   my-3">
                                                            <div className="flex flex-col font-semibold w-[25%] mt-2">
                                                                <p className=" leading-4 text-zinc-800 ">Customer need</p>
                                                                <p className="text-sm leading-5 text-zinc-800">{leadDetails?.needs}</p>
                                                            </div>


                                                            <div className="flex flex-col justify-center  w-[25%]">
                                                                <p className="p-2 leading-4 text-zinc-800 ">Lead type</p>
                                                                <input value={leadDetails?.leadType?.leadTypeName} className="outline-none flex gap-2.5 justify-center px-3 py-2 text-sm font-medium whitespace-nowrap bg-white rounded border border-solid border-black border-opacity-20 text-zinc-800" />
                                                            </div>

                                                            <div className="flex flex-col justify-center w-[25%] ">
                                                                <p className="p-2 leading-4 text-zinc-800 ">Created on</p>
                                                                <input value={leadDetails?.createdAt} className="outline-none flex gap-2.5 justify-center px-3 py-2 text-sm font-medium whitespace-nowrap bg-white rounded border border-solid border-black border-opacity-20 text-zinc-800" />
                                                            </div>


                                                            <div className="flex flex-col justify-center  w-[25%]">
                                                                <p className="p-2 leading-4 text-zinc-800 ">Created by</p>
                                                                <input value={leadDetails?.createdBy} className="outline-none flex gap-2.5 justify-center px-3 py-2 text-sm font-medium whitespace-nowrap bg-white rounded border border-solid border-black border-opacity-20 text-zinc-800" />
                                                            </div>
                                                        </div>

                                                        {/* 2nd Row */}
                                                        <div className="flex gap-6 justify-between   my-3">
                                                            <div className="flex flex-col font-semibold w-[25%] mt-2">
                                                                <p className=" leading-4 text-zinc-800 ">Contact</p>
                                                                <p className="text-sm leading-5 text-zinc-800">{leadDetails?.contact?.name}</p>
                                                            </div>


                                                            <div className="flex flex-col justify-center  w-[25%]">
                                                                <p className="p-2 leading-4 text-zinc-800 ">Registration Method</p>
                                                                <input disabled={edit ? true : false} value={values?.registrationMethod} onChange={handleChange("registrationMethod")} className="outline-none flex gap-2.5 justify-center px-3 py-2 text-sm font-medium whitespace-nowrap bg-white rounded border border-solid border-black border-opacity-20 text-zinc-800" />
                                                                {
                                                                    (errors?.registrationMethod && touched?.registrationMethod) &&
                                                                    <p className='text-red-700 font-medium leading-6 text-xs  '>{errors?.registrationMethod}</p>
                                                                }
                                                            </div>

                                                            <div className="flex flex-col justify-center w-[25%] ">
                                                                <p className="p-2 leading-4 text-zinc-800 ">Source</p>
                                                                <input disabled={edit ? true : false} value={values.source} onChange={handleChange("source")} className="outline-none flex gap-2.5 justify-center px-3 py-2 text-sm font-medium whitespace-nowrap bg-white rounded border border-solid border-black border-opacity-20 text-zinc-800" />
                                                                {
                                                                    (errors?.source && touched?.source) &&
                                                                    <p className='text-red-700 font-medium leading-6 text-xs  '>{errors?.source}</p>
                                                                }
                                                            </div>


                                                            <div className="flex flex-col justify-center  w-[25%]">
                                                                <p className="p-2 leading-4 text-zinc-800 ">Predictive probability</p>
                                                                <input type='number' disabled={edit ? true : false} value={values?.probability} onChange={handleChange("probability")} className="outline-none flex gap-2.5 justify-center px-3 py-2 text-sm font-medium whitespace-nowrap bg-white rounded border border-solid border-black border-opacity-20 text-zinc-800" />
                                                                {
                                                                    (errors?.probability && touched?.probability) &&
                                                                    <p className='text-red-700 font-medium leading-6 text-xs  '>{errors?.probability}</p>
                                                                }
                                                            </div>
                                                        </div>


                                                        {/* 3rd Row */}
                                                        <div className="flex gap-6 justify-between   my-3">
                                                            <div className="flex flex-col font-semibold w-[25%] mt-2">
                                                                <p className="leading-4 text-zinc-800 ">Account</p>
                                                                <p className="text-sm leading-5 text-zinc-800">68769679778987986</p>
                                                            </div>


                                                            <div className="flex flex-col justify-center  w-[25%]">
                                                                <p className="p-2 leading-4 text-zinc-800 ">Assignees group</p>
                                                                <div className="outline-none h-9 flex gap-2.5 justify-center px-3 py-2 text-sm font-medium whitespace-nowrap bg-white rounded border border-solid border-black border-opacity-20 text-zinc-800" >
                                                                    {leadDetails?.assignGroup}
                                                                </div>
                                                            </div>

                                                            <div className="flex flex-col justify-center w-[25%] ">
                                                                <p className="p-2 leading-4 text-zinc-800 ">channel</p>
                                                                <input disabled={edit ? true : false} value={values?.channel} onChange={handleChange("channel")} className="outline-none flex gap-2.5 justify-center px-3 py-2 text-sm font-medium whitespace-nowrap bg-white rounded border border-solid border-black border-opacity-20 text-zinc-800" />
                                                                {
                                                                    (errors?.channel && touched?.channel) &&
                                                                    <p className='text-red-700 font-medium leading-6 text-xs  '>{errors?.channel}</p>
                                                                }
                                                            </div>


                                                            <div className="flex flex-col justify-center  w-[25%]">
                                                                <p className="p-2 leading-4 text-zinc-800 ">Sales channel</p>
                                                                <div className="outline-none h-9 flex gap-2.5 justify-center px-3 py-2 text-sm font-medium whitespace-nowrap bg-white rounded border border-solid border-black border-opacity-20 text-zinc-800" >
                                                                    {leadDetails?.salesChannel}
                                                                </div>
                                                            </div>
                                                        </div>





                                                        <div className="mt-6 text-xs font-semibold leading-4 text-zinc-800 text-opacity-60 max-md:max-w-full">
                                                            Comments
                                                        </div>

                                                        <div className="shrink-0 bg-white rounded border border-solid border-black border-opacity-20 h-[66px] max-md:max-w-full" >
                                                            <p className='p-2' >{leadDetails?.comments}</p>
                                                        </div>

                                                    </div>


                                                </div>
                                                <div className="flex flex-col ml-5 w-[32%] max-md:ml-0 max-md:w-full">
                                                    <div className="flex flex-col grow justify-between">


                                                        <div onClick={() => setActiveTab(2)} className="flex gap-5 pl-8 bg-blue-100 rounded-3xl my-2 cursor-pointer">
                                                            <div className="flex flex-col flex-1 justify-center my-auto">
                                                                <div className="text-sm font-medium leading-5 text-zinc-800">
                                                                    WhatsApp{" "}
                                                                </div>
                                                                <div className="mt-2 text-3xl font-semibold text-black">0</div>
                                                            </div>


                                                            <div className="flex flex-col justify-center items-center py-10 px-16  bg-sky-400  rounded-tr-3xl rounded-br-3xl">
                                                                <img
                                                                    loading="lazy"
                                                                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/4bb94e81995378f7e3b38ae67e0ece1e11a36dd03ee9eb0adeed72674966e0c0?apiKey=4c514e50e07d4444bfd3ae3d48b8bd32&"
                                                                    className="self-end w-4 aspect-square"
                                                                />
                                                                <div className='pb-5'>
                                                                    <img
                                                                        loading="lazy"
                                                                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/e4141d24032fbc87e8d95b21949cb0f4c53b466ffffe6774c7c1fecb4ce0495b?apiKey=4c514e50e07d4444bfd3ae3d48b8bd32&"
                                                                        className="mt-7 w-12 aspect-square"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div onClick={() => setActiveTab(3)} className="flex gap-5 pl-8  bg-violet-200 rounded-3xl my-2 cursor-pointer">
                                                            <div className="flex flex-col flex-1 justify-center my-auto">
                                                                <div className="text-sm font-medium leading-5 text-zinc-800">
                                                                    Outgoing calls
                                                                </div>
                                                                <div className="mt-2 text-3xl font-semibold text-black">0</div>
                                                            </div>
                                                            <div className="flex flex-col justify-center items-center py-10 px-16  bg-slate-500 max-md:pl-5 rounded-tr-3xl rounded-br-3xl">

                                                                <img
                                                                    loading="lazy"
                                                                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/4bb94e81995378f7e3b38ae67e0ece1e11a36dd03ee9eb0adeed72674966e0c0?apiKey=4c514e50e07d4444bfd3ae3d48b8bd32&"
                                                                    className="self-end w-4 aspect-square"
                                                                />

                                                                <div className=' pb-5'>
                                                                    <img
                                                                        loading="lazy"
                                                                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/faf8d5c334c61f890e95e07ef24be1befb793a52ae7ce6616d8fcb496d9d65fd?apiKey=4c514e50e07d4444bfd3ae3d48b8bd32&"
                                                                        className="mt-7 w-12 aspect-square"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>


                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* New */}
                                        <div className='h-full w-[100%]  flex justify-center items-center flex-col mt-7 mb-16  border border-gray-300 rounded-2xl'>
                                            <div className='w-full  flex items-center p-4 rounded-t-2xl'>
                                                <h1 className='text-black font-semibold w-[20%]'>Similar leads</h1>
                                                <div className="flex gap-2 px-2.5 py-2 rounded-md shadow-lg bg-[#0000000a] items-center justify-start  text-slate-950 w-80">
                                                    <IoSearchSharp size={17} color='gray' />
                                                    <input
                                                        value={query}
                                                        onChange={(e) => setQuery(e?.target?.value)}
                                                        placeholder='Search here'
                                                        className='w-full p-1 outline-none bg-transparent text-sm font-semibold text-black'
                                                    />
                                                </div>
                                            </div>
                                            <div className='w-full bg-black flex items-center '>
                                                <h1 className='text-white font-semibold flex-1 border-r py-2 pr-7 pl-5'>Needs / Comments</h1>
                                                <h1 className='text-white font-semibold flex-1 px-5'>Contact</h1>
                                                <h1 className='text-white font-semibold flex-1'>Account</h1>
                                                <h1 className='text-white font-semibold flex-1'>Created on</h1>
                                            </div>
                                            <div className='bg-white p-1 w-full rounded-b-xl shadow-xl'>

                                                {
                                                    leadsList.map((v, i, arr) => (
                                                        <div className={`w-full bg-green flex justify-between items-center   bg-white ${i + 1 === arr.length ? "" : "border-b"}`} onClick={() => navigate(`/leads-details/${v?.id}`)}>
                                                            <div className='flex items-center gap-3 flex-1 border-r p-5 mr-5'>
                                                                <div className=''>
                                                                    <h1 className='text-[#154EE0] font-semibold cursor-pointer'>{v?.needs}</h1>
                                                                    <h1 className='text-[#27292D] font-semibold cursor-pointer'>{v?.comments}</h1>
                                                                </div>
                                                            </div>
                                                            <h1 className='text-[#154EE0] font-semibold flex-1'>{v?.contact?.name}</h1>
                                                            <h1 className='text-[#154EE0] font-semibold flex-1'>{v?.account ? v?.account : "N/A"}</h1>
                                                            <h1 className='text-black font-semibold flex-1'>{v?.createdAt}</h1>
                                                        </div>
                                                    ))
                                                }
                                            </div>


                                        </div>
                                    </div>

                                </div>

                            }

                            {
                                activeTab === 2 &&

                                <SalesWhatsapp />

                            }

                            {
                                activeTab === 3 &&
                                <SalesCallLog />
                            }

                            {
                                activeTab === 4 &&
                                <SalesAttachment />
                            }




                        </div>

                    </div>
            }




        </Template>
    )
}

export default LeadsDetails