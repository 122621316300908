import React from 'react';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="flex gap-5 justify-between items-start px-20 pt-12 pb-10 text-xs text-zinc-800 max-md:flex-wrap max-md:px-5">
        <div className="flex gap-1">
          <div>Copyright © 2024 Bank CRM.</div>
          <div>All Rights Reserved</div>
        </div>
        <div className="flex gap-4 justify-center">
          <div>Follow us</div>
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/53e972ca7a3f2843a5af0810e4304fc39fd56ba69dde2d63fc4828f084033b03?apiKey=4c514e50e07d4444bfd3ae3d48b8bd32&"
            className="shrink-0 w-4 aspect-square"
            alt="Social Icon"
          />
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/bdd6d22a87c45813dd510e171dccedc8a4cb38b0f0312b0b07b84417c667742a?apiKey=4c514e50e07d4444bfd3ae3d48b8bd32&"
            className="shrink-0 w-4 aspect-square"
            alt="Social Icon"
          />
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/407fe1b6841f16498004662eab2fa1bfacb9754de7c95f18cf76f026c5179041?apiKey=4c514e50e07d4444bfd3ae3d48b8bd32&"
            className="shrink-0 w-4 aspect-square"
            alt="Social Icon"
          />
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/3dcb60a4f4b408b652e5461f353a6a694ec3a4153fe8e8f3c8d9dbfca6ed7eab?apiKey=4c514e50e07d4444bfd3ae3d48b8bd32&"
            className="shrink-0 w-4 aspect-square"
            alt="Social Icon"
          />
        </div>
      </div>
    </footer>
  );
}

export default Footer;
