import * as Yup from 'yup';

export const SaveTicketValidation = Yup.object().shape({
    type: Yup.string().required('Type is required'),
    name: Yup.string().required('Name is required'),
    email: Yup.string().email('Invalid email address').required('Email is required'),
    phone: Yup.string()
        .matches(/^[0-9]{10}$/, 'Mobile phone must be exactly 10 digits')
        .required('Mobile phone is required'),
    subject: Yup.string().required('Subject is required'),
    isUpload: Yup.string().required('Image required'),
    message: Yup.string().required('Message is required'),
});
