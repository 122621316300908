import React, { useEffect, useState } from 'react'
import Template from '../../components/common/Template'
import { ReactComponent as SquareIcon } from "../../assets/squareicon.svg"
import AddTicket from './AddTicket'
import { useNavigate } from 'react-router-dom'
import { IoSearchSharp } from 'react-icons/io5'
import AccessComponent from '../../components/utils/checkAccess'
import { AccessIds } from '../../components/utils/allIdList'
import { useDispatch } from 'react-redux'
import { getCustomerTicketListAll, getCustomerTicketListMy, getTicketStatusAdmin, getTicketStatusEmployee } from '../../apis/apiService'
import { useSelector } from 'react-redux'
import Pagination from '../../components/common/pagination'
import Loader from '../../components/Loader/loader'
import { NoData } from '../../components/common/noData'
import { RiArrowDropDownLine } from 'react-icons/ri'
import { MdOutlineDateRange } from 'react-icons/md'
import moment from 'moment/moment'
import SelectPeriodComponent from '../../components/SelectPeriod'
import { getAssignedEmployeeList } from '../../apis/apis'

const CustomerService = () => {

    const [query, setQuery] = useState("")
    const [page, setpage] = useState(0)
    const [selectPeriod, setSelectPeriod] = useState("")


    const navigate = useNavigate();
    const dispatch = useDispatch()
    const { empDetails, xvi_id, branchId } = useSelector(state => state.loginSlice)
    const { ticketList, ticketList_TotalElement, isTicketListLoading, ticketStatus, isTicketStatusLoading } = useSelector(state => state.ticketSlice)
    const { assignedEmployeeList } = useSelector(state => state.campaign)



    // Check Access
    const accessAddTicket = AccessComponent(AccessIds.Ticket_save)
    const accessTicketAllList = AccessComponent(AccessIds.Ticket_allList)
    const accessTicketMyList = AccessComponent(AccessIds.Ticket_myList)


    const [status, setStatus] = useState(null)
    const [createdBy, setCreatedBy] = useState(null)




    // console.log("selectPeriod...............111", moment(selectPeriod?.fromDate, "DD/MM/YYYY").format("DD-MM-YYYY"));

    useEffect(() => {
        console.log("selectPeriod...............111", selectPeriod);
        console.log("selectPeriod...............111", moment(selectPeriod?.fromDate).format("DD-MM-YYYY"));


        // Call All List Here
        if (accessTicketAllList) {
            dispatch(getCustomerTicketListAll({ xvi_id, page, query, status: status, fromPeriod: moment(selectPeriod?.fromDate).format("DD-MM-YYYY"), toPeriod: selectPeriod?.toDate, createdBy: createdBy }))
        }

        // Call My List Here
        if (accessTicketMyList) {
            dispatch(getCustomerTicketListMy(xvi_id, page))
        }
    }, [page, query, status, selectPeriod, createdBy])

    console.log("empDetails", empDetails?.type === "ADMIN");

    useEffect(() => {
        if (empDetails?.type === "ADMIN") {

            dispatch(getTicketStatusAdmin({ xvi_id: xvi_id, createdBy: xvi_id, search: query, fromPeriod: selectPeriod?.fromDate, toPeriod: selectPeriod?.toDate, createdBy: createdBy }))
        } else {
            dispatch(getTicketStatusEmployee({ xvi_id: xvi_id, createdBy: xvi_id }))
        }
    }, [query, createdBy, selectPeriod])

    // its only for dummy
    useEffect(() => {
        dispatch(getAssignedEmployeeList(xvi_id))
    }, [])

    return (
        <Template>

            <div>

                {/* Header */}







                {/* Header */}
                <div className="flex gap-2.5 self-stretch px-28 py-5 font-medium bg-white max-md:flex-wrap max-md:px-5">
                    <div className='flex w-full'>

                        <div className="flex flex-col flex-1 text-slate-950 max-md:max-w-full">
                            <div className="text-3xl max-md:max-w-full">Customer Tickets</div>
                            <div className="flex gap-4 self-start mt-3 text-xs">

                                <select value={createdBy} onChange={(e) => setCreatedBy(e.target.value)} className=' border-none outline-none font-semibold text-lg w-[30%]'>
                                    <option className='  text-black  font-semibold' value={""}>{createdBy ? "Clear" : "Created by"}</option>
                                    {
                                        assignedEmployeeList?.map((val, i) =>
                                            <option key={i} className='capitalize bg-[#f9f9fa] text-black' value={val?.roleAssignId}>{val?.employeeName}</option>
                                        )

                                    }

                                </select>

                                <SelectPeriodComponent selectPeriod={selectPeriod} setSelectPeriod={setSelectPeriod} />

                            </div>
                        </div>

                        <div className="flex gap-2 justify-center my-auto text-xs">
                            {
                                true &&
                                <div className="flex gap-2 px-2.5 py-2 rounded-md shadow-lg bg-[#0000000a] items-center justify-start  text-slate-950 w-80">
                                    <IoSearchSharp size={17} color='gray' />
                                    <input
                                        value={query}
                                        onChange={(e) => setQuery(e?.target?.value)}
                                        placeholder='Search here'
                                        className='w-full p-1 outline-none bg-transparent text-sm font-semibold text-black'
                                    />
                                </div>
                            }

                            <AddTicket />


                        </div>
                    </div>
                </div>


                {
                    isTicketListLoading ?
                        <div className='w-[100%] '>
                            <Loader />
                        </div>
                        :

                        <div className='flex px-4 my-10 py-5 w-[90%] ml-[5%] bg-white justify-between rounded-xl shadow-xl'>

                            <div className="flex flex-col py-3 w-[15%] rounded-lg border border-solid border-zinc-800 border-opacity-20">


                                <div>

                                    {/* All */}
                                    <div onClick={() => setStatus("All")} className="cursor-pointer flex gap-3 px-5 py-3 whitespace-nowrap border-b border-solid border-zinc-800 border-opacity-10">
                                        <div className="flex-1 text-xs font-medium text-zinc-800">All</div>
                                        <div className="justify-center px-1.5 py-px text-xs font-semibold text-white bg-orange-600 rounded-lg">
                                            {ticketStatus?.all}
                                        </div>
                                    </div>
                                    {/* New */}
                                    <div onClick={() => setStatus("New")} className="cursor-pointer flex gap-3 px-5 py-3 whitespace-nowrap border-b border-solid border-zinc-800 border-opacity-10">
                                        <div className="flex-1 text-xs font-medium text-zinc-800">New</div>
                                        <div className="justify-center px-1.5 py-px text-xs font-semibold text-white bg-orange-600 rounded-lg">
                                            {ticketStatus?.new}
                                        </div>
                                    </div>
                                    {/* Solved */}
                                    <div onClick={() => setStatus("Solved")} className="cursor-pointer flex gap-3 px-5 py-3 whitespace-nowrap border-b border-solid border-zinc-800 border-opacity-10">
                                        <div className="flex-1 text-xs font-medium text-zinc-800">Solved</div>
                                        <div className="justify-center px-1.5 py-px text-xs font-semibold text-white bg-orange-600 rounded-lg">
                                            {ticketStatus?.solved}
                                        </div>
                                    </div>
                                    {/* Unsolved */}
                                    <div onClick={() => setStatus("Unsolved")} className="cursor-pointer flex gap-3 px-5 py-3 whitespace-nowrap border-b border-solid border-zinc-800 border-opacity-10">
                                        <div className="flex-1 text-xs font-medium text-zinc-800">Unsolved</div>
                                        <div className="justify-center px-1.5 py-px text-xs font-semibold text-white bg-orange-600 rounded-lg">
                                            {ticketStatus?.unassigned}
                                        </div>
                                    </div>
                                    {/* Unassigned */}
                                    <div onClick={() => setStatus("Unassigned")} className="cursor-pointer flex gap-3 px-5 py-3 whitespace-nowrap border-b border-solid border-zinc-800 border-opacity-10">
                                        <div className="flex-1 text-xs font-medium text-zinc-800">Unassigned</div>
                                        <div className="justify-center px-1.5 py-px text-xs font-semibold text-white bg-orange-600 rounded-lg">
                                            {ticketStatus?.unsolved}
                                        </div>
                                    </div>

                                </div>
                            </div>


                            <div className='w-[83%]'>
                                {
                                    !(ticketList?.length) ?
                                        <NoData />
                                        :

                                        <div>
                                            <table className='w-full'>

                                                <thead className='bg-black text-white'>
                                                    <th className='px-3 py-2 whitespace-nowrap text-left'>
                                                        <div className='flex gap-2 items-center text-white '>
                                                            {/* <SquareIcon fontSize={16} style={{ color: 'black' }} fill='black' /> */}
                                                            Subject
                                                        </div>
                                                    </th>
                                                    <th className='px-3 py-2 whitespace-nowrap text-left'>
                                                        From
                                                    </th>
                                                    <th className='px-3 py-2 whitespace-nowrap text-left'>
                                                        Assign To
                                                    </th>
                                                    <th className='px-3 py-2 whitespace-nowrap text-left'>
                                                        Type
                                                    </th>
                                                    <th className='px-3 py-2 whitespace-nowrap text-left'>
                                                        Priority
                                                    </th>
                                                </thead>


                                                <tbody className='w-full '>
                                                    {
                                                        ticketList.map((v, i, arr) => (
                                                            <tr className={`bg-white  border-gray-300 p-2 cursor-pointer text-black ${i + 1 === arr?.length ? "" : "border-b"} `} onClick={() => navigate(`/customer-ticket/${v?.ticketId}`)}>
                                                                <td className='px-3 py-2 text-black'>
                                                                    <div className='flex items-center gap-5'>
                                                                        {/* <SquareIcon fontSize={16} style={{ color: 'black' }} fill='black' /> */}
                                                                        <div className='flex flex-col  items-start '>
                                                                            {/* <span className='text-black/40'>#26</span> */}
                                                                            <span className='text-xs font-medium capitalize'>{v?.subject}</span>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td className='px-3 py-2 text-black'>
                                                                    <div className='flex flex-col gap-2 items-start'>
                                                                        <span className='text-xs font-medium capitalize'>{v?.name}</span>
                                                                        <span className='text-black/40'>{v?.createdAt}</span>
                                                                    </div>
                                                                </td>
                                                                <td className='px-3 py-2 text-black'>
                                                                    <div className='flex flex-col gap-2 items-start'>
                                                                        <span className='text-xs font-medium capitalize'>Jane Copper</span>
                                                                        <span className='text-black/40'>{v?.assignToDate}</span>
                                                                    </div>
                                                                </td>
                                                                <td className='px-3 py-2 text-black text-xs font-medium capitalize'>
                                                                    {v?.type}
                                                                </td>
                                                                <td className='px-3 py-2 '>
                                                                    <div className='flex gap-2'>
                                                                        <div className='w-3 h-3 rounded-full bg-orange-500' />
                                                                        <span className='text-orange-500  text-xs font-medium capitalize'>
                                                                            High
                                                                        </span>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        ))
                                                    }


                                                </tbody>
                                            </table>
                                        </div>
                                }
                            </div>


                        </div>
                }
                <div className='flex justify-center w-full p-4 mb-4  -bottom-3  left-14 right-0 '>

                    <Pagination
                        className="pagination-bar"
                        currentPage={page + 1}
                        totalCount={ticketList_TotalElement}
                        pageSize={10}

                        onPageChange={(page) => {
                            setpage(page - 1)
                            var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
                            if (currentScroll > 0) {
                                window.scrollTo(0, currentScroll - (currentScroll / 1));
                            }
                        }}
                    />
                </div>

            </div>
        </Template>
    )
}

export default CustomerService




