import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { getAssignedEmployeeList } from '../../../apis/apis';
import { useSelector } from 'react-redux';
import AssignTickets from './AssignTicket';
import { setTicketPriority, setTicketStatus, setTicketType } from '../../../redux/slices/ticketSlice';
import axiosConfig from '../../../www/http';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

const ManageTicket = () => {
    const { ticket_id } = useParams();
    const [isLoading, setisLoading] = useState(false);

    const { ticketDetails } = useSelector(state => state.ticketSlice)
    const dispatch = useDispatch();
    const typeValues = ["query", "question"];
    const handleSave = async() => {
        try {
            const formdata = new FormData();
            formdata.append("priority",ticketDetails?.priority)
            formdata.append("status",ticketDetails?.status)
            formdata.append("type",ticketDetails?.type)
            formdata.append("ticketId", ticket_id)
            setisLoading(true);
            const { data } = await axiosConfig.put(`/edit-ticket`, formdata);
            if (data?.status?.toLowerCase() === "success") {
                toast.success(data?.message)
                setisLoading(false)
            } else {
                toast.error(data?.message)
                setisLoading(false)
            }
        } catch (error) {
            toast.error("Some error occured")
                setisLoading(false)
        }
    }
    return (
        <div className="flex flex-col ml-5 w-[25%] max-md:ml-0 max-md:w-full">
            <div className="flex flex-col grow self-stretch p-8 mx-auto w-full font-medium border-l border-solid bg-zinc-200 bg-opacity-20 border-black border-opacity-10 max-md:px-5">
                <div className="text-base text-zinc-800">Manage Ticket</div>
                <div className="mt-5 text-xs font-semibold leading-4 text-zinc-800 text-opacity-60">
                    Assign to
                </div>
                <AssignTickets assignTo={ticketDetails?.assignTo} />
                <div className="mt-5 text-xs font-semibold leading-4 text-zinc-800 text-opacity-60">
                    Priority
                </div>
                <select className="flex gap-2 py-2 pr-2.5 pl-2 mt-1 text-sm bg-white rounded border border-solid border-black border-opacity-20 text-zinc-800" value={ticketDetails?.priority} onChange={(e)=>dispatch(setTicketPriority(e.target.value))}>
                    <option className="flex-1" value={""}>Select</option>
                    <option className="flex-1">High</option>
                    <option className="flex-1">Medium</option>
                    <option className="flex-1">Low</option>
                </select>
                <div className="mt-5 text-xs font-semibold leading-4 text-zinc-800 text-opacity-60">
                    Type
                </div>
                <select className="flex gap-2 py-2 pr-2.5 pl-2 mt-1 text-sm bg-white rounded border border-solid border-black border-opacity-20 text-zinc-800" value={ticketDetails?.type}  onChange={(e)=>dispatch(setTicketType(e.target.value))}>
                    {!(typeValues.includes(ticketDetails?.type)) && <option className="flex-1" value={ticketDetails?.type}>{ ticketDetails?.type}</option>}
                    {
                        typeValues?.map((type) => (
                            <option className="flex-1" value={type}>{type}</option>
                        ))
                    }
                </select>
                <div className="mt-5 text-xs font-semibold leading-4 text-zinc-800 text-opacity-60">
                    Status
                </div>
                <select className="flex gap-2 py-2 pr-2.5 pl-2 mt-1 text-sm bg-white rounded border border-solid border-black border-opacity-20 text-zinc-800" value={ticketDetails?.status} onChange={(e)=>dispatch(setTicketStatus(e.target.value))}>
                    <option className="flex-1" >UnAssigned</option>
                    <option className="flex-1" >New</option>
                    <option className="flex-1" >Unsolved</option>
                    <option className="flex-1" >Solved</option>
                </select>
                <div className="flex gap-2.5 mt-5 ">
                    <div className="flex-1 justify-center px-4 py-1.5 text-xs leading-5 bg-white rounded-md border border-solid border-black border-opacity-20 text-zinc-800 max-md:px-5">
                        Cancel
                    </div>
                    <button disabled={isLoading?"disable":""} className="flex-1 justify-center px-4 py-1.5 text-sm leading-5 text-white rounded-md bg-slate-600 max-md:px-5" onClick={handleSave}>
                        Save
                    </button>
                </div>
            </div>
        </div>
    )
}

export default ManageTicket