import React, { useEffect, useState } from 'react'
import Template from '../../../components/common/Template'
import { ReactComponent as SquareIcon } from "../../../assets/squareicon.svg"
import { useNavigate } from 'react-router-dom'
import Files from 'react-files'
import { toast } from 'react-toastify'
import { notifyErrorMessage, notifySuccessMessage } from '../../../components/utils/toastMessage'
import axiosConfig from '../../../www/http'
import { useFormik } from 'formik'
import * as Yup from "yup"
import { CiSearch } from "react-icons/ci";
import { FaPhone } from "react-icons/fa6";
import moment from 'moment/moment'
import { useSelector } from 'react-redux'
import AccessComponent from '../../../components/utils/checkAccess'
import { AccessIds } from '../../../components/utils/allIdList'

const AddNewCampaign = () => {
  const { xvi_id, role } = useSelector(state => state.loginSlice)

  const navigate = useNavigate();
  const [formURLlist, setformURLlist] = useState([])
  const [excelSheetData, setexcelSheetData] = useState([])
  const [selectedReceipant, setSelectedReceipant] = useState([])
  const [loading, setLoading] = useState(false)
  const [searchData, setsearchData] = useState([])
  const [searchVal, setSearchVal] = useState("")
  const add_access = AccessComponent(AccessIds?.campaign_save)

  useEffect(() => {
    if (!add_access) {

      navigate(-1)
    }
  }, [])

  const bankCampaignOptions = [
    { label: "Credit Card Promotions", value: "credit_card_promotions" },
    { label: "Deposit Account Promotions", value: "deposit_account_promotions" },
    { label: "Loan Offers", value: "loan_offers" },
    { label: "Referral Programs", value: "referral_programs" },]


  const initialValues = {
    campaignName: "",
    category: "",
    publishDate: "",
    description: "",
    message: "",
    contentImage: "",
    subject: "",
    buttonText: "",
    buttonUrl: "",
    excel: "",
    draftSchedule: ""
  }
  const validationSchema = Yup.object({
    // RADIO BUTTON
    campaignName: Yup.string().required("required"),
    category: Yup.string().required("required"),
    publishDate: Yup.string().required("required"),
    message: Yup.string().required("required"),
    subject: Yup.string().required("required"),
    buttonText: Yup.string().required("required"),
    buttonUrl: Yup.string().required("required"),
    contentImage: Yup.mixed().required("required"),
    excel: Yup.mixed().required("required"),
  })

  const { values, handleBlur, handleChange, handleSubmit, errors, touched, setFieldValue, resetForm } = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: false,
    onSubmit: async (valu, actions) => {
      if (values?.draftSchedule) {

        const formdata = new FormData()
        formdata.append("campaignName", values?.campaignName)
        formdata.append("category", values?.category)
        formdata.append("publishDate", moment(values.publishDate, 'YYYY-MM-DD').format('DD-MM-YYYY'))
        formdata.append("description", values?.description)
        const id = "id"
        let excelData = []
        if (selectedReceipant?.length) {
          const filteredArray = excelSheetData?.filter(obj =>
            !selectedReceipant?.some(item => item.id === obj.id)
          );
          const newArray = filteredArray.map(({ id, ...rest }) => rest);
          excelData = [...newArray]
        } else {
          const newArray = excelSheetData?.map(({ id, ...rest }) => rest);
          excelData = [...newArray]
        }
        formdata.append("recipientList", JSON.stringify(excelData))
        formdata.append("image", values?.contentImage)
        formdata.append("buttonText", values?.buttonText)
        formdata.append("buttonUrl", values?.buttonUrl)
        formdata.append("subject", values?.subject)
        formdata.append("message", values?.message)
        formdata.append("branchId", "eff26b52-6d88-48f6-b94e-45d148ea5101")
        formdata.append("campaignStatus", values?.draftSchedule)
        try {
          setLoading(true)
          const { data } = await axiosConfig.post(`/save-campaign`, formdata, {
            headers: {
              "X-V-I": `${xvi_id}`
            }
          })
          if (data?.status === "SUCCESS") {
            notifySuccessMessage(data?.message)
            handleClearData()
            navigate(-1)
            // document.querySelector("#close-sales-contact-add-modal").click()
          } else {
            notifyErrorMessage(data?.message)
          }
          setLoading(false)

        } catch (error) {
          notifyErrorMessage(error.message)
          setLoading(false)
        }


      }
    }
  })

  const handleReceipantList = (id) => {
    const prevDt = selectedReceipant?.find(v => v?.id === id)
    if (prevDt) {
      const dt = selectedReceipant?.filter(v => v?.id !== id)
      setSelectedReceipant(dt)
    } else {
      const obj = excelSheetData?.find(v => v?.id === id)
      setSelectedReceipant([...selectedReceipant, obj])
    }

  }
  useEffect(() => {
    const getFormUrl = async () => {
      try {

        const { data } = await axiosConfig.get(`/form/get-all-marketing-forms?page=0&pageSize=1111111`, {
          headers: { "X-V-I": 1 }
        })
        setformURLlist(data?.data)
      } catch (error) {

      }

    }
    getFormUrl()
  }, [])


  const handleExcelSheet = async (file) => {
    try {
      const formdata = new FormData()
      formdata.append("excel", file)
      const { data } = await axiosConfig.post(`/import-recipient`, formdata)
      setFieldValue("excel", file)
      if (data?.length) {
        const newData = data?.map((val, i) => {
          return { ...val, id: i + 1 }
        })

        setexcelSheetData(newData)
        setsearchData(newData)
      }

    } catch (error) {

    }
  }

  const sendCampaign = (v) => {
    setFieldValue("draftSchedule", v)
    handleSubmit()
  }

  const handleSearch = (q) => {
    setSearchVal(q)
    if (q) {
      const search = excelSheetData?.filter(v => v?.recipientName?.toLowerCase()?.includes(q?.toLowerCase()))
      // const search = excelSheetData?.filter((list) =>
      //   list?.recipientName?.toLowerCase()?.includes(q?.toLowerCase())
      // );
      console.log("searchhjh", search);
      setsearchData([...search])
    } else {
      setsearchData([...excelSheetData])
    }
  }


  const handleClearData = () => {
    resetForm()
    setexcelSheetData([])
    setsearchData([])
    setSearchVal("")
    setSelectedReceipant([])
  }

  return (
    <Template>
      <div className="w-full bg-slate-800 min-h-[79px] max-md:max-w-full" />
      <div className="max-w-[75%] m-auto bg-white -mt-10 rounded-lg px-6 py-5">
        <div className="flex gap-0 max-md:flex-wrap">
          <div className="flex flex-col">
            <button onClick={() => navigate(-1)} className="flex gap-2 cursor-pointer text-xs whitespace-nowrap text-zinc-800">
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/34deee8d7123904a5003e3e94732b7f59d4e0934eaaef9a558db6eb026d5fca9?apiKey=4c514e50e07d4444bfd3ae3d48b8bd32&"
                className="shrink-0 w-4 aspect-square"
              />
              <div>Back</div>
            </button>
            <div className="mt-2 text-xl font-semibold text-slate-950">
              Add Campaign
            </div>
          </div>
          <div className="flex gap-0 justify-between self-start mt-5 text-xs font-medium capitalize text-zinc-800 max-md:flex-wrap max-md:max-w-full">
            <div className="flex flex-col justify-center">
              <div className="justify-center items-start py-1.5 pr-2.5 pl-5 rounded-2xl bg-zinc-200 bg-opacity-60">
                1. Details
              </div>
            </div>
            <div className="flex gap-0">
              <img
                loading="lazy"
                srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/a0c079b0-5c9d-4173-b4ff-2416483aa731?apiKey=4c514e50e07d4444bfd3ae3d48b8bd32&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/a0c079b0-5c9d-4173-b4ff-2416483aa731?apiKey=4c514e50e07d4444bfd3ae3d48b8bd32&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/a0c079b0-5c9d-4173-b4ff-2416483aa731?apiKey=4c514e50e07d4444bfd3ae3d48b8bd32&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/a0c079b0-5c9d-4173-b4ff-2416483aa731?apiKey=4c514e50e07d4444bfd3ae3d48b8bd32&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/a0c079b0-5c9d-4173-b4ff-2416483aa731?apiKey=4c514e50e07d4444bfd3ae3d48b8bd32&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/a0c079b0-5c9d-4173-b4ff-2416483aa731?apiKey=4c514e50e07d4444bfd3ae3d48b8bd32&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/a0c079b0-5c9d-4173-b4ff-2416483aa731?apiKey=4c514e50e07d4444bfd3ae3d48b8bd32&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/a0c079b0-5c9d-4173-b4ff-2416483aa731?apiKey=4c514e50e07d4444bfd3ae3d48b8bd32&"
                className="shrink-0 aspect-[0.79] fill-zinc-200 fill-opacity-60 w-[22px]"
              />
              <div className="justify-center px-2.5 py-1.5 bg-zinc-200 bg-opacity-60">
                2. Recipient
              </div>
            </div>
            <div className="flex gap-0">
              <img
                loading="lazy"
                srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/448d0371-f3f2-4ae8-a3cd-4f7071b2946f?apiKey=4c514e50e07d4444bfd3ae3d48b8bd32&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/448d0371-f3f2-4ae8-a3cd-4f7071b2946f?apiKey=4c514e50e07d4444bfd3ae3d48b8bd32&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/448d0371-f3f2-4ae8-a3cd-4f7071b2946f?apiKey=4c514e50e07d4444bfd3ae3d48b8bd32&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/448d0371-f3f2-4ae8-a3cd-4f7071b2946f?apiKey=4c514e50e07d4444bfd3ae3d48b8bd32&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/448d0371-f3f2-4ae8-a3cd-4f7071b2946f?apiKey=4c514e50e07d4444bfd3ae3d48b8bd32&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/448d0371-f3f2-4ae8-a3cd-4f7071b2946f?apiKey=4c514e50e07d4444bfd3ae3d48b8bd32&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/448d0371-f3f2-4ae8-a3cd-4f7071b2946f?apiKey=4c514e50e07d4444bfd3ae3d48b8bd32&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/448d0371-f3f2-4ae8-a3cd-4f7071b2946f?apiKey=4c514e50e07d4444bfd3ae3d48b8bd32&"
                className="shrink-0 aspect-[0.79] fill-zinc-200 fill-opacity-60 w-[22px]"
              />
              <div className="justify-center px-2.5 py-1.5 bg-zinc-200 bg-opacity-60">
                3. WhatsApp Content
              </div>
            </div>
            <div className="flex gap-0">
              <img
                loading="lazy"
                srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/0b9645a0-23e8-4f26-8c1a-2c6300406df1?apiKey=4c514e50e07d4444bfd3ae3d48b8bd32&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/0b9645a0-23e8-4f26-8c1a-2c6300406df1?apiKey=4c514e50e07d4444bfd3ae3d48b8bd32&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/0b9645a0-23e8-4f26-8c1a-2c6300406df1?apiKey=4c514e50e07d4444bfd3ae3d48b8bd32&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/0b9645a0-23e8-4f26-8c1a-2c6300406df1?apiKey=4c514e50e07d4444bfd3ae3d48b8bd32&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/0b9645a0-23e8-4f26-8c1a-2c6300406df1?apiKey=4c514e50e07d4444bfd3ae3d48b8bd32&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/0b9645a0-23e8-4f26-8c1a-2c6300406df1?apiKey=4c514e50e07d4444bfd3ae3d48b8bd32&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/0b9645a0-23e8-4f26-8c1a-2c6300406df1?apiKey=4c514e50e07d4444bfd3ae3d48b8bd32&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/0b9645a0-23e8-4f26-8c1a-2c6300406df1?apiKey=4c514e50e07d4444bfd3ae3d48b8bd32&"
                className="shrink-0 aspect-[0.79] fill-zinc-200 fill-opacity-60 w-[22px]"
              />
              <div className="justify-center items-start py-1.5 pr-5 pl-2.5 rounded-none bg-zinc-200 bg-opacity-60">
                4. Publish
              </div>
            </div>
          </div>
        </div>
        <div className="flex gap-2.5 mt-8 text-base font-medium whitespace-nowrap text-slate-950 max-md:flex-wrap">
          <div>1.</div>
          <div className="max-md:max-w-full">Details</div>
        </div>
        <div className="mt-3 text-sm font-medium leading-4 text-[#27292d] max-md:max-w-full">
          Campaign name <span className="text-orange-600">*</span>
        </div>
        <input onChange={handleChange} onBlur={handleBlur} value={values.campaignName} name='campaignName' style={(errors.campaignName && touched.campaignName) && { border: "1px solid red" }} className="shrink-0 px-2 outline-none w-full h-8 bg-white rounded border border-solid border-black border-opacity-20 max-md:max-w-full" />
        <div className="flex gap-5 mt-5 max-md:flex-wrap">
          <div className="flex flex-col flex-1 justify-center">
            <div className="text-sm font-medium  leading-4 text-[#27292d]">
              Category <span className="text-orange-600">*</span>
            </div>
            <select onChange={handleChange} onBlur={handleBlur} value={values.category} name='category' style={(errors.category && touched.category) && { border: "1px solid red" }} className="flex gap-2.5 outline-none justify-center px-3 py-2 text-sm font-medium whitespace-nowrap bg-white rounded border border-solid border-black border-opacity-20 text-zinc-800 text-opacity-60">
              <option value={""}>Select</option>
              {
                bankCampaignOptions?.map((v, i) =>
                  <option key={i} value={v?.value}>{v?.label}</option>
                )
              }
            </select>
          </div>
          <div className="flex flex-col flex-1 justify-center">
            <div className="text-sm font-medium leading-4 text-[#27292d] ">
              Publish date <span className="text-orange-600">*</span>
            </div>
            <input type='date' value={values.publishDate} onChange={handleChange} onBlur={handleBlur} name='publishDate' style={(errors.publishDate && touched.publishDate) && { border: "1px solid red" }} className="flex outline-none flex-col  py-1 px-2 bg-white rounded border border-solid border-black border-opacity-20 max-md:pl-5" />


          </div>
        </div>
        <div className="mt-5 text-sm font-medium leading-4 text-[#27292d]  max-md:max-w-full">
          Description
        </div>
        <textarea value={values.description} onChange={handleChange} onBlur={handleBlur} name='description' style={(errors.description && touched.description) && { border: "1px solid red" }} className="shrink-0 h-24 w-full outline-none p-2 bg-white rounded border border-solid border-black border-opacity-20 max-md:max-w-full" />
        <div className="flex gap-2.5 mt-8 text-base font-medium whitespace-nowrap text-slate-950 max-md:flex-wrap">
          <div>2.</div>
          <div className="flex-1 max-md:max-w-full">Recipient</div>
        </div>
        <div className="flex flex-col gap-4 w-full">
          <div className="flex gap-5 justify-between py-2 pr-2 pl-5 w-full font-medium bg-white rounded-xl max-md:flex-wrap max-md:max-w-full">
            {
              true ?
                <div className="flex gap-2 border-[1px] border-gray-100 w-full px-2 items-center py-2 rounded-md text-slate-950">
                  <CiSearch className='flex justify-center ' size={16} color='black' strokeWidth={1} />
                  <input disabled={excelSheetData?.length ? false : true} value={searchVal} onChange={(e) => handleSearch(e.target.value)} placeholder='Type name to add' className='w-full outline-none bg-transparent' />
                </div> : ""
            }
            <Files
              className='files-dropzone cursor-pointer rounded-full'
              onChange={(file) => handleExcelSheet(file[0])}
              onError={(e) => notifyErrorMessage(e.message)}
              accepts={['.xlsx']}
              multiple
              maxFileSize={10000000}
              minFileSize={0}
              clickable>
              <div style={errors.excel && { border: "1px solid red" }} className="flex  gap-1 py-1.5 pr-3.5 pl-3 whitespace-nowrap rounded-md border border-solid border-black border-opacity-20 leading-[167%] text-zinc-800">
                {
                  values.excel ?
                    <div>{values.excel.name.slice(0, 7)}</div> :
                    <>
                      <img
                        loading="lazy"
                        alt=''
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/9a469dbf9cdbe23c6b91e1ce1062806aefb3845588737890f790d3049a2a6f82?apiKey=4c514e50e07d4444bfd3ae3d48b8bd32&"
                        className="shrink-0 my-auto w-4 aspect-square"
                      />
                      <div>Import</div>
                    </>
                }
              </div>
            </Files>
          </div>
          <div className='max-h-[400px]   overflow-y-auto no-scrollbar'>
            <table className='w-full  '>
              <thead className='bg-black  text-white'>
                {/* <th className='px-3 py-2 whitespace-nowrap text-center'>
                              
                          </th> */}
                <th className='px-3 py-2 whitespace-nowrap text-center'>

                  Full Name
                </th>
                <th className='px-3 py-2 whitespace-nowrap text-center'>
                  Type
                </th>
                <th className='px-3 py-2 whitespace-nowrap text-center'>
                  Account
                </th>
                <th className='px-3 py-2 whitespace-nowrap text-center'>
                  Phone
                </th>
              </thead>
              <tbody className='w-full bg-red-600 '>
                {
                  searchData?.map((v, i) =>
                    <tr key={i} className='bg-white border border-gray-100 cursor-pointer text-black'  >
                      {/* onClick={()=>navigate(`/marketing/campaign/1`)} */}
                      {/* <td className='px-3 py-2 text-black'>
                            <div className="self-stretch my-auto">
                              <input type='checkbox'/>
                              </div>
                            </td> */}
                      <td className='px-3 py-2 text-black'>

                        <div className="flex ">
                          <input type='checkbox' checked={selectedReceipant?.map(({ id }) => id)?.includes(v?.id) ? true : false} onChange={(e) => handleReceipantList(v?.id)} />
                          <div className="my-auto mx-auto">{v?.recipientName}</div>
                        </div>
                      </td>
                      <td className='px-3 py-2 text-black'>
                        <div className="justify-center self-stretch px-2 py-1 my-auto font-medium text-center text-green-700 whitespace-nowrap bg-green-100 rounded-lg">
                          {v?.type}
                        </div>
                      </td>
                      <td className='px-3 py-2 text-black  flex  items-center justify-center'>
                        <div className="text-center mx-auto ">{v?.account}</div>
                      </td>
                      <td className='px-3 py-2  text-black text-xs font-medium capitalize'>
                        <div className="flex  mx-auto  justify-center items-center gap-2 self-start px-2 py-3">
                          <FaPhone size={16} color='green' />
                          <div>{v?.phone}</div>
                        </div>
                      </td>
                    </tr>
                  )
                }
              </tbody>
            </table>
          </div>
        </div>
        <div className="flex gap-2.5 mt-8 text-base font-medium text-slate-950 max-md:flex-wrap">
          <div>3.</div>
          <div className="max-md:max-w-full">WhatsApp Content</div>
        </div>
        <div className="flex  gap-4 pr-20 mt-3 max-md:flex-wrap max-md:pr-5">
          <Files
            className='files-dropzone cursor-pointer rounded-full'
            onChange={(file) => setFieldValue("contentImage", file[0])}
            onError={(e) => notifyErrorMessage(e.message)}

            accepts={['image/png']}
            multiple
            maxFileSize={10000000}
            minFileSize={0}
            clickable>
            {
              values.contentImage ?
                <img src={values.contentImage?.preview?.url} alt='' className="flex justify-center items-center  border-bg-zinc-800 border-[1px] bg-opacity-20 h-[70px] rounded-[200px] w-[70px] max-md:px-5" />

                :
                <div className="flex justify-center items-center px-6 bg-zinc-800 bg-opacity-20 h-[70px] rounded-[200px] w-[70px] max-md:px-5">
                  <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/1456c04a48e8f48c96ca0e871840fc95fef206c62a5caebc4ce5ce8e07fe007f?apiKey=4c514e50e07d4444bfd3ae3d48b8bd32&"
                    className="w-5 aspect-square"
                  />
                </div>
            }
          </Files>
          <div className="flex flex-col my-auto font-semibold text-zinc-800">
            <div style={errors.contentImage && { color: "red" }} className="text-sm text-[#27292d] capitalize">Upload image <span className="text-orange-600">*</span></div>
            <div className="mt-1 text-xs">Max: 2 MB, Supported: png, jpg, webp</div>
          </div>
        </div>
        <div className="mt-5 w-full max-md:max-w-full">
          <div className="flex gap-5 max-md:flex-col max-md:gap-0">
            <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
              <div className="flex flex-col grow px-5 max-md:mt-8">

                <div className="mt-3 text-sm font-medium leading-5 text-[#27292d]">
                  Subject <span className="text-orange-600">*</span>
                </div>
                <input name='subject' value={values.subject} onBlur={handleBlur} onChange={handleChange} style={(errors.subject && touched.subject) && { border: "1px solid red" }} className="shrink-0 px-2 outline-none mt-1 h-8 rounded border border-solid border-black border-opacity-20" />
                <div className="mt-3 text-sm font-medium leading-5 text-[#27292d]">
                  Button Text <span className="text-orange-600">*</span>
                </div>
                <input name='buttonText' value={values.buttonText} onBlur={handleBlur} onChange={handleChange} style={(errors.buttonText && touched.buttonText) && { border: "1px solid red" }} className="shrink-0 px-2 outline-none mt-1 h-8 rounded border border-solid border-black border-opacity-20" />
                <div className="mt-3 text-sm font-medium leading-5 text-[#27292d]">
                  Button URL <span className="text-orange-600">*</span>
                </div>

                <select name='buttonUrl' value={values.buttonUrl} onBlur={handleBlur} onChange={handleChange} style={(errors.buttonUrl && touched.buttonUrl) && { border: "1px solid red" }} className="flex gap-2.5 outline-none justify-center px-3 py-2 text-sm font-medium whitespace-nowrap capitalize bg-white rounded border border-solid border-black border-opacity-20 text-zinc-800 text-opacity-60">
                  <option value={""}>Select a form url</option>
                  {
                    formURLlist?.map((v, i) =>
                      <option className='capitalize' key={i} value={v?.htmlCssFileName}>{v?.formName}</option>
                    )
                  }
                </select>
              </div>
            </div>
            <div className="flex flex-col ml-5 w-6/12 max-md:ml-0 max-md:w-full">
              <div className="flex flex-col grow self-stretch px-5 text-sm font-medium leading-5 text-[#27292d] max-md:mt-8">
                <div>
                  Message <span className="text-orange-600">*</span>
                </div>
                <textarea name='message' value={values.message} onBlur={handleBlur} onChange={handleChange} style={(errors.message && touched.message) && { border: "1px solid red" }} className="shrink-0 mt-1 text-black p-2 rounded border outline-none border-solid border-black border-opacity-20 h-[233px]" />
              </div>
            </div>
          </div>
        </div>
        <div className="flex gap-2 self-stretch pl-20 text-sm font-medium leading-5 text-zinc-800 max-md:flex-wrap max-md:pl-5 justify-end">
          <button disabled={loading} onClick={handleClearData} className="justify-center cursor-pointer px-4 py-2 whitespace-nowrap rounded-md border border-solid border-black border-opacity-20">
            Cancel
          </button>
          <button disabled={loading} onClick={() => sendCampaign("draft")} className="justify-center px-4 py-2 rounded-md border border-solid border-black cursor-default border-opacity-20">
            Save Draft
          </button>
          <button disabled={loading} onClick={() => sendCampaign("scheduled")} className="justify-center cursor-pointer px-4 py-2 rounded-md border border-solid border-black border-opacity-20 bg-[#3050A1] text-white">
            Schedule
          </button>
        </div>

      </div>

    </Template>
  )
}

export default AddNewCampaign