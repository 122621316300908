import { Form, useFormik } from 'formik';
import React from 'react'
import { MdOutlineEmail } from 'react-icons/md';
import axiosConfig from '../../www/http';
import { useDispatch } from 'react-redux';
import { setEmpDetails } from '../../redux/slices/loginSlice';

const EmpLogin = () => {

    const dispatch = useDispatch()

    const initialValues = {
        email: "",
        password: ""
    }

    const { values, handleBlur, handleChange, handleSubmit, errors, touched, setFieldValue } = useFormik({
        initialValues: initialValues,
        onSubmit: async (values) => {

            try {
                console.log("Loading.........................");
                const { data } = await axiosConfig.post(`/employee-login?userName=${values?.email}&password=${values?.password}`)
                console.log("Success", data);
                console.log("EMP_ID", data?.employeeId);
                localStorage.setItem("emp_id", data?.employeeId)
                dispatch(setEmpDetails(data))

            } catch (error) {
                console.log("Faileddddddddddddddddd", error);
            }
            console.log("Values......................", values);
        }
    })


    return (
        <div className="flex gap-5 justify-center max-md:flex-wrap overflow-hidden max-h-screen">
            <div className='flex flex-col relative bg-[#191F3E] min-h-[100vh] justify-center items-center w-1/2'>
                <img
                    loading="lazy"
                    srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/d611332806e1a07fbc96c121cd245aadfdcb4efca1f330635460c8c3db51635d?apiKey=4c514e50e07d4444bfd3ae3d48b8bd32&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/d611332806e1a07fbc96c121cd245aadfdcb4efca1f330635460c8c3db51635d?apiKey=4c514e50e07d4444bfd3ae3d48b8bd32&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/d611332806e1a07fbc96c121cd245aadfdcb4efca1f330635460c8c3db51635d?apiKey=4c514e50e07d4444bfd3ae3d48b8bd32&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/d611332806e1a07fbc96c121cd245aadfdcb4efca1f330635460c8c3db51635d?apiKey=4c514e50e07d4444bfd3ae3d48b8bd32&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/d611332806e1a07fbc96c121cd245aadfdcb4efca1f330635460c8c3db51635d?apiKey=4c514e50e07d4444bfd3ae3d48b8bd32&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/d611332806e1a07fbc96c121cd245aadfdcb4efca1f330635460c8c3db51635d?apiKey=4c514e50e07d4444bfd3ae3d48b8bd32&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/d611332806e1a07fbc96c121cd245aadfdcb4efca1f330635460c8c3db51635d?apiKey=4c514e50e07d4444bfd3ae3d48b8bd32&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/d611332806e1a07fbc96c121cd245aadfdcb4efca1f330635460c8c3db51635d?apiKey=4c514e50e07d4444bfd3ae3d48b8bd32&"
                    className=" max-w-full aspect-[1.54] w-[260px]" />
                <div className='absolute -bottom-44 -left-44'>
                    <div className="flex flex-col justify-center items-start  max-w-full rounded-full border border-sky-600 border-solid w-[388px]">
                        <div className="z-10 shrink-0 mt-0 max-w-full rounded-full border border-sky-600 border-solid h-[280px] w-[307px]" />
                    </div>
                </div>
            </div>
            {/* <div className="flex flex-col flex-1 self-end justify-center items-center max-md:max-w-full ">
    
      />
      <div className="flex flex-col justify-center items-start mt-28 max-w-full rounded-full border border-sky-600 border-solid w-[388px] max-md:pr-5 max-md:mt-10">
        <div className="z-10 shrink-0 mt-0 max-w-full rounded-full border border-sky-600 border-solid h-[280px] w-[307px]" />
      </div>
    </div> */}
            <div className="flex flex-col flex-1 justify-center items-center p-2.5 text-sm bg-white text-zinc-800 max-md:px-5 max-md:max-w-full">
                <div className="mt-48 text-2xl font-bold max-md:mt-10">
                    Hello Again!
                </div>
                <div className="text-lg">Welcome Back</div>
                <div className='relative'>
                    <input type='email' value={values?.email} onChange={handleChange("email")} className="flex flex-col justify-center items-start pl-10 py-5 mt-4 max-w-full whitespace-nowrap bg-white border border-solid border-zinc-800 border-opacity-20 rounded-[30px] text-zinc-800 w-[307px] max-md:px-5" placeholder='Email address' />
                    <div className="absolute left-2 bottom-[25%] flex gap-2 ">
                        <MdOutlineEmail size={18} color='gray' />
                    </div>
                </div>
                <div className='relative'>
                    <input value={values?.password} onChange={handleChange("password")} className="flex flex-col justify-center items-start pl-10 py-5 mt-4 max-w-full whitespace-nowrap bg-white border border-solid border-zinc-800 border-opacity-20 rounded-[30px] text-zinc-800 w-[307px] max-md:px-5" placeholder='Password' />
                    <div className="absolute left-2 bottom-[25%] flex gap-2">
                        <img
                            loading="lazy"
                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/46c5b49337034842ef9c405f28952c9c20d84db14b00ce4b53f4b6ba27bb7b9e?apiKey=4c514e50e07d4444bfd3ae3d48b8bd32&"
                            className="shrink-0 w-6 aspect-square"
                        />
                    </div>
                </div>
                <div onClick={handleSubmit} className="justify-center flex items-center px-7 py-5 mt-4 max-w-full text-white  bg-sky-600 rounded-[30px] w-[307px] max-md:px-5">
                    Login
                </div>
                <div className="mt-4">Forgot Password</div>
            </div>
        </div>

    )
}

export default EmpLogin