import React from 'react'

const PreviewCampaign = () => {
  return (
      <>
          <button type="button" data-hs-overlay="#preview-campaign-modal">
          <div className="flex gap-1 py-1.5 pr-3.5 pl-3 my-auto text-sm font-medium leading-5 text-white cursor-pointer whitespace-nowrap rounded-md bg-slate-600">
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/d7df73ccfd1074d70f5b98d7f3526d30e8811381740ea25a1a15775d8d317120?apiKey=4c514e50e07d4444bfd3ae3d48b8bd32&"
                className="shrink-0 my-auto w-4 aspect-square"
              />
              <div>Preview</div>
            </div>
</button>

<div id="preview-campaign-modal" class="hs-overlay hidden size-full fixed top-0 start-0 z-[80] overflow-x-hidden overflow-y-auto pointer-events-none">
  <div class="hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 mt-0 opacity-0 ease-out transition-all sm:max-w-[400px] sm:w-full m-3 sm:mx-auto h-[calc(100%-3.5rem)] min-h-[calc(100%-3.5rem)] flex items-center">
    <div class="w-full max-h-full overflow-y-auto flex flex-col bg-white rounded-xl pointer-events-auto dark:bg-neutral-800 dark:border-neutral-700 dark:shadow-neutral-700/70">
    <div className="flex flex-col self-stretch text-sm tracking-normal leading-5 text-black max-w-full m-auto">
                          <div className="w-full rounded-lg bg-zinc-300 min-h-[188px]" />
                          <div className=' px-5 py-4'>
      <div className="mt-4 w-full leading-[146%]">Hi Dexter,</div>
      <div className="mt-4 w-full">
        Aliqua id fugiat nostrud irure ex duis ea quis id quis ad et. Sunt qui
        esse pariatur duis deserunt mollit dolore cillum minim tempor enim. Elit
        aute irure tempor cupidatat incididunt sint deserunt ut voluptate aute
        id deserunt nisi.
      </div>
      <div className="mt-4 w-full">
        Sunt qui esse pariatur duis deserunt mollit dolore cillum minim tempor
        enim.
      </div>
      <div className="flex justify-center items-center px-4 py-2 mt-4 w-full text-sm font-medium tracking-normal leading-4 text-blue-600 rounded-lg border border-solid border-black border-opacity-20">
        <div className="flex gap-2.5">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/812072ddf2faa8a5a9f945ce0e18c132f42724dd9f69f60ee42efcf818b0d588?apiKey=4c514e50e07d4444bfd3ae3d48b8bd32&"
            className="shrink-0 self-start w-4 aspect-square"
          />
          <div>Action Button</div>
        </div>
      </div>                  
                          </div>
    </div>
    </div>
  </div>
</div>
    </>
  )
}

export default PreviewCampaign