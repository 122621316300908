import { createSlice } from "@reduxjs/toolkit";

const ticketSlice = createSlice({
    name: "ticket",
    initialState: {

        ticketList: [],
        ticketList_TotalElement: 0,

        // Details
        ticketDetails: {},

        // whatsapp
        ticketWhatsappList: [],
        ticketWhatsapp_total_elements: 0,

        // Calllog
        ticketCallLogList: [],
        ticketCallLog_total_elements: 0,

        // Status
        ticketStatus: {},
        ticketStatusChart:{},
        ticketStatusChartLoading:false,
    },
    reducers: {
        getTicketListLoading: (state) => {
            state.isTicketListLoading = true
        },
        getTicketListSuccess: (state, { payload }) => {
            state.isTicketListLoading = false
            state.ticketList = payload?.lists
            state.ticketList_TotalElement = payload?.totalElements
        },
        getTicketListFail: (state) => {
            state.isTicketListLoading = false
        },

        // Details
        getTicketDetailsLoading: (state) => {
            state.isTicketDetailsLoading = true
        },
        getTicketDetailsSuccess: (state, { payload }) => {
            console.log("PAYLOAD..........................", ...payload);
            console.log("PAYLOAD........8888..................", payload[0]);
            state.isTicketDetailsLoading = false
            state.ticketDetails = payload[0]
        },
        getTicketDetailsFail: (state) => {
            state.isTicketDetailsLoading = false
        },

        // Ticket whatsappList
        getTicketWhatsappListRequest: (state) => {
            state.isTicketWhatsappLoading = true
        },
        getTicketWhatsappListSuccess: (state, { payload }) => {
            console.log("PAYLOAD......................getTicketWhatsappListSuccess", payload);
            state.isTicketWhatsappLoading = false
            state.ticketWhatsappList = payload?.lists
            state.ticketWhatsapp_total_elements = payload?.totalElements

        },
        getTicketWhatsappListFail: (state) => {
            state.isTicketWhatsappLoading = false
        },


        // Ticket Call Log List
        getTicketCallLogRequest: (state) => {
            state.isTicketCallLogLoading = true
        },
        getTicketCallLogSuccess: (state, { payload }) => {
            console.log("PAYLOAD......................CALL LOG", payload);
            state.isTicketCallLogLoading = false
            state.ticketCallLogList = payload?.lists
            state.ticketCallLog_total_elements = payload?.totalElements

        },
        getTicketCallLogFail: (state) => {
            state.isTicketCallLogLoading = false
        },

        // Ticket Status
        getTicketStatusRequest: (state) => {
            state.isTicketStatusLoading = true
        },
        getTicketStatusSuccess: (state, { payload }) => {
            console.log("PAYLOAD......................CALL LOG", payload);
            state.isTicketStatusLoading = false
            state.ticketStatus = payload
        },
        getTicketStatusFail: (state) => {
            state.isTicketStatusLoading = false
        },
        setTicketStatus: (state, { payload }) => {
            state.ticketDetails.status=payload
        },
        setTicketPriority: (state, { payload }) => {
            state.ticketDetails.priority=payload
        },
        setTicketType: (state, { payload }) => {
            state.ticketDetails.type=payload
        },
        getTicketCallLogListStatusRequest: (state) => {
            state.ticketStatusChartLoading = true;
        },
        getTicketCallLogListStatusSuccess: (state,{payload}) => {
            state.ticketStatusChartLoading = false;
            state.ticketStatusChart = payload;
        },
        getTicketCallLogListStatusFail: (state) => {
            state.ticketStatusChartLoading = false;
        },
    }
})

export default ticketSlice.reducer;
export const {
    // List
    getTicketListFail, getTicketListLoading, getTicketListSuccess,
    // Details
    getTicketDetailsFail, getTicketDetailsLoading, getTicketDetailsSuccess,
    // whatsapp
    getTicketWhatsappListFail, getTicketWhatsappListRequest, getTicketWhatsappListSuccess,
    // call log
    getTicketCallLogFail, getTicketCallLogRequest, getTicketCallLogSuccess,
    // Status
    getTicketStatusFail, getTicketStatusRequest, getTicketStatusSuccess,setTicketPriority,setTicketStatus,setTicketType,getTicketCallLogListStatusFail,getTicketCallLogListStatusRequest,getTicketCallLogListStatusSuccess
} = ticketSlice.actions;