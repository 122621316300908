import React from 'react'

export const UserDetailsModal = () => {
    return (
        <div id="hs-vertically-centered-modal_userDetails_acc" class="hs-overlay hidden size-full fixed top-0 start-0 z-[80] overflow-x-hidden overflow-y-auto pointer-events-none">
            <div class="hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 mt-0 opacity-0 ease-out transition-all sm:max-w-lg sm:w-full  sm:mx-auto min-h-[calc(100%-3.5rem)] flex items-center">
                <div class="w-full flex flex-col  bg-white shadow-sm rounded-xl pointer-events-auto">
                    <div className="flex flex-col self-stretch max-w-[438px]">
                        <div className="flex justify-center items-center px-16 py-8 w-full text-white bg-[#9050D0]  border-solid border-slate-950" style={{ background: "#9050D0", borderTopRightRadius: "8px", borderTopLeftRadius: "8px" }}>
                            <div className="flex flex-col items-center max-w-full w-[150px]">
                                <img
                                    loading="lazy"
                                    src='https://c8.alamy.com/comp/JR2316/portrait-of-typical-american-teenager-in-last-year-of-high-school-JR2316.jpg'
                                    className="rounded-full aspect-square w-[70px]"
                                />

                                <div className=' flex items-center justify-center flex-col'>
                                    <div className="self-stretch mt-4 text-2xl font-semibold">
                                        Arlene McCoy
                                    </div>
                                    <div className="mt-2 text-sm">A/C 68300019</div>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col px-10 py-8 w-full">
                            <div className="flex gap-2.5 justify-between">
                                <div className="text-lg font-semibold text-slate-950">
                                    Contact Info
                                </div>
                                <div className="justify-center px-2 py-1 text-xs font-medium text-center text-green-700 bg-green-100 rounded-lg">
                                    Loan Officer{" "}
                                </div>
                            </div>
                            <div className="flex gap-3 mt-8 text-sm whitespace-nowrap text-zinc-800">
                                <img
                                    loading="lazy"
                                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/7943fad6252234986b425f1e8e91b35c33f9376267d37c6afe9a51e7f22c7867?"
                                    className="shrink-0 self-start w-4 aspect-[1.06]"
                                />
                                <div>kajope5182@ummoh.com</div>
                            </div>
                            <div className="flex gap-3 mt-8 text-sm whitespace-nowrap text-zinc-800">
                                <img
                                    loading="lazy"
                                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/d1198af68cfdc9f333e3f871a105a82100794b11eb13c9390d8bd7ca3d052e3f?"
                                    className="shrink-0 self-start w-4 aspect-square"
                                />
                                <div>33757005467</div>
                            </div>
                            <div className="flex gap-3.5 mt-8 text-sm text-zinc-800">
                                <img
                                    loading="lazy"
                                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/473cae68a6a4b384754156cd3dac06a2f295a59ec3a55b6656910f3dd3530be4?"
                                    className="shrink-0 self-start w-3.5 aspect-[0.83]"
                                />
                                <div className="flex flex-col">
                                    <div>2239 Hog Camp Road</div>
                                    <div className="mt-1.5">Schaumburg</div>
                                </div>
                            </div>
                            <div className="justify-center self-end px-4 py-1.5 mt-8 text-xs font-medium leading-5 whitespace-nowrap rounded-md border border-solid border-black border-opacity-20 text-zinc-800">
                                Close
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
