export const not_access_Message = "You don't have the permission to access this"

export const AccessIds = {
    // Leads
    leads_fullPermission: "",
    leads_save: "CRMSL32",
    leads_edit: "CRMEL33",
    leads_allList: "CRMALL36",
    leads_myList: "CRMMLL37",
    leads_details: "CRMLD38",
    leads_assignTo: "CRMATL35",
    // Leads Type
    leadType_fullPermission: "",
    leadType_save: "CRMLTSLT6",
    leadType_edit: "CRMLTELY8",
    leadType_allList: "CRMLTGLLT7",
    leadType_myList: "",
    leadType_details: "",
    leadType_assignTo: "",
    // Contact
    Contact_fullPermission: "",
    Contact_save: "CRMCSC1",
    Contact_edit: "CRMCUC2",
    Contact_allList: "CRMCCL4",
    Contact_myList: "CRMCMC21",
    Contact_details: "CRMCCD3",
    Contact_assignTo: "",
    // Campaign
    campaign_fullPermission: "",
    campaign_save: "CRMCSC49",
    campaign_edit: "CRMCEC51",
    campaign_allList: "CRMCGLC50",
    campaign_myList: "CRMCMLC52",
    campaign_details: "CRMCGDC53",
    // Ticket
    Ticket_fullPermission: "",
    Ticket_save: "CRMTST25",
    Ticket_edit: "",
    Ticket_allList: "CRMTTL26",
    Ticket_myList: "CRMTMT27",
    Ticket_details: "CRMTTD54",
    Ticket_AssignTo: "CRMTATT34",
    // MarkettingForm
    MarkettingForm_fullPermission: "",
    MarkettingForm_save: "CRMSMF20",
    MarkettingForm_edit: "CRMEMF22",
    MarkettingForm_allList: "CRMAMF23",
    MarkettingForm_myList: "CRMMMF24",
    MarkettingForm_details: "CRMMD30",
    MarkettingForm_AssignTo: "",

}