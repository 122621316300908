import React, { useEffect } from 'react'
import Template from '../../../components/common/Template'
import { ReactComponent as SquareIcon } from "../../../assets/squareicon.svg"
import { useNavigate, useParams } from 'react-router-dom'
import PreviewCampaign from './PreviewCampaign'
import { useDispatch } from 'react-redux'
import { getCamapignDetails } from '../../../apis/apis'
import { useSelector } from 'react-redux'
import AccessComponent from '../../../components/utils/checkAccess'
import { AccessIds } from '../../../components/utils/allIdList'
import moment from 'moment'

const CampaignDetails = () => {
  const navigate = useNavigate();
  const { xvi_id, role } = useSelector(state => state.loginSlice)
  const { campaignDeatils } = useSelector(state => state.campaign)

  const params = useParams()
  const campaign_id = params?.campaign_id
  const dispatch = useDispatch()
  const edit_access = AccessComponent(AccessIds?.campaign_details)
  useEffect(() => {
    if (true) {
      if (campaign_id) {
        dispatch(getCamapignDetails({ id: xvi_id, campaign_id }))
      }
    } else {
      // navigate(-1)
    }
  }, [campaign_id, edit_access, xvi_id])
  return (
    <Template>
      <div className="self-stretch px-16 py-6 bg-white border-b border-solid border-black border-opacity-10 max-md:px-5">
        <div className="flex gap-5 max-md:flex-col max-md:gap-0">
          <div className="flex flex-col w-[65%] max-md:ml-0 max-md:w-full">
            <div className="flex flex-col grow self-stretch text-xs text-zinc-800 max-md:mt-6 max-md:max-w-full">
              <button button onClick={() => navigate(-1)} className="flex gap-2 self-start cursor-pointer whitespace-nowrap">
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/34deee8d7123904a5003e3e94732b7f59d4e0934eaaef9a558db6eb026d5fca9?apiKey=4c514e50e07d4444bfd3ae3d48b8bd32&"
                  className="shrink-0 w-4 aspect-square"
                />
                <div>Back</div>
              </button>
              <div className="mt-2 capitalize text-xl font-semibold text-slate-950 max-md:max-w-full">
                {campaignDeatils?.campaignName}
              </div>
              <div className="mt-1 capitalize font-medium max-md:max-w-full">
                {campaignDeatils?.description}
              </div>
            </div>
          </div>
          <div className="flex flex-col ml-5 w-[35%] max-md:ml-0 max-md:w-full">
            <div className="flex gap-2 self-stretch my-auto font-semibold max-md:flex-wrap max-md:mt-8 justify-between">
              <div className="flex flex-col">
                <div className="text-xs leading-4 text-zinc-800 text-opacity-60">
                  Date created
                </div>
                <div className="text-sm leading-5 text-zinc-800">
                  {moment(campaignDeatils?.updatedAt, 'DD-MM-YYYY').format('D MMMM, YYYY')}
                </div>
              </div>
              <div className="flex flex-col">
                <div className="text-xs leading-4 text-zinc-800 text-opacity-60">
                  Created by
                </div>
                <div className="text-sm leading-5 text-zinc-800">
                  Jenny Wilson
                </div>
              </div>
              <PreviewCampaign />
            </div>
          </div>
        </div>
      </div>
      <div className='flex flex-col py-5 px-20 gap-5 '>
        <div className="flex gap-2 self-start max-md:flex-wrap">
          <div className="flex gap-4 p-6 bg-white rounded-lg max-md:px-5">
            <div className="text-2xl font-semibold">{campaignDeatils?.totalDelivered}</div>
            <div className="flex flex-col">
              <div className="text-xs font-medium">Delivered</div>
              <div className="text-xs">Last 24 Hours</div>
            </div>
          </div>
          <div className="flex gap-4 p-6 bg-white rounded-lg max-md:px-5">
            <div className="text-2xl font-semibold">{campaignDeatils?.totalNotDelivered}</div>
            <div className="flex flex-col">
              <div className="text-xs font-medium">Not delivered</div>
              <div className="text-xs">Last 30 Days</div>
            </div>
          </div>
          <div className="flex gap-4 p-6 bg-white rounded-lg max-md:px-5">
            <div className="text-2xl font-semibold">{campaignDeatils?.totalRecipient}</div>
            <div className="flex flex-col">
              <div className="text-xs font-medium">Recipient</div>
              <div className="text-xs">Last 30 days</div>
            </div>
          </div>
          <div className="flex gap-4 p-6 bg-white rounded-lg max-md:px-5">
            <div className="text-2xl font-semibold"></div>
            <div className="flex flex-col">
              <div className="text-xs font-medium">Published on</div>
              <div className="text-xs">{campaignDeatils?.publishDate}</div>
            </div>
          </div>
        </div>
        <div className='flex flex-col gap-4 bg-white rounded-xl'>
          <div className="flex gap-5 justify-between px-5 py-2 w-full   max-md:flex-wrap max-md:max-w-full">
            <div className="flex gap-2 my-auto">
              <div className="text-base font-medium capitalize text-slate-950">
                Recipients
              </div>
            </div>
          </div>
          <table className='w-full'>
            <thead className='bg-black text-white'>
              <th className='px-3 py-2 whitespace-nowrap text-center'>
                <div className='flex gap-4 items-center text-white '>
                  <SquareIcon fontSize={16} style={{ color: 'white' }} fill='white' />
                  Full Name
                </div>
              </th>
              <th className='px-3 py-2 whitespace-nowrap text-center'>
                Status
              </th>
              <th className='px-3 py-2 whitespace-nowrap text-center'>
                Created on
              </th>
              <th className='px-3 py-2 whitespace-nowrap text-center'>
                Account
              </th>
              <th className='px-3 py-2 whitespace-nowrap text-center'>
                Email
              </th>
            </thead>
            <tbody className='w-full'>
              {
                campaignDeatils?.recipientDataList?.map((list, i) =>
                  <tr key={i} className='bg-white cursor-pointer border border-gray-100 text-black' onClick={() => navigate(`/marketing/campaign/1`)}>
                    <td className='px-3 py-2 text-black'>
                      <div className='flex items-center gap-5'>
                        <SquareIcon fontSize={16} style={{ color: 'black' }} fill='black' />
                        <div className="flex gap-3">
                          {/* <div
            className="shrink-0 w-6 aspect-square"
          /> */}
                          <div className="my-auto">{list?.recipientName}</div>
                        </div>
                      </div>
                    </td>
                    <td className='px-3  py-2   text-black'>
                      <div className=" px-2 py-1 my-auto mx-auto text-center text-green-700 whitespace-nowrap bg-green-100 rounded-lg">
                        Processing
                      </div>

                    </td>
                    <td className='px-3 py-2 text-black'>
                      <div className="self-stretch my-auto text-center mx-auto">{campaignDeatils?.createdAt}</div>
                    </td>
                    <td className='px-3 py-2 text-black text-xs font-medium capitalize'>
                      <div className="self-stretch my-auto text-center mx-auto">

                        <div>{list?.account}</div>
                      </div>
                    </td>
                    <td className='px-3 py-2 flex justify-center'>
                      <div className="flex  my-auto mx-auto  gap-2 px-2 py-3 whitespace-nowrap">
                        <img
                          loading="lazy"
                          src="https://cdn.builder.io/api/v1/image/assets/TEMP/cdc482f2912317c4ac93ac998dee6ae2a1421036ed9f221c0d4a0610e98c6b2c?apiKey=4c514e50e07d4444bfd3ae3d48b8bd32&"
                          className="shrink-0 w-4 aspect-square"
                        />
                        <div>{list?.email}</div>
                      </div>

                    </td>
                  </tr>
                )
              }

            </tbody>
          </table>
        </div>
      </div>
    </Template>
  )
}

export default CampaignDetails