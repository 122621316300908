import { createSlice } from "@reduxjs/toolkit";


const campaignSlice =createSlice({
    name:"campaign",
    initialState:{
        campaignList:[],
        campaignListLoading:false,
        campaignDeatils:"",
        assignedEmployeeList:[],
        allassignedEmployeeList:[],
        totalCampaignList:0
    },
    reducers:{
      setCampaignListSuccess:(state,{payload})=>{
        state.campaignList=payload
        state.campaignListLoading=false
      },
      setCampaignListLoading:(state,{payload})=>{
        state.campaignListLoading=true
      },
      setCampaignListFail:(state,{payload})=>{
        state.campaignListLoading=false
      },
      setAssignedEmployeeList:(state,{payload})=>{
        state.assignedEmployeeList=payload
        state.allassignedEmployeeList=payload
      },
      setAllAssignedEmployeeList: (state) => {
        state.assignedEmployeeList = state.allassignedEmployeeList;
      },
      filterAssignedEmployeeList: (state,{payload}) => {
        state.assignedEmployeeList = state.allassignedEmployeeList?.filter((assigned)=>assigned?.email?.toLowerCase()?.includes(payload.toLowerCase())||assigned?.employeeName?.toLowerCase()?.includes(payload.toLowerCase()));
      },
      setTotalCampaignList:(state,{payload})=>{
        state.totalCampaignList=payload
      },
      setCampaignDetails:(state,{payload})=>{
        state.campaignDeatils=payload
      }
      
    }
})

export default campaignSlice.reducer
export const {setCampaignListFail,setCampaignDetails,setTotalCampaignList,setCampaignListLoading,setCampaignListSuccess,setAssignedEmployeeList,filterAssignedEmployeeList,setAllAssignedEmployeeList} = campaignSlice.actions