import React, { useEffect, useState } from 'react'
import { IoMdArrowDropdown } from 'react-icons/io';
import { useDispatch } from 'react-redux';
import { useSelector } from "react-redux";
import axiosConfig from '../../www/http';
import { notifyErrorMessage, notifySuccessMessage } from '../../components/utils/toastMessage';
import { getRoleAssignList } from '../../apis/apiService';
import { useFormik } from 'formik'
import { Dialog, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import { not_access_Message } from '../../components/utils/allIdList';
import { assignTicketValidation } from '../../Validation/assignTicketSchema';

export const EditAssignRole = ({ isOpen, closeModal, details }) => {

    const dispatch = useDispatch()
    const { roleList } = useSelector((state) => state.settingSlice);
    const { xvi_id } = useSelector((state) => state.loginSlice);


    console.log("DATA......................", details);

    const [loading, setLoading] = useState(false)
    const [agentId, setAgentId] = useState(null)
    const [assignId, setAssignId] = useState(null)

    useEffect(() => {
        setFieldValue("password", details?.password)

        const selectedAgent = AgentIDs.filter((v) => v?.agent_id === details?.employeeId)
        console.log("Selected...............", selectedAgent);
        setFieldValue("selected_Agent", selectedAgent[0])

        const selectedRole = roleList.find(elm => elm?.role_id === details?.role_id)

        setFieldValue("role", selectedRole)

        setAgentId(details?.employeeId)
        setAssignId(details?.roleId)
    }, [])

    const initialValues = {
        selected_Agent: "",
        role: "",
        password: "",
    }





    const AgentIDs = [
        { agent_id: "MT001", agent_name: "Bhagyashree", agent_phone: 9018864671, agent_email: 'itzbiswo@gmail.com' },
        { agent_id: "MT002", agent_name: "Somya", agent_phone: 8018899671, agent_email: 'itzbiswo1@gmail.com' },
        { agent_id: "MT003", agent_name: "Mamuni", agent_phone: 8018884671, agent_email: 'itzbiswo2@gmail.com' },
        { agent_id: "MT004", agent_name: "Biswo", agent_phone: 8018864671, agent_email: 'itzbiswo3@gmail.com' },
        { agent_id: "MT005", agent_name: "Manash Barik", agent_phone: 8018864671, agent_email: 'itzbiswo4@gmail.com' },
        { agent_id: "MT006", agent_name: "Tapu Panda", agent_phone: 8018864671, agent_email: 'itzbiswo5@gmail.com' },
        { agent_id: "MT007", agent_name: "Satya Barik", agent_phone: 8018864671, agent_email: 'itzbiswo6@gmail.com' },
        { agent_id: "MT008", agent_name: "Lipu Nayak", agent_phone: 8018864671, agent_email: 'itzbiswo7@gmail.com' },
        { agent_id: "MT009", agent_name: "Suvam Panigrahi", agent_phone: 8018864671, agent_email: 'itzbiswo8@gmail.com' },
        { agent_id: "MT0010", agent_name: "Bisnupriya Mohanty", agent_phone: 8018864671, agent_email: 'itzbiswo9@gmail.com' },
        { agent_id: "MT0011", agent_name: "Prakash Panda", agent_phone: 8018864671, agent_email: 'itzbiswo10@gmail.com' },
    ]


    const handleSelectAgentId = (id) => {
        const selected = AgentIDs.find(elm => elm?.agent_id === id)
        setFieldValue("selected_Agent", selected)
        setAgentId(selected?.agent_id)
    }


    const handleAssignRole = (id) => {
        const selected = roleList.find(elm => elm?.role_id === id)
        console.log("SELECTEDDDDDDDDDDD", selected);
        setFieldValue("role", selected)
        setAssignId(selected?.role_id)
    }








    const { values, handleBlur, handleChange, handleSubmit, errors, touched, setFieldValue, resetForm } = useFormik({
        initialValues: initialValues,
        validationSchema: assignTicketValidation,

        onSubmit: async (values) => {

            const formData = new FormData()
            formData.append("bank_branchId", "eff26b52-6d88-48f6-b94e-45d148ea5101")
            formData.append("email", values?.selected_Agent.agent_email)
            formData.append("employeeId", values?.selected_Agent.agent_id)
            formData.append("employeeName", values?.selected_Agent.agent_name)
            formData.append("phone", values?.selected_Agent.agent_phone)
            formData.append("image", "https://cdn.pixabay.com/photo/2023/05/19/17/18/rose-beetle-8004990_640.jpg")
            formData.append("password", values?.password)
            formData.append("roleId", values?.role.role_id)

            console.log("Valuesssssss", values);

            // CALL THE API HERE
            try {
                setLoading(true)
                const { data } = await axiosConfig.post(`/save-role-assign`, formData, {
                    headers: {
                        "X-V-I": `${xvi_id}`
                    }
                })
                if (data?.status === "SUCCESS") {
                    closeModal()
                    setLoading(false)
                    notifySuccessMessage(data?.message)
                    dispatch(getRoleAssignList(xvi_id, 0))

                }
            } catch (error) {
                setLoading(false)
                notifySuccessMessage(error?.message)
            }

        }
    })



    return (
        <>

            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={() => { }}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black/25" />
                    </Transition.Child>



                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-[420px]  transform overflow-hidden rounded-2xl bg-white  text-left align-middle shadow-xl transition-all">


                                    {/* ---------------------------------------------------------------- */}

                                    <div class="w-full flex flex-col  bg-white shadow-sm rounded-xl pointer-events-auto">
                                        <div className="flex flex-col  max-w-[438px] shadow-2xl rounded-2xl">
                                            <div className="flex items-center justify-between p-8 font-medium text-white bg-[#1B99A1] rounded-t-xl">
                                                <div>
                                                    <div className="text-2xl">Create new assignee</div>
                                                    <div className="mt-1 text-xs">Short description</div>
                                                </div>
                                                <div>
                                                    <svg width="75" height="75" viewBox="0 0 75 75" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path opacity="0.2" d="M74.9977 56.1519V67.4424C75.0019 68.4906 74.7868 69.5281 74.3661 70.4884C73.9453 71.4488 73.3283 72.3109 72.5544 73.0194C71.7805 73.728 70.8669 74.2675 69.872 74.6033C68.8771 74.9391 67.8229 75.0638 66.7769 74.9694C55.173 73.7111 44.0266 69.7538 34.2334 63.4155C25.1221 57.6372 17.3972 49.9277 11.6075 40.8344C5.23448 31.0162 1.26841 19.8378 0.0306079 8.20481C-0.063627 7.16408 0.0603041 6.11516 0.394509 5.12485C0.728714 4.13454 1.26587 3.22452 1.97178 2.45275C2.67769 1.68097 3.53688 1.06434 4.49466 0.642126C5.45244 0.219908 6.48781 0.00134896 7.53486 0.000364881H18.8478C20.6779 -0.0176113 22.4521 0.629166 23.8397 1.82014C25.2273 3.01112 26.1336 4.66503 26.3898 6.4736C26.8672 10.0868 27.7528 13.6345 29.0294 17.0491C29.5368 18.3961 29.6466 19.8601 29.3458 21.2675C29.0451 22.6749 28.3464 23.9668 27.3325 24.9901L22.5434 29.7697C27.9116 39.1918 35.7284 46.9932 45.1692 52.3508L49.9584 47.5711C50.9837 46.5592 52.2781 45.8619 53.6883 45.5617C55.0985 45.2616 56.5654 45.3712 57.9151 45.8775C61.3364 47.1517 64.8912 48.0354 68.5116 48.512C70.3434 48.7699 72.0163 49.6907 73.2122 51.0994C74.4081 52.508 75.0436 54.3062 74.9977 56.1519Z" fill="white" />
                                                    </svg>
                                                </div>
                                            </div>
                                            <div className="flex flex-col p-8 w-full">
                                                <div className="text-sm font-medium leading-5 text-zinc-800">
                                                    Agent ID
                                                </div>
                                                <div className="flex gap-2.5 justify-center px-2 py-3 mt-1 text-sm font-medium whitespace-nowrap rounded border border-solid border-black border-opacity-20 text-zinc-800">
                                                    <select className='w-full outline-none' value={agentId} onChange={(e) => handleSelectAgentId(e.target.value)}>
                                                        <option value="volvo">Select</option>
                                                        {
                                                            AgentIDs?.map((v, i) => (
                                                                <option key={i} value={v?.agent_id}>{v?.agent_id}</option>
                                                            ))
                                                        }
                                                    </select>

                                                </div>
                                                {/* Error text */}
                                                {
                                                    (errors?.selected_Agent && touched?.selected_Agent) &&

                                                    <p className='text-red-700 pt-2'>{errors?.selected_Agent}</p>
                                                }
                                                <div className="flex flex-col px-5 py-4 mt-3 w-full rounded-xl bg-gray-300 bg-opacity-40">
                                                    <div className="flex gap-7">
                                                        <div className="text-xs font-medium leading-5 text-zinc-800 text-opacity-80">
                                                            Name
                                                        </div>
                                                        <div className="text-sm font-semibold leading-5 text-zinc-800">
                                                            {values?.selected_Agent && values?.selected_Agent?.agent_name}
                                                        </div>
                                                    </div>
                                                    <div className="flex gap-7 mt-3">
                                                        <div className="text-xs font-medium leading-5 text-zinc-800 text-opacity-80">
                                                            Phone
                                                        </div>
                                                        <div className="text-sm font-semibold leading-5 text-zinc-800">
                                                            {values?.selected_Agent && values?.selected_Agent?.agent_phone}
                                                        </div>
                                                    </div>
                                                    <div className="flex gap-7  mt-3 whitespace-nowrap">
                                                        <div className="text-xs font-medium leading-5 text-zinc-800 text-opacity-80">
                                                            Email
                                                        </div>
                                                        <div className="text-sm font-semibold leading-5 text-zinc-800">
                                                            {values?.selected_Agent && values?.selected_Agent?.agent_email}
                                                        </div>
                                                    </div>
                                                </div>


                                                <div>
                                                    <div className="mt-3 text-sm font-medium leading-5 text-zinc-800">
                                                        Assign Role
                                                    </div>
                                                    <div className="flex flex-col justify-center items-end px-3 py-3.5 mt-1 rounded border border-solid border-black border-opacity-20 w-full">
                                                        <select value={assignId} onChange={(e) => handleAssignRole(e.target.value)} className='w-full outline-none'>
                                                            <option value="select">Select</option>
                                                            {
                                                                roleList?.map((v, i) => (
                                                                    <option key={i} value={v?.role_id}>{v?.role_name}</option>
                                                                ))
                                                            }
                                                        </select>

                                                    </div>
                                                </div>

                                                {/* Error text */}
                                                {
                                                    (errors?.role && touched?.role) &&

                                                    <p className='text-red-700 pt-2'>{errors?.role}</p>
                                                }

                                                <div>
                                                    <div className="mt-3 text-sm font-medium leading-5 text-zinc-800">
                                                        Password
                                                    </div>
                                                    <input value={values?.password} onChange={handleChange("password")} className="shrink-0 mt-1 h-10 rounded border border-solid border-black border-opacity-20 w-full outline-none px-2" />
                                                </div>

                                                {/* Error text */}
                                                {
                                                    (errors?.password && touched?.password) &&

                                                    <p className='text-red-700 pt-2'>{errors?.password}</p>
                                                }

                                                <div className=" flex gap-2.5  justify-end mt-5 font-medium whitespace-nowrap ">
                                                    <button onClick={closeModal} data-hs-overlay="#assign-role-settings" id="cancel-button" className="cursor-pointer justify-center px-4 py-1.5 text-xs leading-5 rounded-md border border-solid border-black border-opacity-20 text-zinc-800">
                                                        Cancel
                                                    </button>
                                                    <button type='button' disabled={loading ? true : false} onClick={handleSubmit} className="cursor-pointer justify-center px-4 py-1.5 text-sm leading-5 text-white rounded-md bg-[#395185]">
                                                        Save
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>


                </Dialog>
            </Transition>

        </>
    )
}
