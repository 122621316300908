import Chart from 'chart.js/auto'
import { useEffect, useRef } from 'react'

const PieChart = ({ data1 = 10, data2 = 40 }) => {

    console.log("data1", data1);
    console.log("data2", data2);

    const chartRef = useRef(null)
    const chartInstance = useRef(null)



    useEffect(() => {
        if (chartInstance.current) {
            chartInstance.current.destroy()
        }
        const myChartRef = chartRef.current.getContext("2d")

        chartInstance.current = new Chart(myChartRef, {
            type: "pie",
            labels: ['Red', 'Orange', 'Yellow', 'Green', 'Blue'],
            data: {
                datasets: [
                    {
                        label: 'Loan',
                        data: [data1, data2],
                        backgroundColor: ["#ed8c2b", "#a5c92e"],
                    }
                ]
            },
            options: {
                title: {
                    display: true,
                    text: 'Chart.js Pie Chart'
                }
            }
        })
        return () => {
            if (chartInstance.current) {
                chartInstance.current.destroy()
            }
        }
    }, [])

    return (
        <div>
            <h1 className='text-center p-3 font-bold text-2xl'>Break-up of Total Payment</h1>

            <div className='flex items-center justify-center'>
                <div className=' h-80 w-80 '>
                    <canvas ref={chartRef} className='h-60 w-60' />
                </div>
            </div>

            <div className='flex justify-between'>
                <div className='flex gap-3 justify-center items-center p-5'>
                    <div className='h-3 w-3 bg-green-700 rounded-full'></div>
                    <p className='font-semibold'>Prinncpal Loan Amount</p>
                </div>
                <div className='flex gap-3 justify-center items-center p-5'>
                    <div className='h-3 w-3 bg-orange-700 rounded-full'></div>
                    <p className='font-semibold'>Total Intrest</p>
                </div>
            </div>

        </div>
    )
}

export default PieChart