import React, { useEffect, useState } from 'react'
import Template from '../../../components/common/Template'
import { IoIosArrowDropdown, IoMdCheckboxOutline, IoMdTime } from 'react-icons/io'
import { BsTextParagraph } from 'react-icons/bs'
import { MdDateRange, MdDelete, MdOutlineRadioButtonChecked } from 'react-icons/md'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { TiTick } from 'react-icons/ti'
import { useDispatch } from 'react-redux'
import axiosConfig from '../../../www/http'
import { useSelector } from 'react-redux'
import { notifyErrorMessage, notifySuccessMessage } from '../../../components/utils/toastMessage'
import { useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import AccessComponent from '../../../components/utils/checkAccess'
import { AccessIds } from '../../../components/utils/allIdList'
import { getMarkettingFormListAll, getMarkettingFormListMy } from '../../../apis/apiService'


const MarkettingFormsAdd = () => {
  const navigation = useNavigate()
  const dispatch = useDispatch()

  const { xvi_id, branchId } = useSelector(state => state.loginSlice)

  const [formid, setFormid] = useState(null)


  const accessAllList = AccessComponent(AccessIds.MarkettingForm_allList)
  const accessMyList = AccessComponent(AccessIds.MarkettingForm_myList)



  const AddElements = [
    { id: "1", name: "Short answer", labels: "add labels", value: "", icon: <IoMdCheckboxOutline />, html: <input type='text' className="shrink-0 h-8 bg-white rounded border border-solid border-black border-opacity-20 w-full" /> },
    { id: "2", name: "Paragraph", labels: "add labels", value: "", icon: <BsTextParagraph />, html: <input type='text' className="shrink-0 h-8 bg-white rounded border border-solid border-black border-opacity-20 w-full" /> },
    { id: "3", name: "Drop-down", labels: "add labels", value: "", icon: <IoIosArrowDropdown />, html: <input type='text' className="shrink-0 h-8 bg-white rounded border border-solid border-black border-opacity-20 w-full" /> },
    { id: "4", name: "Checkbox", labels: "add labels", value: "", icon: <IoMdCheckboxOutline />, html: <input type='checkbox' className="shrink-0 h-8 bg-white rounded border border-solid border-black border-opacity-20 w-full" /> },
    { id: "5", name: "Multiple choice", labels: "add labels", value: "", icon: <MdOutlineRadioButtonChecked />, html: <input type='radio' className="shrink-0 h-8 bg-white rounded border border-solid border-black border-opacity-20 w-full" /> },
    { id: "6", name: "Date", labels: "add labels", value: "", icon: <MdDateRange />, html: <input type='date' className="shrink-0 h-8 bg-white rounded border border-solid border-black border-opacity-20 w-full" /> },
    { id: "7", name: "Time", labels: "add labels", value: "", icon: <IoMdTime />, html: <input type='time' className="shrink-0 h-8 bg-white rounded border border-solid border-black border-opacity-20 w-full" /> }
  ]


  const [initialdata, setInitialData] = useState([])
  const [formName, setFormName] = useState(null)



  const handleAddEvent = (event) => {
    setInitialData([...initialdata, event])
  }


  const handleEditDelete = (type, event) => {
    if (type == 1) {

    } else {
      console.log("Evevt....", event);
      const filterDta = initialdata.filter((v, i) => (v?.id !== event?.id))
      setInitialData(filterDta)

    }
  }



  const handleChangeLabel = (text, id) => {

    const updatedElements = initialdata.map(element => {
      if (element.id === id) {
        return {
          ...element,
          value: text,
        };
      }
      return element;
    });

    setInitialData(updatedElements);
  };

  function validateArray(array) {



    for (const item of array) {
      if (!item.value) {
        notifyErrorMessage(`Validation failed: Item with id ${item.id} has an empty value.`)
        return false
      }
    }

    return true;
  }

  const handleSaveForm = async (type) => {

    console.log("111111111111111111111111111111");
    if (!formName) {
      notifyErrorMessage("Please enter form name")
      return
    }

    if (validateArray(initialdata)) {



      console.log("22222222222222222222222222222222");

      // type===1===draft && type===2===publish
      console.log("TYPE...............", type);

      // const id = formid
      // const id = "15e0a97c-3cc6-4e0e-8d0b-b34c34667b79"

      const payload = {
        "id": formid,
        "keyPair": initialdata.map((item, index) => ({
          key: item.name.toLowerCase().replace(/ /g, ''),
          pair: item.value,
          sortOrder: index
        })),
        "name": formName,
        "path": "https://crm-pairalabs.netlify.app/#/marketing/forms/fillup/" + formid,
        "status": type
      }

      console.log("Payload......................", payload);
      // const formdata = new FormData()
      // formdata.append("formDTO", payload)

      try {
        const { data } = await axiosConfig.put(`/form/edit-marketing-form`, payload, {
          headers: {
            "X-V-I": `${xvi_id}`,
          }
        })
        console.log("Successssssssssssssssssssss", data);
        if (data?.status === "SUCCESS") {
          notifySuccessMessage(data?.message)
          navigation(-1)
        }

      } catch (error) {
        console.log("Faileddddddddddddddddd", error);
      }

    }
  }

  // Initially Create Form 
  useEffect(() => {
    const CreateForm = async () => {

      const formdata = new FormData()
      formdata.append("branch", branchId)
      formdata.append("formName", "Login Form")
      formdata.append("status", 1)

      try {
        const { data } = await axiosConfig.post(`/form/save-marketing-form`, formdata, {
          headers: {
            "X-V-I": `${xvi_id}`,
            "branch": branchId
          }
        })
        if (data?.status === "SUCCESS") {
          console.log("Form Created..................", data);
          setFormid(data?.id)


          // Call Get Api 
          if (accessAllList) {
            dispatch(getMarkettingFormListAll({ xvi_id, page: 0 }))
          }
          if (accessMyList) {
            dispatch(getMarkettingFormListMy({ xvi_id, page: 0 }))
          }

        }

      } catch (error) {
        console.log("Faileddddddddddddddddd", error);
      }
    }
    CreateForm()
  }, [])




  console.log("initialdata..............................", initialdata);


  return (
    <Template>
      <div>



        {/* Header */}
        <div className="flex gap-2.5 self-stretch px-20 py-5 bg-white max-md:flex-wrap max-md:px-5">
          <div className="flex flex-col flex-1 max-md:max-w-full">
            <div onClick={() => navigation(-1)} className="cursor-pointer flex gap-2 self-start text-xs whitespace-nowrap text-zinc-800">
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/34deee8d7123904a5003e3e94732b7f59d4e0934eaaef9a558db6eb026d5fca9?"
                className="shrink-0 w-4 aspect-square"
              />
              <div>Back</div>
            </div>
            <div className="mt-2 text-xl font-semibold text-slate-950 max-md:max-w-full">
              Add form :
              <input value={formName} onChange={(e) => setFormName(e.target.value)} placeholder='Enter Your Form Name' className='outline-none px-2' />
            </div>
          </div>
          <div className="flex gap-2 justify-center my-auto text-xs font-medium leading-5 text-zinc-800">
            <div className="justify-center px-3.5 py-1.5 whitespace-nowrap rounded-md border border-solid border-black border-opacity-20">
              Cancel
            </div>
            <button onClick={() => handleSaveForm(1)} className="cursor-pointer justify-center px-3.5 py-1.5 rounded-md border border-solid border-black border-opacity-20">
              Save Draft
            </button>
            <button onClick={() => handleSaveForm(2)} className="cursor-pointer justify-center px-3.5 py-1.5 text-sm leading-5 text-white whitespace-nowrap rounded-md bg-slate-600">
              Publish
            </button>
          </div>
        </div>

        {/* Body */}
        <div className='w-full flex justify-center items-center'>
          <div className="flex flex-col self-stretch pb-20 bg-white rounded-lg min-w-[844px] my-10 shadow-2xl">



            <div className="flex flex-col p-10 w-full max-md:px-5 max-md:max-w-full">
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/9a79c51f03c56ec2fa674f32f5950aae48a6b5641f4c716161c15034cebd1e5f?"
                className="max-w-full aspect-[14.29] w-[421px]"
              />


              <div id='market_forms_dynamic'>


                <div>

                  {/* Name */}
                  <div style={{ marginTop: '20px', fontSize: '12px', fontWeight: '600', lineHeight: '16px', color: 'black', fontFamily: 'sans-serif', padding: '8px 0' }}>
                    Name <span style={{ color: 'orange' }}>*</span>
                  </div>
                  <input style={{ outline: "none", padding: "2px", height: '32px', backgroundColor: 'white', borderRadius: '4px', border: '1px solid rgba(0, 0, 0, 0.2)', width: '100%' }} />


                  <div style={{ display: 'flex', gap: '20px', marginTop: '20px' }}>

                    {/* Phone */}
                    <div style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                      <div style={{ marginTop: '20px', fontSize: '12px', fontWeight: '600', lineHeight: '16px', color: 'black', fontFamily: 'sans-serif', padding: '8px 0' }}>
                        Phone <span style={{ color: 'orange' }}>*</span>
                      </div>
                      <input type='number' style={{ outline: "none", display: 'flex', gap: '10px', justifyContent: 'center', padding: '10px', fontSize: '14px', fontWeight: '500', whiteSpace: 'nowrap', backgroundColor: 'white', borderRadius: '4px', border: '1px solid rgba(0, 0, 0, 0.2)', color: '#525252', opacity: 0.6 }} />
                    </div>

                    {/* Email */}
                    <div style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                      <div style={{ marginTop: '20px', fontSize: '12px', fontWeight: '600', lineHeight: '16px', color: 'black', fontFamily: 'sans-serif', padding: '8px 0' }}>
                        Email <span style={{ color: 'orange' }}>*</span>
                      </div>
                      <input type='email' style={{ outline: "none", display: 'flex', gap: '10px', justifyContent: 'center', padding: '10px', fontSize: '14px', fontWeight: '500', whiteSpace: 'nowrap', backgroundColor: 'white', borderRadius: '4px', border: '1px solid rgba(0, 0, 0, 0.2)', color: '#525252', opacity: 0.6 }} />
                    </div>

                    {/* Needs */}
                    <div style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                      <div style={{ marginTop: '20px', fontSize: '12px', fontWeight: '600', lineHeight: '16px', color: 'black', fontFamily: 'sans-serif', padding: '8px 0' }}>
                        Needs <span style={{ color: 'orange' }}>*</span>
                      </div>
                      <input style={{ outline: "none", display: 'flex', gap: '10px', justifyContent: 'center', padding: '10px', fontSize: '14px', fontWeight: '500', whiteSpace: 'nowrap', backgroundColor: 'white', borderRadius: '4px', border: '1px solid rgba(0, 0, 0, 0.2)', color: '#525252', opacity: 0.6 }} />
                    </div>

                  </div>

                  <div style={{ marginTop: '20px', fontSize: '12px', fontWeight: '600', lineHeight: '16px', color: 'black', fontFamily: 'sans-serif', padding: '8px 0' }}>
                    Description
                  </div>
                  <textarea style={{ height: '96px', padding: "4px", backgroundColor: 'white', borderRadius: '4px', border: '1px solid rgba(0, 0, 0, 0.2)', width: '100%' }} />
                </div>

                <div>
                  {/* Dynamic Form */}
                  <div
                    style={{
                      display: 'grid',
                      gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
                      gap: '12px',
                      padding: '7px 0px'
                    }}
                  >
                    {initialdata?.map((card, index) => (

                      <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        flex: 1,
                        justifyContent: 'center',
                        // backgroundColor: '#d1d5db',
                        borderRadius: '12px',
                        // padding: '20px'
                      }}>
                        <div style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          padding: '8px'
                        }}>
                          <input
                            value={card?.value}
                            onChange={(e) => handleChangeLabel(e.target.value, card?.id)}
                            placeholder={card?.labels}
                            style={{
                              fontSize: '14px',
                              fontWeight: '600',
                              lineHeight: '16px',
                              color: 'black',
                              backgroundColor: '#d1d5db',
                              padding: '8px',
                              flex: 1
                            }}
                          />
                          {false ? (
                            <div onClick={() => handleEditDelete(1, card)}>
                              <TiTick color='green' size={20} />
                            </div>
                          ) : (
                            <div onClick={() => handleEditDelete(0, card)}>
                              <MdDelete color='red' size={20} />
                            </div>
                          )}
                        </div>
                        {card.html}
                      </div>

                    ))}
                  </div>
                </div>



              </div>









              {/* <div className="mt-8 text-xs font-semibold leading-4 text-black font-sans py-2 max-md:max-w-full">
                Dynamic Name <span className="text-orange-600">*</span>
              </div>
              <input type='text' className="shrink-0 h-8 bg-white rounded border border-solid border-black border-opacity-20 w-full" /> */}


              <div className="flex gap-2.5 justify-between py-3 pr-3 pl-3.5 mt-5 w-full text-xs font-medium rounded-lg border border-dashed bg-zinc-800 bg-opacity-10 border-zinc-800 border-opacity-20 text-zinc-800 max-md:flex-wrap max-md:max-w-full">
                <div class="mt-60 mx-1 sm:mt-1 hs-dropdown [--auto-close:false] relative sm:inline-flex">
                  <button id="add-element-dropdown" type="button" class="hs-dropdown-toggle">
                    <div className="cursor-pointer flex gap-2.5 justify-center">
                      <img
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/3830bee3aa33acea18ea1de744fda248ee7072517019d90f3ac3058b192c6eac?"
                        className="shrink-0 w-8 aspect-square"
                      />
                      <div className="my-auto">Add element</div>
                    </div>
                  </button>

                  <div class="hs-dropdown-menu transition-[opacity,margin] duration hs-dropdown-open:opacity-100 opacity-0 hidden bg-white w-[220px] shadow-2xl rounded-lg p-2 mt-2" aria-labelledby="add-element-dropdown">
                    <div>

                      {
                        AddElements.map((v, i) => (
                          <div onClick={() => handleAddEvent(v)} key={i} className="flex gap-3 py-3 whitespace-nowrap border-b border-solid border-zinc-800 border-opacity-10">
                            <div>{v?.icon}</div>
                            <div className="my-auto font-semibold text-black text-sm">{v?.name}</div>
                          </div>
                        ))
                      }




                    </div>
                  </div>
                </div>
                <div class="mt-60 mx-1 sm:mt-1 hs-dropdown [--auto-close:false] relative sm:inline-flex">
                  <button id="option-dropdown" type="button" class="hs-dropdown-toggle">
                    <div className="cursor-pointer flex gap-2.5 justify-center p-2 whitespace-nowrap">
                      <div>Option</div>
                      <img
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/1f0d4b9bdf1500cbc4bc35b1a18184617b939f0f9579ad3670520170541063df?"
                        className="shrink-0 w-4 aspect-square"
                      />
                    </div>
                  </button>

                  <div class="hs-dropdown-menu transition-[opacity,margin] duration hs-dropdown-open:opacity-100 opacity-0 hidden bg-white shadow-md rounded-lg p-2 mt-2 dark:bg-neutral-800 dark:border dark:border-neutral-700" aria-labelledby="option-dropdown">
                    <div className="flex flex-col px-5 py-2 text-xs font-medium bg-white rounded-lg shadow-2xl max-w-[240px] text-zinc-800">
                      <div className="flex gap-3 py-3">
                        <img
                          loading="lazy"
                          src="https://cdn.builder.io/api/v1/image/assets/TEMP/57e613c28f0ae40d4bc89a61fc0b4d4abb476eed2293940c65e8d112f9281527?apiKey=4c514e50e07d4444bfd3ae3d48b8bd32&"
                          className="shrink-0 w-5 aspect-square"
                        />
                        <div className="my-auto">1 Column</div>
                      </div>
                      <div className="justify-center items-start px-8 py-3 capitalize border-t border-solid border-zinc-800 border-opacity-10">
                        2 Columns
                      </div>
                      <div className="justify-center items-start px-8 py-3 border-t border-solid border-zinc-800 border-opacity-10">
                        3 Column
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Template>
  )
}

export default MarkettingFormsAdd