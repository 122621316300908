import { setAssignedEmployeeList, setCampaignDetails, setCampaignListFail, setCampaignListLoading, setCampaignListSuccess, setTotalCampaignList } from "../redux/slices/campaignSlice"
import axiosConfig from "../www/http"


export const getCampaignList = (payload) => async (dispatch) => {
    try {
        let url = ""
        if (payload?.from && payload?.to) {
            url = `/get-list-campaign?page=${payload?.page}&pageSize=10&campaignStatus=${payload?.status === 0 ? "" : payload?.status === 1 ? "published" : payload?.status === 2 ? "draft" : "scheduled"}&createdBy=${payload?.created}&fromPeriod=${payload?.from}&toPeriod=${payload?.to}&search=${payload?.search}`
        } else {
            url = `/get-list-campaign?page=${payload?.page}&pageSize=10&campaignStatus=${payload?.status === 0 ? "" : payload?.status === 1 ? "published" : payload?.status === 2 ? "draft" : "scheduled"}&createdBy=${payload?.created}&search=${payload?.search}`
        }
        dispatch(setCampaignListLoading())
        const { data } = await axiosConfig.get(url, {
            headers: {
                "X-V-I": `${payload?.id}`
            }
        })
        dispatch(setCampaignListSuccess(data?.data))
        dispatch(setTotalCampaignList(data?.total_elements))
    } catch (error) {
        dispatch(setCampaignListFail())
    }
}
export const getEmployeeCampaignList = (payload) => async (dispatch) => {
    try {
        dispatch(setCampaignListLoading())
        const { data } = await axiosConfig.get(`/my-list-campaign?page=${payload?.page}&pageSize=10`, {
            headers: {
                "X-V-I": `${payload?.id}`
            }
        })
        dispatch(setCampaignListSuccess(data?.data))
        dispatch(setTotalCampaignList(data?.total_elements))
    } catch (error) {
        dispatch(setCampaignListFail())
    }
}


export const getAssignedEmployeeList = (id) => async (dispatch) => {
    try {
        const { data } = await axiosConfig.get(`/get-list-role-assign?page=0&pageSize=10000`, {
            headers: {
                "X-V-I": `${id}`
            }
        })
        dispatch(setAssignedEmployeeList(data?.data))
    } catch (error) {

    }
}

export const getCamapignDetails = (payload) => async (dispatch) => {
    try {
        const { data } = await axiosConfig.get(`/get-details-campaign?campaignId=${payload?.campaign_id}`, {
            headers: {
                "X-V-I": `${payload?.id}`
            }
        })
        console.log("setCampaignDetails................", data);
        dispatch(setCampaignDetails(data?.campaignDetails))
    } catch (error) {

    }
}