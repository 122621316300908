import React from 'react'
import { IoLogOutOutline } from 'react-icons/io5'
import { useNavigate } from 'react-router-dom'
import * as routesKey from "../../constants/routes"
import { useDispatch } from 'react-redux'
import { setIsAuthenticated } from '../../redux/slices/loginSlice'

export const LogoutModal = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const handleLogout = (e) => {
        dispatch(setIsAuthenticated(false))
        localStorage.clear();
        navigate(routesKey.LOGIN)
    }

    return (
        <>


            <div data-hs-overlay="#logout-modal">
                <IoLogOutOutline fill='white' size={20} color='white' />
            </div>





            <div id="logout-modal" class="hs-overlay hidden size-full fixed top-0 start-0 z-[80] overflow-x-hidden overflow-y-auto pointer-events-none">
                <div class="hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 mt-0 opacity-0 ease-out transition-all sm:max-w-lg sm:w-full m-3 sm:mx-auto">
                    <div class="flex flex-col bg-white border shadow-sm rounded-xl pointer-events-auto">

                        <div class="p-4 overflow-y-auto">
                            <p class="mt-1 text-black font-bold">
                                Are You Sure Want to Logout !!
                            </p>
                        </div>
                        <div class="flex justify-end items-center gap-x-2 py-3 px-4 ">
                            <button type="button" class="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-800" data-hs-overlay="#logout-modal">
                                Close
                            </button>
                            <button onClick={handleLogout} type="button" class="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none" data-hs-overlay="#logout-modal">
                                Yes
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
