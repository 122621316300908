import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getListRole } from '../../apis/apiService'
import { useDispatch } from 'react-redux'
import Loader from '../../components/Loader/loader'
import { NoData } from '../../components/common/noData'
import Pagination from '../../components/common/pagination'
import { EditNewRoleModal } from './editNewRole'
import { MdVerified } from "react-icons/md";
import { VscUnverified } from "react-icons/vsc";

const RolesList = () => {

    const { roleList, isRoleListLoading, roleListTotalElements } = useSelector((state) => state.settingSlice)

    const { xvi_id } = useSelector((state) => state.loginSlice)

    const dispatch = useDispatch()

    const [page, setPage] = useState(0)
    const [isOpenEditRoleModal, setIsOpenEditRoleModal] = useState(false)
    const [data, setData] = useState(null)

    useEffect(() => {
        dispatch(getListRole(xvi_id, page))
    }, [page])

    console.log("roleList,,,,,,,,,,,,,,,,,,,,,", roleList);

    return (
        <div className='w-full justify-center  flex'>

            {
                isOpenEditRoleModal &&
                <EditNewRoleModal isOpenEditRoleModal={isOpenEditRoleModal} data={data} closeModal={() => setIsOpenEditRoleModal(false)} />
            }

            {
                isRoleListLoading ?
                    <Loader />
                    :
                    <div className='w-full justify-center  flex flex-col'>
                        <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>

                            {
                                !(roleList?.length) ?
                                    <div className='w-full'>
                                        <NoData />
                                    </div>
                                    :

                                    <div className='w-[93%] bg-white shadow-2xl rounded-3xl my-8 px-6 py-6'>
                                        {/* Header */}
                                        <div className='bg-black flex items-center p-3'>
                                            <div className='w-[40%] flex items-center gap-3'>
                                                <div className='h-5 w-5 border border-black'></div>
                                                <h1 className='text-white font-semibold'>Role Name</h1>
                                            </div>
                                            {/* <h1 className='text-white font-semibold w-[10%] '>Full Permission</h1>
                                            <h1 className='text-white font-semibold w-[10%] '>Save</h1>
                                            <h1 className='text-white font-semibold w-[10%] '>Edit</h1>
                                            <h1 className='text-white font-semibold w-[10%] '>All List</h1>
                                            <h1 className='text-white font-semibold w-[10%] '>My List</h1>
                                            <h1 className='text-white font-semibold w-[10%] '>Details</h1>
                                            <h1 className='text-white font-semibold w-[10%] '>Assign to</h1> */}
                                        </div>

                                        {/* Body */}

                                        {
                                            roleList?.map((v, i) => (
                                                <div className='bg-white flex items-center p-3 border-b'>
                                                    <div onClick={() => { setIsOpenEditRoleModal(true); setData(v) }} className='w-[40%] flex items-center gap-3 cursor-pointer'>
                                                        <div className='h-5 w-5 border border-white'></div>
                                                        <img src='https://cdn.pixabay.com/photo/2022/05/24/06/23/indian-face-7217718_640.jpg' alt='Profile Image' className='h-8 w-8 rounded-md bg-violet-700' />

                                                        <div>
                                                            <h1 className='text-black font-semibold'>{v?.role_name}</h1>
                                                            <h1 className='text-black font-light text-[10px]'>Created on : {v?.created_at.split(' ')[0]}</h1>
                                                        </div>

                                                    </div>






                                                </div>

                                            ))
                                        }


                                    </div>
                            }
                        </div>

                        <div className='flex justify-center w-full p-4 mb-4  -bottom-3  left-14 right-0 '>

                            <Pagination
                                className="pagination-bar"
                                currentPage={page + 1}
                                totalCount={roleListTotalElements}
                                pageSize={10}

                                onPageChange={(page) => {
                                    setPage(page - 1)
                                    var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
                                    if (currentScroll > 0) {
                                        window.scrollTo(0, currentScroll - (currentScroll / 1));
                                    }
                                }}
                            />
                        </div>

                    </div>

            }
        </div>
    )
}
export default RolesList
