import React, { useEffect, useState } from 'react'
import { Route, Routes } from "react-router-dom"
import * as routesKey from "../constants/routes"
import * as pages from "../pages/index"
import ProtectedRoute from '../components/common/protectedRoute'
import { setBranchId, setEmpDetails, setId, setIsAuthenticated, setRole } from '../redux/slices/loginSlice'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import axiosConfig from '../www/http'
import Stomp from 'stompjs';
import { addNewMessage } from '../redux/slices/notificationSlice'


const AppRouter = () => {


  const { isAuthenticated, role } = useSelector((state) => state.loginSlice)


  const dispatch = useDispatch()

  useEffect(() => {


    const DETAILS = localStorage.getItem("adminDetails")
    const BRANCHID = localStorage.getItem("branchId")

    const DETAILS_OBJ = JSON.parse(DETAILS)

    console.log("API GET LOCAL", JSON.parse(DETAILS));

    if (DETAILS_OBJ) {
      dispatch(setIsAuthenticated(true))

      dispatch(setEmpDetails(DETAILS_OBJ))

      dispatch(setBranchId(JSON.parse(BRANCHID)))

      // dispatch(setRole(role))
      // dispatch(setId(Id))
    } else {
      dispatch(setIsAuthenticated(false))
    }
  }, [dispatch])


  axiosConfig.interceptors.request.use(
    (config) => {
      if (localStorage.getItem("adminDetails")) {

        const DETAILS = localStorage.getItem("adminDetails")
        const DETAILS_OBJ = JSON.parse(DETAILS)

        config.headers["X-V-I"] = `${DETAILS_OBJ.type === "ADMIN" ? DETAILS_OBJ?.adminId : DETAILS_OBJ?.employeeId}`;
      }
      return config;
    },
    (error) => {
      console.error("Authentication request failed:", error);
      return Promise.reject(error);
    }
  );

  const [stompClient, setStompClient] = useState(null);

  useEffect(() => {
    try {
        // Connect to WebSocket endpoint
    const socket = new WebSocket('ws://54.79.168.200:15674/ws');
    const stomp = Stomp.over(socket);

    // Connect to STOMP
    stomp.connect({}, () => {
      console.log('Connected to WebSocket');
      setStompClient(stomp);
      const DETAILS = localStorage.getItem("adminDetails")
      const DETAILS_OBJ = JSON.parse(DETAILS)
      // // Subscribe to topic
      // stomp.subscribe(`/queue/${localStorage.getItem("admin_id")}`, (message) => {
      //   const newMessage = JSON.parse(message.body);
      //   dispatch(addNewMessage(newMessage))
      // });
      stomp.subscribe(`/exchange/CRM/CRM${localStorage.getItem("admin_id")}`, (message) => {
        const newMessage = JSON.parse(message.body);
        dispatch(addNewMessage(newMessage))
      });
    });
    } catch (error) {
      console.log("WebSocket ErrorXXXXXXXXXXXXXXXXXX",error)
    }
    return () => {
      if (stompClient) {
        stompClient.disconnect();
      }
    };
  }, []);

  return (
    <Routes>

      <Route element={<ProtectedRoute isAuthenticated={!isAuthenticated} redirect={routesKey.HOME} />}>
        <Route path={routesKey.LOGIN} exact element={<pages.Login />} />
        <Route path={routesKey.EMPLOGIN} exact element={<pages.EmpLogin />} />
      </Route>

      <Route element={<ProtectedRoute isAuthenticated={isAuthenticated} redirect={routesKey.LOGIN} />}>
        <Route path={routesKey.HOME} exact element={<pages.Home />} />
        <Route path={routesKey.CUSTOMERSERVICE} exact element={<pages.CustomerService />} />
        <Route path={routesKey.SALESCONTACT} exact element={<pages.SalesContact />} />
        <Route path={routesKey.SALESACCOUNT} exact element={<pages.SalesAccount />} />
        <Route path={routesKey.SALESLEADS} exact element={<pages.SalesLeads />} />
        <Route path={routesKey.CUSTOMERTICKETDETAILS} exact element={<pages.CustomerTicketDetails />} />
        <Route path={routesKey.SALESLEADSDETAILS} exact element={<pages.LeadsDetails />} />
        <Route path={routesKey.MARKETINGSUBMISSIONS} exact element={<pages.MarkettingSubmissions />} />
        <Route path={routesKey.MARKETINGCAMPAIGNS} exact element={<pages.MarkettingCampaigns />} />

        <Route path={routesKey.MARKETINGFORMS} exact element={<pages.MarkettingForms />} />
        <Route path={routesKey.MARKETINGFORMSDETAILS} exact element={<pages.MarkettingFormsDetails />} />
        <Route path={routesKey.MARKETINGFORMSADD} exact element={<pages.MarkettingFormsAdd />} />
        <Route path={routesKey.MARKETINGFORMSFILL} exact element={<pages.UsersForm />} />
        <Route path={routesKey.CAMPAIGNSDETAILS} exact element={<pages.CampaignDetails />} />
        <Route path={routesKey.ADDCAMPAIGN} exact element={<pages.AddNewCampaign />} />
        <Route path={routesKey.EDITCAMPAIGN} exact element={<pages.EditCampaign />} />

        <Route path={routesKey.SETTINGS} exact element={<pages.Settings />} />
      </Route>

    </Routes>
  )
}

export default AppRouter