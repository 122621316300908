export const AllIds = [
    {
        name: 'Contact', option:
            [
                { type: false, id: '', },

                { type: true, id: "CRMCSC1", column_name: "Save" },
                { type: true, id: "CRMCUC2", column_name: "Edit" },
                { type: true, id: "CRMCCL4", column_name: "All list" },
                { type: true, id: "CRMCMC21", column_name: "My list" },
                { type: true, id: "CRMCCD3", column_name: "Details" },

                { type: false, id: "" },
            ]
    },

    {
        name: 'Lead Type', option:
            [
                { type: false, id: '' },

                { type: true, id: "CRMLTSLT6", column_name: "Save" },
                { type: true, id: "CRMLTELT8", column_name: "Edit" },
                { type: true, id: "CRMLTGLLT7", column_name: "All list" },

                { type: false, id: "" },
                { type: false, id: "" },
                { type: false, id: "" },
            ]
    },

    {
        name: 'Modules', option:
            [
                { type: false, id: '' },

                { type: true, id: "CRMMSM11", column_name: "Save" },
                { type: true, id: "CRMMEM13", column_name: "Edit" },
                { type: true, id: "CRMMGLM12", column_name: "All List" },

                { type: false, id: "" },
                { type: false, id: "" },
                { type: false, id: "" },
            ]
    },



    {
        name: 'Role Assign', option:
            [
                { type: false, id: '' },
                { type: true, id: "CRMRASRA15", column_name: "Save" },
                { type: false, id: '' },
                { type: true, id: "CRMRASRA16", column_name: "All list" },
                { type: false, id: '' },
                { type: false, id: '' },
                { type: false, id: '' },

            ]
    },
    {
        name: 'Role', option:
            [
                { type: false, id: "" },

                { type: true, id: 'CRMRER17', column_name: "Save" },
                { type: true, id: 'CRMRER19', column_name: "Edit" },
                { type: true, id: 'CRMRER18', column_name: "All list" },
                { type: false, id: "" },
                { type: false, id: "" },
                { type: false, id: "" },
            ]
    },
    {
        name: 'Marketing Forms', option:
            [
                { type: false, id: "" },

                { type: true, id: 'CRMSMF20', column_name: "Save" },

                { type: true, id: 'CRMEMF22', column_name: "Edit" },
                { type: true, id: 'CRMAMF23', column_name: "All list" },
                { type: true, id: 'CRMMMF24', column_name: "My list" },
                { type: true, id: 'CRMMD30', column_name: "Details" },


                { type: false, id: "" },
            ]
    },

    {
        name: 'Ticket', option:
            [
                { type: false, id: '' },

                { type: true, id: "CRMTST25", column_name: "Save" },

                { type: false, id: '' },

                { type: true, id: "CRMTTL26", column_name: "All list" },
                { type: true, id: "CRMTMT27", column_name: "My list" },
                { type: true, id: "CRMTTD54", column_name: "Details" },
                { type: true, id: "CRMTATT34", column_name: "Assign To" },
            ]
    },
    {
        name: 'Ticket Whatsapp', option:
            [
                { type: false, id: '' },

                { type: true, id: "CRMTWSTW40", column_name: "Save" },

                { type: false, id: "" },

                { type: true, id: "CRMTWTWL41", column_name: "All list" },

                { type: false, id: "" },
                { type: false, id: "" },
                { type: false, id: "" },

            ]
    },
    {
        name: 'Ticket Call Log', option:
            [
                { type: false, id: '' },

                { type: true, id: "CRMTCSTC42", column_name: "Save" },

                { type: false, id: "" },

                { type: true, id: "CRMTCTCL43", column_name: "All list" },

                { type: false, id: "" },
                { type: false, id: "" },
                { type: false, id: "" },

            ]
    },
    {
        name: 'Leads', option:
            [
                { type: false, id: "" },

                { type: true, id: 'CRMSL32', column_name: "Save" },
                { type: true, id: 'CRMEL33', column_name: "Edit" },
                { type: true, id: 'CRMALL36', column_name: "All list" },
                { type: true, id: 'CRMMLL37', column_name: "My list" },
                { type: true, id: 'CRMLD38', column_name: "Details" },
                { type: true, id: 'CRMATL35', column_name: "Assign To" },

            ]
    },
    {
        name: 'Leads Call Log', option:
            [
                { type: false, id: '' },
                { type: true, id: 'CRMSLC45', column_name: "Save" },
                { type: false, id: '' },
                { type: true, id: 'CRMGLCL46', column_name: "All list" },
                { type: false, id: '' },
                { type: false, id: '' },
                { type: false, id: '' },
            ]
    },
    {
        name: 'Leads Whatsapp', option:
            [
                { type: false, id: '' },
                { type: true, id: 'CRMSLW47', column_name: "Save" },
                { type: false, id: '' },
                { type: true, id: 'CRMGLW48', column_name: "All list" },
                { type: false, id: '' },
                { type: false, id: '' },
                { type: false, id: '' },
            ]
    },
    {
        name: 'Campaign', option:
            [
                { type: false, id: '' },

                { type: true, id: "CRMCSC49", column_name: "Save" },
                { type: true, id: "CRMCEC51", column_name: "Edit" },
                { type: true, id: "CRMCGLC50", column_name: "All list" },
                { type: true, id: "CRMCMLC52", column_name: "My list" },
                { type: true, id: "CRMCGDC53", column_name: "Details" },

                { type: false, id: "" },
            ]
    }
]
