import React, { useEffect, useState } from 'react'
import { Tab } from '@headlessui/react'
import Template from '../../../components/common/Template';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getTicketDetails } from '../../../apis/apiService';
import { useSelector } from 'react-redux';
import ManageTicket from './manageTicket';
import TicketWhatsapp from './whatsapp';
import CallLog from './callLog';
import Loader from '../../../components/Loader/loader';

const TicketDetails = () => {

    const [tab, setTab] = useState(1)

    const { xvi_id } = useSelector(state => state.loginSlice)
    const { ticketDetails, isTicketDetailsLoading } = useSelector(state => state.ticketSlice)
    const { ticket_id } = useParams();
    const dispatch = useDispatch()


    useEffect(() => {
        dispatch(getTicketDetails({ xvi_id, ticket_id }))
    }, [])


    const windowHeight = window.innerHeight;


    return (
        <Template>
            <div className="flex flex-col pb-6 shadow-xl" style={{ minHeight: `${windowHeight}px` }}>
                <div className="w-full bg-slate-800 min-h-[79px] max-md:max-w-full" />
                <div className="flex  flex-col justify-center self-center mt-[-40px] w-full max-w-screen-xl bg-white rounded-lg shadow-2xl max-md:max-w-full ">
                    <div className="max-md:max-w-full">
                        <div className="flex gap-5 max-md:flex-col max-md:gap-0 " style={{ minHeight: `${windowHeight - 200}px` }}>

                            {/* HEADER */}
                            <div className="flex flex-col w-[75%] max-md:ml-0 max-md:w-full ">

                                <div className="flex flex-col max-md:max-w-full ">




                                    <div className='gap-3 flex bg-[#DBDFEC] rounded-tl-xl '>
                                        <button type="button" onClick={() => setTab(1)}>
                                            <div className={`justify-center self-stretch py-3  text-xs tracking-wider leading-5 uppercase  ${tab === 1 ? "bg-white" : "bg-[#E7ECF6]"} px-7 mt-4 items-center rounded-t-xl`}>
                                                Details <span className='bg-orange-600  py-1 px-3 rounded-full text-white font-semibold'>7</span>
                                            </div>
                                        </button>

                                        <button type="button" onClick={() => setTab(2)}>
                                            <div className={`justify-center self-stretch py-3  text-xs tracking-wider leading-5 uppercase  ${tab === 2 ? "bg-white" : "bg-[#E7ECF6]"} px-7 mt-4 items-center rounded-t-xl`}>
                                                WhatsApp <span className='bg-orange-600  py-1 px-3 rounded-full text-white font-semibold'>7</span>
                                            </div>
                                        </button>


                                        <button type="button" onClick={() => setTab(3)}>
                                            <div className={`justify-center self-stretch py-3  text-xs tracking-wider leading-5 uppercase  ${tab === 3 ? "bg-white" : "bg-[#E7ECF6]"} px-7 mt-4 items-center rounded-t-xl`}>
                                                Phone Calls <span className='bg-orange-600  py-1 px-3 rounded-full text-white font-semibold'>7</span>
                                            </div>
                                        </button>


                                    </div>


                                    <div>

                                        {
                                            tab === 1 &&

                                            <div>
                                                {
                                                    isTicketDetailsLoading ?
                                                        <Loader />
                                                        :

                                                        <div>
                                                            <div className="flex gap-5 justify-between items-start px-8 pt-8 pb-2 w-full bg-white max-md:flex-wrap max-md:px-5 max-md:max-w-full">
                                                                <div className="text-2xl font-medium leading-7 text-neutral-800">
                                                                    {ticketDetails?.subject}
                                                                </div>
                                                                <div className="flex gap-4">
                                                                    <img
                                                                        loading="lazy"
                                                                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/3cba50ec217380e892037e0601fdec60068cf2775fe4b1ad4569b66b8f42f539?apiKey=4c514e50e07d4444bfd3ae3d48b8bd32&"
                                                                        className="shrink-0 w-5 aspect-square"
                                                                    />
                                                                    <img
                                                                        loading="lazy"
                                                                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/1ad559d7204774fed88c0f29b5b2d76e9f171c30b5bdd859738de84a313a75d6?apiKey=4c514e50e07d4444bfd3ae3d48b8bd32&"
                                                                        className="shrink-0 w-5 aspect-square"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="flex gap-5 justify-between px-8 py-1.5 w-full bg-white max-md:flex-wrap max-md:px-5 max-md:max-w-full">
                                                                <div className="flex gap-2">
                                                                    <img
                                                                        loading="lazy"
                                                                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/731ddae7a0c2864bce52a4ad0e0c82280f8800a9c18b6e302e75e1a3a8d42169?apiKey=4c514e50e07d4444bfd3ae3d48b8bd32&"
                                                                        className="shrink-0 w-6 aspect-square fill-black fill-opacity-10"
                                                                    />
                                                                    <div className="flex gap-1 my-auto">
                                                                        <div className="text-sm font-semibold text-neutral-800">
                                                                            {ticketDetails?.name}{" "}
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                                <div className="flex gap-1 my-auto text-xs font-medium tracking-normal leading-4 text-black text-opacity-60">
                                                                    <div>9:14 AM</div>
                                                                    <div>(8 hours ago)</div>
                                                                </div>
                                                            </div>
                                                            <div className="flex flex-col px-16 py-3.5 bg-white max-md:px-5 max-md:max-w-full">
                                                                <div className="text-sm leading-5 text-zinc-800 max-md:max-w-full">
                                                                    description....
                                                                </div>
                                                                <div className="mt-6 text-sm font-bold text-neutral-800 max-md:max-w-full">
                                                                    Attachments
                                                                </div>
                                                                <div className="mt-3 w-full flex flex-wrap ">


                                                                    {ticketDetails?.attachments?.length && ticketDetails?.attachments?.map((v, i) => (
                                                                        <div key={i} className="flex flex-col ml-5 w-[30%] m-4">
                                                                            <img
                                                                                src={v}
                                                                                alt={`Attachment ${i + 1}`}
                                                                                className="bg-gray-300 h-[165px] w-[250px]"
                                                                            />
                                                                        </div>
                                                                    ))}





                                                                </div>
                                                            </div>
                                                        </div>
                                                }
                                            </div>
                                        }

                                        {
                                            tab === 2 &&
                                            <TicketWhatsapp />

                                        }
                                        {
                                            tab === 3 &&
                                            <CallLog />
                                        }
                                    </div>
                                </div>
                            </div>


                            <ManageTicket />


                        </div>
                    </div>
                </div>
            </div>
        </Template>
    )
}

export default TicketDetails