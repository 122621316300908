import React from 'react'

export const AddMoreModal = () => {
    return (
        <div id="hs-vertically-centered-modal_add_acc" class="hs-overlay hidden size-full fixed top-0 start-0 z-[80] overflow-x-hidden overflow-y-auto pointer-events-none">
            <div class="hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 mt-0 opacity-0 ease-out transition-all sm:max-w-lg sm:w-full  sm:mx-auto min-h-[calc(100%-3.5rem)] flex items-center">
                <div class="w-full flex flex-col  bg-white shadow-sm rounded-xl pointer-events-auto">
                    <div className="flex flex-col pt-6 bg-white rounded max-w-[438px]">
                        <div className="px-4 text-lg font-bold text-slate-950">
                            Add Contact
                        </div>
                        <div className="px-8 py-4 mt-6 w-full bg-purple-600 border-b-0 border-solid border-slate-950" style={{ backgroundColor: "#9050D0" }}>
                            <div className="flex gap-5 max-md:flex-col max-md:gap-0">
                                <div className="flex flex-col w-[24%] max-md:ml-0 max-md:w-full">
                                    <div className="flex justify-center items-center px-6 mx-auto bg-white bg-opacity-20 h-[70px] rounded-[200px] w-[70px]">
                                        <img
                                            loading="lazy"
                                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/46c2c751338e2be6d4bcea30e2997e1b140ead2674619509cf87b044fda9381f?"
                                            className="w-5 aspect-square"
                                        />
                                    </div>
                                </div>
                                <div className="flex flex-col ml-5 w-[76%] max-md:ml-0 max-md:w-full">
                                    <div className="flex flex-col self-stretch my-auto font-semibold text-white">
                                        <div className="text-sm capitalize">Upload profile image</div>
                                        <div className="mt-1 text-xs">
                                            Max: 2 MB, Supported: png, jpg, webp
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col p-8 w-full font-medium">
                            <div className="text-sm leading-5 text-zinc-800">Type</div>
                            <div className="flex gap-2.5 justify-center px-3 py-2 mt-1 text-sm whitespace-nowrap rounded border border-solid border-black border-opacity-20 text-zinc-800">
                                <div className="flex-1">Customer</div>
                                <img
                                    loading="lazy"
                                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/027a7fb752ee0a3a3892024525c36bb9c7db6f63b55be13849ee8990217c7127?"
                                    className="shrink-0 my-auto aspect-[1.41] w-[7px]"
                                />
                            </div>


                            <div className="mt-3 text-sm leading-5 text-black">
                                Name <span className="text-orange-600">*</span>
                            </div>
                            <input className="shrink-0 mt-1 h-8 rounded border border-solid border-black border-opacity-20" />



                            <div className="mt-3 text-sm leading-5 text-black">
                                Email <span className="text-orange-600">*</span>
                            </div>
                            <input className="shrink-0 mt-1 h-8 rounded border border-solid border-black border-opacity-20" />



                            <div className="mt-3 text-sm leading-5 text-black">
                                Phone <span className="text-orange-600">*</span>
                            </div>
                            <input className="shrink-0 mt-1 h-8 rounded border border-solid border-black border-opacity-20" />


                            <div className="mt-3 text-sm leading-5 text-black">
                                Address <span className="text-orange-600">*</span>
                            </div>
                            <input className="shrink-0 mt-1 h-8 rounded border border-solid border-black border-opacity-20" />


                            <div className="flex gap-2.5  mt-6 whitespace-nowrap justify-end">
                                <div className="justify-center px-4 py-1.5 text-xs leading-5 rounded-md border border-solid border-black border-opacity-20 text-zinc-800">
                                    Cancel
                                </div>
                                <div className="justify-center px-4 py-1.5 text-sm leading-5 text-white rounded-md bg-slate-600">
                                    Save
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
