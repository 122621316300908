import { createSlice } from "@reduxjs/toolkit";

const dashboardSlice = createSlice({
    name: "dashboard",
    initialState: {
        topChartDetails: {},
        leadChartDetails: {},
        topChartLoading:false,
        leadChartLoading: false,
        leadsList: [],
        leadsLoading: false,
        ticketChartDetails:{},
        ticketChartDetailsLoading: false,
        ticketList: [],
        ticketListLoading: false,
        campaignList: [],
        campaignLisLoading: false,
        campaignChartDetails:{},
        campaignChartDetailsLoading: false,
        markettingformDetails:{},
        markettingformDetailsLoading:false,
    },
    reducers: {
        getTopChartDetailsRequest: (state) => {
            state.topChartLoading = true;
        },
        getTopChartDetailsSuccess: (state,{payload}) => {
            state.topChartLoading = false;
            state.topChartDetails = payload;
        },
        getTopChartDetailsFail: (state) => {
            state.topChartLoading = false;
        },
        getLeadChartDetailsRequest: (state) => {
            state.leadChartLoading = true;
        },
        getLeadChartDetailsSuccess: (state,{payload}) => {
            state.leadChartLoading = false;
            state.leadChartDetails = payload;
        },
        getLeadChartDetailsFail: (state) => {
            state.leadChartLoading = false;
        },
        getLatestLeadListRequest: (state) => {
            state.leadsLoading = true;
        },
        getLatestLeadListSuccess: (state,{payload}) => {
            state.leadsLoading = false;
            state.leadsList = payload;
        },
        getLatestLeadListFail: (state) => {
            state.leadsLoading = false;
        },
        getLatestTicketListRequest: (state) => {
            state.ticketListLoading = true;
        },
        getLatestTicketListSuccess: (state,{payload}) => {
            state.ticketListLoading = false;
            state.ticketList = payload;
        },
        getLatestTicketListFail: (state) => {
            state.ticketListLoading = false;
        },
        getTicketChartDetailsRequest: (state) => {
            state.ticketChartDetailsLoading = true;
        },
        getTicketChartDetailsSuccess: (state,{payload}) => {
            state.ticketChartDetailsLoading = false;
            state.ticketChartDetails = payload;
        },
        getTicketChartDetailsFail: (state) => {
            state.ticketChartDetailsLoading = false;
        },
        getCampaignChartDetailsRequest: (state) => {
            state.campaignChartDetailsLoading = true;
        },
        getCampaignChartDetailsSuccess: (state,{payload}) => {
            state.campaignChartDetailsLoading = false;
            state.campaignChartDetails = payload;
        },
        getCampaignChartDetailsFail: (state) => {
            state.campaignChartDetailsLoading = false;
        },
        getMarkettingFormsChartDetailsRequest: (state) => {
            state.markettingformDetailsLoading = true;
        },
        getMarkettingFormsChartDetailsSuccess: (state,{payload}) => {
            state.markettingformDetailsLoading = false;
            state.markettingformDetails = payload;
        },
        getMarkettingFormsChartDetailsFail: (state) => {
            state.markettingformDetailsLoading = false;
        },
        getCampaignListRequest: (state) => {
            state.campaignLisLoading = true;
        },
        getCampaignListSuccess: (state,{payload}) => {
            state.campaignLisLoading = false;
            state.campaignList = payload;
        },
        getCampaignListFail: (state) => {
            state.campaignLisLoading = false;
        },
    }
})

export default dashboardSlice.reducer;
export const { getTopChartDetailsFail,getTopChartDetailsRequest,getTopChartDetailsSuccess,getLeadChartDetailsFail,getLeadChartDetailsRequest,getLeadChartDetailsSuccess,getLatestLeadListFail,getLatestLeadListRequest,getLatestLeadListSuccess,getLatestTicketListFail,getLatestTicketListRequest,getLatestTicketListSuccess,getTicketChartDetailsFail,getTicketChartDetailsRequest,getTicketChartDetailsSuccess,getCampaignChartDetailsFail,getCampaignChartDetailsRequest,getCampaignChartDetailsSuccess,getCampaignListFail,getCampaignListRequest,getCampaignListSuccess,getMarkettingFormsChartDetailsFail,getMarkettingFormsChartDetailsRequest,getMarkettingFormsChartDetailsSuccess} = dashboardSlice.actions;