import { configureStore } from "@reduxjs/toolkit"
import settingSlice from "./slices/settings";
import loginSlice from "./slices/loginSlice";
import salesSlice from "./slices/salesSlice";
import campaignSlice from "./slices/campaignSlice";
import ticketSlice from "./slices/ticketSlice";
import markettingSlice from "./slices/markettingSlice";
import dashboardSlice from "./slices/dashboardSlice";
import notificationSlice from "./slices/notificationSlice";

const store = configureStore({
    reducer: {
        settingSlice: settingSlice,
        loginSlice: loginSlice,
        salesSlice: salesSlice,
        campaign: campaignSlice,
        ticketSlice: ticketSlice,
        markettingSlice: markettingSlice,
        dashboard: dashboardSlice,
        notification:notificationSlice
    },
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
})

export default store;