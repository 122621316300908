import React, { useEffect, useState } from 'react'
import Template from '../../../components/common/Template'
import { Link, useNavigate } from 'react-router-dom'
import * as RoutesKey from "../../../constants/routes"
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { getAssignedEmployeeList, getCampaignList, getEmployeeCampaignList } from '../../../apis/apis.js'
import { CiCalendar } from "react-icons/ci";
import { IoChevronDownSharp, IoSearch } from 'react-icons/io5'
import SelectPeriodModal from './SelectPeriodModal.js'
import { MdEdit } from "react-icons/md";
import moment from 'moment/moment.js'
import AccessComponent from '../../../components/utils/checkAccess.js'
import { AccessIds } from '../../../components/utils/allIdList.js'
import Pagination from '../../../components/common/pagination.js'
import { notifySuccessMessage } from '../../../components/utils/toastMessage.js'

const MarkettingCampaigns = () => {
  const navigate = useNavigate();
  const [selectedTab, setselectedTab] = useState(0)
  const [page, setpage] = useState(0)
  const [fromPeriod, setFromPeriod] = useState("")
  const [toPeriod, setToPeriod] = useState("")
  const [createdBy, setCreatedBy] = useState("")
  const [search, setSearch] = useState("")
  const { xvi_id, role } = useSelector(state => state.loginSlice)
  const { assignedEmployeeList, campaignList, totalCampaignList } = useSelector(state => state.campaign)
  const dispatch = useDispatch()
  const all_access = AccessComponent(AccessIds?.campaign_allList)
  const my_access = AccessComponent(AccessIds?.campaign_myList)
  useEffect(() => {
    if (all_access) {
      dispatch(getCampaignList({ page, id: xvi_id, status: selectedTab, search: search, from: fromPeriod, to: toPeriod, created: createdBy }))
    } else if (my_access) {
      dispatch(getEmployeeCampaignList({ page, id: xvi_id }))
    } else {
      navigate(-1)
    }

  }, [role, dispatch, xvi_id, createdBy, search, toPeriod, fromPeriod, page, selectedTab])

  useEffect(() => {
    dispatch(getAssignedEmployeeList(xvi_id))
  }, [])

  const caluculateDay = (date) => {

    const today = moment();
    const targetDate = moment(date, 'DD-MM-YYYY');
    console.log("final days", today.diff(targetDate, 'days'));
    // return today.diff(targetDate, 'days');

  }
  const handleChangeTab = (v) => {
    setpage(0)
    setselectedTab(v)
  }

  const handleEdit = (e, campaign) => {
    e.stopPropagation();
    navigate(`/marketing/campaign/edit/${campaign?.campaignId}`)
  }

  return (
    <Template>
      <div className="flex flex-col self-stretch bg-indigo-700">
        <div className="flex flex-col px-20 pt-3 w-full text-white max-md:px-5 max-md:max-w-full">
          <div className="flex gap-2.5 py-5 max-md:flex-wrap">
            <div className="flex flex-col flex-1 max-md:max-w-full">
              <div className="text-xl font-semibold max-md:max-w-full">
                Campaigns
              </div>
              <div className="flex gap-5 justify-between self-start mt-3 text-xs font-medium">

                <select value={createdBy} onChange={(e) => setCreatedBy(e.target.value)} className='bg-transparent capitalize border-none outline-none w-auto'>
                  <option className='capitalize bg-[#27292d] ' value={""}>{createdBy ? "Clear" : "Created By"}</option>
                  {
                    assignedEmployeeList?.map((val, i) =>
                      <option key={i} className='capitalize bg-[#27292d]' value={val?.roleAssignId}>{val?.employeeName}</option>
                    )

                  }

                </select>

                <SelectPeriodModal fromDate={fromPeriod} setToDate={setToPeriod} setfromDate={setFromPeriod} toDate={toPeriod} />
              </div>
            </div>
            <div className="flex gap-2 justify-center my-auto font-medium">
              <div className="flex items-center flex-1 gap-2 px-2.5 py-2 text-xs rounded-md bg-white bg-opacity-20">
                <IoSearch size={14} color='white' />
                <input value={search} onChange={(e) => setSearch(e.target.value)} placeholder='Search here' className='w-full border-none outline-none bg-transparent' />
              </div>
              <Link to={RoutesKey.ADDCAMPAIGN} className="cursor-pointer flex gap-1 py-1.5 pr-3.5 pl-3 text-sm leading-5 rounded-md">
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/f4a0a2e7c581b416ec19457da0700953e2d1a398e047edff236f87a31c6e6e9b?apiKey=4c514e50e07d4444bfd3ae3d48b8bd32&"
                  className="shrink-0 my-auto w-4 aspect-square"
                />
                <div>Create Campaign</div>
              </Link>
            </div>
          </div>
          <nav className="flex gap-1 pr-20  max-md:flex-wrap max-md:pr-5" aria-label="Tabs" role="tablist">
            <button type="button" class="active" id="unstyled-tabs-item-1" data-hs-tab="#unstyled-tabs-1" aria-controls="unstyled-tabs-1" role="tab" onClick={() => { handleChangeTab(0) }}>
              {selectedTab === 0 ? <div className="flex flex-col justify-center self-start text-xs font-medium leading-5 bg-gray-100 rounded-xl text-zinc-800">
                <div className="justify-center px-4 pt-1.5 pb-1 border-b-2 border-gray-100 border-solid">
                  All
                </div>
              </div> : <div className="flex flex-col justify-center py-px rounded-xl bg-zinc-800">
                <div className="flex gap-1 justify-center px-4 pt-1.5 pb-1">
                  <div className="text-xs font-medium leading-5">All</div>
                </div>
              </div>}
            </button>
            <button type="button" id="unstyled-tabs-item-2" data-hs-tab="#unstyled-tabs-2" aria-controls="unstyled-tabs-2" role="tab" onClick={() => handleChangeTab(1)}>
              {selectedTab === 1 ? <div className="flex flex-col justify-center py-px rounded-xl bg-gray-100 text-zinc-800">
                <div className="flex gap-1 justify-center px-4 pt-1.5 pb-1">
                  <div className="text-xs font-medium leading-5">Active</div>
                  <div className="justify-center self-start px-1.5 py-px text-xs font-semibold bg-orange-600 rounded-lg">
                    39
                  </div>
                </div>
              </div> : <div className="flex flex-col justify-center py-px rounded-xl bg-zinc-800">
                <div className="flex gap-1 justify-center px-4 pt-1.5 pb-1">
                  <div className="text-xs font-medium leading-5">Active</div>
                  <div className="justify-center self-start px-1.5 py-px text-xs font-semibold bg-orange-600 rounded-lg">
                    39
                  </div>
                </div>
              </div>}
            </button>
            <button type="button" id="unstyled-tabs-item-3" data-hs-tab="#unstyled-tabs-3" aria-controls="unstyled-tabs-3" role="tab" onClick={() => handleChangeTab(2)}>
              {selectedTab === 2 ? <div className="flex  justify-center self-start text-xs font-medium leading-5 bg-gray-100 rounded-xl text-zinc-800">
                <div className="justify-center px-4 pt-1.5 pb-1 border-b-2 border-gray-100 border-solid">
                  Archived
                </div>
              </div> : <div className="flex flex-col justify-center py-px rounded-xl bg-zinc-800">
                <div className="flex gap-1 justify-center px-4 pt-1.5 pb-1">
                  <div className="text-xs font-medium leading-5">Archived</div>
                </div>
              </div>}
            </button>
            <button type="button" id="unstyled-tabs-item-4" data-hs-tab="#unstyled-tabs-4" aria-controls="unstyled-tabs-4" role="tab" onClick={() => handleChangeTab(3)}>
              {selectedTab === 3 ? <div className="flex  justify-center self-start text-xs font-medium leading-5 bg-gray-100 rounded-xl text-zinc-800">
                <div className="justify-center px-4 pt-1.5 pb-1 border-b-2 border-gray-100 border-solid">
                  Scheduled
                </div>
              </div> : <div className="flex flex-col justify-center py-px rounded-xl bg-zinc-800">
                <div className="flex gap-1 justify-center px-4 pt-1.5 pb-1">
                  <div className="text-xs font-medium leading-5">Scheduled</div>
                </div>
              </div>}
            </button>
          </nav>
        </div>
      </div>


      {/* Tab Section */}
      <div class="mt-3">
        <div id="unstyled-tabs-1" role="tabpanel" aria-labelledby="unstyled-tabs-item-1" >
          <div className="flex gap-1 max-md:flex-wrap max-md:pr-5 bg-white/60 text-black px-20">
            <div className='flex flex-col pt-3 w-full py-4 max-md:px-5 max-md:max-w-full bg-white'>
              <div>
                {campaignList?.map((campaign, i) => (
                  <div
                    key={i}
                    onClick={() => navigate(`/marketing/campaign/${campaign?.campaignId}`)}
                    className="cursor-pointer justify-between flex gap-4 py-3.5 pl-5 w-full border-t border-solid border-zinc-800 border-opacity-10 max-md:flex-wrap max-md:max-w-full bg-white my-5 rounded-lg shadow-md"
                  >
                    <div className="flex gap-3 my-auto  w-[25%]">
                      <img
                        alt=''
                        src={campaign?.whatsappImage}
                        className="shrink-0 self-start w-12 h-12 object-cover rounded bg-neutral-300"
                      />
                      <div className="flex flex-col ">
                        <div className="text-sm font-semibold capitalize">{campaign?.campaignName}</div>
                        <div className="mt-1.5 text-xs capitalize">{campaign?.description}</div>
                      </div>
                    </div>

                    <div className="flex flex-1 gap-2 self-stretch p-4 my-auto max-md:pr-5 ">
                      <div className="my-auto text-xl font-semibold">{campaign?.totalDelivered}</div>
                      <div className="flex flex-col text-xs">
                        <div className="font-medium">Delivered</div>
                        <div>Last 24 Hours</div>
                      </div>
                    </div>

                    <div className="flex flex-1 gap-2 self-stretch p-4 my-auto max-md:pr-5 ">
                      <div className="my-auto text-xl font-semibold">{campaign?.totalNotDelivered}</div>
                      <div className="flex flex-col text-xs">
                        <div className="font-medium">Not delivered</div>
                        <div>Last 13 Hours</div>
                      </div>
                    </div>

                    <div className="flex flex-1 gap-2 self-stretch p-4 my-auto max-md:pr-5 ">
                      <div className="my-auto text-xl font-semibold">{campaign?.totalRecipient}</div>
                      <div className="flex flex-col text-xs">
                        <div className="font-medium">Recipient</div>
                        <div>Last 30 days</div>
                      </div>
                    </div>

                    <div className="flex flex-1 gap-2 self-stretch p-4 my-auto max-md:pr-5 ">
                      <div className="flex flex-col text-xs">
                        <div className="font-medium">Published on</div>
                        <div>{campaign?.publishDate}</div>
                      </div>
                    </div>

                    <div className="flex items-center gap-2.5 self-stretch p-4 text-xs font-semibold text-center text-white max-md:px-5  w-[8%] justify-between">
                      {campaign?.campaignStatus === "published" && (
                        <div className="justify-center items-start py-1 pr-2.5 pl-2 rounded-lg border text-indigo-500 border-indigo-500 border-solid bg-slate-200">
                          Published
                        </div>
                      )}
                      {campaign?.campaignStatus === "draft" && (
                        <div className="justify-center items-start py-1 pr-2.5 pl-2 rounded-lg border border-solid bg-zinc-800 border-zinc-800">
                          Draft
                        </div>
                      )}
                      {campaign?.campaignStatus === "scheduled" && (
                        <div className="justify-center items-start py-1 pr-2.5 pl-2 text-green-600 bg-green-50 rounded-lg border border-green-600 border-solid">
                          Scheduled
                        </div>
                      )}
                      <div onClick={(e) => handleEdit(e, campaign)}>
                        <MdEdit size={16} color='black' />
                      </div>
                    </div>

                  </div>
                ))}
              </div>
              <div className='flex justify-center w-full p-4 mb-4 -bottom-3 left-14 right-0'>
                <Pagination
                  className="pagination-bar"
                  currentPage={page + 1}
                  totalCount={totalCampaignList}
                  pageSize={10}
                  onPageChange={(page) => {
                    setpage(page - 1);
                    var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
                    if (currentScroll > 0) {
                      window.scrollTo(0, currentScroll - (currentScroll / 1));
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </div>


        <div id="unstyled-tabs-2" role="tabpanel" class="hidden" aria-labelledby="unstyled-tabs-item-2" >
          <div className="flex gap-1 max-md:flex-wrap max-md:pr-5 bg-white/60 px-20 text-black">
            <div className='flex flex-col bg-white pt-3 w-full py-4 max-md:px-5 max-md:max-w-full'>
              <table>
                <thead>
                  <tr >
                    <th>
                    </th>
                    <th>
                    </th>
                    <th>
                    </th>
                    <th>
                    </th>
                    <th>
                    </th>
                    <th>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {/* onClick={()=>navigate(`/marketing/campaign/1`)} */}
                  {campaignList?.map((campaign, i) =>
                    <tr key={i} onClick={() => navigate(`/marketing/campaign/${campaign?.campaignId}`)} className=" cursor-pointer  justify-between flex gap-4 py-3.5 pl-5 w-full border-t border-solid border-zinc-800 border-opacity-10 max-md:flex-wrap max-md:max-w-full">
                      <td>
                        <div className="flex gap-3 my-auto">
                          <img alt='' src={campaign?.whatsappImage} className="shrink-0 self-start w-12 h-12 object-cover rounded bg-neutral-300" />
                          <div className="flex flex-col">
                            <div className="text-sm font-semibold capitalize">{campaign?.campaignName}</div>
                            <div className="mt-1.5 text-xs capitalize">
                              {campaign?.description}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="flex flex-1 gap-2 self-stretch p-4 my-auto max-md:pr-5">
                          <div className="my-auto text-xl font-semibold">{campaign?.totalDelivered}</div>
                          <div className="flex flex-col text-xs">
                            <div className="font-medium">Delivered</div>
                            <div>Last 24 Hours</div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="flex flex-1 gap-2 self-stretch p-4 my-auto max-md:pr-5">
                          <div className="my-auto text-xl font-semibold">{campaign?.totalNotDelivered}</div>
                          <div className="flex flex-col text-xs">
                            <div className="font-medium">Not delivered</div>
                            <div>Last 13 Hours</div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="flex flex-1 gap-2 self-stretch p-4 my-auto max-md:pr-5">
                          <div className="my-auto text-xl font-semibold">{campaign?.totalRecipient}</div>
                          <div className="flex flex-col text-xs">
                            <div className="font-medium">Receipant</div>
                            <div>Last 30 days</div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="flex flex-1 gap-2 self-stretch p-4 my-auto max-md:pr-5">
                          <div className="flex flex-col text-xs">
                            <div className="font-medium">Published on</div>
                            <div>{campaign?.publishDate}</div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="flex items-center gap-2.5 self-stretch p-4 text-xs font-semibold text-center text-white  max-md:px-5">

                          {
                            (campaign?.isActive === true || campaign?.isActive === "true") ?
                              <div className="justify-center items-start py-1 pr-2.5 pl-2 rounded-lg border text-indigo-500 border-indigo-500 border-solid bg-slate-200">
                                Published
                              </div> :
                              <div className="justify-center  items-start py-1 pr-2.5 pl-2 rounded-lg border border-solid bg-zinc-800 border-zinc-800">
                                Draft
                              </div>
                          }
                          <MdEdit size={16} color='black' />

                        </div>
                      </td>
                    </tr>
                  )}

                </tbody>
              </table>
              <div className='flex justify-center w-full p-4 mb-4  -bottom-3  left-14 right-0'>

                <Pagination
                  className="pagination-bar"
                  currentPage={page + 1}
                  totalCount={totalCampaignList}
                  pageSize={10}
                  onPageChange={(page) => {
                    setpage(page - 1)
                    var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
                    if (currentScroll > 0) {
                      window.scrollTo(0, currentScroll - (currentScroll / 1));
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </div>


        <div id="unstyled-tabs-3" role="tabpanel" class="hidden" aria-labelledby="unstyled-tabs-item-3" >
          <div className="flex gap-1 max-md:flex-wrap max-md:pr-5 px-20 bg-white/60 text-black">
            <div className='flex flex-col  pt-3 w-full py-4 max-md:px-5 max-md:max-w-full bg-white'>
              <table>
                <thead>
                  <tr >
                    <th>
                    </th>
                    <th>
                    </th>
                    <th>
                    </th>
                    <th>
                    </th>
                    <th>
                    </th>
                    <th>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {/* onClick={()=>navigate(`/marketing/campaign/1`)} */}
                  {campaignList?.map((campaign, i) =>
                    <tr className=" cursor-pointer  justify-between flex gap-4 py-3.5 pl-5 w-full border-t border-solid border-zinc-800 border-opacity-10 max-md:flex-wrap max-md:max-w-full">
                      <td>
                        <div className="flex gap-3 my-auto">
                          <img alt='' src={campaign?.whatsappImage} className="shrink-0 self-start w-12 h-12 object-cover rounded bg-neutral-300" />
                          <div className="flex flex-col">
                            <div className="text-sm font-semibold capitalize">{campaign?.campaignName}</div>
                            <div className="mt-1.5 text-xs capitalize">
                              {campaign?.description}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="flex flex-1 gap-2 self-stretch p-4 my-auto max-md:pr-5">
                          <div className="my-auto text-xl font-semibold">{campaign?.totalDelivered}</div>
                          <div className="flex flex-col text-xs">
                            <div className="font-medium">Delivered</div>
                            <div>Last 24 Hours</div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="flex flex-1 gap-2 self-stretch p-4 my-auto max-md:pr-5">
                          <div className="my-auto text-xl font-semibold">{campaign?.totalNotDelivered}</div>
                          <div className="flex flex-col text-xs">
                            <div className="font-medium">Not delivered</div>
                            <div>Last 13 Hours</div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="flex flex-1 gap-2 self-stretch p-4 my-auto max-md:pr-5">
                          <div className="my-auto text-xl font-semibold">{campaign?.totalRecipient}</div>
                          <div className="flex flex-col text-xs">
                            <div className="font-medium">Receipant</div>
                            <div>Last 30 days</div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="flex flex-1 gap-2 self-stretch p-4 my-auto max-md:pr-5">
                          <div className="flex flex-col text-xs">
                            <div className="font-medium">Published on</div>
                            <div>{campaign?.publishDate}</div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="flex items-center gap-2.5 self-stretch p-4 text-xs font-semibold text-center text-white  max-md:px-5">

                          {
                            (campaign?.campaignStatus === "published") &&
                            <div className="justify-center items-start py-1 pr-2.5 pl-2 rounded-lg border text-indigo-500 border-indigo-500 border-solid bg-slate-200">
                              Published
                            </div>}
                          {
                            (campaign?.campaignStatus === "draft") &&
                            <div className="justify-center  items-start py-1 pr-2.5 pl-2 rounded-lg border border-solid bg-zinc-800 border-zinc-800">
                              Draft
                            </div>
                          }
                          {
                            (campaign?.campaignStatus === "scheduled") &&
                            <div className="justify-center items-start py-1 pr-2.5 pl-2 text-green-600 bg-green-50 rounded-lg border border-green-600 border-solid">
                              Scheduled
                            </div>
                          }
                          <MdEdit size={16} color='black' />
                        </div>
                      </td>
                    </tr>
                  )}

                </tbody>
              </table>
              <div className='flex justify-center w-full p-4 mb-4  -bottom-3  left-14 right-0'>

                <Pagination
                  className="pagination-bar"
                  currentPage={page + 1}
                  totalCount={totalCampaignList}
                  pageSize={10}
                  onPageChange={(page) => {
                    setpage(page - 1)
                    var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
                    if (currentScroll > 0) {
                      window.scrollTo(0, currentScroll - (currentScroll / 1));
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </div>


        <div id="unstyled-tabs-4" role="tabpanel" class="hidden" aria-labelledby="unstyled-tabs-item-4" >
          <div className="flex gap-1 max-md:flex-wrap max-md:pr-5 px-20 bg-white/60 text-black">
            <div className='flex flex-col  pt-3 w-full py-4 max-md:px-5 max-md:max-w-full bg-white'>
              <table>
                <thead>
                  <tr >
                    <th>
                    </th>
                    <th>
                    </th>
                    <th>
                    </th>
                    <th>
                    </th>
                    <th>
                    </th>
                    <th>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {/* onClick={()=>navigate(`/marketing/campaign/1`)} */}
                  {campaignList?.map((campaign, i) =>
                    <tr key={i} onClick={() => navigate(`/marketing/campaign/${campaign?.campaignId}`)} className=" cursor-pointer  justify-between flex gap-4 py-3.5 pl-5 w-full border-t border-solid border-zinc-800 border-opacity-10 max-md:flex-wrap max-md:max-w-full">
                      <td>
                        <div className="flex gap-3 my-auto">
                          <img alt='' src={campaign?.whatsappImage} className="shrink-0 self-start w-12 h-12 object-cover rounded bg-neutral-300" />
                          <div className="flex flex-col">
                            <div className="text-sm font-semibold capitalize">{campaign?.campaignName}</div>
                            <div className="mt-1.5 text-xs capitalize">
                              {campaign?.description}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="flex flex-1 gap-2 self-stretch p-4 my-auto max-md:pr-5">
                          <div className="my-auto text-xl font-semibold">{campaign?.totalDelivered}</div>
                          <div className="flex flex-col text-xs">
                            <div className="font-medium">Delivered</div>
                            <div>Last 24 Hours</div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="flex flex-1 gap-2 self-stretch p-4 my-auto max-md:pr-5">
                          <div className="my-auto text-xl font-semibold">{campaign?.totalNotDelivered}</div>
                          <div className="flex flex-col text-xs">
                            <div className="font-medium">Not delivered</div>
                            <div>Last 13 Hours</div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="flex flex-1 gap-2 self-stretch p-4 my-auto max-md:pr-5">
                          <div className="my-auto text-xl font-semibold">{campaign?.totalRecipient}</div>
                          <div className="flex flex-col text-xs">
                            <div className="font-medium">Receipant</div>
                            <div>Last 30 days</div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="flex flex-1 gap-2 self-stretch p-4 my-auto max-md:pr-5">
                          <div className="flex flex-col text-xs">
                            <div className="font-medium">Published on</div>
                            <div>{campaign?.publishDate}</div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="flex items-center gap-2.5 self-stretch p-4 text-xs font-semibold text-center text-white  max-md:px-5">

                          {
                            (campaign?.campaignStatus === "published") &&
                            <div className="justify-center items-start py-1 pr-2.5 pl-2 rounded-lg border text-indigo-500 border-indigo-500 border-solid bg-slate-200">
                              Published
                            </div>}
                          {
                            (campaign?.campaignStatus === "draft") &&
                            <div className="justify-center  items-start py-1 pr-2.5 pl-2 rounded-lg border border-solid bg-zinc-800 border-zinc-800">
                              Draft
                            </div>
                          }
                          {
                            (campaign?.campaignStatus === "scheduled") &&
                            <div className="justify-center items-start py-1 pr-2.5 pl-2 text-green-600 bg-green-50 rounded-lg border border-green-600 border-solid">
                              Scheduled
                            </div>
                          }
                          <MdEdit size={16} color='black' />
                        </div>
                      </td>
                    </tr>
                  )}

                </tbody>
              </table>
              <div className='flex justify-center w-full p-4 mb-4  -bottom-3  left-14 right-0'>

                <Pagination
                  className="pagination-bar"
                  currentPage={page + 1}
                  totalCount={totalCampaignList}
                  pageSize={10}
                  onPageChange={(page) => {
                    setpage(page - 1)
                    var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
                    if (currentScroll > 0) {
                      window.scrollTo(0, currentScroll - (currentScroll / 1));
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </div>


      </div>
    </Template>
  )
}

export default MarkettingCampaigns