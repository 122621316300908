import React, { useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useState } from 'react'
import { useSelector } from 'react-redux'
import { useFormik } from 'formik'
import moment from 'moment/moment'
import { MdOutlineAccessTime, MdOutlineDateRange } from "react-icons/md";
import { leadaddCallLogValidation } from '../../../Validation/leads_CallLogSchema'
import axiosConfig from '../../../www/http'
import { notifyErrorMessage, notifySuccessMessage } from '../../../components/utils/toastMessage'
import { useParams } from 'react-router-dom'
import { getTicketCallLog } from '../../../apis/apiService'
import { useDispatch } from 'react-redux'


const AddCallLog = () => {
   
    const [isOpen, setIsOpen] = useState(false)

    const { xvi_id, empDetails } = useSelector(state => state.loginSlice)
    const { ticket_id } = useParams();
    const dispatch = useDispatch()

    const [loading, setLoading] = useState(false)
    
    
    
    
    const Status = [
        { id: "", label: "Select" },
        { id: 1, label: "NO_ANSWER" },
        { id: 2, label: "BUSY" },
        { id: 3, label: "DISCONNECTED" },
        { id: 4, label: "ANSWERED" },
    ]

    useEffect(() => {
        resetForm()
    }, [])

    const handleSelectType = (id) => {
        const filterdata = Status.find(v => v?.id == id)
        if (filterdata?.id) {
            setFieldValue("status", filterdata?.label)
        }
    }

    const initialValues = {
        agentPhone: "",
        description: "",
        status: "",
    }


    const currentDate = moment().format('Do MMMM YYYY')
    const currentTime = moment().format('hh:mm:ss A')

    const { values, handleBlur, handleChange, handleSubmit, errors, touched, setFieldValue, resetForm } = useFormik({
        initialValues: initialValues,
        validationSchema: leadaddCallLogValidation,
        onSubmit: async (values) => {



            const formdata = new FormData()
            formdata.append("name", empDetails?.name)
            formdata.append("phoneNo", values?.agentPhone)
            formdata.append("description", values?.description)
            formdata.append("ticketId", ticket_id)
            formdata.append("priority", "High")
            formdata.append("status", values?.status)



            console.log("Values...........................", values, errors);
            try {
                setLoading(true)
                const { data } = await axiosConfig.post(`/save-ticket-callLog`, formdata, {
                    headers: {
                        "X-V-I": `${xvi_id}`
                    }
                })
                if (data?.status === "SUCCESS") {
                    // call Get Api
                    dispatch(getTicketCallLog(xvi_id, 0, ticket_id))
                    notifySuccessMessage(data?.message)
                    setLoading(false)
                    resetForm()
                    setIsOpen(false)


                }

            } catch (error) {
                notifyErrorMessage(error.message)
                setLoading(false)
                console.log("Faileddddddddddddddddd", error);
            }


        }
    })

    return (
        <>
            <button onClick={() => setIsOpen(true)} className="justify-center items-center px-8 py-2 text-sm leading-5 text-white rounded-md bg-slate-600 max-md:px-5">
                Add Call Log
            </button>

            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={() => { }}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black/25" />
                    </Transition.Child>



                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-[500px]  transform overflow-hidden rounded-2xl bg-white  text-left align-middle shadow-xl transition-all">


                                    {/* ---------------------------------------------------------------- */}

                                    <div className="flex flex-col max-w-screen-md font-medium bg-white rounded">
                                        <div className="flex flex-col p-4 bg-[#1B99A1] text-white max-md:px-5 max-md:max-w-full">
                                            <div className="text-2xl max-md:max-w-full">Add call log</div>
                                            <div className="mt-1 text-xs max-md:max-w-full">
                                                Brooklyn Simmons / Business name
                                            </div>
                                        </div>
                                        <div className="flex flex-col self-stretch p-8 max-w-full">

                                            {/* Agent name */}
                                            <div className="text-sm font-medium leading-5 text-zinc-800">
                                                Agent name
                                            </div>
                                            <div className="flex  items-start px-3 py-1 mt-1 rounded border border-solid border-black border-opacity-20">
                                                {empDetails?.name}
                                            </div>

                                            {/* Agent phone number */}
                                            <div className="mt-3 text-sm font-medium leading-5 text-zinc-800">
                                                Agent phone number
                                            </div>
                                            <input type='number' value={values?.agentPhone} onChange={handleChange("agentPhone")} placeholder='Enter Mobile number' className="shrink-0 mt-1 p-2 outline-none  rounded border border-solid border-black border-opacity-20" />

                                            {/* Error Message */}
                                            {
                                                (errors?.agentPhone && touched?.agentPhone) &&
                                                <p className='text-red-700 pt-2'>{errors?.agentPhone}</p>
                                            }

                                            {/* Date & Time */}
                                            <div className="flex gap-3 mt-3">

                                                <div className="flex flex-col flex-1">
                                                    <div className="text-sm font-medium leading-5 text-black">
                                                        Call date & time <span className="text-orange-600">*</span>
                                                    </div>
                                                    <div className="flex  justify-between items-center px-3 py-2 mt-1 rounded border border-solid border-black border-opacity-20">
                                                        {currentDate}
                                                        <MdOutlineDateRange />
                                                    </div>
                                                </div>


                                                <div className="flex flex-col flex-1 pt-6 700">
                                                    <div className="flex  justify-between items-center px-3 py-2 mt-1 rounded border border-solid border-black border-opacity-20">
                                                        {currentTime}
                                                        <MdOutlineAccessTime />
                                                    </div>
                                                </div>


                                            </div>


                                            {/* Status */}
                                            <div className="mt-3 text-sm font-medium leading-5 text-zinc-800">
                                                Status
                                            </div>
                                            <div className="flex flex-col justify-center items-end px-3 py-2 mt-1 rounded border border-solid border-black border-opacity-20">
                                                <select className='w-full outline-none' onChange={(e) => handleSelectType(e.target.value)}>

                                                    {
                                                        Status?.map((v, i) => (
                                                            <option className='p-3' key={i} value={v?.id}>{v?.label}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>

                                            {/* Error Message */}
                                            {
                                                (errors?.status && touched?.status) &&
                                                <p className='text-red-700 pt-2'>{errors?.status}</p>
                                            }

                                            {/* Comments */}
                                            <div className="mt-3 text-sm font-medium leading-5 text-black">
                                                Comments <span className="text-orange-600">*</span>
                                            </div>
                                            <input value={values?.description} onChange={handleChange("description")} placeholder='write something here' className="outline-none p-2 mt-1 rounded border border-solid border-black border-opacity-20" />

                                            {/* Error Message */}
                                            {
                                                (errors?.description && touched?.description) &&
                                                <p className='text-red-700 pt-2'>{errors?.description}</p>
                                            }

                                            {/* Save & Cancle Button */}
                                            <div className="flex gap-2.5 pl-20 mt-5 font-medium justify-end whitespace-nowrap">
                                                <button onClick={() => setIsOpen(false)} className="cursor-pointer justify-center px-4 py-1.5 text-xs leading-5 rounded-md border border-solid border-black border-opacity-20 text-zinc-800">
                                                    Cancel
                                                </button>
                                                <button disabled={loading ? true : false} onClick={handleSubmit} className={`cursor-pointer justify-center px-4 py-1.5 text-sm leading-5 text-white rounded-md ${loading ? "bg-gray-600" : "bg-slate-600"}`}>
                                                    Save
                                                </button>
                                            </div>


                                        </div>
                                    </div>
                                    {/* ---------------------------------------------------------------- */}




                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>


                </Dialog>
            </Transition>

        </>
    )
}

export default AddCallLog;