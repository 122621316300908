import React, { useEffect } from 'react'
import { useFormik } from 'formik';
import axiosConfig from '../../../www/http';
import Files from 'react-files'
import { GrFormUpload } from "react-icons/gr";
import { notifyErrorMessage, notifySuccessMessage } from '../../../components/utils/toastMessage';
import { SaveContactValidation } from '../../../Validation/saveContactSchema';
import AccessComponent from '../../../components/utils/checkAccess';
import { AccessIds } from '../../../components/utils/allIdList';
import { getSalesAllContact, getSalesMyContact } from '../../../apis/apiService';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';


export const ContactAddMoreModal = () => {


    const { xvi_id } = useSelector(state => state.loginSlice)

    const dispatch = useDispatch()

    const accessAllList_Contact = AccessComponent(AccessIds.Contact_allList)
    const accessMyList_Contact = AccessComponent(AccessIds.Contact_myList)

    const TYPES = [
        { id: "", label: "Select" },
        { id: 1, label: "Quation" },
        { id: 2, label: "Incident" },
        { id: 3, label: "Problem" },
    ]

    const handleSelectType = (id) => {

        console.log("ID.....................", id);

        const filterdata = TYPES.find(v => v?.id == id)
        if (filterdata?.id) {
            setFieldValue("type", filterdata?.label)
        }

    }


    const initialValues = {
        type: "",
        name: "",
        email: "",
        mobilePhone: "",
        account: "",
        address: "",
        branch_id: "eff26b52-6d88-48f6-b94e-45d148ea5101",
        profileImage: "",
        isUpload: ""
    }



    const { values, handleBlur, handleChange, handleSubmit, errors, touched, setFieldValue, resetForm } = useFormik({
        initialValues: initialValues,
        validationSchema: SaveContactValidation,
        onSubmit: async (values) => {



            const formdata = new FormData()
            formdata.append("account", values?.account)
            formdata.append("address", values?.address)
            formdata.append("branchId", values?.branch_id)
            formdata.append("email", values?.email)
            formdata.append("mobilePhone", values?.mobilePhone)
            formdata.append("name", values?.name)
            formdata.append("profileImage", values?.profileImage)
            formdata.append("type", values?.type)

            console.log("Values...........................", values, errors);
            try {
                const { data } = await axiosConfig.post(`/save-contact`, formdata, {
                    headers: {
                        "X-V-I": `${xvi_id}`
                    }
                })
                if (data?.status === "SUCCESS") {
                    notifySuccessMessage(data?.message)
                    resetForm()
                    document.querySelector("#close-sales-contact-add-modal").click()

                    if (accessAllList_Contact) {
                        dispatch(getSalesAllContact(xvi_id, 0))
                    }
                    if (accessMyList_Contact) {
                        dispatch(getSalesMyContact(xvi_id, 0))
                    }

                }
                console.log("Successsssssss..............", data);

            } catch (error) {
                notifyErrorMessage(error.message)
                console.log("Faileddddddddddddddddd", error);
            }


        }
    })




    const handleUpload = (files) => {
        console.log(files)
        console.log("----------------URL-------------", files[0]?.preview?.url)
        setFieldValue("profileImage", files[0])
        setFieldValue("isUpload", true)
    }

    const handleError = (error, file) => {

        console.log('error code ' + error.code + ': ' + error.message)
        notifyErrorMessage(error?.message)
    }

    useEffect(() => {
        resetForm()
    }, [])

    console.log("ERRORS", errors);

    return (
        <div id="sales-contact-add-modal" class="hs-overlay hidden size-full fixed top-0 start-0 z-[80] overflow-x-hidden overflow-y-auto pointer-events-none">
            <div class="hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 mt-0 opacity-0 ease-out transition-all sm:max-w-lg sm:w-full  sm:mx-auto min-h-[calc(100%-3.5rem)] flex items-center">
                <div class="w-full flex flex-col  bg-white shadow-sm rounded-xl pointer-events-auto">
                    <div className="flex flex-col pt-6 bg-white rounded max-w-[438px]">
                        <div className="px-4 text-lg font-bold text-slate-950">
                            Add Contact
                        </div>
                        <div className=" px-8 py-4 mt-6 w-full bg-purple-600 border-b-0 border-solid border-slate-950" style={{ backgroundColor: "#9050D0" }}>
                            <div className="flex gap-5 max-md:flex-col max-md:gap-0">
                                <div className={`flex flex-col w-[24%] max-md:ml-0 max-md:w-full ${errors?.isUpload && "border-2 border-red-700 rounded-full"}`}>

                                    <Files
                                        className='files-dropzone rounded-full'
                                        onChange={handleUpload}
                                        onError={handleError}
                                        accepts={['image/png']}
                                        multiple
                                        maxFileSize={2097152}
                                        minFileSize={0}
                                        clickable>
                                        {
                                            values?.isUpload ?
                                                <img src={values?.profileImage?.preview?.url} className="flex justify-center items-center  bg-white bg-opacity-20 h-[70px] rounded-full w-[70px] object-cover" />

                                                :
                                                <div className="flex justify-center items-center px-6 mx-auto bg-white bg-opacity-20 h-[70px] rounded-[200px] w-[70px]">
                                                    <GrFormUpload size={20} />
                                                </div>

                                        }
                                    </Files>
                                </div>
                                <div className="flex flex-col ml-5 w-[76%] max-md:ml-0 max-md:w-full">
                                    <div className="flex flex-col self-stretch my-auto font-semibold text-white">
                                        <div className="text-sm capitalize">Upload profile image</div>
                                        <div className="mt-1 text-xs">
                                            Max: 2 MB, Supported: png, jpg, webp
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col p-8 w-full font-medium">
                            <div className="text-sm leading-5 text-zinc-800">Type</div>
                            <div className="flex gap-2.5 justify-center px-3 py-2 mt-1 text-sm whitespace-nowrap rounded border border-solid border-black border-opacity-20 text-zinc-800">
                                <select className='w-full outline-none' onChange={(e) => handleSelectType(e.target.value)}>

                                    {
                                        TYPES?.map((v, i) => (
                                            <option className='p-3' key={i} value={v?.id}>{v?.label}</option>
                                        ))
                                    }
                                </select>
                            </div>

                            {
                                errors?.type && touched?.type &&
                                <p className='text-red-700 mt-2'>{errors?.type}</p>
                            }


                            <div className="mt-3 text-sm leading-5 text-black">
                                Name <span className="text-orange-600">*</span>
                            </div>
                            <input value={values?.name} onChange={handleChange("name")} className="px-2 outline-none shrink-0 mt-1 h-8 rounded border border-solid border-black border-opacity-20" />

                            {
                                errors?.name && touched?.name &&
                                <p className='text-red-700 mt-2'>{errors?.name}</p>
                            }


                            <div className="mt-3 text-sm leading-5 text-black">
                                Email <span className="text-orange-600">*</span>
                            </div>
                            <input value={values?.email} onChange={handleChange("email")} className="px-2 shrink-0 mt-1 h-8 rounded border border-solid border-black border-opacity-20" />

                            {
                                errors?.email && touched?.email &&
                                <p className='text-red-700 mt-2'>{errors?.email}</p>
                            }


                            <div className="mt-3 text-sm leading-5 text-black">
                                Phone <span className="text-orange-600">*</span>
                            </div>
                            <input type='number' value={values?.mobilePhone} onChange={handleChange("mobilePhone")} className="px-2 shrink-0 mt-1 h-8 rounded border border-solid border-black border-opacity-20" />

                            {
                                errors?.mobilePhone && touched?.mobilePhone &&
                                <p className='text-red-700 mt-2'>{errors?.mobilePhone}</p>
                            }

                            <div className="mt-3 text-sm leading-5 text-black">
                                Account No <span className="text-orange-600">*</span>
                            </div>
                            <input type='number' value={values?.account} onChange={handleChange("account")} className="px-2 shrink-0 mt-1 h-8 rounded border border-solid border-black border-opacity-20" />

                            {
                                errors?.account && touched?.account &&
                                <p className='text-red-700 mt-2'>{errors?.account}</p>
                            }

                            <div className="mt-3 text-sm leading-5 text-black">
                                Address <span className="text-orange-600">*</span>
                            </div>
                            <input value={values?.address} onChange={handleChange("address")} className="px-2 shrink-0 mt-1 h-8 rounded border border-solid border-black border-opacity-20" />

                            {
                                errors?.address && touched?.address &&
                                <p className='text-red-700 mt-2'>{errors?.address}</p>
                            }

                            <div className="flex gap-2.5  mt-6 whitespace-nowrap justify-end">
                                <button data-hs-overlay="#sales-contact-add-modal" id='close-sales-contact-add-modal' className="cursor-pointer justify-center px-4 py-1.5 text-xs leading-5 rounded-md border border-solid border-black border-opacity-20 text-zinc-800">
                                    Cancel
                                </button>
                                <div onClick={handleSubmit} className="cursor-pointer justify-center px-4 py-1.5 text-sm leading-5 text-white rounded-md bg-blue-700">
                                    Save
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
