import { createSlice } from "@reduxjs/toolkit";

const loginSlice = createSlice({
    name: "login",
    initialState: {
        empDetails: [],
        modules: [],
        xvi_id: null,
        role: "",
        isAuthenticated: true,
        branchId: null
    },
    reducers: {
        setEmpDetails: (state, { payload }) => {

            console.log("Payload................JSON", payload);

            state.empDetails = payload
            state.role = payload?.type
            state.modules = payload?.data

            if (payload.type === "ADMIN") {
                state.xvi_id = payload?.adminId
            } else {
                state.xvi_id = payload?.employeeId
            }

            // empDetails.type
            // empDetails.employeeId || empDetails.adminId
            //payload.data===modules 

        },
        setIsAuthenticated: (state, { payload }) => {
            state.isAuthenticated = payload
        },
        setBranchId: (state, { payload }) => {
            state.branchId = payload
        }

    }
})

export default loginSlice.reducer;
export const { setEmpDetails, setIsAuthenticated, setBranchId } = loginSlice.actions;