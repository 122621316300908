import './App.css';
import AppRouter from './router/AppRouter';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { Provider } from 'react-redux';

import "preline/preline";
import store from './redux/store';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  const location = useLocation();

  useEffect(() => {
    window.HSStaticMethods.autoInit();
  }, [location.pathname]);
  return (
    <>
      <Provider store={store}>
        <AppRouter />
      </Provider>
      <ToastContainer />
    </>
  );
}

export default App;
