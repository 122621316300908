export const HOME = "/"
export const CUSTOMERSERVICE = "/customer-tickets"
export const SALESCONTACT = "/sales/contact"
export const SALESACCOUNT = "/sales/account"
export const SALESLEADS = "/sales/leads"
export const CUSTOMERTICKETDETAILS = "/customer-ticket/:ticket_id"
export const SALESLEADSDETAILS = "/leads-details/:lead_id"
//Marketting Routes

export const MARKETINGSUBMISSIONS = "/marketing/submissions"
export const MARKETINGCAMPAIGNS = "/marketing/campaigns"

export const MARKETINGFORMS = "/marketing/forms"
export const MARKETINGFORMSDETAILS = "/marketing/forms/details/:form_id"
export const MARKETINGFORMSPREVIEW = "/marketing/forms/details/preview/:form-id"
export const MARKETINGFORMSADD = "/marketing/forms/add"
export const MARKETINGFORMSFILL = "/marketing/forms/fillup/:id"
export const CAMPAIGNSDETAILS = "/marketing/campaign/:campaign_id"
export const ADDCAMPAIGN = "/marketing/campaign/new"
export const EDITCAMPAIGN = "/marketing/campaign/edit/:id"

export const SETTINGS = "/settings"


export const LOGIN = "/login"
export const EMPLOGIN = "/emp-login"