import React, { useEffect, useState } from 'react'
import PieChart from './piechart';
import MultiRangeSlider from "multi-range-slider-react";


export const InputRange = ({ type }) => {

    console.log("INPUTRANGE TAB_", type);

    const amount_labels = ["0", "25L", "50L", "75L", "100L", "125L", "150L", "175L", "200L"];
    const intrest_labels = ["0", "2.5", "5", "7.5", "10", "12.5", "15", "17.5", "20"];
    const month_labels = ["0", "6", "9", "12", "18", "24", "30"];

    const [loanAmount, setLoanAmount] = useState('')

    const [interestRate, setInterestRate] = useState(0)
    const [loanTermMonths, setLoanTermMonths] = useState(0)
    const [monthlyPayment, setMonthlyPayment] = useState(null);

    const [sliderAmount, setSliderAmount] = useState(0);

    const [sliderRate, setSliderRate] = useState(0)
    const [sliderMonth, setSliderMonth] = useState(0)



    const handleInput = (e, range) => {
        console.log("Calll", loanTermMonths, range);

        if (range === 1) {
            setLoanAmount(e.maxValue)
            setSliderAmount(e.maxValue)
        }
        if (range === 2) {
            setInterestRate(e.maxValue)
            setSliderRate(e.maxValue)
        }
        if (range === 3) {
            setLoanTermMonths(e.maxValue)
            setSliderMonth(e.maxValue)
        }
    };


    console.log("loanTermMonths", loanTermMonths);


    return (
        <div>
            {/* Slider & Input Box */}

            <div>


                <div>
                    <div className='mt-5 flex flex-row gap-10'>

                        <div className='w-60 flex justify-end items-center'>
                            <p className='font-semibold text-black text-[14px] items-center'>Home Loan Amount</p>
                        </div>

                        <div className="flex flex-row">
                            <input value={loanAmount} className='bg-white p-2 border w-80' />
                            <p className='p-4 bg-[#cbd3da]'>₹</p>
                        </div>
                    </div>

                    <div className='m-7'>
                        <MultiRangeSlider
                            min={0}
                            max={200000000}
                            value={loanAmount}
                            step={9}
                            onInput={(e) =>
                                handleInput(e, 1)
                            }
                            labels={amount_labels}
                        />
                    </div>
                </div>

                <div>
                    <div className='mt-5 flex flex-row gap-10'>

                        <div className='w-60 flex justify-end items-center'>
                            <p className='font-semibold text-black text-[14px] items-center'>Intrest Rate</p>
                        </div>

                        <div className="flex flex-row">
                            <input className='bg-white p-2 border w-80' />
                            <p className='p-4 bg-[#cbd3da]'>%</p>
                        </div>
                    </div>

                    <div className='m-7'>
                        <MultiRangeSlider
                            min={0}
                            max={20}
                            step={1}
                            value={sliderRate}
                            onInput={(e) =>
                                handleInput(e, 2)
                            }
                            labels={intrest_labels}
                        />
                    </div>
                </div>


                <div>
                    <div className='mt-5 flex flex-row gap-10'>

                        <div className='w-60 flex justify-end items-center'>
                            <p className='font-semibold text-black text-[14px] items-center'>Loan Tenure</p>
                        </div>

                        <div className="flex flex-row rounded-lg">
                            <input value={loanTermMonths} className='bg-white p-2 border w-80' />
                            <p className='p-4 bg-[#cbd3da]'>Yr</p>
                            <p className='p-4 bg-[#E9ecef]'>Mo</p>
                        </div>
                    </div>

                    <div className='m-7'>
                        <MultiRangeSlider
                            min={0}
                            max={30}
                            onChange={(e) => handleInput(e, 3)}
                            step={1}
                            value={0}
                            minValue={0}
                            labels={month_labels}
                        />
                    </div>
                </div>

            </div>


            {/* Pie Chart */}

            <div className='bg-white  w-full flex border h-[340px]'>
                <div className=' w-[50%] h-[100%]  flex justify-evenly flex-col'>

                    <div className=' justify-center items-center flex flex-col p-7 border-b-2 border-dotted'>
                        <p className='font-sans'>Loan Emi</p>
                        <h1 className='font-semibold text-2xl py-1'>₹ 44,986</h1>
                    </div>

                    <div className=' justify-center items-center flex flex-col p-7 border-b-2 border-dotted'>
                        <p className='font-sans'>Loan Emi</p>
                        <h1 className='font-semibold text-2xl py-1'>₹ 44,986</h1>
                    </div>

                    <div className=' justify-center items-center flex flex-col p-7 border-b-0 border-dotted'>
                        <p className='font-sans'>Loan Emi</p>
                        <h1 className='font-semibold text-2xl py-1'>₹ 44,986</h1>
                    </div>
                </div>
                <div className=' w-[50%] h-full border-l bg-[#e9ecef] flex justify-center items-center'>
                    <PieChart />
                </div>
            </div>
        </div>
    )
}
