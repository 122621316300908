import React, { useEffect, useState } from 'react'
import * as routesKey from "../../constants/routes"
import Template from '../../components/common/Template'
import { useDispatch } from 'react-redux'
import { getCampaignCharts, getCampaignLists, getLatestLeadsList, getLeadChartDetails, getMarkettingCharts, getTicketCharts, getTicketLists, getTopChartDetails } from '../../services/api'
import { useSelector } from 'react-redux'
import moment from 'moment/moment'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
  ArcElement,
} from "chart.js";
import { Bar, Doughnut, Line, Pie, getElementsAtEvent } from "react-chartjs-2";
import { Link } from 'react-router-dom'

const Home = () => {
  const genderColor = [
    "rgba(96, 165, 250, 1)",
    "rgba(253, 186, 116, 1)",
    "rgba(248, 113, 113, 1)",
  ];

  const options = {
    // maintainAspectRatio: false,
    scales: {
      x: {
        ticks: {
          callback: function(val, index) {
            if (index === 0) return null; 
            return this.getLabelForValue(val);
          }
        }
      }
    },
    responsive: true,
    plugins: {
      legend: {
        position: "top",
        display: false,
      },
    },
  };
  const donughntoptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
        display: false,
      },
    },
  };
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    BarElement,
    ArcElement
  );
 
  const { topChartDetails, leadChartDetails, leadsList, ticketChartDetails, ticketList, campaignList, campaignChartDetails, markettingformDetails } = useSelector((state) => state.dashboard);
  const { empDetails } = useSelector((state) => state.loginSlice)
  const { notifications } = useSelector((state) => state.notification);
  const dispatch = useDispatch()
  const [leadType, setleadType] = useState(2);
  const [leadsListType, setleadsListType] = useState(2);
  const [ticketListType, setticketListType] = useState(2)
  const [randomColors, setrandomColors] = useState([])
  const [markettingType, setMarkettingType] = useState(2);
  useEffect(() => {
    if (leadType === 1) {
      const today = moment().format("DD-MM-YYYY");
      dispatch(getLeadChartDetails(today, today, leadType));
    } else if (leadType === 2) {
      const fromDate=moment().subtract(1,'month').format("DD-MM-YYYY")
      const toDate = moment().format("DD-MM-YYYY");
      dispatch(getLeadChartDetails(fromDate, toDate, leadType));
    } else if (leadType === 3) {
      const toDate = moment().format("DD-MM-YYYY");
      dispatch(getLeadChartDetails(toDate, toDate, leadType));
    }
  }, [leadType])
  
  useEffect(() => {
    if (leadsListType === 1) {
      const today = moment().format("DD-MM-YYYY");
      dispatch(getLatestLeadsList(today, today, leadsListType));
    } else if (leadsListType === 2) {
      const fromDate=moment().subtract(1,'month').format("DD-MM-YYYY")
      const toDate = moment().format("DD-MM-YYYY");
      dispatch(getLatestLeadsList(fromDate, toDate, leadsListType));
    } else if (leadsListType === 3) {
      const toDate = moment().format("DD-MM-YYYY");
      const fromDate = moment().subtract(1,'year').format("DD-MM-YYYY");
      dispatch(getLatestLeadsList(fromDate, toDate, leadsListType));
    }
  },[leadsListType])
  useEffect(() => {
    if (ticketListType === 1) {
      const today = moment().format("DD-MM-YYYY");
      dispatch(getTicketLists(today, today));
      dispatch(getTicketCharts(today, today));
    } else if (ticketListType === 2) {
      const fromDate=moment().subtract(1,'month').format("DD-MM-YYYY")
      const toDate = moment().format("DD-MM-YYYY");
      dispatch(getTicketLists(fromDate, toDate));
      dispatch(getTicketCharts(fromDate, toDate));
    } else if (ticketListType === 3) {
      const toDate = moment().format("DD-MM-YYYY");
      const fromDate = moment().subtract(1,'year').format("DD-MM-YYYY");
      dispatch(getTicketLists(fromDate, toDate));
      dispatch(getTicketCharts(fromDate, toDate));
    }
  },[ticketListType])
  useEffect(() => {
    if (markettingType === 1) {
      const today = moment().format("DD-MM-YYYY");
      dispatch(getMarkettingCharts(today, today));
    } else if (markettingType === 2) {
      const fromDate=moment().subtract(1,'month').format("DD-MM-YYYY")
      const toDate = moment().format("DD-MM-YYYY");
      dispatch(getMarkettingCharts(fromDate, toDate));
    } else if (markettingType === 3) {
      const toDate = moment().format("DD-MM-YYYY");
      const fromDate = moment().subtract(1,'year').format("DD-MM-YYYY");
      dispatch(getMarkettingCharts(fromDate, toDate));
    }
  },[markettingType])

  useEffect(() => {
    dispatch(getTopChartDetails())
    dispatch(getCampaignCharts())
    dispatch(getCampaignLists())
  }, [])
  function getRandomColors(num) {
    if (typeof num !== 'number' || num <= 0) {
        // throw new Error('Input must be a positive integer.');
        return []
    }

    const colors = [];

    for (let i = 0; i < num; i++) {
        const randomColor = '#' + Math.floor(Math.random() * 16777215).toString(16).padStart(6, '0');
        colors.push(randomColor);
    }

    return colors;
  }
  
  useEffect(() => {
   setrandomColors(getRandomColors(5))
  },[])
  return (
    <Template>
      <div>
        <div className=' w-full py-14 px-12'>

          <div className=''>


            {/* Part-1 */}
            <div className="flex gap-5 self-stretch max-md:flex-wrap">
              <div className="flex flex-col flex-1  max-md:max-w-full">
                <div className="text-3xl font-semibold text-gray-700 max-md:max-w-full">
                  Welcome, {empDetails?.name}
                </div>
         {notifications?.filter((noti)=>!noti?.isSeen)?.length>0&&       <div className="text-sm font-medium leading-5 text-blue-700 max-md:max-w-full">
                  You have <span className="text-blue-700">{notifications?.filter((noti)=>!noti?.isSeen)?.length} unread</span> notification
                </div>}
              </div>
              <div className="flex gap-10 justify-between my-auto font-semibold">
                <div className="flex flex-col">
                  <div className="text-xs leading-4 text-zinc-800 text-opacity-60">
                    Account ID
                  </div>
                  <div className="text-sm leading-5 text-zinc-800">CID-430</div>
                </div>
                <div className="flex flex-col ">
                  <div className="text-xs leading-4 text-zinc-800 text-opacity-60">
                    Account owner
                  </div>
                  <div className="text-sm leading-5 text-zinc-800">Jenny Wilson</div>
                </div>
              </div>
            </div>

            {/* Part-2 */}
            <div className="self-stretch px-5 py-2 border-t border-b border-solid border-black border-opacity-10 my-10">
              <div className="flex gap-5 max-md:flex-col max-md:gap-0">
                <div className="flex flex-col w-3/12 max-md:ml-0 max-md:w-full">
                  <div className="flex grow gap-5 self-stretch max-md:mt-10">
                    <div className="flex justify-center items-center self-start p-3 bg-purple-500 rounded-xl shadow-sm h-[46px] w-[46px]">
                      <img
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/416593c9c2134707b53ac294523095118077c61922fa822f9919b1332c617c7a?"
                        className="w-6 aspect-square"
                      />
                    </div>
                    <div className="flex flex-col flex-1 leading-[150%]">
                      <div className="text-sm font-medium text-zinc-800">
                        New Submissions
                      </div>
                      <div className="mt-1 text-2xl font-semibold leading-8 text-gray-700">
                        +{topChartDetails?.todaysSubmissions}
                      </div>
                      <div className="flex gap-1.5 mt-1 text-xs">
                        <div className="font-bold text-red-500">-14%</div>
                        <div className="font-medium text-zinc-800">from last month</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col ml-5 w-3/12 max-md:ml-0 max-md:w-full">
                  <div className="flex grow gap-5 self-stretch max-md:mt-10">
                    <div className="flex justify-center items-center self-start p-3 bg-indigo-500 rounded-xl shadow-sm h-[46px] w-[46px]">
                      <img
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/36754bf513cc770672cb7703b0403474ad3068dead5acfbf68a21dfbe1350d12?"
                        className="w-6 aspect-square"
                      />
                    </div>
                    <div className="flex flex-col flex-1 leading-[150%]">
                      <div className="text-sm font-medium text-zinc-800">
                        Today’s Leads
                      </div>
                      <div className="mt-1 text-2xl font-semibold leading-8 text-gray-700">
                      {topChartDetails?.todaysLead}
                      </div>
                      <div className="flex gap-1.5 mt-1 text-xs">
                        <div className="font-bold text-green-400">+5%</div>
                        <div className="font-medium text-zinc-800">from last month</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col ml-5 w-3/12 max-md:ml-0 max-md:w-full">
                  <div className="flex grow gap-5 self-stretch max-md:mt-10">
                    <div className="flex justify-center items-center self-start p-3 bg-emerald-400 rounded-xl shadow-sm h-[46px] w-[46px]">
                      <img
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/8954a8d756690060d5b78801a56d7650e6ebc648b4fb4734dcfd5a603ba35552?"
                        className="w-6 aspect-square"
                      />
                    </div>
                    <div className="flex flex-col flex-1 leading-[150%]">
                      <div className="text-sm font-medium text-zinc-800">
                        Total Contacts
                      </div>
                      <div className="mt-1 text-2xl font-semibold leading-8 text-gray-700">
                      {topChartDetails?.totalContacts}
                      </div>
                      <div className="flex gap-1.5 mt-1 text-xs">
                        <div className="font-bold text-green-400">+55%</div>
                        <div className="font-medium text-zinc-800">from last month</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col ml-5 w-3/12 max-md:ml-0 max-md:w-full">
                  <div className="flex grow gap-5 self-stretch max-md:mt-10">
                    <div className="flex justify-center items-center self-start p-3 bg-amber-300 rounded-xl shadow-sm h-[46px] w-[46px]">
                      <img
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/39b6d490e9625af965fb4416914160ccb204662b32e227ce2122a2d40d503d88?"
                        className="w-6 aspect-square"
                      />
                    </div>
                    <div className="flex flex-col flex-1 leading-[150%]">
                      <div className="text-sm font-medium text-zinc-800">
                        Total Accounts
                      </div>
                      <div className="mt-1 text-2xl font-semibold leading-8 text-gray-700">
                        5,300
                      </div>
                      <div className="flex gap-1.5 mt-1 text-xs">
                        <div className="font-bold text-green-400">+8%</div>
                        <div className="font-medium text-zinc-800">from last month</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Part-3 */}

            <div className=' flex  py-5 gap-5'>

              {/* Left Part */}
              <div className=' flex w-[70%] flex-col'>

                {/* Top  */}
                <div className="flex flex-col self-stretch p-6 bg-white rounded-xl shadow-2xl h-[50%]">
                  <div className="flex gap-2.5 justify-between w-full max-md:flex-wrap max-md:max-w-full">
                    <div className="flex flex-col">
                      <div className="text-base font-semibold text-gray-700">Leads</div>
                      <div className="mt-1 text-xs leading-5 text-zinc-800 text-opacity-60">
                        <span className="font-bold text-green-400">(+23)</span> than last
                        week
                      </div>
                    </div>
                    <select className="flex gap-3 self-start py-1.5 pr-1.5 pl-2.5 text-xs font-medium leading-5 whitespace-nowrap rounded border border-gray-200 border-solid text-zinc-800" value={leadType} onChange={(e)=>setleadType(Number(e.target.value))}>
                    <option value={1}>Today</option>
                    <option value={2}>This Month</option>
                    <option value={3}>This Year</option>
                    </select>
                  </div>
                  <div className="flex gap-4 mt-4 whitespace-nowrap max-md:flex-wrap max-md:max-w-full">
                    <div className="flex flex-col flex-1 items-start px-5 py-4 rounded-lg bg-gray-100 bg-opacity-60">
                      <div className="text-sm font-medium leading-5 text-zinc-800">
                        Approved
                      </div>
                      <div className="mt-1 text-2xl font-semibold leading-8 text-gray-700">
                        {leadChartDetails?.totalApproved}
                      </div>
                    </div>
                    <div className="flex flex-col flex-1 items-start px-5 py-4 rounded-lg bg-gray-100 bg-opacity-60">
                      <div className="text-sm font-medium leading-5 text-zinc-800">
                        Declined
                      </div>
                      <div className="mt-1 text-2xl font-semibold leading-8 text-gray-700">
                        {leadChartDetails?.totalDeclined}
                      </div>
                    </div>
                    <div className="flex flex-col flex-1 items-start px-5 py-4 rounded-lg bg-gray-100 bg-opacity-60">
                      <div className="text-sm font-medium leading-5 text-zinc-800">
                        Ongoing
                      </div>
                      <div className="mt-1 text-2xl font-semibold leading-8 text-gray-700">
                        {leadChartDetails?.totalProcessing}
                      </div>
                    </div>
                  </div>
                  <div className="flex gap-4 mt-4 max-md:flex-wrap max-md:max-w-full h-[348px]">
                  <Line
              options={options} data={{
    labels:leadChartDetails?.approvedLeadChart?Object.keys(leadChartDetails?.approvedLeadChart):[],
    datasets: [
      {
        color: "#fff",
        label: "Approved",
        fill: false,
        lineTension: 0.3,
        backgroundColor: "#62b2fd",
        borderColor: "#62b2fd",
        borderCapStyle: "round",
        borderJoinStyle: "round",
        pointBorderColor: "#62b2fd",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "#62b2fd",
        pointHoverBorderColor: "#fff",
        pointHoverBorderWidth: 5,
        pointRadius: 0,
        pointHitRadius: 5,
        data:leadChartDetails?.approvedLeadChart? Object.values(leadChartDetails?.approvedLeadChart):[]
      },
      {
        color: "#000",
        label: "Declined",
        fill: false,
        lineTension: 0.3,
        backgroundColor: "#f99bab",
        borderColor: "#f99bab",
        borderCapStyle: "round",
        borderJoinStyle: "round",
        pointBorderColor: "#f99bab",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "#f99bab",
        pointHoverBorderColor: "#fff",
        pointHoverBorderWidth: 5,
        pointRadius: 0,
        pointHitRadius: 5,
        data:leadChartDetails?.declinedLeadChart? Object.values(leadChartDetails?.declinedLeadChart):[]
      },
      {
        color: "#000",
        label: "Pendings",
        fill: false,
        lineTension: 0.3,
        backgroundColor: "#9bdfc4",
        borderColor: "#9bdfc4",
        borderCapStyle: "round",
        borderJoinStyle: "round",
        pointBorderColor: "#9bdfc4",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "#9bdfc4",
        pointHoverBorderColor: "#fff",
        pointHoverBorderWidth: 5,
        pointRadius: 0,
        pointHitRadius: 5,
        data:leadChartDetails?.pendingLeadChart? Object.values(leadChartDetails?.pendingLeadChart):[]
      },
    ]
  }}
            />
                    

                  </div>

                </div>

                {/* Button */}
                <div className='bg-white flex h-[50%] flex-col rounded-xl shadow-2xl mt-5 p-5 overflow-auto no-scrollbar'>

                  <div className="flex gap-2.5 justify-between w-[100%] max-md:flex-wrap">
                    <div className="flex flex-col">
                      <div className="text-base font-semibold text-gray-700">
                        Latest Leads
                      </div>
                      <div className="mt-1 text-xs leading-5 text-zinc-800 text-opacity-60">
                        <span className="font-bold text-green-400">(+23)</span> than last
                        week
                      </div>
                    </div>
                    <select className="flex gap-3 self-start py-1.5 pr-1.5 pl-2.5 text-xs font-medium leading-5 whitespace-nowrap rounded border border-gray-200 border-solid text-zinc-800" value={leadsListType} onChange={(e)=>setleadsListType(Number(e.target.value))}>
                    <option value={1}>Today</option>
                    <option value={2}>This Month</option>
                    <option value={3}>This Year</option>
                    </select>
                  </div>

                  {/* Table */}
                  {/* Table Head */}
                  <div className="flex gap-2 justify-between self-stretch text-xs font-medium text-white border-b border-solid bg-neutral-800 border-white border-opacity-10 mt-2">
                    <div className="justify-center items-start px-3 py-2.5 border-r border-solid border-white border-opacity-20 max-md:pr-5 w-[40%]">Name</div>
                    <div className="justify-center items-start px-3 py-2.5  w-[20%]">Status</div>
                    <div className="justify-center items-start px-3 py-2.5  w-[20%]">Lead type</div>
                    <div className="justify-center items-start px-3 py-2.5  w-[20%]">Contact</div>
                  </div>
                  {/* Table Row */}

                  {
                    leadsList?.map((lead) => (
                      <div className="flex gap-2 justify-between self-stretch text-xs font-medium text-white border-b border-solid bg-white  border-black border-opacity-10 ">
                        <div className='w-[40%]'>
                          <div className="justify-center items-start px-3 pt-2  border-r border-solid border-black border-opacity-20 max-md:pr-5  text-[#154EE0] font-bold">{lead?.needs}</div>
                          <div className="justify-center items-start px-3 pb-2 border-r border-solid border-black border-opacity-20 max-md:pr-5  text-[#27292D] font-semibold">{lead?.comments}</div>
                        </div>
                        <div className="justify-center items-start content-center  w-[20%] text-green-700 font-extrabold ">{lead?.leadStatus}</div>
                        <div className="justify-center items-start  content-center w-[20%] text-black font-semibold">{lead?.leadType?.leadTypeName}</div>
                        <div className="justify-center items-start  content-center w-[20%] text-blue-700 font-bold">{ lead?.contact?.name}</div>
                      </div>
                    ))
                  }





                </div>
              </div>

              {/* Right Part */}
              <div className='bg-white flex w-[30%]  rounded-xl shadow-2xl  '>
                <div className="flex flex-col self-stretch px-6 pt-6 mx-auto w-full bg-white rounded-xl shadow-sm max-w-[480px] pb-5">
                  <div className="flex gap-2.5">
                    <div className="flex flex-col flex-1">
                      <div className="text-base font-semibold text-gray-700">
                        Ticket Today
                      </div>
                      <div className="mt-1 text-xs leading-5 text-zinc-800 text-opacity-60">
                        <span className="font-bold text-green-400">(+23)</span> than last
                        week
                      </div>
                    </div>
                    <select className="flex gap-3 self-start py-1.5 pr-1.5 pl-2.5 text-xs font-medium leading-5 whitespace-nowrap rounded border border-gray-200 border-solid text-zinc-800" value={ticketListType} onChange={(e)=>setticketListType(Number(e.target.value))}>
                    <option value={1}>Today</option>
                    <option value={2}>This Month</option>
                    <option value={3}>This Year</option>
                    </select>
                  </div>
                  <div className="flex flex-col px-8 mt-4 font-semibold whitespace-nowrap text-slate-950">
                    <div className="flex gap-5 justify-between pr-2.5 text-xs">
                      <div className="flex gap-2.5">
                        <div className="shrink-0 bg-blue-400 rounded-md h-[15px] w-[15px]" />
                        <div>Solved</div>
                      </div>
                      <div className="flex gap-2.5">
                        <div className="shrink-0 bg-orange-300 rounded-md h-[15px] w-[15px]" />
                        <div>Unsolved</div>
                      </div>
                      <div className="flex gap-2.5">
                        <div className="shrink-0 bg-red-400 rounded-md h-[15px] w-[15px]" />
                        <div>Pending</div>
                      </div>
                    </div>
                    <div className="flex flex-col justify-center px-11 py-3.5 mt-2 text-base">
                      {/* <div className="flex justify-center items-center rounded-full border-solid border-[15px] border-blue-400 border-opacity-10 stroke-[15px]"> */}
                      <Doughnut
            data={{
              labels: ["Sloved","UnSloved","Pending"],
              datasets: [
                {
                  label: "Gender",
                  data: ticketChartDetails?[ticketChartDetails?.totalSolvedTickets,ticketChartDetails?.totalUnsolvedTickets,ticketChartDetails?.othersTicket]:[],
                  backgroundColor: genderColor,
                  hoverOffset: 0,
                  borderWidth: 0,
                },
              ],
            }}
            options={donughntoptions}
            width={100}
            height={100}
            // height="156px"
            // width="156px"
          />
                      {/* </div> */}
                    </div>
                  </div>

                  <div className='max-h-[922px] overflow-auto no-scrollbar'>
                  {
                    ticketList?.map((ticket)=>(
                      <div className="flex flex-col justify-center p-5 mt-4 rounded-lg border border-solid border-zinc-800 border-opacity-10 ">
                        <div className="text-xs font-medium text-zinc-800">
                          {ticket?.message}
                        </div>

                        <div className="flex justify-between mt-3 p-2">

                          <div className="flex flex-col justify-center whitespace-nowrap text-zinc-800 w-[25%]">
                            <div className="text-xs">Type</div>
                            <div className="text-xs font-medium">{ticket?.type}</div>
                          </div>

                          <div className="flex flex-col justify-start items-start whitespace-nowrap w-[25%]">
                            <div className="text-xs text-zinc-800">Priority</div>
                            <div className="flex gap-1.5 justify-center text-xs font-medium text-center text-amber-500 rounded-lg">
                              <div className="shrink-0 my-auto w-2 h-2 bg-amber-500 rounded-full" />
                              <div>{ticket?.priority?.trim()? ticket?.priority:'High'}</div>
                            </div>
                          </div>

                          <div className="flex flex-col flex-1 justify-center text-zinc-800">
                            <div className="text-xs">Assign to</div>
                            <div className="text-xs font-medium capitalize">Employee Name</div>
                          </div>
                        </div>
                      </div>
                    ))
                  }
                  </div>






                </div>
              </div>


            </div>


            {/* Part-4 */}
            <div className=' flex   justify-between'>


              {/* Left Column */}
              <div className="flex flex-col p-6 rounded-l-xl w-[33%] shadow-lg rounded-lg bg-white">
                <div className="flex gap-2.5 justify-between w-full">
                  <div className="flex flex-col">
                    <div className="text-base font-semibold text-gray-700">Campaign</div>
                    <div className="mt-1 text-xs leading-5 text-zinc-800 text-opacity-60">
                      <span className="font-bold text-green-400">(+5) more</span> in 2021
                    </div>
                  </div>
                </div>
                <Pie
            data={{
              labels:campaignChartDetails?Object.keys(campaignChartDetails)?.filter((_,i)=>i<5):[],
              datasets: [
                {
                  label: "Campaign",
                  data: campaignChartDetails?Object.values(campaignChartDetails)?.filter((_,i)=>i<5):[],
                  backgroundColor:randomColors,
                  hoverOffset: 0,
                  borderWidth: 0,
                },
              ],
            }}
            options={donughntoptions}
            width={100}
            height={100}
            // height="156px"
            // width="156px"
          />
                <div className="flex flex-wrap gap-5 justify-center content-center px-3 mt-4 text-xs font-semibold text-slate-950">
                  {
                    Object.keys(campaignChartDetails)?.filter((_, i) => i < 5)?.map((item,index) => (
                      <div className="flex gap-2.5">
                      <div className="shrink-0  rounded-md h-[15px] w-[15px]" style={{background:randomColors[index]}} />
                      <div>{item}</div>
                    </div>
                    ))
                 }
                </div>
              </div>


              {/* Middle Column */}
              <div className="flex flex-col self-stretch p-6 bg-white  w-[33%] shadow-lg rounded-lg">
                <div className="flex gap-2.5">
                  <div className="flex flex-col flex-1">
                    <div className="text-base font-semibold text-gray-700">
                      Current Campaign
                    </div>
                    <div className="mt-1 text-xs leading-5 text-zinc-800 text-opacity-60">
                      <span className="font-bold text-green-400">(+23)</span> than last
                      week
                    </div>
                  </div>
                  {/* <div className="flex gap-3 self-start py-1.5 pr-1.5 pl-2.5 text-xs font-medium leading-5 whitespace-nowrap rounded border border-gray-200 border-solid text-zinc-800">
                    <div>Weekly</div>
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/856f35994dabb4e2b703bc1aa5d64d9c52b44c79c48ffcb97e24d7019989ec57?"
                      className="shrink-0 w-5 aspect-square"
                    />
                  </div> */}
                </div>
                {
                  campaignList?.map((campaign) => (
                    <tr className="flex  items-center  mt-4 font-medium bg-white rounded-md border border-solid border-zinc-100">
                  <td className="px-4 text-left py-3 self-stretch my-auto text-sm leading-5 text-neutral-800">
                    {campaign?.campaignName}
                  </td>
                  {/* <td className="px-4 py-3 text-right flex flex-col flex-1 justify-center self-stretch ">
                    <div className="text-sm leading-5 text-neutral-800">{campaign?.category}</div>
                  </td> */}
                  <td className="px-4 py-3 text-right flex flex-col flex-1 justify-center self-stretch ">
                    <div className="text-sm leading-5 text-neutral-800">{campaign?.campaignStatus}</div>
                  </td>
                </tr>
                  ))
                }
                
              </div>

              {/* Right Column */}
              <div className="flex flex-col justify-between items-center self-stretch px-6 py-16 w-[33%] shadow-lg rounded-lg bg-white">
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/0259d5f12e1a4f269e53600915b7dfff7044ec1e4bde5d6e7d60b365d087023e?"
                  className="self-stretch w-full aspect-square"
                />
                <Link to={routesKey.ADDCAMPAIGN} className="mt-8 text-base font-semibold text-blue-700">
                  Create Campaign
                </Link>
                <div className="mt-1 text-xs leading-5 text-zinc-800 text-opacity-60">
                  Get in front of more eyes by appearing
                </div>
              </div>



            </div>


            {/* Part-5 */}
            <div className=' flex  py-5 justify-between'>
              {/* Left Column */}
             {/*  */}
             <div className="flex flex-col justify-between items-center self-stretch px-6 py-16 w-[35%] shadow-lg rounded-lg bg-white">
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/0259d5f12e1a4f269e53600915b7dfff7044ec1e4bde5d6e7d60b365d087023e?"
                  className="self-stretch w-full aspect-square"
                />
                <Link to={routesKey.MARKETINGFORMSADD} className="mt-8 text-base font-semibold text-blue-700">
                  Create Marketing Forms
                </Link>
                <div className="mt-1 text-xs leading-5 text-zinc-800 text-opacity-60">
                  Get in front of more eyes by appearing
                </div>
              </div>
              {/* Right Columns */}
              <div className="flex flex-col self-stretch p-6 bg-white rounded-xl shadow-sm w-[63%] ">
                <div className="flex gap-2.5 max-md:flex-wrap">
                  <div className="flex flex-col flex-1 max-md:max-w-full">
                    <div className="text-base font-semibold text-gray-700 max-md:max-w-full">
                      Marketing Form
                    </div>
                    <div className="mt-1 text-xs leading-5 text-zinc-800 text-opacity-60 max-md:max-w-full">
                      <span className="font-bold text-green-400">(+23)</span> than last
                      week
                    </div>
                  </div>
                  <select className="flex gap-3 self-start py-1.5 pr-1.5 pl-2.5 text-xs font-medium leading-5 whitespace-nowrap rounded border border-gray-200 border-solid text-zinc-800" value={markettingType} onChange={(e)=>setMarkettingType(Number(e.target.value))}>
                    <option value={1}>Today</option>
                    <option value={2}>This Month</option>
                    <option value={3}>This Year</option>
                    </select>
                </div>
                <div className="flex-wrap content-center px-6 mt-4 max-md:px-5 max-md:max-w-full">
                  <div className="flex gap-5 max-md:flex-col max-md:gap-0">
                    <div className="flex flex-col w-[65%] max-md:ml-0 max-md:w-full">
                      <div className="flex flex-col justify-center max-md:mt-6">
                        <Doughnut
            data={{
              labels: ["Total Submissions","Total Converted"],
              datasets: [
                {
                  label: "Gender",
                  data: markettingformDetails?[markettingformDetails?.totalSubmissions,markettingformDetails?.totalConverted]:[],
                  backgroundColor: ["rgb(96,165,250)","rgb(167,243,208)"],
                  hoverOffset: 0,
                  borderWidth: 0,
                },
              ],
            }}
            options={donughntoptions}
            width={100}
            height={100}
            // height="156px"
            // width="156px"
          />
                      </div>
                    </div>
                    <div className="flex flex-col ml-5 w-[35%] max-md:ml-0 max-md:w-full">
                      <div className="flex flex-col self-stretch my-auto max-md:mt-10">
                        <div className="flex gap-2">
                          <div className="flex flex-col justify-center p-px">
                            <div className="shrink-0 w-2.5 h-2.5 bg-blue-400 rounded-2xl" />
                          </div>
                          <div className="text-sm leading-3 capitalize text-zinc-800">
                            total submissions
                          </div>
                        </div>
                        <div className="self-start mt-3 ml-5 text-base font-semibold leading-4 text-zinc-800 max-md:ml-2.5">
                          {markettingformDetails?.totalSubmissions}
                        </div>
                        <div className="flex gap-2 mt-14 max-md:mt-10">
                          <div className="flex flex-col justify-center p-px">
                            <div className="shrink-0 w-2.5 h-2.5 bg-emerald-200 rounded-2xl" />
                          </div>
                          <div className="text-sm leading-3 capitalize text-zinc-800">
                            total converted
                          </div>
                        </div>
                        <div className="self-start mt-3 ml-5 text-base font-semibold leading-4 text-zinc-800 max-md:ml-2.5">
                          {markettingformDetails?.totalConverted}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </Template>
  )
}

export default Home