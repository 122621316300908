import React, { useEffect } from 'react'
import { Dialog, Transition, Combobox } from '@headlessui/react'
import { Fragment, useState } from 'react'
import { GoPlus } from 'react-icons/go'
import { useDispatch } from 'react-redux'
import { getAllLeadsList, getAllLeadslistType, getLeadsContactList, getLeadsContactListAll, getLeadsContactListMy, getMyLeadsList, getSalesAllContact, getSalesMyContact } from '../../../apis/apiService'
import { useSelector } from 'react-redux'
import { BiSolidCheckboxChecked } from "react-icons/bi";
import { useFormik } from 'formik'
import axiosConfig from '../../../www/http'
import { notifyErrorMessage, notifySuccessMessage } from '../../../components/utils/toastMessage'
import { SaveLeadsValidation } from '../../../Validation/saveLeadsSchema'
import AccessComponent from '../../../components/utils/checkAccess'
import { AccessIds, not_access_Message } from '../../../components/utils/allIdList'

const AddNewLeads = () => {

  const [isOpen, setIsOpen] = useState(false)
  const dispatch = useDispatch()

  const { listLeadType, leadsAllContactList } = useSelector(state => state.salesSlice)
  const { empDetails, xvi_id, branchId } = useSelector(state => state.loginSlice)


  const SaveLeadsAccess = AccessComponent(AccessIds.leads_save)

  const accessAllList = AccessComponent(AccessIds.leads_allList)
  const accessMyList = AccessComponent(AccessIds.leads_myList)

  const accessMyContact = AccessComponent(AccessIds.Contact_myList)
  const accessAllContact = AccessComponent(AccessIds.Contact_allList)


  const accessAllLeadType = AccessComponent(AccessIds.leadType_allList)


  useEffect(() => {


    // Get Leads All Contact List
    if (accessMyContact) {
      dispatch(getLeadsContactListMy(xvi_id, 0))
    }
    if (accessAllContact) {
      dispatch(getLeadsContactListAll(xvi_id, 0))
    }
    // Get All Leads Type
    if (accessAllLeadType) {
      dispatch(getAllLeadslistType(xvi_id, 0))
    }
  }, [])


  const initialValues = {
    needs: "",
    contact: "",//contactId
    account: "",
    leadTypeId: "",
    branch: branchId,
    comments: "",
    createdby: empDetails?.type
  }

  console.log("empDetails", empDetails);

  const handleSelectAgentId = (id) => {
    const selected = listLeadType.find(elm => elm?.leadTypeId === id)
    console.log("SELECTEDDDDDDDDDDD", selected);//leadTypeId
    setFieldValue("leadTypeId", selected?.leadTypeId)
  }




  const { values, handleBlur, handleChange, handleSubmit, errors, touched, setFieldValue, resetForm } = useFormik({
    initialValues: initialValues,
    validationSchema: SaveLeadsValidation,


    onSubmit: async (values) => {

      console.log("errors...............", errors);


      const formdata = new FormData()
      formdata.append("needs", values?.needs)
      formdata.append("contactId", values?.contact?.contactId)
      formdata.append("account", values?.account)
      formdata.append("leadTypeId", values?.leadTypeId)
      formdata.append("branch", "eff26b52-6d88-48f6-b94e-45d148ea5101")
      formdata.append("comments", values?.comments)

      // CALL THE API HERE
      try {
        const { data } = await axiosConfig.post(`/lead/save-lead`, formdata, {
          headers: {
            "X-V-I": `${xvi_id}`
          }
        })
        console.log("Successsssssss..............", data);

        if (data?.status === "SUCCESS") {
          notifySuccessMessage(data?.message)
          resetForm()
          setIsOpen(false)

          // Call List Api according to allliat and mylist
          console.log("CALLLLLLLLLLLLLLLL   API HERE ............................1");
          if (accessAllList) {
            console.log("CALLLLLLLLLLLLLLLL   API HERE ............................2");
            dispatch(getAllLeadsList(xvi_id, 0))
          }
          if (accessMyList) {
            console.log("CALLLLLLLLLLLLLLLL   API HERE ............................3");
            dispatch(getMyLeadsList(xvi_id, 0))
          }

        }

      } catch (error) {
        notifyErrorMessage(error.message)
        console.log("Faileddddddddddddddddd", error);
      }


    }
  })




  console.log("errors...............", errors);


  // const [selected, setSelected] = useState("")
  const [query, setQuery] = useState('')



  const filteredPeople =
    query === '' ? leadsAllContactList : leadsAllContactList.filter((person) => person.name.toLowerCase().replace(/\s+/g, '').includes(query.toLowerCase().replace(/\s+/g, '')))


  const handleOpen = () => {
    if (SaveLeadsAccess) {
      setIsOpen(true)
    } else {
      notifyErrorMessage(not_access_Message)
    }
  }





  return (
    <>

      <button type="button" onClick={handleOpen}>
        <div className="cursor-pointer flex gap-2 px-3 py-2.5 text-sm leading-5 text-white whitespace-nowrap rounded-md bg-[#395185] items-center justify-center">
          <GoPlus size={15} />
          <div>New</div>
        </div>
      </button>


      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => { }}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>



          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-[420px]  transform overflow-hidden rounded-2xl bg-white  text-left align-middle shadow-xl transition-all">


                  {/* ---------------------------------------------------------------- */}

                  <div class="w-full max-h-full overflow-y-auto flex flex-col bg-white rounded-xl pointer-events-auto dark:bg-neutral-800 dark:border-neutral-700 dark:shadow-neutral-700/70">
                    <div className="flex flex-col max-w-screen-md font-medium bg-white rounded">
                      <div className="flex flex-col p-4 bg-[#1B99A1] text-white max-md:px-5 max-md:max-w-full">
                        <div className="text-2xl max-md:max-w-full">Add New Leads</div>
                        <div className="mt-1 text-xs max-md:max-w-full">
                          Brooklyn Simmons / Business name
                        </div>
                      </div>

                      <div className="flex flex-col self-stretch p-8 max-w-full">


                        <div className="text-sm font-medium leading-5 text-black">
                          Customer needs <span className="text-orange-600">*</span>
                        </div>
                        <input value={values?.needs} onChange={handleChange("needs")} type='text' placeholder='Enter Neads' className="shrink-0 mt-1 py-1.5 px-3 rounded border border-solid border-black border-opacity-20 max-md:max-w-full outline-none" />
                        {
                          (errors?.needs && touched?.needs) &&
                          <p className='text-red-700 font-sans pt-2'>{errors?.needs}</p>
                        }

                        <div className="mt-3 text-sm font-medium leading-5 text-black">
                          Account <span className="text-orange-600">*</span>
                        </div>
                        <input value={values?.account} onChange={handleChange("account")} type='text' placeholder='Enter Account Number' className="shrink-0 mt-1 py-1.5 px-3 rounded border border-solid border-black border-opacity-20 max-md:max-w-full outline-none" />


                        <div className="mt-3 text-sm font-medium leading-5 text-zinc-800">
                          Contact
                        </div>
                        {/* Combo Box */}
                        <Combobox value={values?.contact} onChange={(val) => setFieldValue("contact", val)}>
                          <div className="relative mt-1">
                            <div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left outline-none focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm border-2">
                              <Combobox.Input
                                className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
                                displayValue={(person) => person.name}
                                onChange={(event) => setQuery(event.target.value)}
                              />

                            </div>
                            <Transition
                              as={Fragment}
                              leave="transition ease-in duration-100"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0"
                              afterLeave={() => setQuery('')}
                            >
                              <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
                                {filteredPeople.length === 0 && query !== '' ? (
                                  <div className="relative cursor-default select-none px-4 py-2 text-gray-700">
                                    Nothing found.
                                  </div>
                                ) : (
                                  filteredPeople.map((person) => (
                                    <Combobox.Option
                                      key={person.contactId}
                                      className={({ active }) =>
                                        `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-teal-600 text-white' : 'text-gray-900'
                                        }`
                                      }
                                      value={person}
                                    >
                                      {({ selected, active }) => (
                                        <>
                                          <span
                                            className={`block truncate ${selected ? 'font-medium' : 'font-normal'
                                              }`}
                                          >
                                            {person.name}
                                          </span>
                                          {selected ? (
                                            <span
                                              className={`absolute inset-y-0 left-0 flex items-center pl-3 ${active ? 'text-white' : 'text-teal-600'
                                                }`}
                                            >
                                              <BiSolidCheckboxChecked color='green' />
                                            </span>
                                          ) : null}
                                        </>
                                      )}
                                    </Combobox.Option>
                                  ))
                                )}
                              </Combobox.Options>
                            </Transition>
                          </div>
                        </Combobox>

                        {/* Error Text */}
                        {
                          (errors?.contact && touched?.contact) &&
                          <p className='text-red-700 font-sans pt-2'>{errors?.contact}</p>
                        }


                        <div className="mt-3 text-sm font-medium leading-5 text-zinc-800">
                          Lead type
                        </div>
                        <div className="flex flex-col justify-center items-end px-3 py-2 mt-1 rounded border border-solid border-black border-opacity-20">
                          <select className='w-full outline-none' onChange={(e) => handleSelectAgentId(e.target.value)}>
                            <option value="">Select</option>
                            {
                              listLeadType?.map((v, i) => (
                                <option key={i} value={v?.leadTypeId}>{v?.leadTypeName}</option>
                              ))
                            }
                          </select>
                        </div>

                        {
                          (errors?.leadTypeId && touched?.leadTypeId) &&
                          <p className='text-red-700 font-sans pt-2'>{errors?.leadTypeId}</p>
                        }

                        <div className="mt-3 text-sm font-medium leading-5 text-zinc-800">
                          Created by
                        </div>
                        <input value={values?.createdby} disabled type='text' placeholder='Enter Account Number' className="shrink-0 mt-1 py-1.5 px-3 rounded border border-solid border-black border-opacity-20 max-md:max-w-full outline-none" />


                        <div className="mt-3 text-sm font-medium leading-5 text-zinc-800">
                          Comments
                        </div>
                        <input value={values?.comments} onChange={handleChange("comments")} type='text' placeholder='Enter subject' className="shrink-0 mt-1 py-1.5 px-3 rounded border border-solid border-black border-opacity-20 max-md:max-w-full outline-none" />

                        {
                          (errors?.comments && touched?.comments) &&
                          <p className='text-red-700 font-sans pt-2'>{errors?.comments}</p>
                        }

                        <div className="flex justify-end gap-2.5 pl-20 mt-5 font-medium whitespace-nowrap">
                          <button type="button" onClick={() => setIsOpen(false)} className="justify-center px-4 py-1.5 text-xs leading-5 rounded-md border border-solid border-black border-opacity-20 text-zinc-800">
                            Cancel
                          </button>
                          <div onClick={handleSubmit} className="cursor-pointer justify-center px-4 py-1.5 text-sm leading-5 text-white rounded-md bg-slate-600">
                            Save
                          </div>
                        </div>


                      </div>
                    </div>
                  </div>


                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>


        </Dialog>
      </Transition>
    </>
  )
}

export default AddNewLeads

/*

*/