import React from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useState } from 'react'
import { useFormik } from 'formik'
import { useSelector } from 'react-redux'
import Files from 'react-files'
import { GrFormUpload } from 'react-icons/gr'
import { leadWhatsappValidation } from '../../../Validation/leads_WhatsappSchema'
import { notifyErrorMessage, notifySuccessMessage } from '../../../components/utils/toastMessage'
import axiosConfig from '../../../www/http'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { getTicketWhatsappList } from '../../../apis/apiService'
import { FiEdit } from "react-icons/fi";


const ComposeMessage = () => {

    const [isOpen, setIsOpen] = useState(false)

    const { xvi_id } = useSelector(state => state.loginSlice)
    const { ticket_id } = useParams();
    const dispatch = useDispatch()



    const [loading, setLoading] = useState(false)


    const initialValues = {
        buttonText: "",
        buttonUrl: "",
        description: "",
        image: '',
        receiver: "",
        subject: '',
        isupload: ""
    }

    const handleUpload = (files) => {
        setFieldValue("isupload", "true")
        setFieldValue("image", files[0])
    }

    const { values, handleBlur, handleChange, handleSubmit, errors, touched, setFieldValue, resetForm } = useFormik({
        initialValues: initialValues,
        validationSchema: leadWhatsappValidation,

        onSubmit: async (values) => {


            console.log("Errrrrrrrrrrrrrrrrrrrrrrrr", errors);
            const formdata = new FormData()
            formdata.append("buttonText", values?.buttonText)
            formdata.append("buttonUrl ", values?.buttonUrl)
            formdata.append("description ", values?.description)
            formdata.append("image", values?.image)
            formdata.append("ticketId", ticket_id)
            formdata.append("receiver", values?.receiver)
            formdata.append("subject", values?.subject)

            console.log("Values...........................", values, errors);
            try {
                setLoading(true)
                const { data } = await axiosConfig.post(`/save-ticket-whatsapp`, formdata, {
                    headers: {
                        "X-V-I": `${xvi_id}`
                    }
                })
                if (data?.status === "SUCCESS") {
                    //call get api here 
                    dispatch(getTicketWhatsappList(xvi_id, 0, ticket_id))

                    setLoading(false)
                    notifySuccessMessage(data?.message)
                    resetForm()
                    setIsOpen(false)


                }
                console.log("Successsssssss..............", data);

            } catch (error) {
                setLoading(false)
                notifyErrorMessage(error.message)
                console.log("Faileddddddddddddddddd", error);
            }


        }
    })

    console.log("-----------------------------------------", errors);





    return (
        <>
            <button onClick={() => { setIsOpen(true); resetForm(); }} className="flex justify-center items-center px-8 py-2 w-full rounded-md bg-slate-600 max-md:px-5">
                <div className="flex gap-1">
                    <FiEdit />
                    <div>Compose</div>
                </div>
            </button>


            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={() => { }}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black/25" />
                    </Transition.Child>



                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-[650px]  transform overflow-hidden rounded-2xl bg-white  text-left align-middle shadow-xl transition-all">


                                    {/* ---------------------------------------------------------------- */}

                                    <div className="flex flex-col max-w-screen-md font-medium bg-white rounded">
                                        <div className="flex flex-col p-6 bg-[#1B99A1] text-white ">
                                            <div className="text-2xl max-md:max-w-full">New Message</div>
                                            <div className="mt-1 text-xs max-md:max-w-full">
                                                Brooklyn Simmons / Business name
                                            </div>
                                        </div>
                                        <div className="flex flex-col self-stretch p-8 max-w-screen-md max-md:px-5">
                                            <div className="flex gap-4 pr-20 max-md:flex-wrap max-md:pr-5">
                                                <div className={`flex flex-col w-[70px] max-md:ml-0 max-md:w-full ${errors?.image ? "border-2 border-red-700 rounded-full" : "rounded-full border border-black"}`}>

                                                    <Files
                                                        className='files-dropzone rounded-full'
                                                        onChange={handleUpload}
                                                        onError={(error) => notifyErrorMessage(error?.message)}
                                                        accepts={['image/png']}
                                                        multiple
                                                        maxFileSize={2097152}
                                                        minFileSize={0}
                                                        clickable>
                                                        {
                                                            values?.image ?
                                                                <img src={values?.image?.preview?.url} className="flex justify-center items-center  bg-white bg-opacity-20 h-[70px] rounded-full w-[70px] object-cover" />


                                                                :
                                                                <div className="flex justify-center items-center px-6 mx-auto bg-white bg-opacity-20 h-[70px] rounded-[200px] w-[70px]">
                                                                    <GrFormUpload size={20} />
                                                                </div>
                                                        }



                                                    </Files>
                                                </div>
                                                <div className="flex flex-col my-auto font-semibold text-zinc-800">
                                                    <div className="text-sm capitalize">Upload image</div>
                                                    <div className="mt-1 text-xs">
                                                        Max: 2 MB, Supported: png, jpg, webp
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mt-5 max-md:max-w-full">
                                                <div className="flex gap-5 max-md:flex-col max-md:gap-0">
                                                    <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
                                                        <div className="flex flex-col grow text-sm font-medium leading-5  max-md:mt-8">

                                                            <div>
                                                                Recipients name <span className="text-orange-700">*</span>
                                                            </div>
                                                            <input value={values?.receiver} onChange={handleChange("receiver")} type='text' placeholder='Enter recipients name' className="shrink-0 mt-1 py-1.5 px-3 rounded border border-solid border-black border-opacity-20 max-md:max-w-full outline-none" />
                                                            {/* Error Message */}
                                                            {
                                                                (errors?.receiver && touched?.receiver) &&
                                                                <p className='text-red-700 pt-2'>{errors?.receiver}</p>
                                                            }

                                                            <div className="mt-3">
                                                                Subject <span className="text-orange-600">*</span>
                                                            </div>
                                                            <input value={values?.subject} onChange={handleChange("subject")} type='text' placeholder='Enter subject' className="shrink-0 mt-1 py-1.5 px-3 rounded border border-solid border-black border-opacity-20 max-md:max-w-full outline-none" />
                                                            {/* Error Message */}
                                                            {
                                                                (errors?.subject && touched?.subject) &&
                                                                <p className='text-red-700 pt-2'>{errors?.subject}</p>
                                                            }

                                                            <div className="mt-3">
                                                                Button Text <span className="text-orange-600">*</span>
                                                            </div>
                                                            <input value={values?.buttonText} onChange={handleChange("buttonText")} type='text' placeholder='Button Text' className="shrink-0 mt-1 py-1.5 px-3 rounded border border-solid border-black border-opacity-20 max-md:max-w-full outline-none" />
                                                            {/* Error Message */}
                                                            {
                                                                (errors?.buttonText && touched?.buttonText) &&
                                                                <p className='text-red-700 pt-2'>{errors?.buttonText}</p>
                                                            }

                                                            <div className="mt-3">
                                                                Button URL <span className="text-orange-600">*</span>
                                                            </div>
                                                            <input value={values?.buttonUrl} onChange={handleChange("buttonUrl")} type='text' placeholder='Button Text' className="shrink-0 mt-1 py-1.5 px-3 rounded border border-solid border-black border-opacity-20 max-md:max-w-full outline-none" />
                                                            {/* Error Message */}
                                                            {
                                                                (errors?.buttonUrl && touched?.buttonUrl) &&
                                                                <p className='text-red-700 pt-2'>{errors?.buttonUrl}</p>
                                                            }
                                                        </div>
                                                    </div>


                                                    <div className="flex flex-col ml-5 w-6/12 max-md:ml-0 max-md:w-full">
                                                        <div className="flex flex-col grow self-stretch text-sm font-medium leading-5 text-black max-md:mt-8">
                                                            <div>
                                                                Message <span className="text-orange-600">*</span>
                                                            </div>
                                                            <textarea value={values?.description} onChange={handleChange("description")} type='text' placeholder='Enter subject' className="shrink-0 h-[200px] mt-1 py-1.5 px-3 rounded border border-solid border-black border-opacity-20 max-md:max-w-full outline-none" />
                                                            {/* Error Message */}
                                                            {
                                                                (errors?.description && touched?.description) &&
                                                                <p className='text-red-700 pt-2'>{errors?.description}</p>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex gap-2.5 pl-20 mt-5 font-medium whitespace-nowrap max-md:flex-wrap max-md:pl-5 justify-end">
                                                <button type="button" onClick={() => setIsOpen(false)} className="cursor-pointer justify-center px-4 py-1.5 text-xs leading-5 rounded-md border border-solid border-black border-opacity-20 text-zinc-800">
                                                    Cancel
                                                </button>
                                                <button disabled={loading ? true : false} onClick={handleSubmit} className="cursor-pointer justify-center px-4 py-1.5 text-sm leading-5 text-white rounded-md bg-slate-600">
                                                    Save
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                    {/* ---------------------------------------------------------------- */}




                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>


                </Dialog>
            </Transition>
        </>
    )
}

export default ComposeMessage