import React, { useEffect, useState } from 'react'
import Template from '../../../components/common/Template'
import { Link, useNavigate, useParams } from 'react-router-dom'
import * as routesKey from "../../../constants/routes"
import PreviewFormModal from './previewFormModal'
import { useDispatch } from 'react-redux'
import { getMarkettingFormDetails } from '../../../apis/apiService'
import { useSelector } from 'react-redux'
import Loader from '../../../components/Loader/loader'
import Pagination from '../../../components/common/pagination'
import { NoData } from '../../../components/common/noData'
import { UserFormDetails } from './Modals/form_userDetails'
import { IoIosCopy } from 'react-icons/io'


const MarkettingFormsDetails = () => {

    const navigate = useNavigate()

    const { xvi_id } = useSelector(state => state.loginSlice)
    const { markettingFormDetails, markettingFormDetailsTotalElement, ismarkettingFormDetailsLoading, submissionList } = useSelector(state => state.markettingSlice)
    const { form_id } = useParams();
    const dispatch = useDispatch()

    console.log("submissionList..........................", submissionList);

    const [page, setPage] = useState(0)
    const [isOpen, setIsOpen] = useState(false)
    const [data, setData] = useState(null)

    useEffect(() => {
        dispatch(getMarkettingFormDetails({ xvi_id, formId: form_id, page }))
    }, [page])

    console.log("ismarkettingFormDetailsLoading.......................", markettingFormDetails);

    const handleCopy = () => {
        navigator.clipboard.writeText(markettingFormDetails?.path).then(() => {
            console.log("Text copied to clipboard");
        }).catch(err => {
            console.error("Failed to copy text: ", err);
        });
    };

    return (

        <Template>
            {
                <UserFormDetails isOpen={isOpen} closeModal={() => setIsOpen(false)} data={data} />
            }

            <div className='min-h-[600px]'>
                <PreviewFormModal />

                {/* Header */}
                <div className="flex gap-2.5 self-stretch px-20 py-5 bg-white max-md:flex-wrap max-md:px-5">
                    <div className="flex flex-col flex-1 self-start max-md:max-w-full">
                        <div onClick={() => navigate(-1)} className="cursor-pointer flex gap-2 self-start text-xs whitespace-nowrap text-zinc-800">
                            <img
                                loading="lazy"
                                src="https://cdn.builder.io/api/v1/image/assets/TEMP/34deee8d7123904a5003e3e94732b7f59d4e0934eaaef9a558db6eb026d5fca9?"
                                className="shrink-0 w-4 aspect-square"
                            />
                            <div>Back</div>
                        </div>
                        <div className="mt-2 text-xl font-semibold text-slate-950 max-md:max-w-full">
                            Form : {markettingFormDetails?.formName}
                        </div>
                    </div>
                    <div className="flex gap-2 font-semibold max-md:flex-wrap w-[35%] justify-between">

                        <div className="flex flex-col">
                            <div className="text-xs leading-4 text-zinc-800 text-opacity-60">
                                Date created
                            </div>
                            <div className="text-sm leading-5 text-zinc-800">
                                15 January, 2023
                            </div>
                        </div>

                        <div className="flex flex-col">
                            <div className="text-xs leading-4 text-zinc-800 text-opacity-60">
                                Created by
                            </div>
                            <div className="text-sm leading-5 text-zinc-800">Jenny Wilson</div>
                        </div>


                        <div className='flex gap-5'>
                            <Link className="cursor-pointer justify-center px-3.5 py-1.5 my-auto text-xs font-medium leading-5 rounded-md border border-solid border-black border-opacity-20 text-zinc-800">
                                Edit Form
                            </Link>
                            <div onClick={() => navigate(`/marketing/forms/fillup/${form_id}`)} className="cursor-pointer flex gap-1 py-1.5 pr-3.5 pl-3 my-auto text-sm font-medium leading-5 text-white whitespace-nowrap rounded-md bg-[#395185]">
                                <img
                                    loading="lazy"
                                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/ac6a704df886f7a38fc46112b3b5ed698387d0154d20d7e8c796a4f56537af6b?"
                                    className="shrink-0 my-auto w-4 aspect-square"
                                />
                                <div>Preview</div>
                            </div>
                        </div>

                    </div>
                </div>

                {/* Details */}
                {
                    ismarkettingFormDetailsLoading ?
                        <Loader />
                        :

                        <div className=' px-20 my-5 pb-5'>

                            <div className="flex gap-2 self-stretch text-zinc-800 max-md:flex-wrap">
                                <div className="flex flex-1 gap-4 p-6 bg-white rounded-lg max-md:px-5">
                                    <div className="text-2xl font-semibold">{markettingFormDetails?.submissions}</div>
                                    <div className="text-xs font-medium capitalize">
                                        Total <br />
                                        submissions
                                    </div>
                                </div>


                                <div className="flex flex-1 gap-4 p-6 bg-white rounded-lg max-md:px-5">
                                    <div className="text-2xl font-semibold">23</div>
                                    <div className="flex flex-col">
                                        <div className="text-xs font-medium">Clicked</div>
                                        <div className="text-xs">Last 30 days</div>
                                    </div>
                                </div>
                                <div className="flex flex-1 gap-4 p-6 bg-white rounded-lg max-md:px-5">
                                    <div className="text-2xl font-semibold">{markettingFormDetails?.totalConverted}</div>
                                    <div className="flex flex-col">
                                        <div className="text-xs font-medium">Converted</div>
                                        <div className="text-xs">Last 30 Days</div>
                                    </div>
                                </div>
                            </div>

                            <div className=' w-full flex justify-end my-4'>
                                <div onClick={handleCopy} className='cursor-pointer bg-gray-400 flex items-center gap-4 p-2 rounded-md'>
                                    <h1>Copy Your Link</h1>
                                    <IoIosCopy />
                                </div>
                            </div>

                            {
                                (!submissionList?.length) ?
                                    <NoData />
                                    :



                                    <div className='bg-white w-full rounded-lg  my-6 shadow-2xl py-4'>

                                        <div className=' flex gap-5 items-center p-5'>
                                            <h1 className='text-black font-bold'>Total Submissions</h1>
                                            <p className='bg-orange-600 h-7 justify-center items-center flex w-7 p-1 rounded-3xl'>{markettingFormDetails?.submissions}</p>
                                        </div>

                                        {/* Header */}
                                        <div className='flex bg-black p-3 items-center'>
                                            <div className='flex gap-3 items-center w-[20%]'>
                                                <div className='h-5 w-5 border border-black'></div>
                                                <h1 className='text-white font-bold'>Full Name</h1>
                                            </div>

                                            <h1 className='text-white font-bold w-[20%]'>Status</h1>
                                            <h1 className='text-white font-bold w-[20%]'>Created on</h1>
                                            <h1 className='text-white font-bold w-[20%]'>Phone</h1>
                                            <h1 className='text-white font-bold w-[20%]'>Email</h1>
                                        </div>
                                        {/* Body */}

                                        {
                                            submissionList?.map((v, i) => (
                                                <div onClick={() => { setIsOpen(true); setData(v); }} className='flex bg-white border-b p-3 items-center cursor-pointer'>
                                                    <div className='flex gap-3 items-center w-[20%]'>
                                                        <div className='h-5 w-5 border border-white'></div>
                                                        <div className='h-10 w-10 bg-violet-700 rounded-full'></div>
                                                        <h1 className='text-black font-sans'>{v?.name}</h1>
                                                    </div>

                                                    <div className='w-[20%] flex'>
                                                        <p className='font-semibold bg-[#E0F2DF] text-[#137A2D] px-4 py-1 rounded-md'>{v?.submissionsStatus}</p>
                                                    </div>

                                                    <h1 className='text-black font-sans w-[20%]'>{v?.createdAt}</h1>




                                                    <div className='flex items-center gap-2  w-[20%] '>
                                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M11 8.46005V9.96005C11.0005 10.0993 10.972 10.2371 10.9162 10.3647C10.8604 10.4923 10.7786 10.6068 10.676 10.701C10.5734 10.7951 10.4523 10.8668 10.3203 10.9114C10.1884 10.956 10.0487 10.9726 9.90997 10.96C8.37138 10.7929 6.89347 10.2671 5.59497 9.42505C4.38688 8.65738 3.36263 7.63313 2.59497 6.42505C1.74996 5.12065 1.22409 3.63555 1.05997 2.09005C1.04747 1.95178 1.0639 1.81243 1.10822 1.68086C1.15253 1.54929 1.22375 1.42839 1.31735 1.32586C1.41095 1.22332 1.52487 1.1414 1.65186 1.08531C1.77886 1.02922 1.91614 1.00018 2.05497 1.00005H3.55497C3.79762 0.99766 4.03286 1.08359 4.21685 1.24181C4.40083 1.40004 4.52101 1.61977 4.55497 1.86005C4.61828 2.34008 4.73569 2.81141 4.90497 3.26505C4.97224 3.44401 4.9868 3.63851 4.94692 3.82549C4.90704 4.01247 4.8144 4.1841 4.67997 4.32005L4.04497 4.95505C4.75674 6.20682 5.79319 7.24327 7.04497 7.95505L7.67997 7.32005C7.81591 7.18562 7.98754 7.09297 8.17453 7.0531C8.36151 7.01322 8.556 7.02778 8.73497 7.09505C9.1886 7.26432 9.65993 7.38174 10.14 7.44505C10.3828 7.47931 10.6047 7.60165 10.7632 7.7888C10.9218 7.97594 11.0061 8.21484 11 8.46005Z" fill="#08B934" />
                                                        </svg>
                                                        <h1 className='text-black font-sans'>{v?.phone}</h1>
                                                    </div>
                                                    <div className='flex items-center gap-2  w-[20%] overflow-hidden'>
                                                        <svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M15.2028 9.06853C15.2573 8.92103 8.99109 4.18956 8.99109 4.18956C8.20662 3.61066 7.794 3.60153 7.01822 4.18956C7.01822 4.18956 0.761125 8.91722 0.813781 9.06269L0.8125 9.06375C0.813125 9.06503 0.814187 9.06587 0.815062 9.06703C0.868119 9.21166 0.945306 9.34626 1.04334 9.46509C1.08475 9.51531 1.13466 9.55737 1.18266 9.60112L1.18031 9.60294C1.18413 9.60634 1.18922 9.60794 1.19306 9.61131C1.3965 9.79278 1.65303 9.91031 1.93803 9.94387C1.9425 9.94441 1.94609 9.94675 1.95056 9.94728H1.96775C2.01106 9.95144 2.05228 9.96184 2.09666 9.96184H13.9264C14.5089 9.96184 15.0025 9.5955 15.1985 9.08203C15.2008 9.07822 15.2044 9.07544 15.2066 9.07153L15.2028 9.06853Z" fill="#96A9B2" />
                                                            <path d="M8.01339 3.64634L0.845703 9.02213V1.40644C0.845703 0.664125 1.44733 0.0625 2.18964 0.0625H13.8371C14.5795 0.0625 15.1811 0.664125 15.1811 1.40644V9.02209L8.01339 3.64634Z" fill="#B9C5C6" />
                                                            <path d="M0.929199 0.938973L7.04889 5.74566C7.82126 6.31563 8.22754 6.32463 8.99136 5.74566L15.1014 0.946598C14.9954 0.754441 14.8367 0.602629 14.6552 0.481566L14.7393 0.415691C14.5342 0.228973 14.272 0.10791 13.9809 0.0766602L2.0462 0.0770664C1.75598 0.10791 1.49336 0.229379 1.28867 0.415691L1.37211 0.480723C1.19264 0.600129 1.03542 0.749317 0.929199 0.938973Z" fill="#EDECE6" />
                                                            <path d="M0.927121 0.949356C0.873371 1.09457 7.04306 5.75314 7.04306 5.75314C7.81543 6.32311 8.22172 6.33211 8.98553 5.75314C8.98553 5.75314 15.1462 1.09836 15.0943 0.955105L15.0956 0.954074C15.095 0.952824 15.0939 0.951981 15.0931 0.950824C15.0409 0.808412 14.9649 0.675888 14.8683 0.558887C14.8275 0.509449 14.7784 0.468043 14.7312 0.424949L14.7335 0.423168C14.7297 0.419824 14.7247 0.418262 14.7209 0.414918C14.5206 0.236262 14.268 0.120512 13.9874 0.0874805C13.983 0.0869493 13.9795 0.0846682 13.9751 0.084137H13.9582C13.9155 0.0800745 13.8749 0.0698242 13.8312 0.0698242H2.18375C1.61021 0.0698242 1.12428 0.430511 0.931277 0.936105C0.928965 0.939855 0.925434 0.942574 0.92334 0.946449L0.927121 0.949356Z" fill="#DCE2E2" />
                                                        </svg>

                                                        <h1 className='text-black font-sans w-[80%]'>{v?.email}</h1>
                                                    </div>

                                                </div>
                                            ))
                                        }




                                    </div>
                            }

                        </div>
                }
                <div className='flex justify-center w-full p-4 mb-4  -bottom-3  left-14 right-0 '>

                    <Pagination
                        className="pagination-bar"
                        currentPage={page + 1}
                        totalCount={markettingFormDetailsTotalElement}
                        pageSize={10}

                        onPageChange={(page) => {
                            setPage(page - 1)
                            var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
                            if (currentScroll > 0) {
                                window.scrollTo(0, currentScroll - (currentScroll / 1));
                            }
                        }}
                    />
                </div>

            </div>

        </Template>

    )
}
export default MarkettingFormsDetails