import { Dialog, Listbox, Transition } from '@headlessui/react'
import React, { Fragment, useEffect, useState } from 'react'
import {ReactComponent as CloseModalIcon} from "../../../assets/icons/close_modal_icon.svg"
import { CheckIcon } from '@heroicons/react/24/outline'
import demoProfile from "../../../assets/images/profile.png"
import { useNavigate } from 'react-router'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { getAssignedEmployeeList } from '../../../apis/apis'
import { filterAssignedEmployeeList, setAllAssignedEmployeeList } from '../../../redux/slices/campaignSlice'
import { toast } from 'react-toastify'
import { useParams } from 'react-router-dom';
import axiosConfig from '../../../www/http'
const AssignTickets = ({assignTo}) => {
    const { assignedEmployeeList, campaignList, totalCampaignList } = useSelector(state => state.campaign)
    const [isLoading, setisLoading] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { ticket_id } = useParams();
    useEffect(() => {
        dispatch(getAssignedEmployeeList())
    },[dispatch])
    const [selected, setSelected] = useState("")
    const [isshow, setisshow] = useState(false);
    const openModal = () => {
      setisshow(true);
      
  };
  useEffect(() => {
    if (assignTo) {
      setSelected(assignTo);
    } else {
      setSelected("");
    }
  },[assignTo])
    
    
      const changeList = (studio) => {
        setSelected(studio);
      };
    

 


    const replaceStudio = async () => {
        if (!selected) {
            toast.error("Please select one employee to assign")
            return;
        }
        try {
          const formdata = new FormData();
          formdata.append("roleAssignId",selected)
          formdata.append("ticketId", ticket_id)
          setisLoading(true)
          const { data } = await axiosConfig.post(`/assign-to-ticket`, formdata)
          if (data?.status === "SUCCESS") {
            toast.success(data?.message)
            setisLoading(false);
            setisshow(false);
          } else {
            toast.error(data?.message)
            setisLoading(false);
          }
        } catch (error) {
          toast.error("Something went wrong")
          setisLoading(false);
        }
    }
    
  const [searchValue, setsearchValue] = useState("")
  const handleSearch = (e) => {
    const { value } = e?.target;
    setsearchValue(value);
    if (!value.length) {
      dispatch(setAllAssignedEmployeeList())
    } else {
      dispatch(filterAssignedEmployeeList(value))
    }
  }
 
  const closeModal = () => {
    setisshow(false);
    setsearchValue("")
  };
  return (
      <div>
          <Listbox value={selected}  onChange={changeList}>
          <div className="relative w-full flex justify-end pr-4">
            <Listbox.Button
                      onClick={openModal}
                      className={"w-full"}
            >
          <div className="flex w-full gap-2 py-2 pr-2.5 pl-2 mt-1 text-sm bg-white rounded border border-solid border-black border-opacity-20 text-zinc-800">
                    <span className="flex-1 text-left">{selected?assignedEmployeeList?.find((assign)=>assign?.roleAssignId===selected)?.employeeName:"Select"}</span>
                </div>
            </Listbox.Button>
            <Transition
              show={isshow}
              // as={Fragment}
              className="fixed inset-y-0 max-w-sm min-h-screen mx-auto z-50"
            >
              <Dialog as="div" className="relative z-10" onClose={closeModal}>
                <Transition.Child
                  enter="transition-opacity ease-linear duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="transition-opacity ease-linear duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                  className="fixed inset-y-0 inset-x-0 m-auto max-w-full bg-[#00000084] backdrop-blur-sm z-40"
                />
                <Transition.Child
                  enter="transition ease-in-out duration-300 transform"
                  enterFrom="translate-y-full"
                  enterTo="translate-y-0"
                  leave="transition ease-in-out duration-300 transform"
                  leaveFrom="translate-y-0"
                  leaveTo="translate-y-full"
                  className="fixed bottom-0 inset-x-0 m-auto max-w-full md:max-w-[30rem] min-h-[80vh] max-h-[80vh] rounded-t-xl text-base shadow-lg overflow-auto ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-50"
                >
                  <div className="flex flex-col justify-start items-start self-stretch flex-grow relative bg-[#202124] min-h-[inherit]">
                    <div className="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 gap-3 bg-[#202124] sticky top-0 z-50 p-4">
                      <div className="flex justify-between items-center self-stretch flex-grow-0 flex-shrink-0 relative overflow-hidden">
                        <p className="flex-grow-0 flex-shrink-0 text-base font-medium text-center text-white">
                          Assign Ticket
                        </p>
                        <CloseModalIcon className='cursor-pointer' onClick={closeModal} />
                      </div>
                      <div className="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 gap-4">
                        <input
                          type="text"
                          placeholder="Search"
                          className="formInput w-full"
                          onChange={handleSearch}
                          autoComplete="off"
                          value={searchValue}
                        />
                      </div>
                    </div>
                    <Listbox.Options className="flex flex-col flex-grow self-stretch">
                      {assignedEmployeeList?.length === 0 && (
                        <div className="flex flex-col justify-center items-center flex-grow-0 flex-shrink-0 self-stretch p-4">
                          <p className="flex-grow-0 flex-shrink-0 text-base font-normal text-center text-white">
                            No Employee Found
                          </p>
                        </div>
                      )}
                                      {assignedEmployeeList?.map((docter, index) => (
                        <Listbox.Option
                          key={index}
                          className={({ active }) =>
                            `relative cursor-default select-none py-4 pl-5 pr-3 flex justify-start items-center flex-grow-0 flex-shrink-0 self-stretch border-b border-[#ffffff10] last:border-0 ${
                              active
                                ? 'bg-[#19191b55] text-white'
                                : 'text-white'
                            }`
                          }
                          value={docter?.roleAssignId}
                        >
                           <Fragment>
                                            <div className="flex justify-between items-center flex-grow relative gap-4">
                                                      <div className="flex  justify-start items-center flex-grow flex-shrink-0 self-stretch gap-4 relative">    
                                                      <img src={docter?.image?docter?.image:demoProfile} alt='docter_image' className='h-8 w-8 rounded-full object-cover bg-black' />
                                <div className="flex flex-col justify-start items-start flex-grow flex-shrink-0 self-stretch relative">
                                  <p
                                    className={`flex flex-col justify-start items-start flex-grow-0 flex-shrink-0 relative text-left text-white ${
                                      selected===docter?.roleAssignId ? 'font-medium' : 'font-normal'
                                    }`}
                                  >
                                    {docter.employeeName}
                                  </p>
                                  <p
                                    className={`flex flex-col justify-start items-start flex-grow-0 flex-shrink-0 relative text-left text-white ${
                                      selected===docter?.roleAssignId ? 'font-medium' : 'font-normal'
                                    }`}
                                  >
                                    {docter.email}
                                  </p>
                                </div>
                                </div>
                                <div className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative gap-1 pr-3">
                                  <p className="flex-grow-0 flex-shrink-0 text-left capitalize text-[#2fa3ff]">
                                   {docter?.roleName}
                                  </p>
                                </div>
                              </div>
                              {selected===docter?.roleAssignId ? (
                                <div className="absolute left-0 flex flex-grow-0 flex-shrink-0 justify-center items-center ml-5 w-8 h-8 text-white rounded-full bg-[#0362fcad] backdrop-blur-sm">
                                  <CheckIcon
                                    className="h-4 w-4"
                                    aria-hidden="true"
                                  />
                                </div>
                              ) : null}
                            </Fragment>
                        </Listbox.Option>
                      ))}
                                  </Listbox.Options>
                                  <div className="flex justify-start  items-start self-stretch flex-grow-0 flex-shrink-0 gap-2 p-4 absolute w-full bottom-0 z-50 bg-[#242327] border-t border-white/5">
                      <button
                        type="button"
                        className="btn-primary  grow md:grow-0 md:w-28 md:rounded-lg md:py-2"
                        onClick={replaceStudio}
                      disabled={isLoading|| !selected ?"disable":""}
                      style={isLoading|| !selected ?{background:"gray"}:{}}
                      >
                        Done
                      </button>
                    </div>
                  </div>
                </Transition.Child>
              </Dialog>
            </Transition>
          </div>
        </Listbox>
    </div>
  )
}

export default AssignTickets