import React, { useEffect, useState } from 'react'
import ComposeMessage from './ComposeMessage'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { getLeadsWhatsappList } from '../../../../apis/apiService'
import { useParams } from 'react-router-dom'
import Loader from '../../../../components/Loader/loader'
import Pagination from '../../../../components/common/pagination'
import { NoData } from '../../../../components/common/noData'

export const SalesWhatsapp = () => {

    const { lead_id } = useParams()
    const dispatch = useDispatch()
    const { xvi_id } = useSelector(state => state.loginSlice)
    const { leadDetails, whatsappList, whatsapp_total_elements, isWhatsappLoading } = useSelector(state => state.salesSlice)


    const [page, setPage] = useState(0)
    const [isWhatsapp, setIsWhatsapp] = useState(null)

    useEffect(() => {
        dispatch(getLeadsWhatsappList(xvi_id, page, lead_id))
    }, [page])


    console.log("whatsapp.......................", whatsappList);
    console.log("whatsapp_total_elements.......................", whatsapp_total_elements);

    useEffect(() => {
        if (whatsappList && whatsappList.length) {
            whatsappList.find((v, i) => {
                i === 0 &&
                    setIsWhatsapp(v)
            });
        }
    }, [whatsappList]);

    return (

        <div>


            {
                isWhatsappLoading ?
                    <Loader />
                    :
                    <div className="flex gap-5 justify-between items-start px-8 pt-8 pb-2 w-full bg-white max-md:flex-wrap max-md:px-5 max-md:max-w-full">
                        <div className="flex flex-col gap-2 w-[35%]">
                            <div className="flex flex-col justify-center p-5 text-sm font-medium leading-5 text-white  bg-white border-b border-solid border-black border-opacity-10">
                                <ComposeMessage />
                            </div>

                            {/* Left Items */}
                            <div className='flex flex-col gap-3'>
                                {whatsappList.map((v, i) => (
                                    <div onClick={() => setIsWhatsapp(v)} className="flex flex-col py-4 mx-6 border-b border-solid border-black border-opacity-10 max-md:mx-2.5">
                                        <div className="flex gap-5">
                                            <div className="flex flex-1 gap-2.5">
                                                <img
                                                    src={v?.image}
                                                    loading="lazy"
                                                    className="shrink-0 w-10 rounded-full aspect-square"
                                                />
                                                <div className="flex flex-col flex-1 self-start">
                                                    <div className="text-xs text-zinc-800 text-opacity-60">
                                                        {v?.receiver}
                                                    </div>
                                                    <div className="text-sm font-medium leading-5 text-zinc-800">
                                                        {v?.subject}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="self-start text-xs font-medium text-right text-zinc-800 text-opacity-40">
                                                12m ago
                                            </div>
                                        </div>
                                        <div className="mt-1.5 text-xs  text-ellipsis text-zinc-800 text-opacity-60 line-clamp-3">
                                            {v?.description}
                                        </div>
                                    </div>

                                ))}
                            </div>
                        </div>
                        {/* 2nd Part */}
                        {
                            !(whatsappList?.length) ?
                                <NoData />
                                :

                                <div className="flex flex-col max-md:max-w-full w-[65%]">

                                    {
                                        isWhatsapp &&
                                        <div >
                                            <div className="flex gap-5 justify-between px-2.5 py-2 w-full bg-white shadow-sm max-md:flex-wrap max-md:max-w-full">
                                                <div onClick={() => setIsWhatsapp(null)} className='cursor-pointer'>
                                                    <img
                                                        loading="lazy"
                                                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/e599e64930aa6fab8e61d550c7df56db9fb5099c4ee7605d7f2a692a1ecf8f21?apiKey=4c514e50e07d4444bfd3ae3d48b8bd32&"
                                                        className="shrink-0 my-auto aspect-square w-[22px]"
                                                    />
                                                </div>

                                            </div>
                                            <div className="flex gap-5 justify-between py-5 pr-4 pl-16 w-full bg-white max-md:flex-wrap max-md:pl-5 max-md:max-w-full">
                                                <div className="text-2xl font-medium leading-7 text-neutral-800">
                                                    {isWhatsapp?.subject}
                                                </div>

                                            </div>
                                            <div className="flex gap-5 justify-between px-4 py-1.5 w-full bg-white max-md:flex-wrap max-md:max-w-full">
                                                <div className="flex gap-3">
                                                    <img
                                                        loading="lazy"
                                                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/b291f5886003d8cf60c5dfffcdc3a63e3c573e618ad4316aec95bb51efc79f2a?apiKey=4c514e50e07d4444bfd3ae3d48b8bd32&"
                                                        className="shrink-0 w-10 aspect-square fill-black fill-opacity-10"
                                                    />
                                                    <div className="flex flex-col my-auto">
                                                        <div className="flex gap-1">
                                                            <div className="text-sm font-bold text-neutral-800">
                                                                {isWhatsapp?.receiver}
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="flex flex-col items-start py-3.5 pr-5 pl-16 bg-white max-md:pl-5 max-md:max-w-full">
                                                <div className="shrink-0 max-w-full bg-zinc-300 h-[188px] w-[400px]">
                                                    <img src={isWhatsapp?.image} alt="Image" class="h-full w-full object-contain" />
                                                </div>
                                                <div className="mt-6 text-xs tracking-normal leading-4 text-black">
                                                    {isWhatsapp?.description}
                                                </div>

                                            </div>
                                        </div>
                                    }


                                </div>
                        }
                    </div>

            }

            <div className='flex justify-center w-full p-4 mb-4  -bottom-3  left-14 right-0 '>

                <Pagination
                    className="pagination-bar"
                    currentPage={page + 1}
                    totalCount={whatsapp_total_elements}
                    pageSize={10}

                    onPageChange={(page) => {
                        setPage(page - 1)
                        var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
                        if (currentScroll > 0) {
                            window.scrollTo(0, currentScroll - (currentScroll / 1));
                        }
                    }}
                />
            </div>

        </div>
    )
}
