import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useEffect, useState } from 'react'
import axiosConfig from '../../www/http'
import { AllIds } from '../../components/utils/assign_ids'
import { useDispatch } from 'react-redux'
import { getListRole } from '../../apis/apiService'
import { notifyErrorMessage, notifySuccessMessage } from '../../components/utils/toastMessage'
import { useSelector } from 'react-redux'


export const EditNewRoleModal = ({ isOpenEditRoleModal, closeModal, data }) => {


    const { xvi_id } = useSelector((state) => state.loginSlice);
    const dispatch = useDispatch()

    let [loading, setLoading] = useState(false)

    const [roleName, setRoleName] = useState("")
    const [selectedIds, setSelectedIds] = useState([])

    useEffect(() => {
        setRoleName(data?.role_name)
        setSelectedIds(data?.modules_id_list)
    }, [])





    const isAllValidate = () => {
        console.log("Call this function", selectedIds, roleName);
        if (selectedIds?.length && roleName) {
            return true
        } else {
            if (!roleName) {
                notifyErrorMessage("Please Enter Role Name")
            }
            else if (!(selectedIds?.length)) {
                notifyErrorMessage("Please Select minimun one modules")
            }
        }
    }


    const handleSaveRole = async (id) => {





        const formdata = new FormData()
        formdata.append("modulesCodeList", selectedIds)
        formdata.append("roleName", roleName)
        formdata.append("roleId", data?.role_id)

        if (isAllValidate()) {



            try {
                setLoading(true)
                const { data } = await axiosConfig.put(`/edit-role`, formdata, {
                    headers: {
                        "X-V-I": xvi_id
                    }
                })

                if (data?.status === "SUCCESS") {
                    setLoading(false)
                    closeModal()
                    notifySuccessMessage(data?.message)
                    dispatch(getListRole(xvi_id))
                }


            } catch (error) {
                notifySuccessMessage(error?.message)
                setLoading(false)
            }
        }
    }


    const isIdAvailable = (id) => {

        console.log("Callledddddddddddddd.....................", id);

        for (const category of AllIds) {
            for (const option of category.option) {
                if (option.id === id) {
                    return true;
                }
            }
        }
        return false;
    };

    console.log('selectedIds', selectedIds)


    const handleCheckBox = (e, item, parentobj) => {
        // console.log('e.............', e);
        // console.log('item...................', item);
        // console.log('parentObj', parentobj);
        const id = item?.id

        console.log("parentobj...........................", parentobj);

        if ((item?.column_name === "My list" || item?.column_name === "All list") && e.target.checked) {
            const allListId = parentobj?.option.find((obj) => obj?.column_name === "All list").id;
            const MyListId = (parentobj?.option.find((obj) => obj?.column_name === "My list")?.id) || "TEMP_IDX";


            // const MyListId = "CRMCCL4"


            if (item?.column_name === "My list") {
                if (selectedIds.includes(allListId)) {
                    setSelectedIds(selectedIds.filter((id) => id !== allListId))
                }
            } else {
                console.log('MyListId............', MyListId)
                console.log('selectedids............', selectedIds)
                if (selectedIds.includes(MyListId)) {
                    setSelectedIds(selectedIds.filter((id) => id !== MyListId))
                }
            }
        }
        // const findelm=selectedIds.find(v=>v?.id)

        if (isIdAvailable(id)) {
            setSelectedIds((prevSelectedIds) => {
                if (prevSelectedIds.includes(id)) {
                    return prevSelectedIds.filter(selectedId => selectedId !== id);
                } else {
                    return [...prevSelectedIds, id];
                }
            });
        } else {
            console.log(`ID ${id} is not available in AllIds.`);
        }
    };


    console.log("V.....................", data);

    return (
        <>
            <Transition appear show={isOpenEditRoleModal} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={closeModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black/25" />
                    </Transition.Child>



                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-[750px]  transform overflow-hidden rounded-2xl bg-white  text-left align-middle shadow-xl transition-all">


                                    {/* ---------------------------------------------------------------- */}

                                    <div className="flex flex-col font-medium bg-white shadow-xl rounded">
                                        <div className="flex flex-col p-8 text-white bg-[#1b99a1] max-md:px-5 max-md:max-w-full">
                                            <div className="text-2xl max-md:max-w-full">Create new role</div>
                                            <div className="mt-1 text-xs max-md:max-w-full">Short description</div>
                                        </div>
                                        <div className="flex flex-col p-8 w-full max-md:px-5 max-md:max-w-full">
                                            <div className="text-sm leading-5 text-zinc-800 max-md:max-w-full"> Role name </div>

                                            <input value={roleName} onChange={(e) => setRoleName(e.target.value)} className="shrink-0 mt-1 outline-none p-3  h-8 rounded border border-solid border-black border-opacity-20 max-md:max-w-full mb-4" />


                                            <table class="table-auto w-full">
                                                <thead className='bg-black rounded-2xl '>
                                                    <tr >

                                                        <th className="border-b border-black/10 text-xs font-semibold py-3 text-center w-10 text-white ">
                                                            Module
                                                        </th>

                                                        <th className="border-b border-black/10 text-xs font-semibold py-3 text-center w-10 text-white ">
                                                            Full Permission
                                                        </th>

                                                        <th className="border-b border-black/10 text-xs font-semibold py-3 text-center w-10 text-white  ">
                                                            Save
                                                        </th>


                                                        <th className="border-b border-black/10 text-xs font-semibold py-3 text-center w-10 text-white  ">
                                                            Edit
                                                        </th>


                                                        <th className="border-b border-black/10 text-xs font-semibold py-3 text-center w-10 text-white  ">
                                                            AllList
                                                        </th>


                                                        <th className="border-b border-black/10 text-xs font-semibold py-3 text-center w-10 text-white ">
                                                            MyList
                                                        </th>


                                                        <th className="border-b border-black/10 text-xs font-semibold py-3 text-center w-10 text-white">
                                                            Details
                                                        </th>


                                                        <th className="border-b border-black/10 text-xs font-semibold py-3 text-center w-10 text-white  px-4">
                                                            Assign to
                                                        </th>


                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {AllIds?.map((v, i) => (
                                                        <tr
                                                            key={i}
                                                            className="group/item bg-white hover:bg-slate-100 transition-all border-b border-black/5 cursor-pointer"
                                                        >

                                                            <td className="text-sm p-4 text-center">
                                                                {v?.name}
                                                            </td>

                                                            {
                                                                v?.option?.map((item, i) => (
                                                                    <td className="text-sm p-4 text-center">
                                                                        {
                                                                            item?.type === true &&
                                                                            <input onClick={(e) => handleCheckBox(e, item, v)} type='checkbox' checked={selectedIds.includes(item.id) ? true : false} />
                                                                        }
                                                                    </td>
                                                                ))
                                                            }




                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>

                                            <div className="flex gap-2.5 justify-end mt-8 whitespace-nowrap max-md:flex-wrap max-md:pl-5">
                                                <div onClick={closeModal} className="justify-center cursor-pointer px-4 py-1.5 text-xs leading-5 rounded-md border border-solid border-black border-opacity-20 text-zinc-800">
                                                    Cancel
                                                </div>
                                                <button disabled={loading ? true : false} onClick={handleSaveRole} className="justify-center cursor-pointer px-4 py-1.5 text-sm leading-5 text-white rounded-md bg-blue-700">
                                                    Save
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>


                </Dialog>
            </Transition>
        </>
    )
}




