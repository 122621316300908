import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CiCalendar } from 'react-icons/ci'
import { IoChevronDownSharp, IoClose } from 'react-icons/io5'

function SelectPeriodModal({fromDate,setfromDate,toDate,setToDate}) {
    // const [fromDate, setfromDate] = useState("")
    // const [toDate, setToDate] = useState("")
  let [isOpen, setIsOpen] = useState(false)
  function closeModal() {
    setIsOpen(false)
  }

  function openModal() {
    setIsOpen(true)
  }

  const handleClearDate=(e)=>{
     e.stopPropagation()
     setfromDate("")
     setToDate("")
  }
  

  return (
    <>
        <button
          type="button"
          onClick={openModal}
          
        >
          <div className="flex items-center gap-1">
              <CiCalendar size={16} color='white'/>
                <div>Select period</div>
                {
                    (fromDate&&toDate)?
                    <IoClose size={16} color='white' onClick={handleClearDate}/>:
                    <IoChevronDownSharp size={16} color='white'/>
                }
              </div>
        </button>
     

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-0 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden p-4 bg-[#ffffff] rounded-md min-h-[20vh]  text-left align-middle shadow-xl transition-all">
                 <>
                  <div className='flex flex-col gap-10'>
                  <div className='w-full flex items-center gap-2'>
                    <label className='w-auto'>From Date</label>
                    <input value={fromDate} onChange={(e)=>setfromDate(e.target.value)} type='date'  className='w-[60%] px-2 py-1 border-[1px] border-[#91929f] rounded-md outline-none'/>
                  </div>
                  <div className='w-full items-center flex gap-7'>
                    <label className='w-auto'>To Date</label>
                    <input disabled={fromDate?false:true} value={toDate} onChange={(e)=>setToDate(e.target.value)} type='date' min={fromDate} className='w-[60%] px-2 py-1 border-[1px] border-[#91929f] rounded-md outline-none'/>
                  </div>
                  <div className='flex justify-center  items-center w-full'>
                  <button onClick={closeModal} className='px-4 py-1 text-white font-medium  bg-blue-700 rounded-md'>Close</button>

                  </div>
                  </div>
                 </>

                  
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

export default SelectPeriodModal;