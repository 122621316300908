import React, { useState } from 'react'
import Template from '../../../components/common/Template'
import { UserDetailsModal } from './userDetailsModal'
import { AddMoreModal } from './addModal'
import { IoSearchSharp } from 'react-icons/io5'
import SelectPeriodComponent from '../../../components/SelectPeriod'
import { GoPlus } from 'react-icons/go'
import profileImg from "../../../assets/acount_Profile.png"
const SalesAccount = () => {

    const [query, setQuery] = useState(null)
    const [createdBy, setCreatedBy] = useState(null)
    const [selectPeriod, setSelectPeriod] = useState(null)

    const assignedEmployeeList = []

    return (

        <Template>
            <div>

                <UserDetailsModal />
                <AddMoreModal />

                {/* Header  */}
                <div className="flex gap-2.5 self-stretch px-28 py-5 font-medium bg-white max-md:flex-wrap max-md:px-5">
                    <div className='flex w-full'>

                        <div className="flex flex-col flex-1 text-slate-950 max-md:max-w-full">
                            <div className="text-3xl max-md:max-w-full">Account</div>
                            <div className="flex gap-4 self-start mt-3 text-xs">

                                <select value={createdBy} onChange={(e) => setCreatedBy(e.target.value)} className=' border-none outline-none font-semibold text-lg'>
                                    <option className='  text-black  font-semibold' value={""}>{createdBy ? "Clear" : "Created by"}</option>
                                    {
                                        assignedEmployeeList?.map((val, i) =>
                                            <option key={i} className='capitalize bg-[#f9f9fa] text-black' value={val?.roleAssignId}>{val?.employeeName}</option>
                                        )

                                    }

                                </select>

                                <SelectPeriodComponent selectPeriod={selectPeriod} setSelectPeriod={setSelectPeriod} />

                            </div>
                        </div>

                        <div className="flex gap-2 justify-center my-auto text-xs">
                            {
                                true &&
                                <div className="flex gap-2 px-2.5 py-2 rounded-md shadow-lg bg-[#0000000a] items-center justify-start  text-slate-950 w-80">
                                    <IoSearchSharp size={17} color='gray' />
                                    <input
                                        value={query}
                                        onChange={(e) => setQuery(e?.target?.value)}
                                        placeholder='Search here'
                                        className='w-full p-1 outline-none bg-transparent text-sm font-semibold text-black'
                                    />
                                </div>
                            }

                            <button type="button">
                                <div className="cursor-pointer flex gap-2 px-3 py-2.5 text-sm leading-5 text-white whitespace-nowrap rounded-md bg-[#395185] items-center justify-center">
                                    <GoPlus size={15} />
                                    <div>New</div>
                                </div>
                            </button>


                        </div>
                    </div>
                </div>



                {/* Body */}

                {/* Table */}
                <div>
                    <div className='h-full w-[90%] ml-[5%] flex justify-center items-center flex-col mt-7 mb-16'>

                        <div className='w-full bg-[#222329] flex items-center py-2 px-4 rounded-t-lg'>
                            <h1 className='text-white font-semibold flex-1 ml-2'>Customer Name</h1>
                            <h1 className='text-white font-semibold flex-1'>Account Number</h1>
                            <h1 className='text-white font-semibold flex-1'>Account Type</h1>
                            <h1 className='text-white font-semibold flex-1'>Branch</h1>
                            <h1 className='text-white font-semibold flex-1'>Scheme</h1>
                            <h1 className='text-white font-semibold flex-1'>Open Date</h1>
                        </div>
                        <div className='bg-white p-1 w-full rounded-lg shadow-xl'>

                            {
                                [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((v, i, arr) => (
                                    <div className={`w-full bg-green flex justify-between items-center p-5  bg-white ${i + 1 === arr.length ? "" : "border-b"}`}>
                                        <div className='flex items-center gap-3 flex-1'>
                                            <div className='h-8 w-8 bg-black rounded-full'>
                                                <img src={profileImg} className='w-full object-cover h-full' />
                                            </div>
                                            <h1 className='text-[#154EE0] font-semibold cursor-pointer' data-hs-overlay="#hs-vertically-centered-modal_userDetails_acc">Prakash Panda</h1>
                                        </div>

                                        <h1 className='text-black font-semibold flex-1 cursor-pointer' data-hs-overlay="#hs-vertically-centered-modal_add_acc">9878675674</h1>

                                        <h1 className='text-black font-semibold flex-1'>Saving Account</h1>

                                        <div className='flex-1'>
                                            <span className='text-[#137A2D] text-center font-semibold  bg-[#E0F2DF]  py-2 rounded-full px-3'>Bhadrak</span>
                                        </div>


                                        <h1 className='text-black font-semibold flex-1'>Account Based Marketing</h1>
                                        <h1 className='text-black font-semibold flex-1'>08/12/2925</h1>
                                    </div>
                                ))
                            }
                        </div>


                    </div>

                    {/* <div className='h-full w-[90%] ml-[5%] flex justify-center items-center flex-col mt-7 mb-16 bg-blue-700'>
                        <h1>Hello</h1>
                    </div> */}

                </div>






            </div>

        </Template>
    )
}

export default SalesAccount
