import React, { useEffect } from 'react'
import Header from './Header'
import Footer from './Footer'


const Template = ({ children }) => {
  useEffect(() => {
    window.HSStaticMethods.autoInit();
  }, []);

  return (
    <div className='template-container'>
      <Header />
      <div className='content'>
        {children}
      </div>
      <Footer />
    </div>
  );
};

export default Template