import React, { useEffect, useState } from 'react'
import Template from '../../components/common/Template'
import UserList from './assignuserList'
import RolesList from './allrolesList'
import { NewRole } from './newRoleModak'
import { AssignRole } from './assignRoleModal'
import { getListRole, getRoleAssignList } from '../../apis/apiService'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'

function Settings() {

    const [tab, setTab] = useState(1)
    const dispatch = useDispatch()

    const { isAuthenticated, role, xvi_id } = useSelector((state) => state.loginSlice)

    const [roleAssignQuery, setRoleAssignQuery] = useState("")

    const handleTab2 = () => {
        setTab(2)
        setRoleAssignQuery("")
    }


    return (
        <Template>

            <div>
                {/* Header */}

                <div className='bg-blue-700 w-full px-20'>

                    <div className='flex justify-between py-4'>
                        <h1 className='text-white font-bold text-xl w-[60%]'>Settings</h1>
                        <div className=' w-[40%] flex flex-row justify-between'>

                            {
                                tab === 1 ?
                                    <div className='bg-white/20 w-[75%] flex flex-row items-center gap-3 rounded-lg pl-2'>
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.33333 12.6667C10.2789 12.6667 12.6667 10.2789 12.6667 7.33333C12.6667 4.38781 10.2789 2 7.33333 2C4.38781 2 2 4.38781 2 7.33333C2 10.2789 4.38781 12.6667 7.33333 12.6667Z" stroke="white" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M13.9996 14.0001L11.0996 11.1001" stroke="white" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                        <input value={roleAssignQuery} onChange={(e) => setRoleAssignQuery(e.target.value)} placeholder='Serch here' className='text-white font-semibold w-full p-2 outline-none bg-transparent' />
                                    </div>

                                    :
                                    <div className='bg-white/20 w-[75%] flex flex-row items-center gap-3 rounded-lg pl-2'>
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.33333 12.6667C10.2789 12.6667 12.6667 10.2789 12.6667 7.33333C12.6667 4.38781 10.2789 2 7.33333 2C4.38781 2 2 4.38781 2 7.33333C2 10.2789 4.38781 12.6667 7.33333 12.6667Z" stroke="white" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M13.9996 14.0001L11.0996 11.1001" stroke="white" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                        <input placeholder='Serch here....' className='text-white font-semibold w-full p-2 outline-none bg-transparent' />
                                    </div>


                            }
                            {
                                tab === 1 ?
                                    <AssignRole tab={tab} />
                                    :
                                    <NewRole tab={tab} />

                            }





                        </div>
                    </div>

                    <div className='flex gap-2'>
                        <div onClick={() => setTab(1)} className={tab === 1 ? 'bg-white py-2 px-4 rounded-t-xl cursor-pointer' : 'bg-black py-2 px-4 rounded-t-xl cursor-pointer'}>
                            <h1 className={tab === 1 ? 'text-black font-semibold' : 'text-white font-semibold'}>Assign Role</h1>
                        </div>

                        <div onClick={handleTab2} className={tab === 2 ? 'bg-white py-2 px-4 rounded-t-xl cursor-pointer' : 'bg-black py-2 px-4 rounded-t-xl cursor-pointer'}>
                            <h1 className={tab === 2 ? 'text-black font-semibold' : 'text-white font-semibold'}>All Roles</h1>
                        </div>
                    </div>

                </div>

                {
                    tab === 1 ?



                        <UserList roleAssignQuery={roleAssignQuery} />




                        : <RolesList />
                }
            </div>

        </Template>
    )
}

export default Settings