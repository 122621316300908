import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useState } from 'react'
import axiosConfig from '../../www/http'
import { AllIds } from '../../components/utils/assign_ids'
import { useDispatch } from 'react-redux'
import { getListRole } from '../../apis/apiService'
import { notifyErrorMessage, notifySuccessMessage } from '../../components/utils/toastMessage'
import { useSelector } from 'react-redux'
export const NewRole = ({ tab }) => {


    const { xvi_id } = useSelector((state) => state.loginSlice);
    const dispatch = useDispatch()

    let [isOpen, setIsOpen] = useState(false)
    let [loading, setLoading] = useState(false)

    const [roleName, setRoleName] = useState("")
    const [selectedIds, setSelectedIds] = useState([])
    const [selectAll, setSelectAll] = useState(false)



    function closeModal() {
        setIsOpen(false)
    }

    function openModal() {
        setSelectedIds([])
        setSelectedIds([])
        setSelectAll(false)
        setIsOpen(true)
        setRoleName("")
    }

    const isAllValidate = () => {
        console.log("Call this function", selectedIds, roleName);
        if (selectedIds?.length && roleName) {
            return true
        } else {
            if (!roleName) {
                notifyErrorMessage("Please Enter Role Name")
            }
            else if (!(selectedIds?.length)) {
                notifyErrorMessage("Please Select minimun one modules")
            }
        }
    }


    const handleSaveRole = async (id) => {



        const preparePayload = {
            modulesCodeList: selectedIds,
            roleName: roleName
        }

        const formdata = new FormData()
        formdata.append("modulesCodeList", selectedIds)
        formdata.append("roleName", roleName)

        if (isAllValidate()) {



            try {
                setLoading(true)
                const { data } = await axiosConfig.post(`/save-role`, formdata, {
                    headers: {
                        "X-V-I": xvi_id
                    }
                })

                if (data?.status === "SUCCESS") {
                    setSelectedIds([])
                    setSelectAll(false)
                    setLoading(false)
                    setRoleName("")
                    closeModal()
                    notifySuccessMessage(data?.message)
                    dispatch(getListRole(xvi_id))
                }


            } catch (error) {
                notifyErrorMessage(error?.message)
                setLoading(false)
            }
        }
    }


    const isIdAvailable = (id) => {

        console.log("Callledddddddddddddd.....................", id);

        for (const category of AllIds) {
            for (const option of category.option) {
                if (option.id === id) {
                    return true;
                }
            }
        }
        return false;
    };

    console.log('selectedIds.................', selectedIds)


    const handleCheckBox = (e, item, parentobj) => {
        // console.log('e.............', e);
        // console.log('item...................', item);
        // console.log('parentObj', parentobj);
        const id = item?.id

        console.log("parentobj...........................", parentobj);

        if ((item?.column_name === "My list" || item?.column_name === "All list") && e.target.checked) {
            const allListId = parentobj?.option.find((obj) => obj?.column_name === "All list").id;
            const MyListId = (parentobj?.option.find((obj) => obj?.column_name === "My list")?.id) || "TEMP_IDX";


            // const MyListId = "CRMCCL4"


            if (item?.column_name === "My list") {
                if (selectedIds.includes(allListId)) {
                    setSelectedIds(selectedIds.filter((id) => id !== allListId))
                }
            } else {
                console.log('MyListId............', MyListId)
                console.log('selectedids............', selectedIds)
                if (selectedIds.includes(MyListId)) {
                    setSelectedIds(selectedIds.filter((id) => id !== MyListId))
                }
            }
        }
        // const findelm=selectedIds.find(v=>v?.id)

        if (isIdAvailable(id)) {
            setSelectedIds((prevSelectedIds) => {
                if (prevSelectedIds.includes(id)) {
                    return prevSelectedIds.filter(selectedId => selectedId !== id);
                } else {
                    return [...prevSelectedIds, id];
                }
            });
        } else {
            console.log(`ID ${id} is not available in AllIds.`);
        }
    };



    const data = [
        "CRMTTL26",
        "CRMD55",
        "CRMEL33",
        "CRMMF31",
        "CRMTATT34",
        " CRMBSB10",
        "CRMCGLC50",
        "CRMCSC49",
        "CRMMGLM12",
        "CRMLAS44",
        "CRMSMF20",
        " CRMCMLC52",
        "CRMLD38",
        "CRMAMF23",
        "CRMLTGLLT7",
        "CRMTASTA29",
        "CRMALL36",
        "CRMCGDC53",
        "CRMTWTWL41",
        "CRMCGLC50",
        " CRMLTDLT9",
        "CRMTCSTC42",
        "CRMRER19",
        "CRMSLC45",
        "CRMCEC51",
        "CRMMD30",
        "CRMCDS5",
        "CRMTTD54",
        "CRMCUC2",
        "CRMRER18",
        "CRMCSTL55",
        "CRMTCTCL43",
        "CRMSL32",
        "CRMTST25",
        "CRMLTSLT6",
        "CRMMSM11",
        "CRMEMF22",
        "CRMSLW47",
        "CRMATL35",
        "CRMGLW48",
        "CRMTWSTW40",
        "CRMCCD3",
        "CRMLTELT8",
        "CRMRASRA16",
        "CRMMDM14",
        "CRMLSU39",
        "CRMMEM13",
        "CRMCCL4",
        "CRMTDT28",
        "CRMRASRA15",
        "CRMRER17",
        "CRMCSC1",
        "CRMGLCL46"
    ]

    const handleSelectAll = (type) => {
        if (type === 1) {
            setSelectAll(true)
            setSelectedIds(data)
        } else {
            setSelectAll(false)
            setSelectedIds([])
        }
    }

    return (
        <>
            <button onClick={openModal} data-hs-overlay="#hs-small-modal" className='cursor-pointer bg-[#3050A1] w-[20%] flex flex-row items-center gap-3 rounded-lg p-2'>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8 3.3335V12.6668" stroke="white" stroke-width="1.33" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M3.33398 8H12.6673" stroke="white" stroke-width="1.33" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                <p className='text-white font-semibold'>New Rule</p>
            </button>

            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={closeModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black/25" />
                    </Transition.Child>



                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-[750px]  transform overflow-hidden rounded-2xl bg-white  text-left align-middle shadow-xl transition-all">


                                    {/* ---------------------------------------------------------------- */}

                                    <div className="flex flex-col font-medium bg-white shadow-xl rounded">
                                        <div className="flex flex-col p-8 text-white bg-[#1b99a1] max-md:px-5 max-md:max-w-full">
                                            <div className="text-2xl max-md:max-w-full">Create new role</div>
                                            <div className="mt-1 text-xs max-md:max-w-full">Short description</div>
                                        </div>
                                        <div className="flex flex-col p-8 w-full max-md:px-5 max-md:max-w-full">
                                            <div className="text-sm leading-5 text-zinc-800 max-md:max-w-full"> Role name </div>

                                            <input value={roleName} onChange={(e) => setRoleName(e.target.value)} className="shrink-0 mt-1 outline-none p-3  h-8 rounded border border-solid border-black border-opacity-20 max-md:max-w-full mb-4" />


                                            <table class="table-auto w-full">
                                                <thead className='bg-black rounded-2xl '>
                                                    <tr >

                                                        <th className="border-b border-black/10 text-xs font-semibold py-3 text-center w-10 text-white ">
                                                            Module
                                                        </th>

                                                        <th className="border-b border-black/10 text-xs font-semibold py-3 text-center w-10 text-white ">
                                                            Full Permission
                                                        </th>

                                                        <th className="border-b border-black/10 text-xs font-semibold py-3 text-center w-10 text-white  ">
                                                            Save
                                                        </th>


                                                        <th className="border-b border-black/10 text-xs font-semibold py-3 text-center w-10 text-white  ">
                                                            Edit
                                                        </th>


                                                        <th className="border-b border-black/10 text-xs font-semibold py-3 text-center w-10 text-white  ">
                                                            AllList
                                                        </th>


                                                        <th className="border-b border-black/10 text-xs font-semibold py-3 text-center w-10 text-white ">
                                                            MyList
                                                        </th>


                                                        <th className="border-b border-black/10 text-xs font-semibold py-3 text-center w-10 text-white">
                                                            Details
                                                        </th>


                                                        <th className="border-b border-black/10 text-xs font-semibold py-3 text-center w-10 text-white  px-4">
                                                            Assign to
                                                        </th>


                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {AllIds?.map((v, i) => (
                                                        <tr
                                                            key={i}
                                                            className="group/item bg-white hover:bg-slate-100 transition-all border-b border-black/5 cursor-pointer"
                                                        >

                                                            <td className="text-sm p-4 text-center">
                                                                {v?.name}
                                                            </td>

                                                            {
                                                                v?.option?.map((item, i) => (
                                                                    <td className="text-sm p-4 text-center">
                                                                        {
                                                                            item?.type === true &&
                                                                            <input onClick={(e) => handleCheckBox(e, item, v)} type='checkbox' checked={selectedIds.includes(item.id) ? true : false} />
                                                                        }
                                                                    </td>
                                                                ))
                                                            }




                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>

                                            <div className="flex gap-2.5 justify-end mt-8 whitespace-nowrap max-md:flex-wrap max-md:pl-5">
                                                {
                                                    selectAll ?

                                                        <button onClick={handleSelectAll} className="justify-center cursor-pointer px-4 py-1.5 text-sm leading-5 text-white rounded-md bg-blue-700">
                                                            Unselect All
                                                        </button>
                                                        :
                                                        <button onClick={() => handleSelectAll(1)} className="justify-center cursor-pointer px-4 py-1.5 text-sm leading-5 text-white rounded-md bg-gray-600">
                                                            Select All
                                                        </button>

                                                }
                                                <div onClick={closeModal} className="justify-center cursor-pointer px-4 py-1.5 text-xs leading-5 rounded-md border border-solid border-black border-opacity-20 text-zinc-800">
                                                    Cancel
                                                </div>
                                                <button disabled={loading ? true : false} onClick={handleSaveRole} className="justify-center cursor-pointer px-4 py-1.5 text-sm leading-5 text-white rounded-md bg-blue-700">
                                                    Save
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>


                </Dialog>
            </Transition>
        </>
    )
}




