import * as Yup from 'yup';

export const SaveContactValidation = Yup.object().shape({
    type: Yup.string().required('Type is required'),
    name: Yup.string().required('Name is required'),
    email: Yup.string().email('Invalid email address').required('Email is required'),
    mobilePhone: Yup.string()
        .matches(/^[0-9]{10}$/, 'Mobile phone must be exactly 10 digits')
        .required('Mobile phone is required'),
    account: Yup.string().required('Account is required'),
    isUpload: Yup.string().required('Image required'),
    address: Yup.string().required('Address is required'),
});
