import React from 'react'

function PreviewFormModal() {
    return (
        <div id="show-Preview-Form" class="hs-overlay hidden size-full fixed top-0 start-0 z-[80] overflow-x-hidden overflow-y-auto pointer-events-none">
            <div class="hs-overlay-open:mt-7 m-auto hs-overlay-open:opacity-100 hs-overlay-open:duration-500 mt-0 opacity-0 ease-out transition-all sm:max-w-5xl sm:w-full  sm:mx-auto min-h-[calc(100%-3.5rem)] flex items-center">



                {/* ----------------------------------------------------------- */}


                <div className="flex flex-col items-center bg-white rounded-3xl shadow-2xl ">

                    <div className="justify-center items-start self-stretch p-10 w-full text-2xl font-medium bg-green-300 text-slate-950 max-md:px-5 max-md:max-w-full rounded-t-3xl">
                        Registration Form
                    </div>

                    <div className='px-10'>


                        <div className="mt-10 py-2 text-xs font-semibold leading-4 text-black max-md:max-w-full items-start">
                            Something <span className="text-orange-600">*</span>
                        </div>

                        <input className="shrink-0 max-w-full h-8 bg-white rounded border border-solid border-black border-opacity-20 w-[764px]" />

                        <div className="flex gap-5  mt-5 w-full max-w-[804px] max-md:flex-wrap max-md:max-w-full">
                            <div className="flex flex-col flex-1 justify-center">
                                <div className="text-xs font-semibold leading-4 text-black py-2">
                                    Something <span className="text-orange-600">*</span>
                                </div>
                                <div className="flex gap-2.5 justify-center px-3 py-2 text-sm font-medium whitespace-nowrap bg-white rounded border border-solid border-black border-opacity-20 text-zinc-800 text-opacity-60">
                                    <div className="flex-1">Select</div>
                                    <img
                                        loading="lazy"
                                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/47d40c5a17bcb5f7db74e3154a187d896af719166d0fcac788ca5f2fb6ccad93?"
                                        className="shrink-0 my-auto aspect-[1.41] w-[7px]"
                                    />
                                </div>
                            </div>
                            <div className="flex flex-col flex-1 justify-center">
                                <div className="text-xs font-semibold leading-4 text-black py-2">
                                    Something <span className="text-orange-600">*</span>
                                </div>
                                <div className="flex gap-2.5 justify-center px-3 py-2 text-sm font-medium whitespace-nowrap bg-white rounded border border-solid border-black border-opacity-20 text-zinc-800 text-opacity-60">
                                    <div className="flex-1">Select</div>
                                    <img
                                        loading="lazy"
                                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/c9d159ebaf09ff2e4a7a9404d47f4502f0b0ec958d7ff7d6ee4bca68aac029fc?"
                                        className="shrink-0 my-auto w-2 aspect-[1.59]"
                                    />
                                </div>
                            </div>
                            <div className="flex flex-col flex-1 justify-center">
                                <div className="text-xs font-semibold leading-4 text-black py-2">
                                    Something <span className="text-orange-600">*</span>
                                </div>
                                <div className="flex flex-col justify-center items-end px-3 py-2 bg-white rounded border border-solid border-black border-opacity-20 max-md:pl-5">
                                    <img
                                        loading="lazy"
                                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/0888f2e7053e860e5cb45448cc9a329d488977c6f5e5f4395692a39d70b12a0d?"
                                        className="w-4 aspect-square"
                                    />
                                </div>
                            </div>
                        </div>


                        <div className="mt-5 text-xs font-semibold leading-4 text-black py-2 text-opacity-60 max-md:max-w-full">
                            Description
                        </div>


                    </div>


                    <div className="shrink-0 max-w-full h-24 bg-white rounded border border-solid border-black border-opacity-20 w-[764px]" />


                    <div className="flex gap-2 justify-between self-stretch p-10 mt-10 w-full text-sm font-medium leading-5 border-t border-solid border-zinc-800 border-opacity-20 max-md:flex-wrap max-md:px-5 max-md:max-w-full">
                        <div className="justify-center px-4 py-2 rounded-md border border-solid border-black border-opacity-20 text-zinc-800">
                            Clear Form
                        </div>
                        <div className="flex gap-2 whitespace-nowrap">
                            <div className="justify-center px-4 py-2 rounded-md border border-solid border-black border-opacity-20 text-zinc-800">
                                Cancel
                            </div>
                            <div className="justify-center px-4 py-2 text-white rounded-md bg-blue-700">
                                Submit
                            </div>
                        </div>
                    </div>
                </div>

                {/* ----------------------------------------------------------- */}






            </div>
        </div>
    )
}

export default PreviewFormModal