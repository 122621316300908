import { css } from "glamor";
import { toast } from "react-toastify";

export const notifySuccessMessage = (messsage) => {
    const capitalizedMessage = messsage.charAt(0).toUpperCase() + messsage.slice(1);
    toast.success(capitalizedMessage, {
        position: "top-right",
        autoClose: 5000,
        toastId: messsage,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        className: css({
            background: "#1ab394 !important"

        })
    })
}
export const notifyErrorMessage = (messsage) => {
    toast.error(messsage, {
        position: "top-right",
        autoClose: 5000,
        toastId: messsage,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        className: css({
            background: "#ed5565 !important"
        })
    });
};
export function warningMessage(messsage) {
    toast.warn(messsage, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        className: css({
            background: "#f8ac59 !important"
        })
    });
}
export function loadingToast(messsage) {
    toast.loading(messsage, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        className: css({
            background: "#f8ac59 !important"
        })
    });
}