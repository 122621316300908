import React, { useState } from 'react'
import { ReactComponent as Logo } from "../../assets/logo.svg"
import { BiNotification } from 'react-icons/bi'
import { IoLogOutOutline, IoNotificationsCircle } from 'react-icons/io5'
import Calculator from '../calculator'
import * as routesKey from "../../constants/routes"
import { Link } from 'react-router-dom'
import Notification from './notification'
import { LogoutModal } from './logout'
const Header = () => {

  const [showModal, setShowModal] = useState(false)

  console.log("MODAL", showModal);

  return (
    <header class="flex  flex-wrap md:justify-start md:flex-nowrap z-50 w-full py-2 bg-[#25243F]">
      <nav class="relative max-w-full w-full flex flex-wrap basis-full items-center px-4  md:px-8 mx-auto" aria-label="Global">
        <div class="md:col-span-3">
          <a class="flex-none rounded-xl text-xl inline-block font-semibold focus:outline-none focus:opacity-80" aria-label="Preline">
            <Logo />
          </a>
        </div>

        <div class="flex items-center gap-x-2 ms-auto py-1 md:ps-6 md:order-3 md:col-span-3">
          <Notification />
          <LogoutModal />

          <div class="md:hidden">
            <button type="button" class="hs-collapse-toggle size-[2.375rem] flex justify-center items-center text-sm font-semibold rounded-xl border border-gray-200 text-black hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none dark:text-white dark:border-neutral-700 dark:hover:bg-neutral-700" data-hs-collapse="#navbar-collapse-with-animation" aria-controls="navbar-collapse-with-animation" aria-label="Toggle navigation">
              <svg class="hs-collapse-open:hidden flex-shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><line x1="3" x2="21" y1="6" y2="6" /><line x1="3" x2="21" y1="12" y2="12" /><line x1="3" x2="21" y1="18" y2="18" /></svg>
              <svg class="hs-collapse-open:block hidden flex-shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M18 6 6 18" /><path d="m6 6 12 12" /></svg>
            </button>
          </div>
        </div>
        <div id="navbar-collapse-with-animation" class="hs-collapse hidden overflow-hidden transition-all duration-300 basis-full grow md:block md:w-auto md:basis-auto md:order-2 md:col-span-6">
          <div class="flex flex-col gap-y-4 gap-x-0 mt-5 md:flex-row md:justify-center md:items-center md:gap-y-0 md:gap-x-7 md:mt-0">

            <a className="font-medium text-white sm:py-6 text-[.8125rem]" href="#" aria-current="page">Dashboard</a>
            <div className="hs-dropdown [--strategy:static] sm:[--strategy:fixed] [--adaptive:none] sm:[--trigger:hover] sm:py-4">
              <button type="button" className="flex items-center w-full text-white/80 hover:text-white font-medium text-[.8125rem]">
                Sales
                <svg className="flex-shrink-0 ms-2 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="m6 9 6 6 6-6" /></svg>
              </button>
              <div className="hs-dropdown-menu transition-[opacity,margin] duration-[0.1ms] sm:duration-[150ms] hs-dropdown-open:opacity-100 opacity-0 sm:w-48 hidden z-10 bg-black/80 sm:shadow-md rounded-lg p-2 dark:bg-neutral-800 sm:dark:border dark:border-neutral-700 dark:divide-neutral-700 before:absolute top-full sm:border before:-top-5 before:start-0 before:w-full before:h-5">
                <Link to={routesKey.SALESACCOUNT} className="flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-white hover:bg-black/80 focus:ring-2 focus:ring-blue-500 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 text-[.8125rem]">
                  Accounts
                </Link>
                <Link to={routesKey.SALESLEADS} className="flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-white hover:bg-black/80 focus:ring-2 focus:ring-blue-500 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 text-[.8125rem]">
                  Leads
                </Link>
                <Link className="flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-white hover:bg-black/80 focus:ring-2 focus:ring-blue-500 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 text-[.8125rem]" to={routesKey.SALESCONTACT}>
                  Contacts
                </Link>

              </div>
            </div>

            <div className="hs-dropdown [--strategy:static] sm:[--strategy:fixed] [--adaptive:none] sm:[--trigger:hover] sm:py-4">
              <button type="button" className="flex items-center w-full text-white/80 hover:text-white font-medium text-[.8125rem]">
                Marketting
                Marketting
                <svg className="flex-shrink-0 ms-2 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="m6 9 6 6 6-6" /></svg>
              </button>
              <div className="hs-dropdown-menu transition-[opacity,margin] duration-[0.1ms] sm:duration-[150ms] hs-dropdown-open:opacity-100 opacity-0 sm:w-48 hidden z-10 bg-black/80 sm:shadow-md rounded-lg p-2 dark:bg-neutral-800 sm:dark:border dark:border-neutral-700 dark:divide-neutral-700 before:absolute top-full sm:border before:-top-5 before:start-0 before:w-full before:h-5">
                <Link className="flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-white hover:bg-black/80 focus:ring-2 focus:ring-blue-500 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 text-[.8125rem]" to={routesKey.MARKETINGSUBMISSIONS}>
                  Submissions
                </Link>
                <Link to={routesKey.MARKETINGCAMPAIGNS} className="flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-white hover:bg-black/80 focus:ring-2 focus:ring-blue-500 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 text-[.8125rem]">
                  Campaigns
                </Link>
                <a className="flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-white hover:bg-black/80 focus:ring-2 focus:ring-blue-500 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 text-[.8125rem]" href="#">
                  Events
                </a>
                <Link to={routesKey.MARKETINGFORMS} className="flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-white hover:bg-black/80 focus:ring-2 focus:ring-blue-500 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 text-[.8125rem]">
                  Marketting Forms
                </Link>
              </div>
            </div>
            <div>

              <Link to={'/customer-tickets'} className="font-medium text-white sm:py-6 text-[.8125rem]" href="#" aria-current="page">Customer Service</Link>
            </div>
            <div>
              <a className="font-medium cursor-pointer text-white sm:py-6 text-[.8125rem]" aria-current="page" type="button" data-hs-overlay="#hs-large-modal">Loan Calculator</a>
            </div>
            <div>
              <Link className="font-medium text-white sm:py-6 text-[.8125rem]" to={routesKey.SETTINGS} aria-current="page" >Settings</Link>
            </div>
            <div>
            </div>
            <div>
            </div>
          </div>
        </div>
      </nav>
      <Calculator />
    </header>
  )
}

export default Header


