import React, { useState } from 'react'
import { notifyErrorMessage, notifySuccessMessage } from '../../../../components/utils/toastMessage'
import Files from 'react-files'
import { BsArrowRight } from "react-icons/bs";
import { useSelector } from 'react-redux'
import axiosConfig from '../../../../www/http'
import { useDispatch } from 'react-redux';
import { getLeadDetails } from '../../../../apis/apiService';
import { useParams } from 'react-router-dom';
import { ShowAttachmentModal } from "./attachmentDetails"

export const SalesAttachment = () => {

    const { leadDetails } = useSelector(state => state.salesSlice)
    const { xvi_id } = useSelector(state => state.loginSlice)

    const dispatch = useDispatch()
    const { lead_id } = useParams();
    const [loading, setLoading] = useState(false)
    const [showAttachment, setShowAttachment] = useState(false)
    const [updateData, setUpdateData] = useState(null)


    const handleUpload = async (files) => {

        const formdata = new FormData()
        formdata.append("attachmentFiles", files[0])
        formdata.append("leadId", leadDetails?.id)


        try {
            setLoading(true)
            const { data } = await axiosConfig.post(`/lead/save-lead-attachment`, formdata, {
                headers: {
                    "X-V-I": `${xvi_id}`
                }
            })
            if (data?.status === "SUCCESS") {
                dispatch(getLeadDetails({ xvi_id, page: 0, lead_id }))
                notifySuccessMessage(data?.message)
                setLoading(false)
            }
            console.log("Successsssssss..............", data);

        } catch (error) {
            setLoading(false)
            notifyErrorMessage(error.message)
            console.log("Faileddddddddddddddddd", error);
        }


    }

    const handleError = (error, file) => {

        console.log('error code ' + error.code + ': ' + error.message)
        notifyErrorMessage(error?.message)
    }






    return (

        <div className='bg-white min-h-[600px] mb-5'>

            {
                showAttachment &&
                <ShowAttachmentModal isOpen={showAttachment} closeModal={() => setShowAttachment(false)} data={updateData} />
            }

            <div className='w-full flex justify-end py-5'>

                <Files
                    className='files-dropzone rounded-full'
                    onChange={handleUpload}
                    onError={handleError}
                    accepts={['image/png']}
                    multiple
                    maxFileSize={2097152}
                    minFileSize={0}
                    clickable={loading ? false : true}
                >
                    <h1 className={`${loading ? "bg-gray-700" : "bg-blue-600"} cursor-pointer rounded-lg px-5 py-2 mr-6`}>Upload</h1>
                </Files>

            </div>


            <div className="flex flex-col justify-center p-6 rounded-lg border border-solid bg-white bg-opacity-80 border-black border-opacity-10 m-auto max-w-[901px] max-md:px-5">

                {
                    leadDetails?.attachments?.map((v, i) => (
                        <div>

                            <div onClick={() => { setShowAttachment(true); setUpdateData(v); }} className="cursor-pointer flex gap-5 justify-between items-center w-full max-md:flex-wrap max-md:max-w-full py-5 border-b">
                                <div className="flex gap-4">
                                    <div className="flex justify-center items-center px-3.5 w-12 h-12 bg-sky-100 rounded-[100px]">
                                        <img
                                            loading="lazy"
                                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/aecc617c335c3823b627ee06d217f2335c97a45cb4953c339ee3b41a4fb3601f?apiKey=4c514e50e07d4444bfd3ae3d48b8bd32&"
                                            className="w-5 aspect-square"
                                        />
                                    </div>


                                    <div className="flex flex-col my-auto">
                                        <div className="text-sm font-semibold text-slate-800">
                                            Document name
                                        </div>
                                        <div className="mt-1 text-xs text-zinc-800 text-opacity-80">
                                            5:12 pm • America
                                        </div>
                                    </div>

                                </div>
                                <BsArrowRight />
                            </div>
                        </div>
                    ))
                }


                {/* <div className="shrink-0 mt-6 h-px border border-solid bg-zinc-800 bg-opacity-10 border-zinc-800 border-opacity-10 max-md:max-w-full" /> */}

            </div>

        </div>
    )
}
