import React, { useState } from 'react'
import { InputRange } from './inputRange';
function Calculator() {


    const [type, setType] = useState("homeloan")

    console.log("Tab...............", type);

    return (


        <div id="hs-large-modal" class="hs-overlay hidden size-full fixed top-0 start-0 z-[80] overflow-x-hidden overflow-y-auto pointer-events-none">
            <div class="hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 mt-0 opacity-0 ease-out transition-all lg:max-w-7xl lg:w-full m-3 lg:mx-auto">

                <div class="flex flex-col fixed w-[60%] bg-[#FAFAFB] border shadow-sm rounded-xl pointer-events-auto bg-[rgba(250, 250, 251, 1);] h-[100vh]  dark:shadow-neutral-700/70 px-10">
                    <div class="flex justify-between items-center py-8">



                        <h3 class="font-bold text-[#030229] text-[24px]">
                            Loan Calculator
                        </h3>
                    </div>




                    <div className="h-[80vh] overflow-y-auto w-full bg-[#ffffff] border-2 rounded-xl hideCalculatorScrollBar">


                        {/* Header */}
                        <div className='flex flex-row  border-b'>

                            <div onClick={() => setType("homeloan")} className={`bg-gray-300-500 py-3 px-5  cursor-pointer ${type === "homeloan" ? `bg-white` : `bg-[#eeeeee] border`}`}>
                                <p className='text-[#030229] text-[14px]  font-bold'>Home Loan</p>
                            </div>
                            <div onClick={() => setType("personalloan")} className={`bg-gray-300-500 py-3 px-5 border cursor-pointer ${type === "personalloan" ? `bg-white` : `bg-[#eeeeee]`}`}>
                                <p className='text-[#030229] text-[14px]  font-bold'>Personal Loan</p>
                            </div>
                            <div onClick={() => setType("carloan")} className={`bg-gray-300-500 py-3 px-5 border cursor-pointer ${type === "carloan" ? `bg-white` : `bg-[#eeeeee]`}`}>
                                <p className='text-[#030229] text-[14px]  font-bold'>Car Loan</p>
                            </div>
                            <div onClick={() => setType("goldloan")} className={`bg-gray-300-500 py-3 px-5 border cursor-pointer ${type === "goldloan" ? `bg-white` : `bg-[#eeeeee]`}`}>
                                <p className='text-[#030229] text-[14px]  font-bold'>Gold Loan</p>
                            </div>
                        </div>

                        {/* Body */}
                        <InputRange type={type} />




                    </div>



                </div>
            </div>
        </div>



    )
}

export default Calculator