import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

export const MarketForms = ({ details }) => {
    const navigate = useNavigate();

    return (
        <div
            onClick={() => navigate(`/marketing/forms/details/${details?.id}`)}
            className="cursor-pointer flex flex-wrap gap-4 items-center py-10 px-10 bg-white rounded-md shadow-xl my-4 border"
        >


            <div className="flex flex-1 justify-between items-center flex-wrap text-zinc-800 ">

                <div className="flex flex-1 gap-4 items-center">
                    <div className="flex flex-col justify-center items-center p-3.5 bg-gray-200 shadow-2xl rounded-full">
                        <img
                            loading="lazy"
                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/c4cfc52dac7ca3ccfdd9eb80727aa935ec7367d4790644ef3e6918b91315df31?"
                            className="w-5 aspect-square"
                        />
                    </div>
                    <div className="flex flex-col justify-center my-auto text-zinc-800">
                        <div className="text-base font-semibold">{details?.formName}</div>
                        <div className="mt-1 text-xs">{details?.path?.substring(0, 45)}</div>
                    </div>
                </div>

                <div className="flex flex-col items-center text-center flex-1">
                    <div className="text-xs">View</div>
                    <div className="font-bold leading-6">15</div>
                </div>

                <div className="flex flex-col items-center text-center flex-1">
                    <div className="text-xs">Submissions</div>
                    <div className=" font-bold leading-6">{details?.submissions}</div>
                </div>

                <div className="flex flex-col items-center text-center flex-1">
                    <div className="text-xs">Created by</div>
                    <div className=" font-bold leading-6">{details?.createdBy}</div>
                </div>

                <div className="flex flex-col items-center text-center flex-1">
                    <div className="text-xs">Last modified</div>
                    <div className=" font-semibold leading-6">{details?.createdAt}</div>
                </div>

                <div className="flex items-center justify-end gap-2 py-1.5 pr-2 text-xs font-semibold   w-[10%]">
                    <div className={`flex items-center justify-center gap-2 py-1 pr-2.5 pl-2 rounded-lg border  ${details?.formStatus === "draft" ? "bg-black text-white" : "bg-slate-200 border-indigo-500 text-[#605BFF]"} w-[60%]`}>
                        <svg width="5" height="6" viewBox="0 0 5 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="2.5" cy="3" r="2.5" fill={details?.formStatus === "draft" ? "red" : `#605BFF`} />
                        </svg>
                        <span>
                            {details.formStatus.charAt(0).toUpperCase() + details.formStatus.slice(1)}
                        </span>
                    </div>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.99967 8.66683C8.36786 8.66683 8.66634 8.36835 8.66634 8.00016C8.66634 7.63197 8.36786 7.3335 7.99967 7.3335C7.63148 7.3335 7.33301 7.63197 7.33301 8.00016C7.33301 8.36835 7.63148 8.66683 7.99967 8.66683Z" stroke="#27292D" strokeWidth="1.33" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M7.99967 3.99984C8.36786 3.99984 8.66634 3.70136 8.66634 3.33317C8.66634 2.96498 8.36786 2.6665 7.99967 2.6665C7.63148 2.6665 7.33301 2.96498 7.33301 3.33317C7.33301 3.70136 7.63148 3.99984 7.99967 3.99984Z" stroke="#27292D" strokeWidth="1.33" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M7.99967 13.3333C8.36786 13.3333 8.66634 13.0349 8.66634 12.6667C8.66634 12.2985 8.36786 12 7.99967 12C7.63148 12 7.33301 12.2985 7.33301 12.6667C7.33301 13.0349 7.63148 13.3333 7.99967 13.3333Z" stroke="#27292D" strokeWidth="1.33" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>

                </div>




            </div>
        </div>
    );
};
