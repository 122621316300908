import React from 'react'
import { GoPlus } from "react-icons/go";
import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useState } from 'react'
import { useSelector } from 'react-redux'
import { notifyErrorMessage, notifySuccessMessage } from '../../components/utils/toastMessage'
import { useFormik } from 'formik'
import axiosConfig from '../../www/http';
import { GrFormUpload } from "react-icons/gr";
import Files from 'react-files'
import AccessComponent from '../../components/utils/checkAccess';
import { AccessIds, not_access_Message } from '../../components/utils/allIdList';
import { SaveTicketValidation } from '../../Validation/saveTicketSchema';
import { useDispatch } from 'react-redux';
import { getCustomerTicketListAll, getCustomerTicketListMy } from '../../apis/apiService';


const AddTicket = () => {

  const { empDetails, xvi_id, branchId } = useSelector(state => state.loginSlice)

  const [isOpen, setIsOpen] = useState(false)
  const [loading, setLoading] = useState(false)

  const dispatch = useDispatch()

  // Check Access
  const accessAddTicket = AccessComponent(AccessIds.Ticket_save)
  const accessTicketAllList = AccessComponent(AccessIds.Ticket_allList)
  const accessTicketMyList = AccessComponent(AccessIds.Ticket_myList)



  const initialValues = {
    attachmentFiles: "",
    isUpload: false,
    email: "",
    message: "",
    name: "",
    phone: "",
    subject: "",
    type: ''
  }



  const { values, handleBlur, handleChange, handleSubmit, errors, touched, setFieldValue, resetForm } = useFormik({
    initialValues: initialValues,
    validationSchema: SaveTicketValidation,

    onSubmit: async (values) => {

      console.log("errors...............", errors);


      const formdata = new FormData()
      formdata.append("attachmentFiles", values?.attachmentFiles)
      formdata.append("branchId", branchId)
      formdata.append("email", values?.email)
      formdata.append("message", values?.message)
      formdata.append("name", values?.name)
      formdata.append("phone", values?.phone)
      formdata.append("subject", values?.subject)
      formdata.append("type", values?.type)

      // CALL THE API HERE
      try {
        setLoading(true)
        const { data } = await axiosConfig.post(`/save-ticket`, formdata, {
          headers: {
            "X-V-I": `${xvi_id}`
          }
        })
        console.log("Successsssssss..............", data);

        if (data?.status === "SUCCESS") {
          notifySuccessMessage(data?.message)
          resetForm()
          setIsOpen(false)
          setLoading(false)

          // Call All List Here
          if (accessTicketAllList) {
            dispatch(getCustomerTicketListAll(xvi_id, 0))
          }

          // Call My List Here
          if (accessTicketMyList) {
            dispatch(getCustomerTicketListMy(xvi_id, 0))
          }


        }

      } catch (error) {
        setLoading(false)
        notifyErrorMessage(error.message)
        console.log("Faileddddddddddddddddd", error);
      }


    }
  })

  const handleAddTicket = () => {
    if (accessAddTicket) {
      setIsOpen(true)
    } else {
      notifyErrorMessage(not_access_Message)
    }

  }

  const handleUpload = (files) => {
    console.log(files)
    console.log("----------------URL-------------", files[0]?.preview?.url)
    setFieldValue("attachmentFiles", files[0])
    setFieldValue("isUpload", true)
  }

  return (
    <>
      <button type="button" onClick={handleAddTicket}>
        <div className="flex gap-2 px-3 py-1.5 text-sm leading-5 text-white whitespace-nowrap rounded-md bg-[#395185] items-center justify-center">
          <GoPlus size={15} />
          <div>Add</div>
        </div>
      </button>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => { }}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>



          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-[420px]  transform overflow-hidden rounded-2xl bg-white  text-left align-middle shadow-xl transition-all">


                  {/* ---------------------------------------------------------------- */}

                  <div className="flex flex-col max-w-screen-md font-medium bg-white rounded">
                    <div className="flex flex-col p-4 bg-[#1B99A1] text-white max-md:px-5 max-md:max-w-full">
                      <div className="text-2xl max-md:max-w-full">Create Ticket</div>
                      <div className="mt-1 text-xs max-md:max-w-full">
                        Brooklyn Simmons / Business name
                      </div>
                    </div>
                    <div className="flex flex-col p-8 w-full max-md:px-5 max-md:max-w-full">

                      {/* Subject */}
                      <div className="text-sm leading-5 text-black max-md:max-w-full py-1">
                        Subject <span className="text-orange-600">*</span>
                      </div>
                      <input value={values?.subject} onChange={handleChange("subject")} type='text' placeholder='Enter subject' className="shrink-0 mt-1 py-1.5 px-3 rounded border border-solid border-black border-opacity-20 max-md:max-w-full outline-none" />

                      {/* Error Text */}
                      {
                        (errors?.subject && touched.subject) &&
                        <p className='text-red-700 pt-2'>{errors?.subject}</p>
                      }
                      {/* Name */}
                      <div className="text-sm leading-5 text-black max-md:max-w-full py-1">
                        name <span className="text-orange-600">*</span>
                      </div>
                      <input value={values?.name} onChange={handleChange("name")} type='text' placeholder='Enter name' className="shrink-0 mt-1 py-1.5 px-3 rounded border border-solid border-black border-opacity-20 max-md:max-w-full outline-none" />

                      {/* Error Text */}
                      {
                        (errors?.name && touched.name) &&
                        <p className='text-red-700 pt-2'>{errors?.name}</p>
                      }
                      {/* Email */}
                      <div className="text-sm leading-5 text-black max-md:max-w-full py-1">
                        Email <span className="text-orange-600">*</span>
                      </div>
                      <input value={values?.email} onChange={handleChange("email")} type='text' placeholder='Enter email' className="shrink-0 mt-1 py-1.5 px-3 rounded border border-solid border-black border-opacity-20 max-md:max-w-full outline-none" />

                      {/* Error Text */}
                      {
                        (errors?.email && touched.email) &&
                        <p className='text-red-700 pt-2'>{errors?.email}</p>
                      }
                      {/* Phone */}
                      <div className="text-sm leading-5 text-black max-md:max-w-full py-1">
                        Phone <span className="text-orange-600">*</span>
                      </div>
                      <input value={values?.phone} onChange={handleChange("phone")} type='text' placeholder='Enter Phone' className="shrink-0 mt-1 py-1.5 px-3 rounded border border-solid border-black border-opacity-20 max-md:max-w-full outline-none" />

                      {/* Error Text */}
                      {
                        (errors?.phone && touched.phone) &&
                        <p className='text-red-700 pt-2'>{errors?.phone}</p>
                      }

                      <div className="mt-3 text-sm leading-5 text-black max-md:max-w-full py-1">
                        Creating for <span className="text-orange-600">*</span>
                      </div>
                      <input value={values?.type} onChange={handleChange("type")} type='text' placeholder='Enter Reason' className="shrink-0 mt-1 py-1.5 px-3 rounded border border-solid border-black border-opacity-20 max-md:max-w-full outline-none" />

                      {/* Error Text */}
                      {
                        (errors?.type && touched.type) &&
                        <p className='text-red-700 pt-2'>{errors?.type}</p>
                      }

                      <div className="mt-8 text-sm leading-5 text-black max-md:max-w-full py-1">
                        Message <span className="text-orange-600">*</span>
                      </div>
                      <textarea value={values?.message} onChange={handleChange("message")} className="shrink-0 mt-1 py-1.5 px-3 rounded border border-solid border-black border-opacity-20 max-md:max-w-full outline-none h-[120px]" />
                      {/* Error Text */}
                      {
                        (errors?.message && touched.message) &&
                        <p className='text-red-700 pt-2'>{errors?.message}</p>
                      }

                      <div className="flex gap-2 self-start mt-5 text-sm leading-5 whitespace-nowrap text-zinc-800">
                        <div className="my-auto">Attachment</div>


                        <Files
                          className='files-dropzone rounded-full'
                          onChange={handleUpload}
                          onError={(error) => notifyErrorMessage(error?.message)}
                          accepts={['image/png']}
                          multiple
                          maxFileSize={2097152}
                          minFileSize={0}
                          clickable>
                          {
                            values?.attachmentFiles ?
                              <p className='text-green-700'>Image is Present</p>

                              :
                              <GrFormUpload size={20} />

                          }
                        </Files>
                      </div>
                      {/* Error Text */}
                      {
                        (errors?.isUpload && touched.isUpload) &&
                        <p className='text-red-700 pt-2'>{errors?.isUpload}</p>
                      }
                      <div className="flex w-full justify-end gap-2.5 pl-20 mt-5 whitespace-nowrap max-md:flex-wrap max-md:pl-5">
                        <div onClick={() => setIsOpen(false)} className="cursor-pointer justify-center px-4 py-1.5 text-xs leading-5 rounded-md border border-solid border-black border-opacity-20 text-zinc-800">
                          Cancel
                        </div>
                        <button onClick={handleSubmit} disabled={loading ? true : false} className="cursor-pointer justify-center px-4 py-1.5 text-sm leading-5 text-white rounded-md bg-slate-600">
                          Save
                        </button>
                      </div>
                    </div>
                  </div>

                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>


        </Dialog>
      </Transition>
    </>
  )
}

export default AddTicket