import { createSlice } from "@reduxjs/toolkit";

const settingSlice = createSlice({
    name: "settings",
    initialState: {
        roleList: [],
        assignRoleListTotalElements: 0,
        assignRoleList: [],
        roleListTotalElements: 0
    },
    reducers: {
        getRoleListRequest: (state) => {
            state.isRoleListLoading = true
        },
        getRoleListSuccess: (state, { payload }) => {
            console.log("PAYLOADDDDDDDDDDDDDDDDDDDDDDD", payload);
            state.isRoleListLoading = false
            state.roleList = payload?.data
            state.roleListTotalElements = payload?.total_elements
        },
        getRoleListFail: (state) => {
            state.isRoleListLoading = false
        },

        getAssignRoleListRequest: (state) => {
            state.isAssignRoleListLoading = true
        },
        getAssignRoleListSuccess: (state, { payload }) => {
            console.log("PAYLOAD...........................getAssignRoleListSuccess", payload);
            state.isAssignRoleListLoading = false
            state.assignRoleList = payload?.data
            state.assignRoleListTotalElements = payload?.total_elements

        },
        getAssignRoleListFail: (state, { payload }) => {
            state.isAssignRoleListLoading = false
        },
    }
})

export default settingSlice.reducer;
export const { getRoleListFail, getRoleListRequest, getRoleListSuccess,
    getAssignRoleListFail, getAssignRoleListRequest, getAssignRoleListSuccess
} = settingSlice.actions;