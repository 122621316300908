import React, { useEffect, useState } from 'react'
import Template from '../../../components/common/Template'
import { MarketForms } from './form'
import { Link } from 'react-router-dom'
import * as routesKey from "../../../constants/routes"
import { useDispatch } from 'react-redux'
import { getMarkettingFormListAll, getMarkettingFormListMy } from '../../../apis/apiService'
import { useSelector } from 'react-redux'
import Loader from '../../../components/Loader/loader'
import { NoData } from '../../../components/common/noData'
import Pagination from '../../../components/common/pagination'
import AccessComponent from '../../../components/utils/checkAccess'
import { AccessIds } from '../../../components/utils/allIdList'
import { IoSearchSharp } from 'react-icons/io5'
import SelectPeriodComponent from '../../../components/SelectPeriod'
import moment from 'moment'
import { GoPlus } from 'react-icons/go'


const MarkettingForms = () => {

  const { xvi_id } = useSelector(state => state.loginSlice)
  const { markettingFormList, ismarkettingFormLoading, markettingFormListTotalElement } = useSelector(state => state.markettingSlice)
  const { assignedEmployeeList } = useSelector(state => state.campaign)

  const dispatch = useDispatch()
  const [page, setPage] = useState(0)
  const [query, setQuery] = useState(null)
  const [selectPeriod, setSelectPeriod] = useState("")
  const [createdBy, setCreatedBy] = useState(null)



  const accessAllList = AccessComponent(AccessIds.MarkettingForm_allList)
  const accessMyList = AccessComponent(AccessIds.MarkettingForm_myList)

  useEffect(() => {
    if (accessAllList) {
      dispatch(getMarkettingFormListAll({ xvi_id, page, fromPeriod: selectPeriod?.fromDate, toPeriod: selectPeriod?.toDate, createdBy: createdBy }))
    }
    if (accessMyList) {
      dispatch(getMarkettingFormListMy({ xvi_id, page }))
    }
  }, [page, query, selectPeriod, createdBy])

  console.log("markettingFormList", markettingFormList);

  return (
    <Template>

      <div>




        {/* Header */}
        <div className="flex gap-2.5 self-stretch px-28 py-5 font-medium bg-white max-md:flex-wrap max-md:px-5">
          <div className='flex w-full'>

            <div className="flex flex-col flex-1 text-slate-950 max-md:max-w-full">
              <div className="text-3xl max-md:max-w-full">Marketing Forms</div>
              <div className="flex gap-4 self-start mt-3 text-xs">

                <select value={createdBy} onChange={(e) => setCreatedBy(e.target.value)} className=' border-none outline-none font-semibold text-lg w-[30%]'>
                  <option className='  text-black  font-semibold' value={""}>{createdBy ? "Clear" : "Created by"}</option>
                  {
                    assignedEmployeeList?.map((val, i) =>
                      <option key={i} className='capitalize bg-[#f9f9fa] text-black' value={val?.roleAssignId}>{val?.employeeName}</option>
                    )

                  }

                </select>

                <SelectPeriodComponent selectPeriod={selectPeriod} setSelectPeriod={setSelectPeriod} />

              </div>
            </div>

            <div className="flex gap-2 justify-center my-auto text-xs">
              {
                true &&
                <div className="flex gap-2 px-2.5 py-2 rounded-md shadow-lg bg-[#0000000a] items-center justify-start  text-slate-950 w-80">
                  <IoSearchSharp size={17} color='gray' />
                  <input
                    value={query}
                    onChange={(e) => setQuery(e?.target?.value)}
                    placeholder='Search here'
                    className='w-full p-1 outline-none bg-transparent text-sm font-semibold text-black'
                  />
                </div>
              }

              {
                true &&
                <Link to={routesKey.MARKETINGFORMSADD} className="flex gap-2 px-3 py-1.5 text-sm leading-5 text-white whitespace-nowrap rounded-md bg-[#395185] items-center justify-center cursor-pointer" data-hs-overlay="#sales-contact-add-modal">
                  <GoPlus size={15} />
                  <div>New</div>
                </Link>
              }


            </div>
          </div>
        </div>




        {/* Body */}

        <div>
          {
            ismarkettingFormLoading ?
              <Loader />
              :
              <div>
                {
                  !(markettingFormList?.length)
                    ?
                    <NoData />
                    :


                    <div className='w-full px-28 py-5'>
                      {
                        markettingFormList?.map((v, i) => (
                          <MarketForms details={v} />
                        ))
                      }
                    </div>
                }
              </div>
          }
        </div>


        <div className='flex justify-center w-full p-4 mb-4  -bottom-3  left-14 right-0 '>

          <Pagination
            className="pagination-bar"
            currentPage={page + 1}
            totalCount={markettingFormListTotalElement}
            pageSize={10}

            onPageChange={(page) => {
              setPage(page - 1)
              var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
              if (currentScroll > 0) {
                window.scrollTo(0, currentScroll - (currentScroll / 1));
              }
            }}
          />
        </div>


      </div>

    </Template>
  )
}

export default MarkettingForms