import { setBranchId } from "../redux/slices/loginSlice";
import { getAllSubmissionListFail, getAllSubmissionListLoading, getAllSubmissionListSuccess, getMarkettingFormDetailsFail, getMarkettingFormDetailsLoading, getMarkettingFormDetailsSuccess, getMarkettingFormListFail, getMarkettingFormListLoading, getMarkettingFormListSuccess } from "../redux/slices/markettingSlice";
import { getCallLogFail, getCallLogRequest, getCallLogSuccess, getLeadDetailsFail, getLeadDetailsRequest, getLeadDetailsSuccess, getLeadsContactListSuccess, getWhatsappListFail, getWhatsappListRequest, getWhatsappListSuccess, setAllContactFail, setAllContactRequest, setAllContactSuccess, setAllLeadsType, setContactDetails, setLeadListFail, setLeadListRequest, setLeadsList, setLeadsListSuccess } from "../redux/slices/salesSlice";
import { getAssignRoleListFail, getAssignRoleListRequest, getAssignRoleListSuccess, getRoleListFail, getRoleListRequest, getRoleListSuccess, setAssignRoleList, setRoleList } from "../redux/slices/settings";
import { getTicketCallLogFail, getTicketCallLogRequest, getTicketCallLogSuccess, getTicketDetailsFail, getTicketDetailsLoading, getTicketDetailsSuccess, getTicketListFail, getTicketListLoading, getTicketListSuccess, getTicketStatusFail, getTicketStatusRequest, getTicketStatusSuccess, getTicketWhatsappListFail, getTicketWhatsappListRequest, getTicketWhatsappListSuccess } from "../redux/slices/ticketSlice";
import axiosConfig from "../www/http"

// eff26b52-6d88-48f6-b94e-45d148ea5101   Branch_id

// ------------------------------------------------------------------------------------------------------------------
//                                                 LOGIN
// ------------------------------------------------------------------------------------------------------------------

export const getBranchListId = (id) => async (dispatch) => {

    try {
        console.log("IsLoading..............!!");
        const { data } = await axiosConfig.get(`/get-list-branch?page=${0}&pageSize=${100}`, {
            headers: {
                "X-V-I": `${id}`
            }
        })
        if (data?.status === "SUCCESS") {
            console.log("data..............................1", data);
            dispatch(setBranchId(data?.data[0]?.branchId))
            localStorage.setItem("branchId", JSON.stringify(data?.data[0]?.branchId))

        }
    } catch (error) {
        console.log("Failedddddddddddddddd.............", error);
    }
}


// ------------------------------------------------------------------------------------------------------------------
//                                                 SETTINGS
// ------------------------------------------------------------------------------------------------------------------

export const getListRole = (id, page) => async (dispatch) => {

    console.log("Payloadf", id);
    console.log("Payloadf..............", page);

    try {
        dispatch(getRoleListRequest())
        const { data } = await axiosConfig.get(`/get-list-role?page=${0}&pageSize=${10}`, {
            headers: {
                "X-V-I": `${id}`
            }
        })
        if (data?.status === "SUCCESS") {
            console.log("data..............................1", data);
            dispatch(getRoleListSuccess(data))
        }
    } catch (error) {
        dispatch(getRoleListFail())
        console.log("Failedddddddddddddddd.............", error);
    }
}
export const getRoleAssignList = (id, page, search) => async (dispatch) => {
    try {
        dispatch(getAssignRoleListRequest())
        const { data } = await axiosConfig.get(`/get-list-role-assign?page=${page}&pageSize=${10}&search=${search ? search : ""}`, {
            headers: {
                "X-V-I": `${id}`
            }
        })
        if (data?.status === "SUCCESS") {
            console.log("data..............................2", data);
            dispatch(getAssignRoleListSuccess(data))
        }
    } catch (error) {
        dispatch(getAssignRoleListFail())
        console.log("Failedddddddddddddddd.............", error);
    }
}





// ------------------------------------------------------------------------------------------------------------------
//                                               Sales  Leads
// ------------------------------------------------------------------------------------------------------------------

// if my access not all then call this for get contact use in Add Contact Modal
export const getLeadsContactListMy = (xvi_id, page) => async (dispatch) => {
    try {
        console.log("Loadingggggg........................");
        const { data } = await axiosConfig.get(`/my-contacts?page=${page}&pageSize=${10000}`, {
            headers: {
                "X-V-I": `${xvi_id}`
            }
        })
        if (data?.data?.status === "SUCCESS") {
            dispatch(getLeadsContactListSuccess(data?.data))
        }
    } catch (error) {
        console.log("Error.................", error);
    }
}

// if all access then call this for get contact use in Add Contact Modal
export const getLeadsContactListAll = (xvi_id, page) => async (dispatch) => {
    try {
        console.log("Loadingggggg........................");
        const { data } = await axiosConfig.get(`/contacts?page=${page}&pageSize=${10000}`, {
            headers: {
                "X-V-I": `${xvi_id}`
            }
        })
        if (data?.data?.status === "SUCCESS") {
            dispatch(getLeadsContactListSuccess(data?.data))
        }
    } catch (error) {
        console.log("Error.................", error);
    }
}

export const getAllLeadslistType = (xvi_id, page) => async (dispatch) => {
    try {
        console.log("Loadingggggg........................");
        const { data } = await axiosConfig.get(`/get-list-lead-type?page=${page}&pageSize=${1000}`, {
            headers: {
                "X-V-I": `${xvi_id}`
            }
        })
        if (data?.status === "SUCCESS") {
            dispatch(setAllLeadsType(data))
        }
    } catch (error) {
        console.log("Error.................", error);
    }
}


export const getMyLeadsList = (xvi_id, page, query) => async (dispatch) => {
    console.log("Page................", xvi_id, page);

    try {
        dispatch(setLeadListRequest())
        const { data } = await axiosConfig.get(`/lead/get-my-leads?page=${page}&pageSize=${10}&search=${query ? query : ""}`, {
            headers: {
                "X-V-I": `${xvi_id}`
            }
        })
        console.log("SCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCC", data);
        if (data?.status === "SUCCESS") {
            dispatch(setLeadsListSuccess(data))
        }

    } catch (error) {
        console.log("ECECECECCECECCECEC....................", error);
        dispatch(setLeadListFail())
    }
}


export const getAllLeadsList = ({ xvi_id, page, query, createdBy, fromPeriod, toPeriod, status }) => async (dispatch) => {

    const queryParams = new URLSearchParams();
    if (createdBy) queryParams.append('createdBy', createdBy);
    if (fromPeriod) queryParams.append('fromPeriod', fromPeriod);
    if (toPeriod) queryParams.append('toPeriod', toPeriod);
    if (query) queryParams.append('search', query);
    if (status) queryParams.append('status', status);
    if (true) queryParams.append('page', page);
    if (true) queryParams.append('pageSize', 10);

    const url = `/lead/get-all-leads?${queryParams.toString()}`;


    try {
        dispatch(setLeadListRequest())
        const { data } = await axiosConfig.get(url, {
            headers: {
                "X-V-I": `${xvi_id}`
            }
        })
        if (data?.status === "SUCCESS") {
            dispatch(setLeadsListSuccess(data))
        }
        console.log("getAllLeadsList....................", data);
    } catch (error) {
        dispatch(setLeadListFail())
    }
}

// Details
export const getLeadDetails = ({ xvi_id, page, lead_id }) => async (dispatch) => {
    // console.log("xvi_id, page, lead_id", xvi_id, page, lead_id);
    try {
        dispatch(getLeadDetailsRequest())
        const { data } = await axiosConfig.get(`/lead/lead-details?leadId=${lead_id}&page=${page}&pageSize=${1}`, {
            headers: {
                "X-V-I": `${xvi_id}`
            }
        })
        if (data?.status === "SUCCESS") {
            dispatch(getLeadDetailsSuccess(data?.leadDetails))
        }
    } catch (error) {
        dispatch(getLeadDetailsFail())
    }
}

// leads Whatsapp

export const getLeadsWhatsappList = (xvi_id, page, leadId) => async (dispatch) => {
    try {
        dispatch(getWhatsappListRequest())
        const { data } = await axiosConfig.get(`/lead/lead-whatsapp?page=${page}&pageSize=${10}&leadId=${leadId}`, {
            headers: {
                "X-V-I": `${xvi_id}`
            }
        })
        if (data?.status === "SUCCESS") {
            dispatch(getWhatsappListSuccess(data))
            console.log("DATA+DATA", data?.data);
        }
    } catch (error) {
        dispatch(getWhatsappListFail())
        console.log("Error.................", error);
    }
}
// leads Call log

export const getLeadsCallLog = (xvi_id, page, leadId) => async (dispatch) => {
    try {
        dispatch(getCallLogRequest())
        const { data } = await axiosConfig.get(`/lead/lead-call-log?page=${page}&pageSize=${10}&leadId=${leadId}`, {
            headers: {
                "X-V-I": `${xvi_id}`
            }
        })
        if (data?.status === "SUCCESS") {
            console.log("CALL LOG SUCCESS", data);
            dispatch(getCallLogSuccess(data))
        }
    } catch (error) {
        dispatch(getCallLogFail())
        console.log("Error.................", error);
    }
}



// ------------------------------------------------------------------------------------------------------------------
//                                               Sales  Contact
// ------------------------------------------------------------------------------------------------------------------


export const getSalesAllContact = ({ xvi_id, page, query, createdBy, fromPeriod, toPeriod, status }) => async (dispatch) => {

    const queryParams = new URLSearchParams();
    if (createdBy) queryParams.append('createdBy', createdBy);
    if (fromPeriod) queryParams.append('fromPeriod', fromPeriod);
    if (toPeriod) queryParams.append('toPeriod', toPeriod);
    if (query) queryParams.append('search', query);
    if (status) queryParams.append('status', status);
    if (true) queryParams.append('page', page);
    if (true) queryParams.append('pageSize', 10);

    const url = `/contacts?${queryParams.toString()}`;

    try {
        dispatch(setAllContactRequest())
        const { data } = await axiosConfig.get(url, {
            headers: {
                "X-V-I": `${xvi_id}`
            }
        })
        if (data?.data?.status === "SUCCESS") {
            console.log("DTA-------------------", data);
            dispatch(setAllContactSuccess(data?.data))
        }
    } catch (error) {
        dispatch(setAllContactFail())
        console.log("Error.................", error);
    }
}


export const getSalesMyContact = (xvi, page, query) => async (dispatch) => {
    try {
        dispatch(setAllContactRequest())
        const { data } = await axiosConfig.get(`/my-contacts?page=${page}&pageSize=${10}&search=${query ? query : ""}`, {
            headers: {
                "X-V-I": `${xvi}`
            }
        })
        if (data?.data?.status === "SUCCESS") {
            dispatch(setAllContactSuccess(data?.data))
        }
    } catch (error) {
        dispatch(setAllContactFail())
        console.log("Error.................", error);
    }
}


export const getSalesContactDetails = (id) => async (dispatch) => {
    try {
        console.log("Loadingggggg........................");
        const { data } = await axiosConfig.get(`/contact-details?contact_id=${id}`, {
            headers: {
                "X-V-I": `${1}`
            }
        })
        if (data?.data?.status === "SUCCESS") {
            console.log("Data................", data);
            dispatch(setContactDetails(data?.data?.lists[0]))
        }
    } catch (error) {
        console.log("Error.................", error);
    }
}




// ------------------------------------------------------------------------------------------------------------------
//                                               Customer Service---Ticket
// ------------------------------------------------------------------------------------------------------------------

// if my access not all then call this for get contact use in Add Contact Modal
export const getCustomerTicketListMy = (xvi_id, page) => async (dispatch) => {
    try {
        dispatch(getTicketListLoading())
        const { data } = await axiosConfig.get(`/my-tickets?page=${page}&pageSize=${10}`, {
            headers: {
                "X-V-I": `${xvi_id}`
            }
        })
        if (data?.data?.status === "SUCCESS") {
            dispatch(getTicketListSuccess(data?.data))
        }
    } catch (error) {
        dispatch(getTicketListFail())
        console.log("Error.................", error);
    }
}


// if all access then call this for get contact use in Add Contact Modal
export const getCustomerTicketListAll = ({ xvi_id, page, query, createdBy, fromPeriod, toPeriod, status }) => async (dispatch) => {
    console.log("QUERY...................111111", xvi_id);
    console.log("QUERY...................2222", page);
    console.log("QUERY...................33333", query);

    const queryParams = new URLSearchParams();
    if (createdBy) queryParams.append('createdBy', createdBy);
    if (fromPeriod) queryParams.append('fromPeriod', fromPeriod);
    if (toPeriod) queryParams.append('toPeriod', toPeriod);
    if (query) queryParams.append('search', query);
    if (status) queryParams.append('status', status);
    if (true) queryParams.append('page', page);
    if (true) queryParams.append('pageSize', 10);

    const url = `/tickets?${queryParams.toString()}`;


    try {
        dispatch(getTicketListLoading())
        const { data } = await axiosConfig.get(url, {
            headers: {
                "X-V-I": `${xvi_id}`
            }
        })


        if (data?.data?.status === "SUCCESS") {
            dispatch(getTicketListSuccess(data?.data))
        }

    } catch (error) {
        console.log("ECECECECCECECCECEC....................", error);
        dispatch(getTicketListFail())
    }
}


// Details
export const getTicketDetails = ({ xvi_id, ticket_id }) => async (dispatch) => {
    try {
        dispatch(getTicketDetailsLoading())
        const { data } = await axiosConfig.get(`/ticket-details?ticketId=${ticket_id}`, {
            headers: {
                "X-V-I": `${xvi_id}`
            }
        })
        if (data?.data?.status === "SUCCESS") {
            console.log("SUCCESS............................", data?.data?.lists);
            dispatch(getTicketDetailsSuccess(data?.data?.lists))
        }
    } catch (error) {
        dispatch(getTicketDetailsFail())
        console.log("EROROROROROROR", error);
    }
}


// ticket Whatsapp

export const getTicketWhatsappList = (xvi_id, page, ticketId) => async (dispatch) => {
    try {
        dispatch(getTicketWhatsappListRequest())
        const { data } = await axiosConfig.get(`/ticket-whatsapp?page=${page}&pageSize=${10}&ticketId=${ticketId}`, {
            headers: {
                "X-V-I": `${xvi_id}`
            }
        })
        console.log("DATA+DATA....................", data);
        if (data?.data?.status === "SUCCESS") {
            dispatch(getTicketWhatsappListSuccess(data?.data))
            // console.log("DATA+DATA", data?.data);
        }
    } catch (error) {
        dispatch(getTicketWhatsappListFail())
        console.log("Error.................", error);
    }
}
// ticket Call log

export const getTicketCallLog = (xvi_id, page, ticketId) => async (dispatch) => {
    try {
        dispatch(getTicketCallLogRequest())
        const { data } = await axiosConfig.get(`/ticket-callLog?page=${page}&pageSize=${10}&ticketId=${ticketId}`, {
            headers: {
                "X-V-I": `${xvi_id}`
            }
        })
        if (data?.data?.status === "SUCCESS") {
            console.log("CALL LOG SUCCESS", data?.data);
            dispatch(getTicketCallLogSuccess(data?.data))
        }
    } catch (error) {
        dispatch(getTicketCallLogFail())
        console.log("Error.................", error);
    }
}


// Status Api  Admin
export const getTicketStatusAdmin = ({ createdBy, fromPeriod, toPeriod, search, xvi_id }) => async (dispatch) => {
    try {
        dispatch(getTicketStatusRequest())
        const queryParams = new URLSearchParams();
        if (createdBy) queryParams.append('createdBy', createdBy);
        if (fromPeriod) queryParams.append('fromPeriod', fromPeriod);
        if (toPeriod) queryParams.append('toPeriod', toPeriod);
        if (search) queryParams.append('search', search);

        const url = `/get-ticket-status-num?${queryParams.toString()}`;

        console.log("Urlll", url);

        const { data } = await axiosConfig.get(url, {
            headers: {
                "X-V-I": `${xvi_id}`
            }
        });

        if (data?.status === "SUCCESS") {
            dispatch(getTicketStatusSuccess(data))
            console.log("CALL LOG SUCCESS ADMIN................", data);
        }
    } catch (error) {
        dispatch(getTicketStatusFail())
        console.log("Error.................", error);
    }
};




// Status Api  Employee

export const getTicketStatusEmployee = ({ createdBy, xvi_id }) => async (dispatch) => {
    try {
        const { data } = await axiosConfig.get(`/get-ticket-status-num?createdBy=${createdBy}`, {
            headers: {
                "X-V-I": `${xvi_id}`
            }
        })
        console.log("CALL LOG SUCCESS EMPLOYEE...............", data?.data);
        if (data?.data?.status === "SUCCESS") {
        }
    } catch (error) {
        console.log("Error.................", error);
    }
}



// ------------------------------------------------------------------------------------------------------------------
//                                               Marketting Forms
// ------------------------------------------------------------------------------------------------------------------

export const getMarkettingFormListMy = ({ xvi_id, page }) => async (dispatch) => {
    try {
        dispatch(getMarkettingFormListLoading())
        const { data } = await axiosConfig.get(`/form/get-my-marketing-forms?page=${page}&pageSize=${10}`, {
            headers: {
                "X-V-I": `${xvi_id}`
            }
        })
        if (data?.status === "SUCCESS") {
            dispatch(getMarkettingFormListSuccess(data))
        }
    } catch (error) {
        dispatch(getMarkettingFormListFail())
        console.log("Error.................", error);
    }
}


export const getMarkettingFormListAll = ({ xvi_id, page, query, createdBy, fromPeriod, toPeriod }) => async (dispatch) => {


    const queryParams = new URLSearchParams();
    if (createdBy) queryParams.append('createdBy', createdBy);
    if (fromPeriod) queryParams.append('fromPeriod', fromPeriod);
    if (toPeriod) queryParams.append('toPeriod', toPeriod);
    if (query) queryParams.append('search', query);
    if (true) queryParams.append('page', page);
    if (true) queryParams.append('pageSize', 10);

    const url = `/form/get-all-marketing-forms?${queryParams.toString()}`;


    try {
        dispatch(getMarkettingFormListLoading())
        const { data } = await axiosConfig.get(url, {
            headers: {
                "X-V-I": `${xvi_id}`
            }
        })


        console.log("getMarkettingFormListAll.........................", data);

        if (data?.status === "SUCCESS") {
            dispatch(getMarkettingFormListSuccess(data))
        }

    } catch (error) {
        console.log("ECECECECCECECCECEC....................", error);
        dispatch(getMarkettingFormListFail())
    }
}



export const getMarkettingFormDetails = ({ xvi_id, formId, page }) => async (dispatch) => {
    try {
        dispatch(getMarkettingFormDetailsLoading())
        const { data } = await axiosConfig.get(`/form/marketing-forms-details?formId=${formId}&page=${page}&pageSize=${10}`, {
            headers: {
                "X-V-I": `${xvi_id}`
            }
        })
        if (data?.status === "SUCCESS") {
            dispatch(getMarkettingFormDetailsSuccess(data))
        }
    } catch (error) {
        dispatch(getMarkettingFormDetailsFail())
        console.log("Error.................", error);
    }
}

// Submission

export const getSubmissionListAll = ({ xvi_id, page, query, createdBy, fromPeriod, toPeriod, status }) => async (dispatch) => {

    const queryParams = new URLSearchParams();
    if (createdBy) queryParams.append('createdBy', createdBy);
    if (fromPeriod) queryParams.append('fromPeriod', fromPeriod);
    if (toPeriod) queryParams.append('toPeriod', toPeriod);
    if (query) queryParams.append('search', query);
    if (status) queryParams.append('status', status);
    if (true) queryParams.append('page', page);
    if (true) queryParams.append('pageSize', 10);

    const url = `/form/forms-submissions?${queryParams.toString()}`;


    try {
        dispatch(getAllSubmissionListLoading())
        const { data } = await axiosConfig.get(url, {
            headers: {
                "X-V-I": `${xvi_id}`
            }
        })

        console.log(".....getSubmissionListAll", data);

        if (data?.status === "SUCCESS") {
            dispatch(getAllSubmissionListSuccess(data))
        }

    } catch (error) {
        console.log("ECECECECCECECCECEC....................", error);
        dispatch(getAllSubmissionListFail())
    }
}