import React, { useEffect, useState } from 'react'
import axiosConfig from '../../../www/http'
import { useSelector } from 'react-redux'
import { useFormik } from 'formik'
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
import { notifySuccessMessage } from '../../../components/utils/toastMessage';


const UsersForm = () => {

  const { xvi_id, branchId } = useSelector(state => state.loginSlice)

  const { id } = useParams()

  const [formData, setFormData] = useState(null)

  // initially Get The Data
  useEffect(() => {
    const getData = async () => {

      const formdata = new FormData()
      formdata.append("formId", id)

      try {
        const { data } = await axiosConfig.get(`/form/forms-key-pair?formId=${id}`, {
          headers: {
            "formId": `${id}`
          }
        })
        if (data?.status === "SUCCESS") {
          setFormData(data?.marketingForm)
          console.log("data..............................1", data);
        }
      } catch (error) {
        console.log("Failedddddddddddddddd.............", error);
      }

    }
    getData()
  }, [])


  const ValidationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    email: Yup.string().email('Invalid email address').required('Email is required'),
    phone: Yup.string()
      .matches(/^[0-9]{10}$/, 'Mobile phone must be exactly 10 digits')
      .required('Mobile phone is required'),
    need: Yup.string().required('Need is required'),
    message: Yup.string().required('Message is required'),
    address: Yup.string().required('Address is required'),
  });


  const initialValues = {
    name: '',
    phone: "",
    email: "",
    need: '',
    message: '',
    address: ''
  }

  const { values, handleBlur, handleChange, handleSubmit, errors, touched, setFieldValue, resetForm } = useFormik({
    initialValues: initialValues,
    validationSchema: ValidationSchema,

    onSubmit: async (values) => {



      const formdata = new FormData()
      formdata.append("name", values?.name)
      formdata.append("phone", values?.phone)
      formdata.append("email", values?.email)
      formdata.append("need", values?.need)
      formdata.append("message", values?.message)
      formdata.append("address", values?.address)
      formdata.append("formId", id)


      // CALL THE API HERE
      try {
        const { data } = await axiosConfig.post(`/form/save-submissions`, formdata, {
          headers: {
            "X-V-I": `${xvi_id}`
          }
        })
        console.log("Successsssssss..............", data);
        resetForm()
        notifySuccessMessage("Successfully Submited Your Form")


      } catch (error) {
        console.log("Faileddddddddddddddddd", error);
      }


    }
  })

  return (
    <div className=''>

      <div id='market_forms_dynamic' className='w-[80%] bg-white shadow-lg mt-5 p-6 ml-[10%] rounded-md my-10'>


        <div>

          {/* Name */}
          <div style={{ marginTop: '20px', fontSize: '12px', fontWeight: '600', lineHeight: '16px', color: 'black', fontFamily: 'sans-serif', padding: '8px 0' }}>
            Name <span style={{ color: 'orange' }}>*</span>
          </div>
          <input value={values?.name} onChange={handleChange("name")} style={{ outline: "none", padding: "2px", height: '32px', backgroundColor: 'white', borderRadius: '4px', border: '1px solid rgba(0, 0, 0, 0.2)', width: '100%', borderColor: `${errors?.name ? "red" : "rgba(0,0,0,0.2)"}` }} />



          <div style={{ display: 'flex', gap: '20px', marginTop: '20px' }}>

            {/* Phone */}
            <div style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
              <div style={{ marginTop: '20px', fontSize: '12px', fontWeight: '600', lineHeight: '16px', color: 'black', fontFamily: 'sans-serif', padding: '8px 0' }}>
                Phone <span style={{ color: 'orange' }}>*</span>
              </div>
              <input value={values?.phone} onChange={handleChange("phone")} type='number' style={{ outline: "none", display: 'flex', gap: '10px', justifyContent: 'center', padding: '10px', fontSize: '14px', fontWeight: '500', whiteSpace: 'nowrap', backgroundColor: 'white', borderRadius: '4px', border: '1px solid rgba(0, 0, 0, 0.2)', color: '#525252', opacity: 0.6, borderColor: `${errors?.phone ? "red" : "rgba(0,0,0,0.2)"}` }} />
            </div>

            {/* Email */}
            <div style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
              <div style={{ marginTop: '20px', fontSize: '12px', fontWeight: '600', lineHeight: '16px', color: 'black', fontFamily: 'sans-serif', padding: '8px 0' }}>
                Email <span style={{ color: 'orange' }}>*</span>
              </div>
              <input value={values?.email} onChange={handleChange("email")} type='email' style={{ outline: "none", display: 'flex', gap: '10px', justifyContent: 'center', padding: '10px', fontSize: '14px', fontWeight: '500', whiteSpace: 'nowrap', backgroundColor: 'white', borderRadius: '4px', border: '1px solid rgba(0, 0, 0, 0.2)', color: '#525252', opacity: 0.6, borderColor: `${errors?.email ? "red" : "rgba(0,0,0,0.2)"}` }} />
            </div>

            {/* Needs */}
            <div style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
              <div style={{ marginTop: '20px', fontSize: '12px', fontWeight: '600', lineHeight: '16px', color: 'black', fontFamily: 'sans-serif', padding: '8px 0' }}>
                Needs <span style={{ color: 'orange' }}>*</span>
              </div>
              <input value={values?.need} onChange={handleChange("need")} style={{ outline: "none", display: 'flex', gap: '10px', justifyContent: 'center', padding: '10px', fontSize: '14px', fontWeight: '500', whiteSpace: 'nowrap', backgroundColor: 'white', borderRadius: '4px', border: '1px solid rgba(0, 0, 0, 0.2)', color: '#525252', opacity: 0.6, borderColor: `${errors?.need ? "red" : "rgba(0,0,0,0.2)"}` }} />
            </div>

          </div>

          <div style={{ marginTop: '20px', fontSize: '12px', fontWeight: '600', lineHeight: '16px', color: 'black', fontFamily: 'sans-serif', padding: '8px 0' }}>
            Description
          </div>
          <textarea value={values?.message} onChange={handleChange("message")} style={{ height: '96px', padding: "4px", backgroundColor: 'white', borderRadius: '4px', border: '1px solid rgba(0, 0, 0, 0.2)', width: '100%', borderColor: `${errors?.message ? "red" : "rgba(0,0,0,0.2)"}` }} />


          <div style={{ marginTop: '20px', fontSize: '12px', fontWeight: '600', lineHeight: '16px', color: 'black', fontFamily: 'sans-serif', padding: '8px 0' }}>
            Address
          </div>
          <textarea value={values?.address} onChange={handleChange("address")} style={{ height: '96px', padding: "4px", backgroundColor: 'white', borderRadius: '4px', border: '1px solid rgba(0, 0, 0, 0.2)', width: '100%', borderColor: `${errors?.address ? "red" : "rgba(0,0,0,0.2)"}` }} />


        </div>

        <div className='w-full'>
          <div className='flex flex-wrap'>
            {
              formData?.keyPair.map((v, i) => (
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '30%', marginRight: '3%', marginTop: "20px" }}>
                  <div style={{ marginTop: '20px', fontSize: '12px', fontWeight: '600', lineHeight: '16px', color: 'black', fontFamily: 'sans-serif', padding: '8px 0' }}>
                    {v?.pair} <span style={{ color: 'orange' }}>*</span>
                  </div>
                  <input type={v?.key} style={{ outline: "none", width: "100%", display: 'flex', justifyContent: 'center', padding: '10px', fontSize: '14px', fontWeight: '500', whiteSpace: 'nowrap', borderRadius: '4px', border: '1px solid rgba(0, 0, 0, 0.2)', color: '#525252', opacity: 0.6 }} />
                </div>
              ))
            }




          </div>
        </div>

        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: "10px" }}>
          <h1 onClick={handleSubmit} style={{
            backgroundColor: '#2563EB',
            cursor: 'pointer',
            padding: '0.5rem 1rem',
            borderRadius: '0.375rem',
            color: '#ffffff',
            fontWeight: '600'
          }}>
            Submit
          </h1>
        </div>



      </div>

    </div>
  )
}

export default UsersForm