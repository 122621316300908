import * as Yup from 'yup';

export const leadWhatsappValidation = Yup.object().shape({
    buttonText: Yup.string().required('Button Text is required'),
    buttonUrl: Yup.string().required('Button Url is required'),
    description: Yup.string().required('Message is required'),
    image: Yup.mixed().required('Image is required'),
    receiver: Yup.string().required('Recipients name  is required'),
    subject: Yup.string().required('Subject is required'),
    isupload: Yup.string().required('Image is required'),

});
