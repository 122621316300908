import { createSlice } from "@reduxjs/toolkit";

const salesSlice = createSlice({
    name: "sales",
    initialState: {

        // Contact
        allContact: [],
        total_elements_Contact: 0,
        contactDetails: {},

        // Leads
        leadsAllContactList: [],
        listLeadType: [],
        leadsList: [],
        leadsList_total_elements: 0,
        // LeadDetails
        leadDetails: [],
        leadCallLogStatus: {},
        leadCallLogStatusLoading:false,

        // whatsappList
        whatsappList: [],
        whatsapp_total_elements: 0,

        // CallLog List
        callLogList: [],
        callLog_total_elements: 0

    },
    reducers: {
        // Contact


        // Contact List
        setAllContactRequest: (state, { payload }) => {
            state.isContactListLoading = true
        },

        setAllContactSuccess: (state, { payload }) => {

            console.log("PAYLOAD..........................setAllContactSuccess", payload);

            state.isContactListLoading = false
            state.allContact = payload?.lists
            state.total_elements_Contact = payload?.totalElements
        },

        setAllContactFail: (state, { payload }) => {
            state.isContactListLoading = false
        },



        setContactDetails: (state, { payload }) => {
            state.contactDetails = payload
        },


        // Leads
        setAllLeadsType: (state, { payload }) => {
            state.listLeadType = payload?.data
        },

        setLeadListRequest: (state) => {
            state.leadListLoading = true
        },
        setLeadsListSuccess: (state, { payload }) => {
            state.leadListLoading = false
            state.leadsList = payload?.data    //Page-payload?.total_pages
            // state.leadsList_total_elements=payload?.total_elements
            state.leadsList_total_elements = payload?.total_elements
        },
        setLeadListFail: (state) => {
            state.leadListLoading = false
        },

        getLeadsCallLogStatusRequest: (state) => {
            state.leadCallLogStatusLoading = true;
        },
        getLeadsCallLogStatusSuccess: (state, { payload }) => {
            state.leadCallLogStatusLoading = false;
            state.leadCallLogStatus = payload;
        },
        getLeadsCallLogStatusFail: (state) => {
            state.leadCallLogStatusLoading = false;
        },

        getLeadsContactListSuccess: (state, { payload }) => {
            state.leadsAllContactList = payload?.lists
            // payload.totalElements
        },


        // Lead Details
        getLeadDetailsRequest: (state) => {
            state.isLeadDetailsLoading = true
        },
        getLeadDetailsSuccess: (state, { payload }) => {
            state.isLeadDetailsLoading = false
            state.leadDetails = payload
        },
        getLeadDetailsFail: (state) => {
            state.isLeadDetailsLoading = false
        },

        // Leads whatsappList
        getWhatsappListRequest: (state) => {
            state.isWhatsappLoading = true
        },
        getWhatsappListSuccess: (state, { payload }) => {
            console.log("PAYLOAD......................", payload);
            state.isWhatsappLoading = false
            state.whatsappList = payload?.data
            state.whatsapp_total_elements = payload?.total_elements

        },
        getWhatsappListFail: (state) => {
            state.isWhatsappLoading = false
        },
        // Leads Call Log List
        getCallLogRequest: (state) => {
            state.isCallLogLoading = true
        },
        getCallLogSuccess: (state, { payload }) => {
            console.log("PAYLOAD......................CALL LOG", payload);
            state.isCallLogLoading = false
            state.callLogList = payload?.data
            state.callLog_total_elements = payload?.total_elements

        },
        getCallLogFail: (state) => {
            state.isCallLogLoading = false
        },

    }
})

export default salesSlice.reducer;
export const {
    // contact List
    setAllContactSuccess, setAllContactFail, setAllContactRequest,
    setContactDetails, setAllLeadsType,
    // Lead List
    setLeadsListSuccess, setLeadListFail, setLeadListRequest,
    getLeadsContactListSuccess,
    // Lead Details
    getLeadDetailsFail, getLeadDetailsRequest, getLeadDetailsSuccess,

    //Whatsapp
    getWhatsappListFail, getWhatsappListRequest, getWhatsappListSuccess,
    // callLogList
    getCallLogFail, getCallLogRequest, getCallLogSuccess,
    //lead call log status
    getLeadsCallLogStatusFail,getLeadsCallLogStatusRequest,getLeadsCallLogStatusSuccess
} = salesSlice.actions;