import React, { useEffect, useState } from 'react'
import ComposeMessage from './composeMessage'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { getTicketWhatsappList } from '../../../apis/apiService'
import { useParams } from 'react-router-dom'
import Loader from '../../../components/Loader/loader'
import Pagination from '../../../components/common/pagination'

const TicketWhatsapp = () => {

    const dispatch = useDispatch()
    const { xvi_id } = useSelector(state => state.loginSlice)
    const { isTicketWhatsappLoading, ticketWhatsappList, ticketWhatsapp_total_elements } = useSelector(state => state.ticketSlice)
    const { ticket_id } = useParams();


    const [page, setPage] = useState(0)

    const [showWhatsappDetails, setShowWhatsappDetails] = useState(null)


    useEffect(() => {
        dispatch(getTicketWhatsappList(xvi_id, page, ticket_id))
    }, [page])

    console.log("ticketWhatsappList", ticketWhatsappList);

    useEffect(() => {
        if (ticketWhatsappList && ticketWhatsappList.length) {
            ticketWhatsappList.find((v, i) => {
                i === 0 &&
                    setShowWhatsappDetails(v)
            });
        }
    }, [ticketWhatsappList]);
    return (

        <div>

            {
                isTicketWhatsappLoading ?

                    <Loader />
                    :

                    <div className="flex gap-5 justify-between items-start px-8 pt-8 pb-2 w-full  max-md:flex-wrap max-md:px-5 max-md:max-w-full">
                        <div className="flex flex-col gap-2 w-[35%]">
                            <div className="flex flex-col justify-center p-5 text-sm font-medium leading-5 text-white  bg-white border-b border-solid border-black border-opacity-10">
                                <ComposeMessage />
                            </div>
                            <div className='flex flex-col gap-3'>
                                {ticketWhatsappList.map((v, i) => (
                                    <div onClick={() => setShowWhatsappDetails(v)} className="cursor-pointer flex flex-col py-4 mx-6 border-b border-solid border-black border-opacity-10 max-md:mx-2.5">
                                        <div className="flex gap-5">
                                            <div className="flex flex-1 gap-2.5">
                                                <img
                                                    src={v?.image}
                                                    loading="lazy"
                                                    className="shrink-0 w-10 rounded-full aspect-square"
                                                />
                                                <div className="flex flex-col flex-1 self-start">
                                                    <div className="text-xs text-zinc-800 text-opacity-60">
                                                        {v?.receiver}
                                                    </div>
                                                    <div className="text-sm font-medium leading-5 text-zinc-800">
                                                        {v?.subject}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="self-start text-xs font-medium text-right text-zinc-800 text-opacity-40">
                                                12m ago
                                            </div>
                                        </div>
                                        <div className="mt-1.5 text-xs  text-ellipsis text-zinc-800 text-opacity-60 line-clamp-3">
                                            {v?.description}
                                        </div>
                                    </div>

                                ))}
                            </div>
                        </div>
                        {/* 2nd Part */}

                        {
                            showWhatsappDetails &&


                            <div className="flex flex-col max-md:max-w-full w-[65%]  ">
                                <div className="flex gap-5 bg-white justify-between px-2.5 py-2 w-full shadow-sm max-md:flex-wrap max-md:max-w-full">
                                    <div onClick={() => setShowWhatsappDetails(null)} className='cursor-pointer'>
                                        <img
                                            loading="lazy"
                                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/e599e64930aa6fab8e61d550c7df56db9fb5099c4ee7605d7f2a692a1ecf8f21?apiKey=4c514e50e07d4444bfd3ae3d48b8bd32&"
                                            className="shrink-0 my-auto aspect-square w-[22px]"
                                        />
                                    </div>
                                    <div className="flex gap-4 justify-between py-1.5 pl-2">
                                        <div className="my-auto text-sm text-black text-opacity-50">
                                            1–50 of 2,619
                                        </div>
                                        <div className="flex gap-4">
                                            <img
                                                loading="lazy"
                                                src="https://cdn.builder.io/api/v1/image/assets/TEMP/3281d9f95f10d3347ceedb924d1767fbdc4b71545312a9f3b79f811f6e256f04?apiKey=4c514e50e07d4444bfd3ae3d48b8bd32&"
                                                className="shrink-0 aspect-square w-[22px]"
                                            />
                                            <img
                                                loading="lazy"
                                                src="https://cdn.builder.io/api/v1/image/assets/TEMP/595c16cce86e1fda1a0d8f49bce4b83ae691e3d724df0c4813a763ce5341ab82?apiKey=4c514e50e07d4444bfd3ae3d48b8bd32&"
                                                className="shrink-0 aspect-square w-[22px]"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="flex gap-5 justify-between py-5 pr-4 pl-16 w-full bg-white max-md:flex-wrap max-md:pl-5 max-md:max-w-full">
                                    <div className="text-2xl font-medium leading-7 text-neutral-800">
                                        {showWhatsappDetails?.subject}
                                    </div>
                                    <div className="flex gap-4 self-start">
                                        <img
                                            loading="lazy"
                                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/b912de3a497693f3ff39e4d55467e2c893409ad40931b7e9025c487609ca14e8?apiKey=4c514e50e07d4444bfd3ae3d48b8bd32&"
                                            className="shrink-0 w-5 aspect-square"
                                        />
                                        <img
                                            loading="lazy"
                                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/f63b36d8188667d8a02a3544100db1e66e63e3adb388dcde57a8b68d9c0a5cff?apiKey=4c514e50e07d4444bfd3ae3d48b8bd32&"
                                            className="shrink-0 w-5 aspect-square"
                                        />
                                    </div>
                                </div>
                                <div className="flex gap-5 justify-between px-4 py-1.5 w-full bg-white max-md:flex-wrap max-md:max-w-full">
                                    <div className="flex gap-3">
                                        <img
                                            loading="lazy"
                                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/b291f5886003d8cf60c5dfffcdc3a63e3c573e618ad4316aec95bb51efc79f2a?apiKey=4c514e50e07d4444bfd3ae3d48b8bd32&"
                                            className="shrink-0 w-10 aspect-square fill-black fill-opacity-10"
                                        />
                                        <div className="flex flex-col my-auto">
                                            <div className="flex gap-1">
                                                <div className="text-sm font-bold text-neutral-800">
                                                    {showWhatsappDetails?.receiver}
                                                </div>
                                                <div className="flex gap-0 self-start text-xs font-medium tracking-normal leading-4 text-black text-opacity-50">
                                                    <div>&lt;</div>
                                                    <div>(405) 555-0128</div>
                                                    <div>&gt;</div>
                                                </div>
                                            </div>
                                            <div className="flex gap-1 mt-1 text-xs font-medium tracking-normal leading-4 text-black text-opacity-50">
                                                <div className="justify-center self-start py-1 ">
                                                    to
                                                </div>
                                                <div className="flex gap-0 pr-2">
                                                    <div>me, Sidney, Sharon</div>
                                                    <img
                                                        loading="lazy"
                                                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/58762ad91fd30713aa41c55314d775a386c18e2600685048afc86c2e767a75af?apiKey=4c514e50e07d4444bfd3ae3d48b8bd32&"
                                                        className="shrink-0 w-4 aspect-square"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex gap-1 self-start text-xs font-medium tracking-normal leading-4 text-black text-opacity-60">
                                        <div>9:14 AM</div>
                                        <div>(8 hours ago)</div>
                                    </div>
                                </div>
                                <div className="flex flex-col items-start py-3.5 pr-5 pl-16 bg-white max-md:pl-5 max-md:max-w-full">

                                    <div className="shrink-0 max-w-full bg-zinc-300 h-[188px] w-[400px]">
                                        <img src={showWhatsappDetails?.image} alt="Image" class="h-full w-full object-contain" />
                                    </div>

                                    <div className="mt-6 text-xs tracking-normal leading-4 text-black">
                                        {
                                            showWhatsappDetails?.description
                                        }
                                    </div>
                                    <div className="justify-center px-4 py-2 mt-6 text-sm font-medium tracking-normal leading-4 text-blue-600 rounded-lg border border-solid border-black border-opacity-20">
                                        Action Button
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
            }



            <div className='flex justify-center w-full p-4 mb-4  -bottom-3  left-14 right-0 '>

                <Pagination
                    className="pagination-bar"
                    currentPage={page + 1}
                    totalCount={ticketWhatsapp_total_elements}
                    pageSize={10}

                    onPageChange={(page) => {
                        setPage(page - 1)
                        var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
                        if (currentScroll > 0) {
                            window.scrollTo(0, currentScroll - (currentScroll / 1));
                        }
                    }}
                />
            </div>

        </div>

    )
}

export default TicketWhatsapp