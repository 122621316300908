import React, { useEffect, useState } from 'react'
import Template from '../../../components/common/Template'
import { ReactComponent as SquareIcon } from "../../../assets/squareicon.svg"
import { useNavigate } from 'react-router-dom'
import AddNewLeads from './AddNewLeads'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { getAllLeadsList, getMyLeadsList } from '../../../apis/apiService'
import AccessComponent from '../../../components/utils/checkAccess'
import { AccessIds, not_access_Message } from '../../../components/utils/allIdList'
import { NoData } from '../../../components/common/noData'
import { RiArrowDropDownLine } from "react-icons/ri";
import { MdOutlineDateRange } from "react-icons/md";
import Pagination from '../../../components/common/pagination'
import { IoSearchSharp } from 'react-icons/io5'
import { GoPlus } from 'react-icons/go'
import { notifyErrorMessage } from '../../../components/utils/toastMessage'
import Loader from '../../../components/Loader/loader'
import SelectPeriodComponent from '../../../components/SelectPeriod'
import { getAssignedEmployeeList } from '../../../apis/apis'


const SalesLeads = () => {
  const navigate = useNavigate();

  const { xvi_id, modules } = useSelector(state => state.loginSlice)
  const { leadsList, leadsList_total_elements, leadListLoading } = useSelector(state => state.salesSlice)
  const { assignedEmployeeList } = useSelector(state => state.campaign)

  const [page, setpage] = useState(0)
  const [query, setQuery] = useState("")
  const [selectPeriod, setSelectPeriod] = useState("")
  const [createdBy, setCreatedBy] = useState(null)




  const dispatch = useDispatch()

  const accessAllList = AccessComponent(AccessIds.leads_allList)
  const accessMyList = AccessComponent(AccessIds.leads_myList)

  // its only for dummy
  useEffect(() => {
    dispatch(getAssignedEmployeeList(xvi_id))
  }, [])

  useEffect(() => {
    if (accessAllList) {
      dispatch(getAllLeadsList({ page: page, xvi_id: xvi_id, query: query, fromPeriod: selectPeriod?.fromDate, toPeriod: selectPeriod?.toDate, createdBy: createdBy }))
    }
    if (accessMyList) {
      dispatch(getMyLeadsList(xvi_id, page, query))
    }
  }, [page, query, selectPeriod, createdBy])

  // Use Custom  Access Component 
  // if (!AccessComponent("CRMMLL37")) {
  //   return <div></div>
  // }

  const handleCreated = () => {
    if (accessAllList) {
    } else {
      notifyErrorMessage(not_access_Message)
    }
  }

  const handlePeriod = () => {
    if (accessAllList) {
    } else {
      notifyErrorMessage(not_access_Message)
    }
  }

  const handleSearch = (value) => {
    setQuery(value)
  }

  console.log("leadsList.......................", leadsList);

  return (
    <Template>


      {/* Header  */}
      <div className="flex gap-2.5 self-stretch px-28 py-5 font-medium bg-white max-md:flex-wrap max-md:px-5">
        <div className='flex w-full'>

          <div className="flex flex-col flex-1 text-slate-950 max-md:max-w-full">
            <div className="text-3xl max-md:max-w-full">Leads</div>
            <div className="flex gap-4 self-start mt-3 text-xs">

              <select value={createdBy} onChange={(e) => setCreatedBy(e.target.value)} className=' border-none outline-none  font-semibold text-lg'>
                <option className='  text-black  font-semibold' value={""}>{createdBy ? "Clear" : "Created by"}</option>
                {
                  assignedEmployeeList?.map((val, i) =>
                    <option key={i} className='capitalize bg-[#f9f9fa] text-black' value={val?.roleAssignId}>{val?.employeeName}</option>
                  )

                }

              </select>

              <SelectPeriodComponent selectPeriod={selectPeriod} setSelectPeriod={setSelectPeriod} />

            </div>
          </div>

          <div className="flex gap-2 justify-center my-auto text-xs">
            {
              accessAllList &&
              <div className="flex gap-2 px-2.5 py-2 rounded-md shadow-lg bg-[#0000000a] items-center justify-start  text-slate-950 w-80">
                <IoSearchSharp size={17} color='gray' />
                <input
                  value={query}
                  onChange={(e) => handleSearch(e?.target?.value)}
                  placeholder='Search here'
                  className='w-full p-1 outline-none bg-transparent text-sm font-semibold text-black'
                />
              </div>
            }

            <AddNewLeads />


          </div>
        </div>
      </div>


      {
        leadListLoading ?
          <div className='w-[90%] ml-[5%] flex justify-center items-center flex-col mt-7 mb-16'>
            <Loader />
          </div>
          :
          <div>
            {
              !(leadsList?.length) ? <NoData />
                :


                <div>

                  <div className='h-full w-[90%] ml-[5%] flex justify-center items-center flex-col mt-7 mb-16'>

                    <div className='w-full bg-[#222329] flex items-center  rounded-t-lg'>
                      <h1 className='text-white font-semibold flex-1 border-r py-2 px-5 mr-4 border-gray-50'>Needs / Comments</h1>
                      <h1 className='text-white font-semibold flex-1'>Status</h1>
                      <h1 className='text-white font-semibold flex-1'>Lead Type</h1>
                      <h1 className='text-white font-semibold flex-1'>Contact</h1>
                      <h1 className='text-white font-semibold flex-1'>Account</h1>
                      <h1 className='text-white font-semibold flex-1'>Created on</h1>
                    </div>
                    <div className='bg-white p-1 w-full rounded-b-xl shadow-xl'>

                      {
                        leadsList.map((v, i, arr) => (
                          <div className={`w-full bg-green flex justify-between items-center   bg-white ${i + 1 === arr.length ? "" : "border-b"}`} onClick={() => navigate(`/leads-details/${v?.id}`)}>
                            <div className='flex items-center  flex-1 mr-4 border-r py-3 px-5'>
                              <div className=''>
                                <h1 className='text-[#154EE0] font-semibold cursor-pointer'>{v?.needs}</h1>
                                <h1 className='text-[#27292D] font-semibold cursor-pointer'>{v?.comments}</h1>
                              </div>
                            </div>

                            <div className='flex-1'>
                              <span className='text-[#137A2D] text-center font-semibold  bg-[#E0F2DF]  py-2 rounded-full px-3'>{v?.leadStatus}</span>
                            </div>

                            <h1 className='text-black font-semibold flex-1 cursor-pointer'>{v?.contact?.type}</h1>

                            <h1 className='text-[#154EE0] font-semibold flex-1'>{v?.contact?.name}</h1>



                            <h1 className='text-[#154EE0] font-semibold flex-1'>{v?.account ? v?.account : "N/A"}</h1>
                            <h1 className='text-black font-semibold flex-1'>{v?.createdAt}</h1>
                          </div>
                        ))
                      }
                    </div>


                  </div>


                  <div className='flex justify-center w-full p-4 mb-4  -bottom-3  left-14 right-0 '>

                    <Pagination
                      className="pagination-bar"
                      currentPage={page + 1}
                      totalCount={leadsList_total_elements}
                      pageSize={10}

                      onPageChange={(page) => {
                        setpage(page - 1)
                        var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
                        if (currentScroll > 0) {
                          window.scrollTo(0, currentScroll - (currentScroll / 1));
                        }
                      }}
                    />
                  </div>

                </div>

            }
          </div>
      }
    </Template>
  )
}

export default SalesLeads