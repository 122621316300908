import { createSlice } from "@reduxjs/toolkit";

const markettingSlice = createSlice({
    name: "marketting",
    initialState: {
        markettingFormList: {},
        submissionList: [],
        markettingFormListTotalElement: 0,

        // Details
        markettingFormDetails: [],
        markettingFormDetailsTotalElement: 0,

        // Submission
        allSubmissionList: [],
        allSubmissionListTotalPages: 0,

    },
    reducers: {
        getMarkettingFormListLoading: (state) => {
            state.ismarkettingFormLoading = true
        },
        getMarkettingFormListSuccess: (state, { payload }) => {
            state.ismarkettingFormLoading = false
            state.markettingFormList = payload?.data
            state.markettingFormListTotalElement = payload?.total_elements
        },
        getMarkettingFormListFail: (state) => {
            state.ismarkettingFormLoading = false
        },

        // Details
        getMarkettingFormDetailsLoading: (state) => {
            state.ismarkettingFormDetailsLoading = true
        },
        getMarkettingFormDetailsSuccess: (state, { payload }) => {
            state.ismarkettingFormDetailsLoading = false
            state.markettingFormDetails = payload?.marketingForm
            state.markettingFormDetailsTotalElement = payload?.total_elements
            state.submissionList = payload?.submission_data
        },
        getMarkettingFormDetailsFail: (state) => {
            state.ismarkettingFormDetailsLoading = false
        },

        // Submission List
        getAllSubmissionListLoading: (state) => {
            state.isAllSubmissionListLoading = true
        },
        getAllSubmissionListSuccess: (state, { payload }) => {

            console.log("getAllSubmissionListSuccess..............", payload);

            state.isAllSubmissionListLoading = false
            state.allSubmissionList = payload?.submission_data
            state.allSubmissionListTotalPages = payload?.total_elements
        },
        getAllSubmissionListFail: (state) => {
            state.isAllSubmissionListLoading = false
        },

    }
})

export default markettingSlice.reducer;
export const {
    getMarkettingFormListFail, getMarkettingFormListLoading, getMarkettingFormListSuccess,
    // Details
    getMarkettingFormDetailsFail, getMarkettingFormDetailsLoading, getMarkettingFormDetailsSuccess,
    // All Submission List
    getAllSubmissionListFail, getAllSubmissionListLoading, getAllSubmissionListSuccess,

} = markettingSlice.actions;