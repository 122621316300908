import React, { useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { MdOutlineEdit } from "react-icons/md";
import SubmissionToLead from './submissionToLead';


export const UserFormDetails = ({ isOpen, closeModal, data }) => {


    console.log("Data.............................", data);



    return (
        <>
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={() => { }}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black/25" />
                    </Transition.Child>



                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-[420px]  transform overflow-hidden rounded-2xl bg-white  text-left align-middle shadow-xl transition-all">


                                    {/* ---------------------------------------------------------------- */}

                                    <div class="w-full flex flex-col  bg-white shadow-sm rounded-xl pointer-events-auto">
                                        <div className="flex flex-col self-stretch max-w-[438px]">
                                            <div className="flex justify-center items-center px-16 py-8 w-full text-white bg-[#9050D0]" style={{ background: "#9050D0", borderTopRightRadius: "8px", borderTopLeftRadius: "8px" }}>



                                                <SubmissionToLead submissionId={data?.id} />

                                                <div className="flex flex-col items-center max-w-full w-[150px]">
                                                    <h1>{data?.name}</h1>
                                                </div>
                                            </div>
                                            <div className="flex flex-col px-10 py-8 w-full">
                                                <div onClick={closeModal} className="cursor-pointer justify-center self-end px-4 py-1.5 mt-8 text-xs font-medium leading-5 whitespace-nowrap rounded-md border border-solid border-black border-opacity-20 text-zinc-800">
                                                    Close
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>


                </Dialog>
            </Transition>
        </>
    )
}
