import React, { useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useState } from 'react'
import axiosConfig from '../../../www/http';
import { setContactDetails } from '../../../redux/slices/salesSlice';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { MdOutlineEdit } from "react-icons/md";
import { ContactEditModal } from './editModal';
import { getSalesContactDetails } from '../../../apis/apiService';


export const UserDetails = ({ isOpen, closeModal, id }) => {

    const dispatch = useDispatch()
    const { contactDetails } = useSelector(state => state.salesSlice)

    const [isShowEditModal, setIsShowModal] = useState(false)

    useEffect(() => {
        dispatch(getSalesContactDetails(id))
    }, [])

    console.log("contactDetails?.profileImage", contactDetails?.profileImage);

    return (
        <>
            {
                isShowEditModal &&
                <ContactEditModal isOpen={isShowEditModal} closeModal={() => setIsShowModal(false)} data={contactDetails} />
            }
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={() => { }}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black/25" />
                    </Transition.Child>



                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-[420px]  transform overflow-hidden rounded-2xl bg-white  text-left align-middle shadow-xl transition-all">


                                    {/* ---------------------------------------------------------------- */}

                                    <div class="w-full flex flex-col  bg-white shadow-sm rounded-xl pointer-events-auto">
                                        <div className="flex flex-col self-stretch max-w-[438px]">
                                            <div className="flex justify-center items-center px-16 py-8 w-full text-white bg-[#9050D0]" style={{ background: "#9050D0", borderTopRightRadius: "8px", borderTopLeftRadius: "8px" }}>

                                                <div onClick={() => setIsShowModal(true)} className='cursor-pointer  absolute right-0 flex flex-row-reverse p-2 top-10 '>
                                                    <MdOutlineEdit size={18} />
                                                </div>

                                                <div className="flex flex-col items-center max-w-full w-[150px]">
                                                    <img
                                                        loading="lazy"
                                                        src={contactDetails?.profileImage}
                                                        className="rounded-full aspect-square w-[70px]"
                                                    />
                                                    <div className="self-stretch mt-4 text-2xl font-semibold justify-center items-center flex    ">
                                                        {contactDetails?.name}
                                                    </div>
                                                    <div className="mt-2 text-sm">A/C {contactDetails?.account}</div>
                                                </div>
                                            </div>
                                            <div className="flex flex-col px-10 py-8 w-full">
                                                <div className="flex gap-2.5 justify-between">
                                                    <div className="text-lg font-semibold text-slate-950">
                                                        Contact Info
                                                    </div>
                                                    <div className="justify-center px-2 py-1 text-xs font-medium text-center text-green-700 bg-green-100 rounded-lg">
                                                        Loan Officer{" "}
                                                    </div>
                                                </div>
                                                <div className="flex gap-3 mt-8 text-sm whitespace-nowrap text-zinc-800">
                                                    <img
                                                        loading="lazy"
                                                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/7943fad6252234986b425f1e8e91b35c33f9376267d37c6afe9a51e7f22c7867?"
                                                        className="shrink-0 self-start w-4 aspect-[1.06]"
                                                    />
                                                    <div>{contactDetails?.email}</div>
                                                </div>
                                                <div className="flex gap-3 mt-8 text-sm whitespace-nowrap text-zinc-800">
                                                    <img
                                                        loading="lazy"
                                                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/d1198af68cfdc9f333e3f871a105a82100794b11eb13c9390d8bd7ca3d052e3f?"
                                                        className="shrink-0 self-start w-4 aspect-square"
                                                    />
                                                    <div>{contactDetails?.mobilePhone}</div>
                                                </div>
                                                <div className="flex gap-3.5 mt-8 text-sm text-zinc-800">
                                                    <img
                                                        loading="lazy"
                                                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/473cae68a6a4b384754156cd3dac06a2f295a59ec3a55b6656910f3dd3530be4?"
                                                        className="shrink-0 self-start w-3.5 aspect-[0.83]"
                                                    />
                                                    <div className="flex flex-col">
                                                        <div>{contactDetails?.address}</div>
                                                        <div className="mt-1.5"></div>
                                                    </div>
                                                </div>
                                                <div onClick={closeModal} className="cursor-pointer justify-center self-end px-4 py-1.5 mt-8 text-xs font-medium leading-5 whitespace-nowrap rounded-md border border-solid border-black border-opacity-20 text-zinc-800">
                                                    Close
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>


                </Dialog>
            </Transition>
        </>
    )
}
