import { getCampaignChartDetailsFail, getCampaignChartDetailsRequest, getCampaignChartDetailsSuccess, getCampaignListFail, getCampaignListRequest, getCampaignListSuccess, getLatestLeadListFail, getLatestLeadListRequest, getLatestLeadListSuccess, getLatestTicketListFail, getLatestTicketListRequest, getLatestTicketListSuccess, getLeadChartDetailsFail, getLeadChartDetailsRequest, getLeadChartDetailsSuccess, getMarkettingFormsChartDetailsFail, getMarkettingFormsChartDetailsRequest, getMarkettingFormsChartDetailsSuccess, getTicketChartDetailsFail, getTicketChartDetailsRequest, getTicketChartDetailsSuccess, getTopChartDetailsFail, getTopChartDetailsRequest, getTopChartDetailsSuccess } from "../redux/slices/dashboardSlice";
import { getAllNotificationsFail, getAllNotificationsRequest, getAllNotificationsSuccess } from "../redux/slices/notificationSlice";
import { getLeadsCallLogStatusFail, getLeadsCallLogStatusRequest, getLeadsCallLogStatusSuccess } from "../redux/slices/salesSlice";
import { getTicketCallLogListStatusRequest, getTicketCallLogListStatusSuccess } from "../redux/slices/ticketSlice";
import axiosConfig from "../www/http"

export const getTopChartDetails = () => async(dispatch) => {
    try {
        dispatch(getTopChartDetailsRequest())
        const { data } = await axiosConfig.get(`/dashboard/top-chart`);
        dispatch(getTopChartDetailsSuccess(data))
    } catch (error) {
        dispatch(getTopChartDetailsFail())
    }
}
export const getLeadChartDetails = (fromPeriod,toPeriod,type) => async(dispatch) => {
    try {
        dispatch(getLeadChartDetailsRequest())
        const { data } = await axiosConfig.get(`/dashboard/lead-chart?fromPeriod=${fromPeriod}&toPeriod=${toPeriod}&type=${type}`);
        dispatch(getLeadChartDetailsSuccess(data))
    } catch (error) {
        dispatch(getLeadChartDetailsFail())
    }
}
export const getLatestLeadsList = (fromPeriod,toPeriod) => async(dispatch) => {
    try {
        dispatch(getLatestLeadListRequest())
        const { data } = await axiosConfig.get(`/lead/get-all-leads?fromPeriod=${fromPeriod}&page=0&pageSize=10&toPeriod=${toPeriod}`);
        dispatch(getLatestLeadListSuccess(data?.data))
    } catch (error) {
        dispatch(getLatestLeadListFail())
    }
}
export const getTicketCharts = (fromPeriod,toPeriod) => async(dispatch) => {
    try {
        dispatch(getTicketChartDetailsRequest())
        const { data } = await axiosConfig.get(`/dashboard/ticket-chart?fromPeriod=${fromPeriod}&toPeriod=${toPeriod}`);
        dispatch(getTicketChartDetailsSuccess(data))
    } catch (error) {
        dispatch(getTicketChartDetailsFail())
    }
}
export const getTicketLists = (fromPeriod,toPeriod) => async(dispatch) => {
    try {
        dispatch(getLatestTicketListRequest())
        const { data } = await axiosConfig.get(`/tickets?page=0&pageSize=10&fromPeriod=${fromPeriod}&toPeriod=${toPeriod}`);
        dispatch(getLatestTicketListSuccess(data?.data?.lists))
    } catch (error) {
        dispatch(getLatestTicketListFail())
    }
}
export const getCampaignLists = () => async(dispatch) => {
    try {
        dispatch(getCampaignListRequest())
        const { data } = await axiosConfig.get(`/get-list-campaign?page=0&pageSize=7`);
        dispatch(getCampaignListSuccess(data?.data))
    } catch (error) {
        dispatch(getCampaignListFail())
    }
}
export const getCampaignCharts = () => async(dispatch) => {
    try {
        dispatch(getCampaignChartDetailsRequest())
        const { data } = await axiosConfig.get(`/dashboard/campaign-chart`);
        dispatch(getCampaignChartDetailsSuccess(data?.campaignChart))
    } catch (error) {
        dispatch(getCampaignChartDetailsFail())
    }
}
export const getMarkettingCharts = (fromDate,toDate) => async(dispatch) => {
    try {
        dispatch(getMarkettingFormsChartDetailsRequest())
        const { data } = await axiosConfig.get(`/dashboard/marketing-chart?fromPeriod=${fromDate}&toPeriod=${toDate}`);
        dispatch(getMarkettingFormsChartDetailsSuccess(data))
    } catch (error) {
        dispatch(getMarkettingFormsChartDetailsFail())
    }
}

/* Notification Module */
export const getAllNotifications = (user_id) => async (dispatch) => {
    try {
        dispatch(getAllNotificationsRequest())
        const { data } = await axiosConfig.get(`/notifications?page=0&pageSize=10000&user=${user_id}`);
        dispatch(getAllNotificationsSuccess(data?.notifications))
    } catch (error) {
        dispatch(getAllNotificationsFail())
    }
}


/*Ticket Call log details */
export const getCallLogStatuses = (ticket_id) =>async(dispatch)=> {
    try {
        dispatch(getTicketCallLogListStatusRequest())
        const { data } = await axiosConfig.get(`/ticket-call-log-status?ticketId=${ticket_id}`);
        dispatch(getTicketCallLogListStatusSuccess(data?.statusChart))
    } catch (error) {
        
    }
}
/*Lead Call log details */
export const getLeadCallLogStatuses = (lead_id) =>async(dispatch)=> {
    try {
        dispatch(getLeadsCallLogStatusRequest())
        const { data } = await axiosConfig.get(`/lead/lead-call-log-status?leadId=${lead_id}`);
        dispatch(getLeadsCallLogStatusSuccess(data?.statusChart))
    } catch (error) {
        dispatch(getLeadsCallLogStatusFail())
    }
}