import React, { useEffect } from 'react'
import { useFormik } from 'formik';
import axiosConfig from '../../../www/http';
import Files from 'react-files'
import { GrFormUpload } from "react-icons/gr";
import { notifyErrorMessage, notifySuccessMessage } from '../../../components/utils/toastMessage';
import { SaveContactValidation } from '../../../Validation/saveContactSchema';
import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useState } from 'react'
import { useDispatch } from 'react-redux';
import { getSalesContactDetails } from '../../../apis/apiService';


export const ContactEditModal = ({ isOpen, closeModal, data }) => {

    // console.log("ISUPON....................", data);

    const dispatch = useDispatch()

    const TYPES = [
        { id: "", label: "Select" },
        { id: 1, label: "Quation" },
        { id: 2, label: "Incident" },
        { id: 3, label: "Problem" },
    ]

    const handleSelectType = (id) => {

        // console.log("ID.....................", id);

        const filterdata = TYPES.find(v => v?.id == id)
        if (filterdata?.id) {
            setFieldValue("type", filterdata?.label)
        }

    }


    const initialValues = {
        type: data?.type,
        name: data?.name,
        email: data?.email,
        mobilePhone: data?.mobilePhone,
        account: data?.account,
        address: data?.address,
        branch_id: "eff26b52-6d88-48f6-b94e-45d148ea5101",
        profileImage: "",
        isUpload: false
    }



    const { values, handleBlur, handleChange, handleSubmit, errors, touched, setFieldValue, resetForm } = useFormik({
        initialValues: initialValues,
        validationSchema: SaveContactValidation,
        onSubmit: async (values) => {



            const formdata = new FormData()
            formdata.append("account", values?.account)
            formdata.append("address", values?.address)
            formdata.append("branch_id", values?.branch_id)
            formdata.append("email", values?.email)
            formdata.append("mobilePhone", values?.mobilePhone)
            formdata.append("name", values?.name)
            formdata.append("type", values?.type)
            formdata.append("contactId", data?.contactId)
            {
                values?.isUpload &&
                    formdata.append("profileImage", values?.profileImage)
            }

            // console.log("Values...........................", values, errors);
            try {
                const { data } = await axiosConfig.put(`/edit-contact`, formdata, {
                    headers: {
                        "X-V-I": `${1}`
                    }
                })
                if (data?.status === "SUCCESS") {
                    notifySuccessMessage(data?.message)
                    dispatch(getSalesContactDetails(data?.contactId))
                    closeModal()
                    resetForm()
                }
                // console.log("Successsssssss..............", data);

            } catch (error) {
                notifyErrorMessage(error.message)
                console.log("Faileddddddddddddddddd", error);
            }


        }
    })




    const handleUpload = (files) => {
        // console.log(files)
        // console.log("----------------URL-------------", files[0]?.preview?.url)
        setFieldValue("profileImage", files[0])
        setFieldValue("isUpload", true)
    }

    const handleError = (error, file) => {

        notifyErrorMessage(error?.message)
    }

    useEffect(() => {
        resetForm()
    }, [])

    // console.log("values?.isUpload", values?.isUpload);
    // console.log("erroes?.isUpload", errors?.isUpload);
    console.log("-----------------", values?.profileImage?.preview?.url, data?.profileImage);

    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={() => { }}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black/25" />
                </Transition.Child>



                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="w-[420px]  transform overflow-hidden rounded-2xl bg-white  text-left align-middle shadow-xl transition-all">


                                {/* ---------------------------------------------------------------- */}

                                <div className="flex flex-col pt-6 bg-white rounded max-w-[438px]">
                                    <div className="px-4 text-lg font-bold text-slate-950">
                                        Edit Contact
                                    </div>
                                    <div className=" px-8 py-4 mt-6 w-full bg-purple-600 border-b-0 border-solid border-slate-950" style={{ backgroundColor: "#9050D0" }}>
                                        <div className="flex gap-5 max-md:flex-col max-md:gap-0">
                                            <div className={`flex flex-col w-[24%] max-md:ml-0 max-md:w-full ${errors?.isUpload && "border-2 border-red-700 rounded-full"}`}>

                                                <Files
                                                    className='files-dropzone rounded-full'
                                                    onChange={handleUpload}
                                                    onError={handleError}
                                                    accepts={['image/png']}
                                                    multiple
                                                    maxFileSize={2097152}
                                                    minFileSize={0}
                                                    clickable>
                                                    <img src={values?.profileImage?.preview?.url || data?.profileImage} loading='lazy' className="flex justify-center items-center  bg-white bg-opacity-20 h-[70px] rounded-full w-[70px] object-cover" />

                                                </Files>
                                            </div>
                                            <div className="flex flex-col ml-5 w-[76%] max-md:ml-0 max-md:w-full">
                                                <div className="flex flex-col self-stretch my-auto font-semibold text-white">
                                                    <div className="text-sm capitalize">Upload profile image</div>
                                                    <div className="mt-1 text-xs">
                                                        Max: 2 MB, Supported: png, jpg, webp
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex flex-col p-8 w-full font-medium">
                                        <div className="text-sm leading-5 text-zinc-800">Type</div>
                                        <div className="flex gap-2.5 justify-center px-3 py-2 mt-1 text-sm whitespace-nowrap rounded border border-solid border-black border-opacity-20 text-zinc-800">
                                            <select className='w-full outline-none' onChange={(e) => handleSelectType(e.target.value)}>

                                                {
                                                    TYPES?.map((v, i) => (
                                                        <option className='p-3' key={i} value={v?.id}>{v?.label}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>

                                        {
                                            errors?.type && touched?.type &&
                                            <p className='text-red-700 mt-2'>{errors?.type}</p>
                                        }


                                        <div className="mt-3 text-sm leading-5 text-black">
                                            Name <span className="text-orange-600">*</span>
                                        </div>
                                        <input value={values?.name} onChange={handleChange("name")} className="px-2 outline-none shrink-0 mt-1 h-8 rounded border border-solid border-black border-opacity-20" />

                                        {
                                            errors?.name && touched?.name &&
                                            <p className='text-red-700 mt-2'>{errors?.name}</p>
                                        }


                                        <div className="mt-3 text-sm leading-5 text-black">
                                            Email <span className="text-orange-600">*</span>
                                        </div>
                                        <input value={values?.email} onChange={handleChange("email")} className="px-2 shrink-0 mt-1 h-8 rounded border border-solid border-black border-opacity-20" />

                                        {
                                            errors?.email && touched?.email &&
                                            <p className='text-red-700 mt-2'>{errors?.email}</p>
                                        }


                                        <div className="mt-3 text-sm leading-5 text-black">
                                            Phone <span className="text-orange-600">*</span>
                                        </div>
                                        <input type='number' value={values?.mobilePhone} onChange={handleChange("mobilePhone")} className="px-2 shrink-0 mt-1 h-8 rounded border border-solid border-black border-opacity-20" />

                                        {
                                            errors?.mobilePhone && touched?.mobilePhone &&
                                            <p className='text-red-700 mt-2'>{errors?.mobilePhone}</p>
                                        }

                                        <div className="mt-3 text-sm leading-5 text-black">
                                            Account No <span className="text-orange-600">*</span>
                                        </div>
                                        <input type='number' value={values?.account} onChange={handleChange("account")} className="px-2 shrink-0 mt-1 h-8 rounded border border-solid border-black border-opacity-20" />

                                        {
                                            errors?.account && touched?.account &&
                                            <p className='text-red-700 mt-2'>{errors?.account}</p>
                                        }

                                        <div className="mt-3 text-sm leading-5 text-black">
                                            Address <span className="text-orange-600">*</span>
                                        </div>
                                        <input value={values?.address} onChange={handleChange("address")} className="px-2 shrink-0 mt-1 h-8 rounded border border-solid border-black border-opacity-20" />

                                        {
                                            errors?.address && touched?.address &&
                                            <p className='text-red-700 mt-2'>{errors?.address}</p>
                                        }

                                        <div className="flex gap-2.5  mt-6 whitespace-nowrap justify-end">
                                            <button onClick={closeModal} className="cursor-pointer justify-center px-4 py-1.5 text-xs leading-5 rounded-md border border-solid border-black border-opacity-20 text-zinc-800">
                                                Cancel
                                            </button>
                                            <div onClick={handleSubmit} className="cursor-pointer justify-center px-4 py-1.5 text-sm leading-5 text-white rounded-md bg-blue-700">
                                                Save
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>


            </Dialog>
        </Transition>
    )
}
